import { DetailsList, IColumn, ScrollablePane, SelectionMode } from "@fluentui/react";
import React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IJobResult } from "./DetailsListJobHistory";
import { IJobHistoryPanelData } from "./JobHistoryPanel";

export interface IJobHistoryServerDetailsListProps {
    serverDetailsData: IJobResult[];
}

export const ServerDetailsList: React.FunctionComponent<IJobHistoryServerDetailsListProps> = (props: IJobHistoryServerDetailsListProps)=> {
    const [dataGridData, SetDataGridData] = React.useState<IJobResult[]>(props.serverDetailsData);

    // column sorting
    const [columnIsSortedDescending, SetColumnIsSortedDescending] = React.useState<boolean>(false);
    const [sortedColumn, SetSortedColumn] = React.useState<string>("Title");
    const [descendingColumn, SetDescendingColumn] = React.useState<string>("Title");

    React.useEffect(() => {
        SetDataGridData(props.serverDetailsData);
    }, [props])

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            const initialSorting: boolean = !currColumn.isSortedDescending;
            SetDescendingColumn(column.key);
            SetColumnIsSortedDescending(!initialSorting);
            SetSortedColumn(column.key);
          }
        });

        const newItems: IJobResult[] = copyAndSort(dataGridData, currColumn.key!, isSortedDescending(column.key));

        SetDataGridData(newItems);
    }

    const copyAndSort = (items: IJobResult[], columnKey: string, isSortedDescending?: boolean): IJobResult[] => {
        const key = columnKey as keyof IJobResult;

        return items.slice(0).sort((a: IJobResult, b: IJobResult) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
    
    const isSortedDescending = (column: string): boolean => {
        if (column === descendingColumn) {
            return columnIsSortedDescending;
        } else {
            return true;
        }
    }

    const isSorted = (column: string): boolean => {
        return column === sortedColumn;
    }
   
    const columns: IColumn[] = [
        {
            key: "title",
            name: "Title",
            fieldName: "Title",
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("title") ? false : true,
            isSorted: isSorted("title"),
        },
        {
            key: "installationStatus",
            name: "Status",
            fieldName: "InstallationStatus",
            minWidth: 70,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("installationStatus") ? false : true,
            isSorted: isSorted("installationStatus"),
        },
        // {
        //     key: "timeGenerated",
        //     name: "Date",
        //     fieldName: "TimeGenerated",
        //     minWidth: 70,
        //     maxWidth: 250,
        //     isResizable: true,
        // },
    ];

    const RenderItemColumn= (item?: IJobHistoryPanelData, index?: number, column?: IColumn) => {
        if (item !== undefined && column !== undefined) {
            switch (column.fieldName) {
                default:
                    return <span>{item[column.key as keyof IJobHistoryPanelData] as string}</span>;
            }
        }
    }

    return (
        <>
            <ScrollablePane style={{ height: "55vh", position: "relative" }}>
                <DetailsList
                    columns={columns}
                    items={dataGridData}
                    compact={true}
                    onRenderDetailsHeader={RenderStickyHeader}
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={RenderItemColumn}
                />
            </ScrollablePane>
        </>
    );
}