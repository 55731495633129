import { Depths, ScrollablePane, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackPageView } from "../components/AppInsights/LoggingHelper";
import { DetailsListJobHistory, IDetailsListJobHistoryProps } from "../components/JobHistory/DetailsListJobHistory";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { UserPermission } from "../services/AuthenticationService/AuthReducer";
import { DataActionEnum } from "../services/DataContext/DataActions";
import { breakWord, _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { PAGE } from "../App";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";

const pageTitle: string = "Job History";

export const JobHistoryPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const { state, dispatch } = React.useContext(RootContext); // auth token context

    const appInsights = useAppInsights();

    React.useEffect(() => {
        document.title = pageTitle;

        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "jobhistory"
        });

        const telemetry = buildPageViewTelemetry(
            pageTitle,
            "/jobhistory",
            "/jobhistory",
            state.AuthStore.Email,
            state.AuthStore.isAdmin,
        );

        trackPageView(appInsights, telemetry);
    }, []);

    const deploymentProps: IDetailsListJobHistoryProps = {
        appInsights: appInsights,
    }

    return (
        <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
            <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                <AdminMessageBanner page={PAGE.JOBHISTORY} />
            </div>
            <div className={_Styles.rootDiv}>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Permitted || state.AuthStore.UserTimeOut}>
                    {/* Load DetailsList after data is loaded */}
                    <h1 id="jobhistorytitle" className={breakWord}>
                        {ReactHtmlParser(pageTitle)}
                    </h1>
                    {state.AuthStore.Token !== "" ? (
                        <DetailsListJobHistory {...deploymentProps} />
                    ) : (
                        <div style={{
                            position: "absolute", left: "50%", top: "40%",
                            transform: "translate(-50%, -50%)"}}>
                            <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                        </div>
                    )}
                </div>
                <PagePermissionsComponent />
                <BackToTopButton />
            </div>
        </ScrollablePane >
    );
};
