import { Announced, DetailsListLayoutMode, Dropdown, IColumn, IconButton,
    IDetailsList, IDropdownStyles, IStackTokens, mergeStyles,
    ScrollablePane, Selection, SelectionMode, ShimmeredDetailsList,
    Stack, TooltipHost } from "@fluentui/react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ITextFieldStyles, TextField } from "@fluentui/react/lib/TextField";
import { Guid } from "guid-typescript";
import * as React from "react";
import { IServerType, IServiceDataType } from "../../App";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { timeAgoFunction } from "../../Helpers/Utils";
import { IPanelOptions } from "../../Pages/ManageJobsPage";
import { ServerPanelType } from "../../Pages/ManageServersPage";
import HttpService from "../../services/HttpService/HttpService";
import { TimeZone } from "../Layout/Header";
import { IDetailsListManageJobsItem } from "../ManageJobs/DetailsListManageJobs";
import { RootContext } from "../Stores/RootStore";
import { IManageServerPanelProps, ManageServerPanel } from "./ManageServerPanel";
import { IPatchNowPanelProps, PatchNowPanel } from "./PatchNowPanel";
import { OnboardedServicesDropdown } from "../Shared/OnboardedServicesDropdown";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { CSVLink } from "react-csv";

// Styles //

const textFieldStyles: Partial<ITextFieldStyles> = { 
    root: { maxWidth: "300px", minWidth: "150px" } 
};

const dropdownStyles: Partial<IDropdownStyles> = { 
    dropdown: { maxWidth: "300px", minWidth: "100px" } 
};

const stackTokens: IStackTokens = {
    childrenGap: 0 + " " + 0, // vertical gap + ' ' + horizontal gap
    padding: `0px 0px 0px 0px`, // padding: right, left, top, bottom 
};

const mainDivStyles = mergeStyles({
    display: "block",
    margin: "auto",
    overflow: "auto"
});

const stackItemsPadding = mergeStyles({
    padding: "0px 20px 0px 0px"
});

const calloutProps = { gapSpace: 0 };

export enum PatchComplianceType {
    NotCompliant,
    Compliant,
    NotScanned
}

// Interfaces //

export interface IJobInfo {
    jobId: Guid;
    jobName: string;
}

export interface IJobData {
    jobId: Guid;
    jobName: string;
    serverId: number;
    serverName: string;
    serviceName: string;
    serviceGUID: Guid;
    dayOfTheWeek: string;
    weekOfTheMonth: number;
    startTimeHours: number;
    startTimeMinutes: number;
    duration: string;
    recurring: boolean;
    reboot: boolean;
    allServersSelected: boolean;
    lastScheduledDateTime: Date;
    nextScheduledDateTime: Date;
}

export interface IManageServersData {
    serverName: string;
    serverId: number;
    serviceName: string;
    serviceTreeGUID: Guid;
    serverLastSeen: Date;
    jobCount: number;   
    lastScheduledDateTime: Date;
    nextScheduledDateTime: Date;
    patchCompliance: PatchComplianceType;
    vulnerabilityCount: number;
    oSType: string;
}

interface IManageServersExportData {
    serverName: string;
    serverId: number;
    serviceTreeGUID: Guid;
    serverLastSeen: Date;
    jobName: string; 
    lastScheduledDateTime: Date;
    nextScheduledDateTime: Date;
    patchCompliance: PatchComplianceType;
    vulnerabilityCount: number;
    oSType: string;
}

interface IManageServersState {
    serverJobMap: Map<number, Set<IDetailsListManageJobsItem>>;
    allServerData: IManageServersData[];
    serverData: IManageServersData[];
    columns: IColumn[];
    showItemIndexInView: boolean;
    selectedServers: IManageServersData[];
    selectionDetails: string;
    selectedServiceGUID: string;
    selectedServiceName: string;
    serverFilteredText: string;
    serversLoaded: boolean;
    panelOptions: IPanelOptions;
    serviceServerOptions: IServerType[];
    dataToExport: IManageServersExportData[];
    selectedColumns: string[]; 
    complianceSelection: number[]; 
    filtersHidden: boolean;
    oSSelection: string[];
    announcedItems: JSX.Element | undefined;
    announcedFiltersExpand: JSX.Element | undefined;
}

export interface IManageServersProps {
    refreshData: number;
    timezone: TimeZone | undefined;
    panelOptions: IPanelOptions;
    appInsights: ApplicationInsights | undefined;
}

// Consts //

const exportCsvFileName = "serversExport";

export class DetailsListManageServers extends React.Component<IManageServersProps, IManageServersState> {
    public static contextType: React.Context<any> = RootContext;
    private _root = React.createRef<IDetailsList>();
    private _selection: Selection;
    private _columns: IColumn[];
    private _columnsMap: Map<string, IColumn> = new Map();
    private _columnsShownMap: Map<string, boolean> = new Map();
    private _columnChooseDropdownOptions: IDropdownOption[] = [];
    private _panelOptions: IPanelOptions;
    private _timeZoneHours: number;
    private _timeZoneDisplay: string; 
    private _complianceOptions: IDropdownOption[] = []; 
    private _oSOptions: IDropdownOption[] = [];

    constructor(props: IManageServersProps) {
        super(props); 

        let i=0;
        for (const p of Object.keys(PatchComplianceType).filter((v) => isNaN(Number(v)))) {
            this._complianceOptions.push({
                key: i++,
                text: p,
            });
        }

        this._oSOptions = [
            { key: "Windows", text: "Windows" },
            { key: "Linux", text: "Linux" },
        ];
         
        this._selection = new Selection({
            onSelectionChanged: () => this.setState({ selectionDetails: this._getSelectionDetails() }),
        });

        const currentDate = new Date();

        this._timeZoneHours = (this.props.timezone?.standardName !== undefined && this.props.timezone?.standardName !== null) 
                                ? this.props.timezone?.offset! / 60 
                                : - currentDate.getTimezoneOffset() / 60;

        this._timeZoneDisplay = this._timeZoneHours > 0 ? "+" + this._timeZoneHours.toString() : this._timeZoneHours.toString();

        this._columns = [
            { key: "serverName", name: "Server Name", fieldName: "serverName", minWidth: 50, maxWidth: 125, isResizable: true, isSorted: true, onColumnClick: this._onColumnClick },
            { key: "jobCount", name: "Jobs", fieldName: "jobCount", minWidth: 35, maxWidth: 70, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "jobsDetails", name: "Jobs Details", fieldName: "jobsDetails", minWidth: 50, isResizable: true, maxWidth: 100 },
            { key: "serverLastSeen", name: "Last Seen", fieldName: "serverLastSeen", minWidth: 50, maxWidth: 110, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "lastScheduledDateTime", name: "Last Patching Job (UTC" + this._timeZoneDisplay + ")", fieldName: "lastScheduledDateTime", minWidth: 75, maxWidth: 170, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "nextScheduledDateTime", name: "Next Patching Job (UTC" + this._timeZoneDisplay + ")", fieldName: "nextScheduledDateTime", minWidth: 75, maxWidth: 170, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "patchCompliance", name: "Compliance", fieldName: "patchCompliance", minWidth: 50, maxWidth: 150, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "vulnerabilityCount", name: "Vulnerabilites", fieldName: "vulnerabilityCount", minWidth: 50, maxWidth: 150, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "oSType", name: "OS", fieldName: "oSType", minWidth: 50, maxWidth: 150, isResizable: true, onColumnClick: this._onColumnClick },
        ];

        this._columns.forEach((col) => {
            if (col.fieldName !== undefined) {
                this._columnsMap.set(col.fieldName, col);
                this._columnChooseDropdownOptions.push({
                    key: col.fieldName,
                    text: col.name,
                });
                this._columnsShownMap.set(col.fieldName, true);
            }
        });

        this._panelOptions = {
            daysOptions: this.props.panelOptions.daysOptions,
            durationOptions: this.props.panelOptions.durationOptions,
            weekOfMonthOptions: this.props.panelOptions.weekOfMonthOptions,
            serverOptions: this.props.panelOptions.serverOptions,
        };

        this.state = {
            serverJobMap: new Map(),
            serverData: [],
            allServerData: [],
            showItemIndexInView: false,
            selectedServers: [],
            selectionDetails: "",
            selectedServiceGUID: "",
            selectedServiceName: "",
            serverFilteredText: "",
            serversLoaded: true,
            columns: this._columns,
            panelOptions: this._panelOptions,
            serviceServerOptions: [],
            dataToExport: [],
            selectedColumns: Array.from(this._columnsMap.keys()),
             complianceSelection: [], 
            filtersHidden: true,
            oSSelection: [],
            announcedItems: undefined,
            announcedFiltersExpand: undefined,
        };

        this.refreshDataGrid = this.refreshDataGrid.bind(this);
    }

    public componentDidMount() {}

    public componentDidUpdate(prevProps: any, prevState: any) {
        const {   complianceSelection, 
             serverFilteredText, oSSelection } = this.state;

        // if any of the filters change
        if (prevState.complianceSelection != complianceSelection
            || prevState.oSSelection != oSSelection
            || prevState.serverFilteredText != serverFilteredText)  {
            this.applyFilters();

            this._announceNumberOfServers(this.state.serverData.length);
        }
    }

    public delay(ms: number) {
        return new Promise( (resolve) => setTimeout(resolve, ms) );
    }

    public async refreshDataGrid() {
        this.fetchServerData(this.state.selectedServiceGUID, false);
    }

    public render(): JSX.Element {
        const { serverData, selectedServiceGUID, serverFilteredText, serversLoaded, columns, filtersHidden,
            dataToExport, selectedColumns, complianceSelection, 
            oSSelection, selectedServers, announcedItems, announcedFiltersExpand } = this.state;

        const patchNowProps: IPatchNowPanelProps = {
            refreshDataGrid: () => this.refreshDataGrid,
            serversToBePatched: selectedServers,
        };

        return (
            <div className={mainDivStyles}>
                <Stack wrap horizontal grow tokens={stackTokens}>
                    <Stack.Item align="start" className={stackItemsPadding}> 
                        <OnboardedServicesDropdown
                            id={"serviceDropdown"}
                            serviceTreeGuidFromParam={selectedServiceGUID}
                            onSelectService={this._onSelectService}
                        />
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>
                        <IconButton
                            id={"filterButton"}
                            key={"showFilters"}
                            label={"filters " + (filtersHidden ? "hidden" : "shown")}
                            ariaLabel={"filters " + (filtersHidden ? "hidden" : "shown")}
                            aria-live={"polite"}
                            iconProps={{
                                iconName: ("Filter"),
                            }}
                            role={"button"}
                            onClick={this._toggleFilterCollapsible}
                        />
                        {announcedFiltersExpand}
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>
                        <PatchNowPanel {...patchNowProps} />
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>
                        <TooltipHost
                                content={"Refresh Server Data"}
                                calloutProps={calloutProps}
                            >
                            <IconButton 
                                disabled={!serversLoaded}
                                key={"Refresh"}
                                label={"Refresh"}
                                ariaLabel={"Refresh"}
                                iconProps={{
                                    iconName: ("Refresh"),
                                }}
                                role={"button"}
                                onClick={() => this.fetchServerData(selectedServiceGUID, true)}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>                       
                        <CSVLink 
                            aria-label={"Export Data"} 
                            filename={exportCsvFileName} 
                            title={"Export Data"} 
                            data={dataToExport}>
                                Export Data
                        </CSVLink>
                    </Stack.Item>
                </Stack>
                <hr hidden={selectedServiceGUID === ""} />
                    <Stack wrap horizontal grow tokens={stackTokens}>
                        <Stack wrap horizontal grow tokens={stackTokens}>
                            <Stack.Item align="start" hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                                <TextField
                                    id="serverNameFilterField"
                                    label="Filter by Server Name"
                                    ariaLabel="TextField Filter by Server Name"
                                    onChange={this._onFilterByServer}
                                    value={serverFilteredText}
                                    styles={textFieldStyles}
                                    placeholder={"Server Name"}
                                />
                            </Stack.Item>
                             
                            <Stack.Item align="end" hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                                <Dropdown
                                    id="complianceDropdown"
                                    label="Compliance"
                                    onChange={this._onFilterCompliance}
                                    styles={dropdownStyles}
                                    options={this._complianceOptions}
                                    ariaLabel="Compliance Dropdown"
                                    selectedKeys={complianceSelection}
                                    multiSelect={true}
                                />
                            </Stack.Item> 
                            <Stack.Item align="end" hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                                <Dropdown
                                    id="OSDropdown"
                                    label="Operating System"
                                    onChange={this._onFilterOS}
                                    styles={dropdownStyles}
                                    options={this._oSOptions}
                                    ariaLabel="Operating System"
                                    selectedKeys={oSSelection}
                                    multiSelect={true}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack wrap horizontal grow horizontalAlign="end" tokens={stackTokens}>
                            <Stack.Item hidden={selectedServiceGUID === "" || filtersHidden} align="end" className={stackItemsPadding}>
                                <Dropdown
                                    id={"columnOptionsDropdown"}
                                    placeholder="Column Options"
                                    ariaLabel="Column Options"
                                    label="Column Options"
                                    selectedKeys={selectedColumns}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onChange={this._updateColumns.bind(this)}
                                    multiSelect
                                    options={this._columnChooseDropdownOptions}
                                    styles={dropdownStyles}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                <div id="manageServersDetailsList" hidden={selectedServiceGUID === ""}>
                    {announcedItems}
                    <ScrollablePane style={{ height: "65vh", position: "relative" }}>
                        <ShimmeredDetailsList
                            items={serverData}
                            columns={columns}
                            onRenderItemColumn={this._renderItemColumn.bind(this)}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selection={this._selection}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForGrid="Server Table"
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="Row checkbox"
                            onItemInvoked={this._onItemInvoked}
                            componentRef={this._root}
                            selectionMode={SelectionMode.multiple}
                            compact={true}
                            enableShimmer={!serversLoaded}
                            onRenderDetailsHeader={RenderStickyHeader}
                        />
                    </ScrollablePane>
                </div>
            </div>
        );
    }

    private _announceNumberOfServers = (items: number) => {
        this.setState({
            announcedItems: <Announced
                id={"serverCountAnnouncement"}
                message={"Number of Servers: " + items}
            />}
        );
    }

    private _announceFiltersExpand = (expanded: boolean) => {
        this.setState({
            announcedFiltersExpand: <Announced
                id={"filtersExpandAnnouncement"}
                message={"Filters" + expanded ? " Expanded" : " Collapsed"}
            />}
        );
    }

    // Get Selected Servers as a string
    private _getSelectionDetails(): string {
        const selectionCount: number = this._selection.getSelectedCount();
        const values: string[] = [];
        const selectedServers: IManageServersData[] = [];

        for (let i = 0; i < this._selection.getSelectedCount(); i++) {
            if ((this._selection.getSelection()[i] as IManageServersData)?.serverName !== undefined) {
                selectedServers.push(this._selection.getSelection()[i] as IManageServersData);
                values.push((this._selection.getSelection()[i] as IManageServersData)?.serverName);
            }
        }

        const allSelectedServers: string = values.join(", ");

        selectedServers.forEach((x: IManageServersData) => x.serviceName = this.state.selectedServiceName);

        this.setState({
            selectedServers: selectedServers,
        });

        switch (true) {
            case selectionCount === 0:
            return "No servers selected";
            case selectionCount < 5:
            return "Selected: " + allSelectedServers;
            default:
            return "Selected: " + `${selectionCount} servers selected`;
        }
    }

    private manageServerData(serviceTreeGUID: string, hardRefresh: boolean): Promise<any> {
        return HttpService(this.props.appInsights, this.context.state)
            .get({
                url: "api/ManageServers/manageServerData",
                token: this.context.state.AuthStore.Token,
                params: {
                    serviceTreeGUID: serviceTreeGUID,
                    timezone: this.context.state.AuthStore.timezone?.standardName,
                    hardRefresh: hardRefresh
                },
            });
    }

    private fetchServerData(selectedServiceGUID: string, hardRefresh: boolean) {
        this.setState({
            serversLoaded: false,
            serverJobMap: new Map(),
            serverData: [],
            allServerData: [],
            serviceServerOptions: [],
            dataToExport: []
        });

        const serverOptions: IServerType[] = [];
        const newServerData: IManageServersData[] = [];
        const exportData: IManageServersExportData[] = [];
        const patchingServerJobData: Map<number, Set<IDetailsListManageJobsItem>> = new Map();

        this.manageServerData(selectedServiceGUID, hardRefresh).then((resp: any) => {
            const data: any = resp?.data;

            const manageServerResponseData = data?.ManageServerData;
            const patchingJobResponseData = data?.PatchingJobData;

            for (const server of manageServerResponseData) {
                if (server) {
                    newServerData.push({
                        serverName: server.Name,
                        serverId: server.ServerId,
                        serviceName: server.ServiceName,
                        serviceTreeGUID: Guid.parse(server.ServiceTreeGUID),
                        serverLastSeen: server.ServerId % 2 == 0 ? new Date((server.AgentLastSeenDateTime.toString())) : new Date(),
                        jobCount: server.JobCount, 
                        lastScheduledDateTime: new Date((server.LastScheduledDateTime.toString())),
                        nextScheduledDateTime: new Date((server.NextScheduledDateTime.toString())), 
                        patchCompliance: server.PatchCompliance,
                        vulnerabilityCount: server.VulnerabilityCount,
                        oSType: server.OSType,
                    });

                    serverOptions.push({
                        ServerId: server.ServerId,
                        ServerName: server.Name,
                        ServiceName: server.ServiceName,
                        ServiceTreeGUID: server.ServiceTreeGUID,
                        AgentLastSeenDateTime: new Date(server.AgentLastSeenDateTime), 
                        LastScheduledDateTime: new Date((server.LastScheduledDateTime.toString())),
                        NextScheduledDateTime: new Date((server.NextScheduledDateTime.toString())),
                        PatchCompliance: server.PatchCompliance,
                        VulnerabilityCount: server.PatchCompliance === 2 ? -1 : server.VulnerabilityCount,
                        OSType: server.OSType
                    });
                    
                    if (patchingJobResponseData[server.ServerId] !== undefined) {
                        for (const job of patchingJobResponseData[server.ServerId]) {
                            if (job) {
                                const jobResponse: IDetailsListManageJobsItem = {
                                    eTag: job.ETag,
                                    jobId: job.JobId as Guid,
                                    jobName: job.JobName,
                                    dayOfTheWeek: job.DayOfTheWeek,
                                    weekOfTheMonth: job.WeekOfTheMonth,
                                    startTimeHours: job.StartTimeHours ?? 0,
                                    startTimeMinutes: job.StartTimeMinutes ?? 0,
                                    duration: job.Duration,
                                    recurring: Boolean(job.Recurring),
                                    reboot: Boolean(job.Reboot),
                                    alwaysReboot: Boolean(job.AlwaysReboot),
                                    serviceName: job.ServiceName,
                                    serviceTreeGUID: job.ServiceTreeGUID,
                                    serversCount: job.PatchingServerCount,
                                    isAllServersSelected: job.IsAllServerSelected,
                                    lastScheduledDateTime: new Date((job.LastScheduledDateTime.toString())),
                                    nextScheduledDateTime: new Date((job.NextScheduledDateTime.toString())),
                                };

                                if (patchingServerJobData.has(server.ServerId)) {
                                    const existingJobSet: Set<IDetailsListManageJobsItem> = patchingServerJobData.get(server.ServerId)!;
                                    existingJobSet.add(jobResponse)
                                    patchingServerJobData.set(server.ServerId, existingJobSet);
                                } else {
                                    const newJobSet: Set<IDetailsListManageJobsItem> = new Set<IDetailsListManageJobsItem>();
                                    newJobSet.add(jobResponse);
                                    patchingServerJobData.set(server.ServerId, newJobSet);
                                }  

                                exportData.push({
                                    serverName: server.Name,
                                    serverId: server.ServerId,
                                    serviceTreeGUID: Guid.parse(server.ServiceTreeGUID),
                                    serverLastSeen: new Date((server.AgentLastSeenDateTime.toString())),
                                    jobName: job.JobName,  
                                    lastScheduledDateTime: new Date((job.LastScheduledDateTime.toString())),
                                    nextScheduledDateTime: new Date((job.NextScheduledDateTime.toString())),
                                    patchCompliance: server.PatchCompliance,
                                    vulnerabilityCount: server.PatchCompliance === 2 ? -1 : server.VulnerabilityCount,
                                    oSType: server.OSType,
                                }); 
                            }
                        }
                    }
                }
            }

            this.setState({
                serverJobMap: patchingServerJobData,
                serverData: newServerData,
                allServerData: newServerData,
                serversLoaded: true,
                serviceServerOptions: serverOptions,
                dataToExport: exportData,
            });

        }).catch((reason: any) => {
            console.log(reason);
        });
    }

    private _onSelectService = (service: IServiceDataType) => {
        this.setState({
            selectedServiceGUID: service?.ServiceTreeGUID,    
            selectedServiceName: service?.ServiceName
        });

        this.fetchServerData(service.ServiceTreeGUID, false);
    }

    private _toggleFilterCollapsible = () => {
        const { filtersHidden } = this.state;

        this.setState({
           filtersHidden: !filtersHidden,
        });

        this._announceFiltersExpand(!filtersHidden);
    }

    private _onFilterByServer = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        this.setState({
            serverFilteredText: newValue!,
        });
    }

 

    private _onFilterOS = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        const { oSSelection } = this.state;

        const newSelection: string[] = [];

        for(const p of this._oSOptions) {
            if(p.text === option?.text) {
                if(option?.selected) {
                    newSelection.push(p.text);
                }
            } else {
                if (oSSelection.includes(p.text)) {
                    newSelection.push(p.text);
                }
            }
        }

        this.setState({
            oSSelection: newSelection,
        });
    }

    private _onFilterCompliance = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        const { complianceSelection } = this.state;

        const newSelection: number[] = [];

        for(const p of this._complianceOptions) {
            if(p.key === option?.key) {
                if(option?.selected) {
                    newSelection.push(Number(p.key));
                }
            } else {
                if (complianceSelection.includes(Number(p.key))) {
                    newSelection.push(Number(p.key));
                }
            }
        }

        this.setState({
            complianceSelection: newSelection,
        });
    }
 

    private applyFilters = () => {
        const { allServerData, selectedServiceGUID, complianceSelection, 
             serverFilteredText, oSSelection } = this.state;

        // filter by service
        let combinedFilters: IManageServersData[] = allServerData.filter((i) => i.serviceTreeGUID.toString() === selectedServiceGUID);

        // compliance 
        if (complianceSelection.length > 0) {
            combinedFilters = combinedFilters.filter((i) => complianceSelection.includes(i.patchCompliance));
        }

        // OS 
        if (oSSelection.length > 0) {
            combinedFilters = combinedFilters.filter((i) => oSSelection.includes(i.oSType));
        }
         

        // filter by server name
        if (serverFilteredText !== "") {
            combinedFilters = combinedFilters.filter((i) => 
            i.serverName!.toLowerCase().indexOf(serverFilteredText.toLowerCase()) > -1);
        }
        
        this.setState({
            serverData: [...new Set(combinedFilters)],
        })
    }

    private _onItemInvoked = (item: IManageServersData): void => {
        // alert(`Invoked: ${item.serverName}`);
    }

    private _renderSortedHeader = (title: string, isSortedDescending: boolean) => {
        const order: string = isSortedDescending ? "Ascending" : "Descending";
        return (
            <TooltipHost 
                content={"sorted " + order}
            >
                <div aria-label={"sorted " + order}>{title}</div>
            </TooltipHost>
        );
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, serverData } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
            currColumn.onRenderHeader = () => this._renderSortedHeader(currColumn.name!, !currColumn.isSortedDescending!);
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });

        const newItems = this._copyAndSort(serverData, currColumn.fieldName!, currColumn.isSortedDescending);

        this.setState({
          columns: newColumns,
          serverData: newItems,
        });
      }

    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    private _updateColumns(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) {
        const { selectedColumns } = this.state;
        if (option?.selected !== undefined && option?.key !== undefined && selectedColumns && selectedColumns !== undefined) {
            const adjustedColumns: IColumn[] = this._renderColumns(option.key as string, option?.selected);
            const adjustedSelectedColumns: any[] = option.selected ?  [...selectedColumns, option.key as string] : selectedColumns.filter((key: string) => key !== option.key);

            this.setState({
                columns: adjustedColumns,
                selectedColumns: adjustedSelectedColumns,
            });
        }
    }

    private _renderColumns(fieldName: string, show: boolean): IColumn[] {
        const newColumns: IColumn[] = [];

        this._columnsShownMap.set(fieldName, show);

        for (const k of this._columnsShownMap.keys()) {
            const col: IColumn | undefined = this._columnsMap.get(k);
            if (this._columnsShownMap.get(k) && col !== undefined) {
                newColumns.push(col);
            }
        }

        return newColumns;
    }

    private _formattedDateString(d :Date): string{
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString("en-US", {hour:'2-digit', minute: '2-digit', hour12:false})}`;
    }

    private _renderItemColumn(item?: IManageServersData, index?: number, column?: IColumn) {
        const { serverJobMap, serviceServerOptions } = this.state;
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IManageServersData] as string | Date | Guid | number | boolean | PatchComplianceType;
            const jobsSet: Set<IDetailsListManageJobsItem> = serverJobMap.get(item.serverId) ?? new Set<IDetailsListManageJobsItem>();
            const dateNow: Date = new Date();

            switch (column.fieldName) {
                case "serverName" : {
                    return <span id="serverName">{item.serverName}</span>;
                }
                case "jobCount": {
                    const jobCount: number = item.jobCount; // jobsSet.size;
                    const jobString: string = jobCount?.toString() + " jobs";

                    if (jobCount === 0) {
                        return  <TooltipHost
                                    content={jobString}
                                    calloutProps={calloutProps}
                                >
                                    <IconButton
                                        key={"jobCount"}
                                        label={"jobCount"}
                                        ariaLabel={jobString}
                                        iconProps={{
                                            iconName: ("Error"),
                                        }}
                                        role={"button"}
                                        onClick={() => {}}
                                    />
                                </TooltipHost>;
                    } else {
                        return <span id="jobCount">{jobCount?.toString()}</span>;
                    }
                }
                case "jobsDetails": {
                    const panelPlusServers: IPanelOptions = {
                        daysOptions: this.props.panelOptions.daysOptions,
                        durationOptions: this.props.panelOptions.durationOptions,
                        weekOfMonthOptions: this.props.panelOptions.weekOfMonthOptions,
                        serverOptions: serviceServerOptions, // Loaded per service
                    };

                    const manageServersPanelProps: IManageServerPanelProps = {
                        serverName: item.serverName,
                        refreshDataGrid: () => this.refreshDataGrid(),
                        requestType: ServerPanelType.Jobs,
                        linkText: "View Jobs",
                        jobs: jobsSet,
                        panelOptions: panelPlusServers,
                    };

                    return <ManageServerPanel {...manageServersPanelProps} />;
                } 
                case "lastScheduledDateTime": {
                    let dateString: string = "";

                    if (fieldContent instanceof Date) {
                        dateString = (dateNow.getFullYear() - (fieldContent as Date).getFullYear()) > 5 ? "N/A" : this._formattedDateString(fieldContent as Date);
                    } else {
                        dateString = "N/A";
                    }

                    return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{dateString}</span>;
                }
                case "serverLastSeen": {
                    const currentDateTime: Date = new Date();
                    const duration = currentDateTime.getTime() - (fieldContent as Date).getTime();
                    const daysAgo: number = ~~(duration / (3600 * 24 * 1000)); // ~~ === Math.Floor
                    const dateString: string = timeAgoFunction(Math.round(duration) / 1000); 

                    if (daysAgo > 1) {
                        return  <TooltipHost
                                    content={dateString}
                                    calloutProps={calloutProps}
                                >
                                    <IconButton
                                        key={"serverLastSeen"}
                                        label={"serverLastSeen"}
                                        ariaLabel={dateString}
                                        iconProps={{
                                            iconName: ("Error"),
                                        }}
                                        role={"button"}
                                        onClick={() => {}}
                                    />
                                </TooltipHost>;
                    } else {
                        return <span>{dateString}</span>;
                    }
                }
                case "nextScheduledDateTime": {
                    let dateString: string = "";

                    if (fieldContent instanceof Date) {
                        // dateString = (dateNow.getFullYear() - (fieldContent as Date).getFullYear()) > 5 ? "N/A" : (fieldContent as Date).toLocaleString([], { hour: '2-digit', minute:'2-digit', hour12: false});
                        dateString = (dateNow.getFullYear() - (fieldContent as Date).getFullYear()) > 5 ? "N/A" : this._formattedDateString(fieldContent as Date);
                    } else {
                        dateString = "N/A";
                    }

                    return <TooltipHost
                                content={"Refreshes ~2 hours. If you have just created a job dated before the Next Patching Job stated here, that job WILL run successfully on time."}
                                calloutProps={calloutProps}
                            > 
                            <span
                                className={mergeStyles({
                                    display: "block",
                                    padding: "0px",
                                    margin: "0px",
                                    minHeight: "18px",
                                    maxHeight: "20px",
                                })}>
                                {dateString}
                            </span>
                        </TooltipHost>;
                }
                case "patchCompliance": {
                    /*
                    PatchComplianceType {
                        0: NotCompliant,
                        1: Compliant,
                        2: NotScanned
                    }
                    */

                    const label: string = fieldContent === 2 ? "Server not scanned in last 72 hours." : "Server is "  + (fieldContent === 0 ? "not" : "" ) + " compliant.";

                    return  <TooltipHost
                                content={label}
                                calloutProps={calloutProps}
                            >
                                <IconButton
                                    id={item.serverName + ": " + "Compliance: " + (fieldContent === 0 ? "NotCompliant" : fieldContent === 1 ? "Compliant" : "NotScanned")}
                                    key={item.serverName + ": " + "Compliance: " + (fieldContent === 0 ? "NotCompliant" : fieldContent === 1 ? "Compliant" : "NotScanned")}
                                    label={label}
                                    ariaLabel={label}
                                    iconProps={{
                                        iconName: (fieldContent === 0 ? "ErrorBadge" : fieldContent === 1 ? "CheckMark" : "Unknown"),
                                    }}
                                    role={"button"}
                                    onClick={() => {}}
                                />
                            </TooltipHost>;
                }
                case "vulnerabilityCount": {
                    return fieldContent as number >= 0 ? fieldContent : "N/A";
                }
                default:
                    return <span>{fieldContent?.toString()}</span>;
            }
        }
    }
}
