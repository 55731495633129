import { mergeStyles } from "@fluentui/react";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Banner from "./Banner.png";

interface HomeBannerProps {
  Title: string,
  Text: string,
}

// Consts //

const bannerStyle = mergeStyles({
  backgroundImage: `url(${Banner})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "white",
  height: "100px",
  width: "100%",
  marginBottom: "16px",
  selectors: {
    "@media(min-width: 576px)": {
      padding: "4rem 2rem",
    },
    "@media(max-width:576px)": {
      background: "none",
      backgroundColor: "#001F57",
      padding: "2rem 2rem",
    },
  },
});

const HomeBanner: React.FunctionComponent<HomeBannerProps> = (props) => {
  return (
    <div className={`${bannerStyle} jumbotron`}>
      <div className="col-md-6">
        <h1 id="hometitle">
            {props.Title}
        </h1>
        <h4>
            {ReactHtmlParser(props.Text)}
        </h4>
      </div>
      <div className="col-md-6"></div>
    </div>
  );
};

export default HomeBanner;
