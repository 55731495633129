import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { FontIcon, FontSizes, IStackTokens, Label, List, Stack, Text, TooltipHost, mergeStyles } from "@fluentui/react";
import React from "react";
import { IDetailsListAutoEnrollItem, IServerDetails } from "./DetailsListAutoEnroll";
import { CSVLink } from "react-csv";


export interface IServersListPanelProps {
    clickedRow: IDetailsListAutoEnrollItem; // row that was clicked
    servers: IServerDetails[];
    isOpen: Boolean; // setting to true will show panel
    onDismiss: Function; // expect parent to pass a function to inform parent when panel is closed by user
}


export class ServersListPanel extends React.Component<IServersListPanelProps, any> {
    constructor(props: IServersListPanelProps) {
        super(props);

        let onboardedSet = new Set<IServerDetails>();
        Array.from(props.servers).filter(d => d.OrganizationalUnit == this.props.clickedRow.organizationalUnit && d.IsOnboarded == true).forEach( d => onboardedSet.add(d));

        let validSet = new Set<IServerDetails>();
        Array.from(props.servers).filter(d => d.OrganizationalUnit == this.props.clickedRow.organizationalUnit && d.Valid == true)
        .forEach( d => { if(!onboardedSet.has(d)){validSet.add(d)}});
        
        let invalidSet = new Set<IServerDetails>();
        Array.from(props.servers).filter(d => d.OrganizationalUnit == this.props.clickedRow.organizationalUnit && d.Valid != true)
        .forEach( d => { if(!onboardedSet.has(d)){invalidSet.add(d)}});;

        let onboardeditems = this._copyAndSort(Array.from<IServerDetails>(onboardedSet),"Name", false);
        let validitems = this._copyAndSort(Array.from<IServerDetails>(validSet),"Name", false);
        let invaliditems = this._copyAndSort(Array.from<IServerDetails>(invalidSet),"Name", false);
        let exportData = [onboardeditems, validitems, invaliditems].map( d => Array.from<IServerDetails>(d)).flat().map( d => {
            return {
                ServiceName: d.ServiceName,
                Name: d.Name,
                ServerId: d.ServerId,
                IsOnboarded: d.IsOnboarded,
                Valid: d.Valid,
                Errors: d.Errors,
                NetworkDomain: d.NetworkDomain,
                OrganizationalUnit: d.OrganizationalUnit
            } as IServerDetails
        });
        

        this.state = {
            title: props.clickedRow.serviceName,
            isOpen: props.isOpen,
            onboardeditems: onboardeditems,
            validitems: validitems,
            invaliditems: invaliditems,
            onDismiss: props.onDismiss,
            clickedItem: props.clickedRow,
            exportData : exportData
        };
    }

    horizontalGapStackTokens: IStackTokens = {
    childrenGap: 20,
    padding: 10,
    };
    
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items
            .slice(0) // new copy
            .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    public render(): JSX.Element {
        return <CoherencePanel
            titleText={this.state.clickedItem.serviceName}
            isOpen={this.state.isOpen}
            hasCloseButton={true}
            onDismiss={this.state.onDismiss}
            panelSize={CoherencePanelSize.medium}
        >
            <div>

                <p/>
                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold"}}>
                    Organizational Unit
                    </Label>
                <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                    {this.props.clickedRow.organizationalUnit}
                </Label>
                <p/>
                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold"}}>
                    Total Servers
                    </Label>
                <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                    {this.props.clickedRow.totalCount}
                </Label>
                <p/>
                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold"}}>
                    List of Servers
                    <div style={{display: "inline", marginLeft: "20px"}}>
                        <CSVLink 
                            aria-label={"Export Data"} 
                            filename={`export-autoenroll${this.state.clickedItem.serviceName?"-"+this.state.clickedItem.serviceName:""}`} 
                            title={"Export Data"} 
                            data={this.state.exportData}>
                                Export Data
                        </CSVLink>
                    </div>
                </Label>
                <Stack 
                    style={{width: "100%"}}
                    enableScopedSelectors 
                    horizontal 
                    disableShrink 
                    tokens={this.horizontalGapStackTokens}
                    >
                    <div>
                        <Label> Available ({this.state.validitems.length})</Label>
                        <List
                            items = {this.state.validitems}
                            onRenderCell={obj => <div className={mergeStyles({marginTop: "4px"})}> <Text variant="medium"> {(obj as IServerDetails).Name} </Text>  </div>} />
                    </div>
                    <div>
                        <Label> Out Of Scope ({this.state.invaliditems.length})</Label>
                        <List
                            items={this.state.invaliditems}
                            onRenderCell={obj => 
                                        {
                                            var item = obj as IServerDetails;
                                            var errorsList = item.Errors.split("|");
                                            var cont:JSX.Element = <ul> {errorsList.map(d => <li key={d}> { d }</li>)} </ul>
                                            return (
                                                <div className={mergeStyles({marginTop: "4px"})}>
                                                    <TooltipHost content={cont}>
                                                        <Text variant="medium"> {item.Name} </Text>
                                                        <div className={mergeStyles({display: "inline-flex", verticalAlign: "center"})}> 
                                                            (<FontIcon aria-label="Errors" iconName="StatusCircleErrorX" className={mergeStyles({ color: "red", fontSize:"20px" })} /> 
                                                                <Text variant="medium"> {errorsList.length} {errorsList.length==1?" Error": " Errors"}  </Text>
                                                            ) 
                                                        </div>
                                                    </TooltipHost>
                                                </div>
                                                )
                                         }
                                        
                                    }
                            />
                    </div>
                    <div>
                        <Label> Onboarded ({this.state.onboardeditems.length}) </Label>
                        <List
                            items={this.state.onboardeditems}
                            onRenderCell={obj => <div className={mergeStyles({marginTop: "4px"})}> <Text variant="medium"> {(obj as IServerDetails).Name} </Text>  </div>} />
                    </div>
                
                </Stack>
            </div>
        </CoherencePanel>;
    }
}
