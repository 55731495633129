import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { Checkbox, ComboBox, Dropdown, FontSizes, IComboBox, IComboBoxOption, IDropdownOption, IDropdownStyleProps, IDropdownStyles, List, mergeStyles, 
    MessageBar, MessageBarType, PrimaryButton, Spinner, Text, TextField } from "@fluentui/react";
import React from "react";
import { Link } from "react-router-dom";
import HttpService from "../../services/HttpService/HttpService";
import { RootContext } from "../Stores/RootStore";


export interface IRequestAccessPanelProps {
    // clickedRow: IDetailsListMyServiceItem; // row that was clicked
    isOpen: boolean; // setting to true will show panel
    onDismiss: Function; // expect parent to pass a function to inform parent when panel is closed by user
    userServices: any[];
    rootContext: any;
}

export interface IRequestAccessPanelState {
        title: string, // Panel Title
        isOpen: boolean, // Is panel open
        mode: PANELMODES, // Which mode is the panel in ?
        onDismiss: Function, // (callback) On panel dismissed
        userServices: any[] // all services user has access to
        allOnboardedServices: any[] // all services onboarded on the portal
        isOtherServiceSelected: boolean, // (checkbox) for non-onboarded service
        justification: string, // (textfield) for justification
        otherServiceName: string, // (combobox) 
        selectedOnboardedServiceGUID: string, // (from dropdown selection),
        selectedOnboardedServiceName: string, // (from dropdown selection),,
        icmCreateStatus: ICM_CREATE_STATUS, // Create IcM API call status
        error_other_service_is_required: string, // error message text
        error_please_select_a_service: string, // error message text
        error_justification_not_provided: string, // error message text
        servicesStatus: LOADING_SERVICES_STATUS, // loading services for spinner
}

enum ICM_CREATE_STATUS {
    NONE = "N/A",
    PENDING = "Pending",
    SUCCESS = "Success",
    FAILED = "Failed"
}

enum LOADING_SERVICES_STATUS {
    HIDE = "Hide",
    LOADING = "Loading",
    SUCCESS = "Success",
    FAILED = "Failed"
}

enum PANELMODES {
    INPUT, // for user input
    CONFIRM, // for user to confirm
    RESULT // final result
}


const dropdownStyles: Partial<IDropdownStyles> = {
        subComponentStyles: {
            label: {
                root: {
                    padding: "5px",
                },
            },
            multiSelectItem: {},
            panel: {},
        },
    };


export class RequestAccessPanel extends React.Component<IRequestAccessPanelProps, IRequestAccessPanelState> {

    public static contextType: React.Context<any> = RootContext;
    private _httpService = HttpService();
    private rootContext:any;
    
    constructor(props: IRequestAccessPanelProps) {
        super(props);
        this.rootContext = props.rootContext;
        this.state = this.initialState();
        this.fetchUserServices();
    }

    initialState = ():IRequestAccessPanelState => {
        
        return {
                title: "Request Access to a Service",
                isOpen: this.props.isOpen,
                onDismiss: this.props.onDismiss,
                mode: PANELMODES.INPUT,
                userServices: this.props.userServices,
                allOnboardedServices: [],
                isOtherServiceSelected: false,
                justification: "",
                selectedOnboardedServiceGUID: "",
                selectedOnboardedServiceName: "",
                otherServiceName: "",
                icmCreateStatus: ICM_CREATE_STATUS.NONE,
                error_other_service_is_required: "",
                error_please_select_a_service: "",
                error_justification_not_provided: "",
                servicesStatus: LOADING_SERVICES_STATUS.LOADING,
            }
    }

    
    fetchUserServices(){
        this.setState({
            servicesStatus: LOADING_SERVICES_STATUS.LOADING   
        })

        // get a list of services users has access to via service tree and via delegation, to populate combobox
        var apicall = this._httpService.get({
            url: "api/ServiceAdministration/fetchUserAccessibleServices",
            token: this.rootContext.state.AuthStore.Token,
            params: {},
        })

        // get a list of all services onboarded to populate dropdown
        var apicall2 = this._httpService.get({
            url: "api/ServiceAdministration/fetchAllOnboardedServices",
            token: this.rootContext.state.AuthStore.Token,
            params: {},
        })

        return Promise.all([apicall, apicall2])
        .then((resp: any[])=>{
            if(resp[0].data !=null){
                this.setState({
                    userServices: resp[0].data,
                })
            }

            if(resp[1].data !=null ){
                this.setState({
                    allOnboardedServices: resp[1].data,
                })
            }

            this.setState({
                servicesStatus: LOADING_SERVICES_STATUS.SUCCESS   
            })
        }).catch(err => {
            console.log(err);
            this.setState({
                servicesStatus: LOADING_SERVICES_STATUS.FAILED
            })
        })
    }

    
    // api call to create IcM 
    createIcM() 
    {
        // set status pending
        this.setState({icmCreateStatus: ICM_CREATE_STATUS.PENDING})

        // make api call
        this._httpService.post({
            url: "api/IcM/requestAccessToService",
            token: this.rootContext.state.AuthStore.Token,
            data: {
                serviceTreeGUID : this.state.selectedOnboardedServiceGUID,
                userEmail: this.rootContext.state.AuthStore.Email,
                businessJustification: this.state.justification
              },
        })        
        .then((resp: any)=>{
            console.debug(resp);
            if(resp.data.incidentId != null && resp.data.incidentId > 0){
                this.setState({icmCreateStatus: ICM_CREATE_STATUS.SUCCESS})
            }else{
                this.setState({icmCreateStatus: ICM_CREATE_STATUS.FAILED})
            }
        }).catch(err => {
            console.log(err);
            this.setState({icmCreateStatus: ICM_CREATE_STATUS.FAILED})
        });
    }

    // on user input change handlers
    onJustificationChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        this.setState({justification : newValue! });
    }
    
    onUserChangedServiceName = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        this.setState({otherServiceName : value! });
    }

    onOtherServiceChecked = ():void => {
        this.setState({ 
            isOtherServiceSelected : !this.state.isOtherServiceSelected
        });
    }

    onUserSelectedService = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        this.setState({ 
            selectedOnboardedServiceGUID : item?.key + "",
            selectedOnboardedServiceName : item?.text + ""
        });
    };

   

    // input validation
    validateUserInput = (): boolean => {

        var hasErrors = false;

        // error if "other" checkbox is selected and service name is not provided
        if(this.state.isOtherServiceSelected){
            if (this.isEmpty(this.state.otherServiceName)) {
                this.setState({error_other_service_is_required: "Service is required"})
                hasErrors = true;
            } else{
                this.setState({error_other_service_is_required: ""});
            }
        }
        
        // error if "other" checkbox is not selected and service is not selected
        if(!this.state.isOtherServiceSelected){
            if (this.isEmpty(this.state.selectedOnboardedServiceName)) {
                this.setState({error_please_select_a_service: "Service is required"})
                hasErrors = true;
            } else{
                this.setState({error_please_select_a_service: ""});
            }
        }
        
        // error if justification not present
        if (this.isEmpty(this.state.justification)) {
            this.setState({error_justification_not_provided: "Business Justification is required"})
            hasErrors = true;
        } else{
            this.setState({error_justification_not_provided: ""});
        }

        // return true if validated ok
        return !hasErrors;
        
    }

    // utility function to check null / empty strings
    isEmpty = (str :string|null) :boolean => {
        return str == null || str.length == 0;
    }

    // utility function for showing IcM result to user
    resultMessage(): string{
        // switch on Create ICM API call status 
        switch(this.state.icmCreateStatus){
            case ICM_CREATE_STATUS.SUCCESS: {
                return `Thanks for requesting access to ${this.state.isOtherServiceSelected ? this.state.otherServiceName : this.state.selectedOnboardedServiceName}. Our team will reach out to you shortly when access has been provided.`
            }
            case ICM_CREATE_STATUS.PENDING:{
                return ""
            }
            case ICM_CREATE_STATUS.FAILED: {
                return "Request Failed. Please try again."
            }
        }

        return "";
        
    }

    public render(): JSX.Element {

        // populate combobox with all services user has access via ST and Delegation
        const options: IComboBoxOption[] = [];

        if(this.state.userServices!=null && this.state.userServices.length>0){
            var list = this.state.userServices.sort((a,b)=>{return new String(a["serviceName"]).toUpperCase() >new String(b["serviceName"]).toUpperCase()?1:-1});
            list.forEach( (d :any) => {
                options.push({ key: d["serviceTreeGuid"], text: d["serviceName"] },)
            })
        }

        

        // populate dropdown with all onboarded services
        const dropdownOptions: IDropdownOption[] = [];

        if(this.state.allOnboardedServices!=null && this.state.allOnboardedServices.length>0){
            var list = this.state.allOnboardedServices.sort((a,b)=>{return new String(a["serviceName"]).toUpperCase() >new String(b["serviceName"]).toUpperCase()?1:-1});
            list.forEach( (d :any) => {
                dropdownOptions.push({ key: d["serviceTreeGUID"], text: d["serviceName"] },)
            })
        }

    return (<div> 
    
        
        {this.state.isOpen 
        && 
        <CoherencePanel
            titleText={this.state.title}
            isOpen={this.state.isOpen}
            hasCloseButton={true}
            onDismiss={() => { this.state.onDismiss() }}
            panelSize={CoherencePanelSize.medium}
        >
            {/* Panel in INPUT MODE */}
            { this.state.mode == PANELMODES.INPUT && 
            
            <div>
                <p>
                    <Text variant="medium"> 
                        Please visit <Link to={{ pathname: "/serviceadministration/" }}>  Service Administration </Link> to check services you have access to.
                        <br />
                        <br />
                        To request access to a different service, select one from the dropdown or type in the name of the service and submit to create a request.
                    </Text>
                </p>
                {this.state.servicesStatus == LOADING_SERVICES_STATUS.LOADING && <Spinner label="Loading services..." ariaLive="assertive" labelPosition="left"/>}
                {this.state.servicesStatus == LOADING_SERVICES_STATUS.FAILED && <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>Failed to load services. Please try again.</MessageBar>}
                {this.state.servicesStatus == LOADING_SERVICES_STATUS.SUCCESS && 
                <div>
                    <div style={{ padding: "5px" }}>
                        <Dropdown required
                            className={mergeStyles({marginTop: "10px"})}
                            placeholder="Select a service"
                            label="Select a service"
                            ariaLabel="Select a service"
                            aria-required="true"
                            options={!this.state.isOtherServiceSelected?dropdownOptions:[]}
                            onChange={this.onUserSelectedService}
                            styles={dropdownStyles}
                            disabled={this.state.isOtherServiceSelected}
                            errorMessage = {this.state.error_please_select_a_service.length>0? this.state.error_please_select_a_service:""}
                        />
                    </div>

                    <div style={{ padding: "5px" }}>
                        <Checkbox 
                            className={mergeStyles({marginTop: "10px"})}
                            label="Don't see your service?"
                            ariaLabel="Don't see your service?"
                            // aria-describedby={tooltipId}
                            aria-required="false"
                            defaultChecked={false}
                            onChange={this.onOtherServiceChecked}
                            disabled={false}
                        />
                    </div>
                    
                    {this.state.isOtherServiceSelected && 
                        <div style={{ padding: "5px" }}>
                        <ComboBox
                            className={mergeStyles({marginTop: "10px"})}
                            label="Select or type a service name"
                            allowFreeform={true}
                            text={this.state.otherServiceName}
                            autoComplete={'on'}
                            options={options}
                            onChange={this.onUserChangedServiceName}
                            errorMessage = {this.state.error_other_service_is_required || ""}
                            disabled={!this.state.isOtherServiceSelected}
                            />
                        </div>
                    }
                    

                    <div style={{ padding: "5px" }}>
                        <TextField
                            className={mergeStyles({marginTop: "10px"})}
                            value={this.state.justification}
                            required
                            multiline={true}
                            label="Business Justification"
                            ariaLabel="Business Justification"
                            onChange={this.onJustificationChange}
                            errorMessage = {this.state.error_justification_not_provided.length>0? this.state.error_justification_not_provided:""}
                        />
                    </div>

                    <div>
                        <PrimaryButton
                            className={mergeStyles({marginTop: "15px"})}
                            text="Next"
                            ariaLabel="Next Button"
                            onClick={() =>  {
                                if(this.validateUserInput()) {
                                    this.setState({
                                        mode : PANELMODES.CONFIRM
                                    })
                                } 
                            }}
                        />
                    </div>
                </div>
                }
            </div>
            }

            {/* Panel in CONFIRMATION MODE - Let user confirm before proceeeding*/}
            { this.state.mode == PANELMODES.CONFIRM && 

                <div>
                <p>
                    <MessageBar 
                        messageBarType={MessageBarType.info}
                        style={{fontSize: FontSizes.medium}}
                        isMultiline={true}>
                            Please verify and confirm your request details
                    </MessageBar>
                </p>

                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    Service
                    </label>
                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.state.selectedOnboardedServiceName.length > 0 ? this.state.selectedOnboardedServiceName :  this.state.otherServiceName}
                </label>

                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    User
                    </label>
                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.rootContext.state.AuthStore.Email}
                </label>

                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    Business Justification
                    </label>
                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.state.justification}
                </label>

                
                <div>
                <PrimaryButton
                    style={{marginTop: "20px"}}
                    text="Confirm"
                    ariaLabel="Confirm Button"
                    onClick={() =>  {
                        this.createIcM();
                        this.setState ({ mode :PANELMODES.RESULT});
                    }}
                />
                </div>
                </div>
            
            }

            {/* Panel in RESULT MODE - Show user result of Create IcM API call*/}
            { this.state.mode == PANELMODES.RESULT && 

                <div>
                <p>
                    <MessageBar
                            messageBarType={
                                this.state.icmCreateStatus == ICM_CREATE_STATUS.SUCCESS ? MessageBarType.success : 
                                this.state.icmCreateStatus == ICM_CREATE_STATUS.FAILED ? MessageBarType.error : 
                                MessageBarType.info
                            }
                            isMultiline={true}
                            style={{fontSize: FontSizes.medium}}
                        >
                        { 
                            (this.state.icmCreateStatus == ICM_CREATE_STATUS.PENDING 
                            && <Spinner label="Submitting request..." ariaLive="assertive" labelPosition="left"/>) 
                            || this.resultMessage()
                        }
                    </MessageBar>
                </p>

                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    Service
                    </label>
                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.state.selectedOnboardedServiceName.length > 0 ? this.state.selectedOnboardedServiceName :  this.state.otherServiceName}
                </label>

                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    User
                    </label>
                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.rootContext.state.AuthStore.Email}
                </label>

                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    Business Justification
                    </label>
                <label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.state.justification}
                </label>


                <div>
                <PrimaryButton
                    text="Complete"
                    ariaLabel="Complete Button"
                    onClick={() =>  {
                        this.props.onDismiss();
                    }}
                    disabled = {this.state.icmCreateStatus == ICM_CREATE_STATUS.PENDING}
                />
                </div>
                </div>
            
            }

           
        </CoherencePanel>
    }

    </div>);
    }
}

