import { FontIcon, TooltipHost } from '@fluentui/react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import * as React from 'react';
import { ServerForOnboarding, SortResults, SortableColumn, _getKey, classNames, sortByColumn } from './OnboardingModels';


// -------------------------
// Function component props
// -------------------------
export interface IDetailsListOutOfScopeProps{
  items: any;
}


// }
// ------------------
// Function component to show servers that do not validate for onboarding.
// ------------------
export const DetailsListOutOfScope = (props:IDetailsListOutOfScopeProps) => {

  const [items, SetItems] = React.useState<ServerForOnboarding[]>(props.items);
  
    
  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    let r:SortResults = sortByColumn(column, columns, items);
    SetColumns(r.newColumns);
    SetItems(r.newItems);
  }


  
  let colOSType: SortableColumn;
  let colName: SortableColumn;
  let colDomain: SortableColumn;
  let colServerId: SortableColumn;
  let colErrors: SortableColumn;


    colOSType  = {
      key: 'column1',
      name: 'OS Type',
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: 'Operating System',
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'OSType',
      minWidth: 16,
      maxWidth: 16,
      onColumnClick: _onColumnClick,
      onRender: (item: ServerForOnboarding) => (
      <TooltipHost content={`${item.OSType}`}>
          {
              item.OSType.toLowerCase().includes("windows")
              ? <FontIcon aria-label="Windows" iconName="WindowsLogo" className={mergeStyles({ color: "rgba(0, 120, 212, 1)" })} />
              : <FontIcon aria-label="Windows" iconName="InfoSolid" className={mergeStyles({ color: "rgba(0, 0, 0, 1)" })} />
          }
        </TooltipHost>
      ),
    }

    colName = {
      key: 'column2',
      name: 'Name',
      fieldName: 'Name',
      ariaLabel: 'Server Name',
      minWidth: 30,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: _onColumnClick,
      data: 'string',
      isPadded: true,
      onRender: (item: ServerForOnboarding) => {
        return <span>{item.Name}</span>;
      },
    }

    colDomain = {
      key: 'column4',
      name: 'Domain',
      fieldName: 'NetworkDomain',
      ariaLabel: 'Domain',
      minWidth: 30,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onColumnClick: _onColumnClick,
      onRender: (item: ServerForOnboarding) => {
        return <span>{item.NetworkDomain}</span>;
      },
    }

    colServerId = {
      key: 'column5',
      name: 'Server Id',
      fieldName: 'ServerId',
      ariaLabel: 'Server Id',
      minWidth: 30,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'number',
      onColumnClick: _onColumnClick,
      onRender: (item: ServerForOnboarding) => {
        return <span>{item.ServerId}</span>;
      },
    };

    colErrors = {
      key: 'column6',
      name: 'Errors',
      fieldName: 'Errors',
      ariaLabel: 'Errors',
      minWidth: 30,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      isMultiline: true,
      data: 'string',
      onRender: (item: ServerForOnboarding) => {
      var text = `${item.Errors}`;
      var cont:JSX.Element = <ul> {item.Errors.split("|").map(d => <li key={d}> { d }</li>)} </ul>
          return (
          <TooltipHost content={cont}>
            <span>({text.split("|").length}) {text.split("|").join(",")}</span>
          </TooltipHost>)
        }
    }

    const [columns, SetColumns] = React.useState<IColumn[]>([colOSType, colName, colDomain, colServerId, colErrors]);
  
  return (<div id={"outOfScopeServersDetailsList"}>
    <DetailsList
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
        getKey={_getKey}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
  </div>)
};