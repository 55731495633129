import { CheckboxVisibility, DefaultButton, DetailsList, Dialog, DialogFooter, DialogType,
    DirectionalHint, IColumn, IconButton,
    PrimaryButton, ScrollablePane, TooltipHost } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React, { Dispatch, SetStateAction, useRef } from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import HttpService from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { ListItemActionSet } from "../Shared/ListItemActionSet";
import { PeoplePicker, PeoplePickerRequestType } from "../Shared/PeoplePicker";
import { RootContext } from "../Stores/RootStore";
import { Colors, Status } from "../../Styles/Colors";

// Interfaces //

interface IDelegatePermissionsProps {
    serviceAdmins: string[];
    selectedServiceTreeGUID: string;
    delegatedUserList: string[];
    delegatedPermissionsDisplayed: IDelegatedPermissionsType[];
    graphToken: any;
    SetTeamsDisabled: Dispatch<SetStateAction<boolean>>;
    updateDelegatedDataDisplayed: (serviceID: string) => void;
}

export interface IDelegatedPermissionsType {
    ServiceTreeGUID: string;
    User: string;
    Role: string;
    DelegatedBy: string;
}

export const DelegatePermission: React.FunctionComponent<IDelegatePermissionsProps> = (props: IDelegatePermissionsProps) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [resultText, SetResultText] = React.useState<string>("");
    const [newAdmin, SetNewAdmin] = React.useState<string>();
    const [newEmail, SetNewEmail] = React.useState<string>();
    const [userToBeDeleted, SetUserToBeDeleted] = React.useState<string>("");
    const [userToBeDeletedDelegatedBy, SetUserToBeDeletedDelegatedBy] = React.useState<string>("");
    const [userToBeDeletedServiceTreeGUID, SetUserToBeDeletedServiceTreeGUID] = React.useState<string>("");
    const [hideDialog, SetHideDialog] = React.useState<boolean>(true);

    // Confirm on Delegate Permissions
    const [closeCompleteButtonText, SetCloseCompleteButtonText] = React.useState<string>("Close");
    const [removeSubText, SetRemoveSubtext] = React.useState<string>("Confirm removing admin privilages for: ");
    const [confirmForfeitButtonDisabled, SetConfirmForfeitButtonDisabled] = React.useState<boolean>(false);
    const [grantPermissionButtonDisabled, SetGrantPermissionButtonDisabled] = React.useState<boolean>(true);

    // reload - used for PeoplePicker
    const [reload, SetReload] = React.useState(1);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    // focus ref for complete button
    const completeButtonRef = useRef<null | HTMLElement>(null);

    React.useEffect(() => {
        if (reload === 0) {
            SetReload((p) => p + 1);
        }
    }, [reload]);

    // enable Grant Permission button only when user is in people picker
    React.useEffect(() => {
        if (newAdmin !== "") {
            SetGrantPermissionButtonDisabled(false);
        } else {
            SetGrantPermissionButtonDisabled(true);
        }
    }, [newAdmin]);

    const delegatedPermissionsColumns: IColumn[] = [
        {
            key: "user",
            name: "User",
            fieldName: "User",
            isResizable: true,
            maxWidth: 95,
            minWidth: 50,
        },
        {
            key: "role",
            name: "Role",
            fieldName: "Role",
            isResizable: true,
            maxWidth: 75,
            minWidth: 50,
        },
        {
            key: "delegatedBy",
            name: "Delegated By",
            fieldName: "DelegatedBy",
            isResizable: true,
            maxWidth: 95,
            minWidth: 75,
        },
        {
            key: "actions",
            name: "Delete",
            maxWidth: 75,
            minWidth: 50,
            onRender: (item: IDelegatedPermissionsType) => deleteDelegatedUser(item),
        },
    ];

    const deleteDelegatedUser = (item: IDelegatedPermissionsType) => {
        const visibleActions: React.ReactNode[] = [];
        const deleteDelegatedUserLabel = "click here to remove delegation for: ";
        visibleActions.push(
            <TooltipHost
                key={"Remove"}
                aria-label={deleteDelegatedUserLabel + item.User}
                content={deleteDelegatedUserLabel + item.User}
                styles={{ root: { marginLeft: "10px" } }}
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 }}
            >
                <IconButton
                    key={"Remove"}
                    label={"Remove"}
                    ariaLabel={deleteDelegatedUserLabel + item.User}
                    iconProps={{
                        iconName: "Delete",
                    }}
                    role={"button"}
                    onClick={() => {
                        SetUserToBeDeleted(item.User);
                        SetUserToBeDeletedServiceTreeGUID(item.ServiceTreeGUID);
                        SetUserToBeDeletedDelegatedBy(item.DelegatedBy);
                        SetHideDialog(false);
                    }}
                />
            </TooltipHost>,
        );

        return (
            <ListItemActionSet
                visibleActions={visibleActions}
            />
        );
    };

    // Grant permission to delegated alias
    const grantPermission = async () => {
        SetResultText("Granting permission for: " + newAdmin);
        const currentAdmins: string[] = [];

        // create array of current delegated users
        for (const v of props.delegatedPermissionsDisplayed) {
            currentAdmins.push(v.User);
        }

        const adminsToSubmit: string[] = [];

        // remove user from submission if already delegated
        if (!currentAdmins.includes(newAdmin!)) {
            adminsToSubmit.push(newAdmin!);
        }

        if (props.selectedServiceTreeGUID !== "" && adminsToSubmit.length > 0) {
            SetResultText("Processing...");

            const newAliases: string[] = [];

            for (const a of adminsToSubmit) {
                newAliases.push(a.split("@")[0]);
            }

            await httpService.post({
                url: "api/ServiceAdministration/grantPermission",
                token: state.AuthStore.Token,
                data: {
                    ServiceTreeGUID: props.selectedServiceTreeGUID,
                    Alias: newAliases,
                    AliasEmail: newEmail,
                    // DelegatedBy: *Supplied by API*,
                },
            }).then((permissionResponse: any) => {
                    if (permissionResponse?.data === "Permission has been granted successfully!") {
                        SetGrantPermissionButtonDisabled(true);
                        SetResultText("Successfully Granted Permission for: " + newAliases + "!");
                    } else {
                        trackException(appInsights, new Error(), SeverityLevel.Error, "ServiceAdministration", "Delegate Permission", permissionResponse?.data, state.AuthStore, {});
                        SetResultText(permissionResponse?.data ?? "Oops, something went wrong. Please reload application");
                    }

                    SetReload(0);
                    SetNewAdmin("");
                    SetNewEmail("");
                    props.updateDelegatedDataDisplayed(props.selectedServiceTreeGUID);
                    trackEvent(appInsights, "ServiceAdministration", "Delegate Permission", newAliases +  " delegated for service: " + props.selectedServiceTreeGUID, state.AuthStore, {});
                }).catch((reason: any) => {
                    trackException(appInsights, reason, SeverityLevel.Error, "ServiceAdministration", "Delegate Permission", "Grant Permissions Error", state.AuthStore, {});
                });
        } else {
            // alert("Service not selected!");
        }
    };

    // New Service selected
    const resetMembers = async () => {
        // Reset fields
        SetResultText("");
        SetNewAdmin("");
        SetNewEmail("");
    };

    // New service selected, reset all inputs
    React.useEffect(() => {
        SetReload(0);
        resetMembers();
    }, [props.selectedServiceTreeGUID]);

    function forfeitPermissionFunction(fAlias: string, fDelegatedBy: string, fServiceId: string) {
        const forfeitPermission = async (alias: string[], delegatedBy: string, serviceId: string) =>
            httpService.post({
                url: "api/ServiceAdministration/forfeitPermission",
                token: state.AuthStore.Token,
                data: {
                    ServiceTreeGUID: serviceId,
                    Alias: alias,
                    DelegatedBy: delegatedBy,
                    AliasEmail: newEmail,
                },
            });

        forfeitPermission([fAlias], fDelegatedBy, fServiceId).then((response: any) => {
            if (response !== undefined) {
                if (fServiceId !== "") {
                    props.updateDelegatedDataDisplayed(fServiceId);
                    SetRemoveSubtext("Successfully removed: ");
                }
            } else {
                trackException(appInsights, new Error(), SeverityLevel.Error, "ServiceAdministration", "Delegate Permission", "Forfeit Permissions Error", state.AuthStore, {});
                SetRemoveSubtext("Error removing: ");
            }
            SetCloseCompleteButtonText("Complete");
            SetConfirmForfeitButtonDisabled(true);

            // focus on Complete button
            completeButtonRef?.current!.focus();
            trackEvent(appInsights, "ServiceAdministration", "Delegate Permission", fAlias + " removed from delegation by " + fDelegatedBy + " for service: " + fServiceId, state.AuthStore, {});
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "ServiceAdministration", "Delegate Permission", "Forfeit Permissions Error", state.AuthStore, {});
        });
    }

    function closeDialogFunction() {
        SetHideDialog(true);
        SetCloseCompleteButtonText("Close");
        SetConfirmForfeitButtonDisabled(false);
        SetRemoveSubtext("Confirm removing admin privilages for: ");
    }

    return (
        <div>
            {/* Delete Confirmation Dialog */}
            <Dialog
                hidden={hideDialog}
                closeButtonAriaLabel="Dialog"
                onDismiss={() => {
                    closeDialogFunction();
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Confirm Deletion",
                    subText: removeSubText + userToBeDeleted,
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        text="Confirm"
                        ariaLabel="Confirm Button"
                        onClick={() => {
                            forfeitPermissionFunction(userToBeDeleted, userToBeDeletedDelegatedBy, userToBeDeletedServiceTreeGUID);
                            }
                        }
                        disabled={confirmForfeitButtonDisabled}
                    />
                    <DefaultButton
                        text={closeCompleteButtonText}
                        ariaLabel={closeCompleteButtonText === "Complete" ? removeSubText + userToBeDeleted + " button Complete" : "Close button"}
                        onClick={() => {
                            closeDialogFunction();
                        }}
                        componentRef={completeButtonRef as any}
                    />
                </DialogFooter>
            </Dialog>
            <div id="delegationDetailsList"  style={{ position: "relative", height: "75%" }}>
                <ScrollablePane style={{ height: "200px", position: "relative" }}>
                <DetailsList
                    columns={delegatedPermissionsColumns}
                    items={props.delegatedPermissionsDisplayed}
                    compact={true}
                    onRenderDetailsHeader={RenderStickyHeader}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    ariaLabelForGrid="Delegated Permissions List"
                />
                </ScrollablePane>
            </div>
            <div style={{ fontWeight: 600, width: 150 }}>
                <fieldset id="peoplePicker">
                <legend>(Required):</legend>
                    Search for an Alias<span style={{ color: Colors.VisualIndicators.RedDark}}>*</span>
                    <div>
                    {/* reload people picker on submission -- this resets the field */}
                    {Boolean(reload) && (
                        <PeoplePicker
                            token={props.graphToken}
                            account={state.AuthStore.Account}
                            defaultValue={""}
                            itemLimit={1}
                            alias={newAdmin}
                            SetAlias={SetNewAdmin}
                            SetNewEmail={SetNewEmail}
                            SetAliasInput={() => {}} // not necessary for Delegate Permissions
                            PeoplePickerRequestTypeValue={PeoplePickerRequestType.Alias}
                            ariaLabel={"Search for an Alias People picker combo box required collapsed has auto complete editable"}
                        />
                    )}
                    </div>
                </fieldset>
            </div>
            <br />
            <div>
                <PrimaryButton
                    secondaryText="Grant Permission"
                    onClick={grantPermission}
                    text="Grant Permission"
                    disabled={grantPermissionButtonDisabled}
                    ariaLabel={grantPermissionButtonDisabled ? "Grant Permission disabled incorrect information provided." : "Grant Permission Button"}
                /><p aria-label={resultText} style={{ color: Status.DarkGreen}}>{resultText}</p>
            </div>
        </div>
    );
};
