import axios from "axios";
import { authenticationParameters, labauthenticationParameters, prodAuthProvider } from "./AuthProvider";
const axiosInstance = axios.create({
  /* other custom settings */
});
export const prodApiClient = axios.create({
  baseURL: process.env.API_MYER_BASE_URL,
});
prodApiClient.interceptors.request.use(
  async (config: any) => {
    const scopes = {
      scopes: authenticationParameters.scopes,
    };
    const tokenObject = await prodAuthProvider.acquireTokenSilent(scopes);
    const token = tokenObject.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);
export const labApiClient = axios.create({
  baseURL: process.env.API_LAB_BASE_URL,
});
labApiClient.interceptors.request.use(
  async (config: any) => {
    const tokenObject = await prodAuthProvider.acquireTokenSilent(labauthenticationParameters);
    const token = tokenObject.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);
export default axiosInstance;
