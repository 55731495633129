import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { ActionButton, Announced, Checkbox, CheckboxVisibility, ChoiceGroup, DefaultButton,  DetailsList,  DirectionalHint,
    Dropdown, FontSizes, IChoiceGroupOption, IColumn, IconButton, IDropdownStyles, IStackTokens,
    ITextFieldStyles, Link, mergeStyles, PrimaryButton,
    ScrollablePane, Spinner, Stack, TextField, TooltipHost
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import dayjs from "dayjs";
import duration, { Duration } from "dayjs/plugin/duration";
import { Guid } from "guid-typescript";
import { toNumber } from "lodash";
import * as React from "react";
import { IServerType, IServiceDataType } from "../../App";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { GetNextScheduledDateTime } from "../../Helpers/Utils";
import { invalidCharArray, invalidCharSet, IPanelOptions } from "../../Pages/ManageJobsPage";
import HttpService from "../../services/HttpService/HttpService";
import { OpenPanelType } from "../../Styles/Page.types";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { PatchComplianceType } from "../ManageServers/DetailsListManageServers";
import { ListItemActionSet } from "../Shared/ListItemActionSet";
import { TimePicker } from "../Shared/TimePicker";
import { RootContext } from "../Stores/RootStore";
import { IDeleteJob } from "./DeleteJobsPanel";
import { ChildClass } from "../../Styles/Page.styles";
import { OnboardedServicesDropdown } from "../Shared/OnboardedServicesDropdown";
import { Colors } from "../../Styles/Colors";

dayjs.extend(duration);

// Consts

const calloutProps = { gapSpace: 0 };
const textStyles: Partial<ITextFieldStyles> = {};

const stackTokens: IStackTokens = {
    childrenGap: 0 + " " + 20, // vertical gap + ' ' + horizontal gap
};

const dropdownStyles: Partial<IDropdownStyles> = {
    subComponentStyles: {
        label: {
            root: {
                // fontWeight: "bold",
                padding: "5px",
            },
        },
        multiSelectItem: {},
        panel: {},
    },
};

// Styles //
const textFieldStyles: Partial<ITextFieldStyles> = { root: { minWidth: "230px", maxWidth: "400px" } };

enum SelectedJobSections {
    MainInfo,
    ServerInfo,
    Review,
    Close,
    Delete,
}

// Interfaces //

export interface IManageJobPanelProps {
    eTag: string;
    jobName: string;
    dayOfTheWeek: string;
    weekOfTheMonth: number;
    startTimeHours: number;
    startTimeMinutes: number;
    duration: string;
    serviceName: string;
    serviceTreeGUID: string;
    reboot: boolean;
    alwaysReboot: boolean;
    recurring: boolean;
    jobID: Guid;
    requestType: OpenPanelType;  // add new job or edit existing job
    panelOptions: IPanelOptions;
    allServersSelected: boolean;
    hideDialog: boolean;
    refreshDataGrid: () => void;
    lastScheduledDateTime: Date;
    nextScheduledDateTime: Date;
    linkText: string;
}

export const ManageJobPanel: React.FunctionComponent<IManageJobPanelProps> = (props: IManageJobPanelProps) => {
    // Panel Data
    const [eTag, SetETag] = React.useState<string | undefined | "">(props.eTag);
    const [jobName, SetJobName] = React.useState<string>(props.jobName);
    const [jobID, SetJobID] = React.useState<Guid>(props.jobID);
    const [dayOfTheWeek, SetDayOfTheWeek] = React.useState<string | undefined>(props.dayOfTheWeek);
    const [weekOfTheMonth, SetWeekOfTheMonth] = React.useState<number>(props.weekOfTheMonth);
    const [hours, SetHours] = React.useState<number>(props.startTimeHours);
    const [minutes, SetMinutes] = React.useState<number>(props.startTimeMinutes);
    const [duration, SetDuration] = React.useState<string | undefined>(props.duration);
    const [serviceName, SetSelectedServiceName] = React.useState<string>(props.serviceName);
    const [serviceTreeGUID, SetSelectedServiceTreeGUID] = React.useState<string>(props.serviceTreeGUID);
    const [reboot, SetReboot] = React.useState<boolean>(props.reboot);
    const [alwaysReboot, SetAlwaysReboot] = React.useState<boolean>(props.alwaysReboot);
    const [recurring, SetRecurring] = React.useState<boolean>(props.recurring);
    const [confirmationTextHidden, SetConfirmationTextHidden] = React.useState<boolean>(true);
    const [jobDeleted, SetJobDeleted] = React.useState<boolean>(false);
    const [currentPanelSection, SetCurrentPageSection] = React.useState<SelectedJobSections>(SelectedJobSections.MainInfo);
    const [isAllServerSelectedChecked, SetIsAllServerSelectedChecked] = React.useState<boolean>(props.allServersSelected);
    const [lastScheduledDateTime, SetLastScheduledDateTime] = React.useState<Date>(props.lastScheduledDateTime);
    const [nextScheduledDateTime, SetNextScheduledDateTime] = React.useState<Date>(props.nextScheduledDateTime);
    const [jobOutsideOfLagTimeWindow, SetJobOutsideOfLagTimeWindow] = React.useState<boolean>(false);

    // Variable Options
    const [daysOptions, SetDaysOptions] = React.useState<IDropdownOption[]>(props.panelOptions.daysOptions);
    const [recurringDisabled, SetRecurringDisabled] = React.useState<boolean>(false);

    // Panel Validation
    const [jobNameErrMsg, SetJobNameErrMsg] = React.useState<string>("");
    const [serverErrMsg, SetServerErrMsg] = React.useState<string>("");
    const [weekDayErrMsg, SetWeekDayErrMsg] = React.useState<string>("");
    const [weekofMonthErrMsg, SetWeekofMonthErrMsg] = React.useState<string>("");
    const [windowDurationErrMsg, SetWindowDurationErrMsg] = React.useState<string>("");
    const [startHoursErrMsg, SetStartHoursErrMsg] = React.useState<string>("");
    const [jobTimeErrMsg, SetJobTimeErrMsg] = React.useState<string>("");

    // Refs
    const jobNameRef = React.useRef<any>(null);
    const serverRef = React.useRef<any>(null);
    const weekRef = React.useRef<any>(null);
    const dayRef = React.useRef<any>(null);
    const durationRef = React.useRef<any>(null);
    const jobLagRef = React.useRef<any>(null);

    // Panel Operation
    const newJobButtonIdText = "newJobButton";
    const editJobButtonIdText = "editJobLink";

    const [editLinkText, SetEditLinkText] = React.useState<string>(props.linkText);
    const [panelLinkIdText, SetPanelLinkIdText] = React.useState<string>(props.linkText + " " + props.jobName);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string | undefined | "">("Next");
    const [secondaryButtonText, SetSecondaryButtonText] = React.useState<string | undefined | "">("");
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const { state } = React.useContext(RootContext);
    const [deleteJobSubtext, SetDeleteJobSubtext] = React.useState("Are you sure you want to delete this job?");
    const [serviceSelected, SetServiceSelected] = React.useState<boolean>(false);

    // Server DataGrid
    const [selectedServers, SetSelectedServers] = React.useState<IServerType[]>([]);
    const [allServerDataFiltered, SetAllServerDataFiltered] = React.useState<IServerType[]>([]);
    const [availableServers, SetAvailableServers] = React.useState<IServerType[]>([]);

    // Labels
    const [timeZoneLabel, SetTimeZoneLabel] = React.useState<string>("");
    const [nextScheduledLabel, SetNextScheduledLabel] = React.useState<string>("");
    const [reviewTimeText, SetReviewTimeText] = React.useState<string>("");

    // Annoucements
    const [announcedServersFilter, SetAnnounceServersFiltered] = React.useState<JSX.Element | undefined>(undefined);
    const [announcedServersAddedAlert, SetAnnounceAllServersAddedAlert] = React.useState<JSX.Element | undefined>(undefined);
    const [announcedServersRemovedAlert, SetAnnounceAllServersRemovedAlert] = React.useState<JSX.Element | undefined>(undefined);

    // Panel Sections
    const [hideMainDiv, SetHideMainDiv] = React.useState<boolean>(false);
    const [hideServerDiv, SetHideServerDiv] = React.useState<boolean>(true);
    const [hideReviewDiv, SetHideReviewDiv] = React.useState<boolean>(true);
    const [hideCloseDiv, SetHideCloseDiv] = React.useState<boolean>(true);
    const [hideDeleteDiv, SetHideDeleteDiv] = React.useState<boolean>(true);

    // Results
    const [submitResult, SetSubmitResult] = React.useState<string>("");

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    const tooltipId = useId("tooltip");

    // Error Messages
    const jobLagTime: number = 20;
    const jobLagTimeMessage: string = "Cannot schedule a job within " + jobLagTime.toString() + " minutes of the current time. Please adjust the start time of this job or visit the Manage Servers page and use the \"Patch Now!\" feature.";
    const jobNextMonthMessage: string = "Job won't execute until next month.";
    const noServersMessage: string = "You cannot create Job without Server(s)";
    const blankJobMessage: string = "Job Name cannot be blank";
    const jobNameTooLongMessage: string = "The length must be between 1 and 54 characters."; 
    const weekCycleMessage: string = "Week of the Patching Cycle cannot be a blank";
    const weekFiveMessage: string = "Not every patching cycle has a 5th week for patching, please note this when scheduling. \"Recurring\" jobs are not an option for jobs scheduled on the 5th week.";
    const dayCycleMessage: string = "Day of the Patching Cycle cannot be a blank";
    const blankDurationMessage: string = "Duration cannot be a blank";
    const addServerAriaLabel: string = "Click here to add a server: ";
    const removeServerAriaLabel: string = "Click here to remove a server: ";

    const rebootToolTip: string = "Reboot if required = Your server(s) will reboot during the patch window if required by an update. Always Reboot = Your server(s) will reboot during the patch window. Do not reboot = Your server(s) will not reboot during the patch window."
    const firstchar: string = "The first character must be a letter or number.";
    const middlechars: string = "Unsupported characters found: Names must only include letters, numbers, underscores, or dashes.";
    const lastchar: string = "The last character must be a letter, number, or underscore.";
    const rebootOptions: IChoiceGroupOption[] = [
        { key: "0", text: 'Reboot if required' },
        { key: "1", text: 'Always reboot' },
        { key: "2", text: 'Do not reboot'},
    ];

    // Updates on Panel load
    React.useEffect(() => {
        const currentDate = new Date();
        if (currentDate !== undefined && currentDate.toString().indexOf("(") !== 1) {
            SetTimeZoneLabel("Start Time (" + state.AuthStore.timezone?.standardName! + ")");
        }

        // Add new job
        if (props.requestType === OpenPanelType.Add) {
            SetEditLinkText(props.linkText);
            SetPanelLinkIdText(newJobButtonIdText);
            ClearPanelErrors();
            SetReboot(true);
            SetAlwaysReboot(false);
            SetRecurring(true);
        } else {
            SetPanelLinkIdText(panelLinkIdText);
        }
    }, [state]);

    React.useEffect(() => {
        // edit job
        if (!isAdd()) {
            SetETag(props.eTag);
            SetJobName(props.jobName);
            SetJobID(props.jobID);
            SetDayOfTheWeek(props.dayOfTheWeek);
            SetWeekOfTheMonth(props.weekOfTheMonth);
            SetHours(props.startTimeHours);
            SetMinutes(props.startTimeMinutes);
            SetDuration(props.duration);
            SetReboot(props.reboot);
            SetAlwaysReboot(props.alwaysReboot);
            SetRecurring(props.recurring);
            SetSelectedServiceName(props.serviceName);
            SetSelectedServiceTreeGUID(props.serviceTreeGUID);
            SetIsAllServerSelectedChecked(props.allServersSelected);
            SetServiceSelected(true);
            SetLastScheduledDateTime(props.lastScheduledDateTime);
            SetNextScheduledDateTime(props.nextScheduledDateTime);

            const reviewHour: string = props.startTimeHours! < 10 ? "0" + props.startTimeHours?.toString() : props.startTimeHours?.toString();
            const reviewMinutes: string = props.startTimeHours! < 10 ? "0" + props.startTimeMinutes?.toString() : props.startTimeMinutes?.toString();

            SetReviewTimeText(reviewHour + ":" + reviewMinutes);
        } else {
            resetPanelData();
        }
    }, [isOpen]);

    // Update day of the week options when the
    //      user chooses the first week of the month
    //  Monday is not an option for the first week
    React.useEffect(() => {
        if (weekOfTheMonth == 1) {
            const propsDaysOptions: IDropdownOption[] = [];

            for (let i = 2; i < props.panelOptions.daysOptions.length; i += 1) {
                propsDaysOptions.push(props.panelOptions.daysOptions[i]);
            }

            SetDaysOptions(propsDaysOptions);
        } else {
            SetDaysOptions(props.panelOptions.daysOptions);
        }
    }, [weekOfTheMonth]);

    // Update Next Scheduled Date Label when user updates dates/times
    React.useEffect(() => {
        if (isOpen) {
            if (weekOfTheMonth !== undefined && dayOfTheWeek && hours !== undefined && minutes !== undefined) {
                SetJobOutsideOfLagTimeWindow(false);

                const nextJobDate: Date = GetNextScheduledDateTime(weekOfTheMonth, dayOfTheWeek, hours, minutes);
                SetNextScheduledLabel(_formattedDateString(nextJobDate));

                const now: Date = new Date();
                if (nextJobDate.getMonth() != now.getMonth()) {
                    SetJobTimeErrMsg(jobNextMonthMessage);
                } else if (nextJobDate.getTime() / 60000 - jobLagTime < now.getTime() / 60000) {
                    SetJobOutsideOfLagTimeWindow(true);
                    SetJobTimeErrMsg(jobLagTimeMessage);
                } else {
                    SetJobTimeErrMsg("");
                }
            }
        }
    }, [isOpen, hours, minutes, weekOfTheMonth, dayOfTheWeek]);

    // Functions for handling data in panel

    const onDayOptionChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        SetDayOfTheWeek(item?.text);
        if (item !== undefined && item?.text.toString().length > 0) {
            SetWeekDayErrMsg("");
        }
    };

    const _formattedDateString = (d :Date): string => {
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString("en-US", {hour:'2-digit', minute: '2-digit', hour12:false})}`;
    }

    const onJobNameChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
            if (newValue) {
                let hasInvalidChar: boolean = false;

                for (const c of newValue) {
                    if (invalidCharSet.has(c) ) {
                        hasInvalidChar = true;
                    }
                }

                if (hasInvalidChar) {
                    SetJobNameErrMsg("Job name cannot contain characters: " + invalidCharArray.join(", "));
                } else {
                    SetJobName(newValue);
                    if (newValue.length > 0) {
                        SetJobNameErrMsg("");
                    }
                    if (newValue.length >= 54) {
                        SetJobNameErrMsg("Job Name cannot be greater than 54");
                    }
                }
            } else {
                SetJobName("");
            }
        }, [],
    );

    const onTimePickerChange = (value: Duration): void => {
        const newHour: number = value.hours();
        const newMinute: number = value.minutes();

        if (newHour !== undefined) {
            SetHours(newHour);
            SetStartHoursErrMsg("");
        }

        if (newMinute !== undefined) {
            SetMinutes(newMinute);
            SetStartHoursErrMsg("");
        }

        const dispHour: string = newHour < 10 ? "0" + newHour.toString() : newHour.toString();
        const dispMinute: string = newMinute < 10 ? "0" + newMinute.toString() : newMinute.toString();

        SetReviewTimeText(dispHour + ":" + dispMinute);
        // ValidateJobScheduleRange(newHour, newMinute);
    };

    const onDurationOptionChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        SetDuration(item?.text);
        if (item !== undefined && item?.text.toString().length > 0) {
            SetWindowDurationErrMsg("");
        }
    };

    const onWeekOfMonthChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item !== undefined && item?.text.toString().length > 0) {
            SetWeekofMonthErrMsg("");
        }
        if (item !== undefined) {
            SetWeekOfTheMonth(toNumber(item?.text));
            if (item?.text.toString() === "1" && (dayOfTheWeek === "Monday" || dayOfTheWeek === "Tuesday")) {
                SetWeekofMonthErrMsg("No patching jobs can be scheduled before Wednesday during the first patching week.");
                SetRecurringDisabled(false);
                SetRecurring(true);
            } else if (item?.text.toString() === "5") {
                SetWeekofMonthErrMsg(weekFiveMessage);
                SetRecurring(false);
                SetRecurringDisabled(true);
            } else {
                SetWeekDayErrMsg("");
                SetRecurringDisabled(false);
                SetRecurring(true);
            }
        }
    };

    const onAllServersCheckboxChecked = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        if (isChecked !== undefined) {
            SetIsAllServerSelectedChecked(isChecked);
        }
    };

    const onRebootChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined): void => {
        switch (option?.key) {
            case "0":
                SetReboot(true);
                SetAlwaysReboot(false);
                break;
            case "1":
                SetReboot(false);
                SetAlwaysReboot(true);
                break;
            case "2":
                SetReboot(false);
                SetAlwaysReboot(false);
                break;
            default: 
                SetReboot(false);
                SetAlwaysReboot(false);
                break;
        }
    }

    const onRecurringChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, ischecked?: boolean): void => {
        if (ischecked !== undefined) {
            SetRecurring(ischecked);
        }
    };

    // API Calls //

    // Create or update job information
    const saveJobData = async (): Promise<any> => {
        const mainInputResults: string[] = ValidateMainInputParameters();
        const serverInputResults: string[] = ValidateServerInputParameters();

        if (mainInputResults.length === 0 && serverInputResults.length === 0) {
            SetPrimaryButtonText("Submitting...");
            const d = new Date();
            const timezoneOffset: number = (state.AuthStore.timezone?.offset !== undefined && state.AuthStore.timezone?.standardName !== null) 
                                    ? - state.AuthStore.timezone?.offset 
                                    : d.getTimezoneOffset();

            let apiUrl = "api/PatchingJob/";

            if (isAdd()) {
                apiUrl += "add";

                return await httpService.post({
                    url: apiUrl,
                    token: state.AuthStore.Token,
                    data: {
                        Reboot: reboot,
                        AlwaysReboot: alwaysReboot,
                        Recurring: recurring,
                        JobName: jobName,
                        WeekOftheMonth: weekOfTheMonth,
                        DayOftheWeek: dayOfTheWeek,
                        StartTimeHours: hours,
                        StartTimeMinutes: minutes,
                        Duration: duration,
                        PatchingServerRequest: selectedServers,
                        Offset: timezoneOffset,
                        IsAllServerSelected: isAllServerSelectedChecked,
                        ServiceTreeGUID : serviceTreeGUID,
                        LastUpdatedTimeZone: state.AuthStore.timezone?.standardName
                    },
                }).then((resp: any) => {
                    SetSecondaryButtonText("");
                    return resp?.data;
                }).catch((reason: any) => {
                    SetSecondaryButtonText("");
                    trackException(appInsights, reason, SeverityLevel.Error, "Manage Jobs", "saveJobData", "Add Patching Job", state.AuthStore, {});
                });
            } else {
                apiUrl += "update";
                return await httpService.post({
                    url: apiUrl,
                    token: state.AuthStore.Token,
                    data: {
                        ETag: eTag,
                        Reboot: reboot,
                        AlwaysReboot: alwaysReboot,
                        Recurring: recurring,
                        JobName: jobName,
                        WeekOftheMonth: weekOfTheMonth,
                        DayOftheWeek: dayOfTheWeek,
                        StartTimeHours: hours,
                        StartTimeMinutes: minutes,
                        Duration: duration,
                        PatchingServerRequest: selectedServers,
                        JobId: jobID,
                        Offset: timezoneOffset,
                        IsAllServerSelected: isAllServerSelectedChecked,
                        ServiceTreeGUID : serviceTreeGUID,
                        LastScheduledDateTime: lastScheduledDateTime,
                        NextScheduledDateTime: nextScheduledDateTime,
                        LastUpdatedTimeZone: state.AuthStore.timezone?.standardName
                    },
                }).then((resp: any) => {
                    SetSecondaryButtonText("");
                    return resp?.data;
                }).catch((reason: any) => {
                    SetSecondaryButtonText("");
                    trackException(appInsights, reason, SeverityLevel.Error, "Manage Jobs", "saveJobData", "Update Patching Job", state.AuthStore, {});
                });
            }
        } else {
            return mainInputResults.join(", ") + serverInputResults.join(", ");
        }
    };

    function closePanel() {
        dismissPanel();
        resetPanelData();
    }

    async function deleteOK() {
        SetDeleteJobSubtext("Processing...");
        SetPrimaryButtonText("");
        SetSecondaryButtonText("");

        if (props.jobID.toString() !== "00000000-0000-0000-0000-000000000000") {
            const deleteJob: IDeleteJob = {
                JobIds: [props.jobID],
                ServiceTreeGUID: serviceTreeGUID
            }

            await httpService.delete({
                url: "api/PatchingJob/delete",
                token: state.AuthStore.Token,
                params: deleteJob,
            }).then((resp: any) => {
                SetJobDeleted(true);
                trackEvent(appInsights, "Manage Jobs", "Delete Job", "Deleted job: " + props.jobID, state.AuthStore, {});
                SetDeleteJobSubtext("Job deleted. Please click COMPLETE");
                SetPrimaryButtonText("COMPLETE");
                SetSecondaryButtonText("");
            }).catch((reason: any) => {
                SetDeleteJobSubtext("Something went wrong, please restart application and try again.");
                SetPrimaryButtonText("COMPLETE");
                SetSecondaryButtonText("");
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Jobs", "Delete Job", "", state.AuthStore, {});
            });
        }
    }

    const postSave = () => {
        SetConfirmationTextHidden(false);
        SetPrimaryButtonText("Close");
        SetHideMainDiv(true);
        SetHideServerDiv(true);
        SetHideReviewDiv(true);
        SetHideCloseDiv(false);
        SetHideDeleteDiv(true);
    };

    const addServer = (serverData: IServerType) => {
        const newServers: IServerType[] = [...selectedServers, serverData];

        SetSelectedServers(newServers.sort((s1, s2) => {
            if (s1.ServerName > s2.ServerName) { return 1; }
            if (s2.ServerName > s1.ServerName) { return -1; }
            return 0;
        }));

        // remove from top list of servers
        let addedServerRemovedArray: IServerType[] = [];

        for (const s of availableServers) {
            if (s.ServerId !== serverData.ServerId) {
                addedServerRemovedArray = [...addedServerRemovedArray, {
                    ServerId: s.ServerId,
                    ServerName: s.ServerName,
                    ServiceName: s.ServiceName,
                    ServiceTreeGUID: s.ServiceTreeGUID,
                    AgentLastSeenDateTime: s.AgentLastSeenDateTime, 
                    LastScheduledDateTime: s.LastScheduledDateTime,
                    NextScheduledDateTime: s.NextScheduledDateTime,
                    PatchCompliance: PatchComplianceType.Compliant,
                    VulnerabilityCount: 0,
                    OSType: s.OSType,
                }];
            }
        }

        SetAvailableServers(addedServerRemovedArray.sort((s1, s2) => {
            if (s1.ServerName > s2.ServerName) { return 1; }
            if (s2.ServerName > s1.ServerName) { return -1; }
            return 0;
        }));
    };

    const removeServer = (serverData: IServerType) => {
        const currentServers: IServerType[] = selectedServers;
        const newServers: IServerType[] = [];

        // search current servers and remove server requested to be removed
        for (const s in currentServers) {
            if (currentServers[s].ServerId !== serverData.ServerId) {
                newServers.push(currentServers[s]);
            }
        }

        SetSelectedServers(newServers.sort((s1, s2) => {
            if (s1.ServerName > s2.ServerName) { return 1; }
            if (s2.ServerName > s1.ServerName) { return -1; }
            return 0;
        }));

        // add to top list of servers
        const addBackServerArray: IServerType[] = [...availableServers, serverData];

        SetAvailableServers(addBackServerArray.sort((s1, s2) => {
            if (s1.ServerName > s2.ServerName) { return 1; }
            if (s2.ServerName > s1.ServerName) { return -1; }
            return 0;
        }));
    };

    // Open panel -- fetch data if editing an existing job
    const openPanelData = async () => {
        SetConfirmationTextHidden(true); // hide confirmation text
        SetJobNameErrMsg("");
        SetSelectedServers([]);
        SetAllServerDataFiltered([]);
        SetAvailableServers([]);

        const filteredServers = props.panelOptions.serverOptions.filter((i: IServerType) => i.ServiceName === props.serviceName);

        SetAllServerDataFiltered(filteredServers);
        const editJobID: Guid = props.jobID;

        // Load server data for job
        if (props.requestType === OpenPanelType.Edit) {
            SetEditLinkText("Loading");
            
            await httpService.get({
                url: "api/PatchingServer",
                token: state.AuthStore.Token,
                params: {
                    jobId: editJobID,
                },
            }).then((res: any) => {
                const data = JSON.stringify(res.data);
                if (data !== "" && data !== undefined && data !== null) {
                    let fetchedServers: IServerType[] = [];
                    let fetchedServiceTreeGUID: string = "";
                    const parsedData = JSON.parse(data);

                    for (const p of parsedData) {
                        if (p.serverId !== null && typeof p.serverName !== 'undefined' && p.serverName) {
                            fetchedServiceTreeGUID = p.serviceTreeGUID;

                            const serverTypeValue: IServerType = {
                                ServerId: toNumber(p.serverId),
                                ServerName: p.serverName,
                                ServiceName: props.serviceName,
                                ServiceTreeGUID: p.serviceTreeGUID,
                                AgentLastSeenDateTime: p.AgentLastSeenDateTime!, // XXX 
                                LastScheduledDateTime: new Date(p.LastScheduledDateTime),
                                NextScheduledDateTime: new Date(p.NextScheduledDateTime),
                                PatchCompliance: p.PatchCompliance,
                                VulnerabilityCount: p.VulnerabilityCount,
                                OSType: p.OSType,
                            };

                            fetchedServers = [...fetchedServers, serverTypeValue];
                        }
                    }
                    
                    SetSelectedServers(fetchedServers.sort((s1, s2) => {
                        if (s1.ServerName > s2.ServerName) { return 1; }
                        if (s2.ServerName > s1.ServerName) { return -1; }
                        return 0;
                    }));

                    const uniqueRemainingServers: IServerType[] = filteredServers.filter(({ ServerId: id1 }) => !fetchedServers.some(({ ServerId: id2 }) => id2 === id1));

                    SetAvailableServers(uniqueRemainingServers.sort((s1, s2) => {
                        if (s1.ServerName > s2.ServerName) { return 1; }
                        if (s2.ServerName > s1.ServerName) { return -1; }
                        return 0;
                    }));
                    SetSelectedServiceTreeGUID(fetchedServiceTreeGUID.toString());
                    openPanel();
                    SetEditLinkText(props.linkText);
                } else {
                    SetEditLinkText(res.data.message);
                }

            }).catch((reason: any) => {
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Jobs", "Manage Jobs Panel", "openPanelData", state.AuthStore, {});
            });
        } else { // ADD JOB
            trackEvent(appInsights, "Manage Jobs", "Manage Jobs Panel", "Add Job", state.AuthStore, {});
            openPanel();
            SetEditLinkText(props.linkText);
        }       
    };

    const resetPanelData = () => {
        SetETag("");
        SetJobName("");
        SetJobID(Guid.createEmpty());
        SetSelectedServers([]);
        SetDayOfTheWeek("");
        SetWeekOfTheMonth(0);
        SetHours(1);
        SetMinutes(0);
        SetDuration("");
        SetIsAllServerSelectedChecked(false);
        SetAllServerDataFiltered([]);
        SetAvailableServers([]);

        // keep service tree information if user filters a service on the Manage Jobs page
        SetSelectedServiceName(props.serviceName);
        SetSelectedServiceTreeGUID(props.serviceTreeGUID);

        if (props.serviceName !== "" && props.serviceTreeGUID !== "") {
            filterByService({
                ServiceName: props.serviceName,
                ServiceTreeGUID: props.serviceTreeGUID,
            });
        } else {
            SetServiceSelected(false);
        }

        const dispHour: string = hours < 10 ? "0" + hours.toString() : hours.toString();
        const dispMinute: string = minutes < 10 ? "0" + minutes.toString() : minutes.toString();

        SetReviewTimeText(dispHour + ":" + dispMinute);
        SetReboot(true);
        SetAlwaysReboot(false);
        SetRecurring(true);
        SetLastScheduledDateTime(new Date());
        SetNextScheduledDateTime(new Date());
        SetSecondaryButtonText("");
        SetPrimaryButtonText("Next");
        SetCurrentPageSection(SelectedJobSections.MainInfo);
        SetHideMainDiv(false);
        SetHideServerDiv(true);
        SetHideReviewDiv(true);
        SetHideCloseDiv(true);
        SetHideDeleteDiv(true);
        SetJobDeleted(false);
        SetDeleteJobSubtext("Are you sure you want to delete this job?");
        ClearPanelErrors();
    };

    // DataGrid Columns
    const serversColumns: IColumn[] = [
        {
            key: "ServerName",
            name: "Available Servers",
            fieldName: "ServerName",
            minWidth: 60,
            maxWidth: 140,
            isResizable: true,
        },
        {
            key: "Add",
            name: "Add",
            fieldName: "Add",
            ariaLabel: "Add Server",
            minWidth: 40,
            maxWidth: 40,
            isIconOnly: false,
            isResizable: true,
        },
    ];

    // DataGrid Columns
    const selectedServersColumns: IColumn[] = [
        {
            key: "ServerName",
            name: "Selected Servers",
            fieldName: "ServerName",
            minWidth: 60,
            maxWidth: 140,
            isResizable: true,
        },
        {
            key: "Remove",
            name: "Remove",
            fieldName: "Remove",
            ariaLabel: "Remove Server",
            minWidth: 40,
            maxWidth: 40,
        },
    ];

    // DataGrid Columns
    const selectedROServersColumns: IColumn[] = [
        {
            key: "ServerName",
            name: "Selected Servers",
            fieldName: "ServerName",
            minWidth: 30,
            maxWidth: 180,
            isResizable: true,
        },
    ];

    const renderActionRowAddServer = (item: IServerType): React.ReactNode => {
        return (
            <TooltipHost
                key={"addButton"}
                aria-label={addServerAriaLabel + item.ServerName}
                content={addServerAriaLabel + item.ServerName}
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 }}
            >
                <IconButton
                    key={"addButton"}
                    label={"addButton"}
                    ariaLabel={addServerAriaLabel + item.ServerName}
                    iconProps={{
                        iconName: "AddIn",
                    }}
                    id={"AddServer" + item.ServerName}
                    role={"button"}
                    onClick={() => {
                        const serverToBeAdded: IServerType = {
                            ServerId: item.ServerId,
                            ServerName: item.ServerName,
                            ServiceName: item.ServiceName,
                            ServiceTreeGUID: item.ServiceTreeGUID,
                            AgentLastSeenDateTime: item.AgentLastSeenDateTime, 
                            LastScheduledDateTime: item.LastScheduledDateTime,
                            NextScheduledDateTime: item.NextScheduledDateTime,
                            PatchCompliance: PatchComplianceType.Compliant,
                            VulnerabilityCount: 0,
                            OSType: item.OSType,
                        };
                        addServer(serverToBeAdded);
                    }
                } />
            </TooltipHost>
        );
    };

    const renderActionRowRemoveServer = (item: IServerType): React.ReactNode => {
        const visibleActions: React.ReactNode[] = [];
        visibleActions.push(
            <TooltipHost
                key={"removeButton"}
                aria-label={removeServerAriaLabel + item.ServerName}
                content={removeServerAriaLabel + item.ServerName}
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 }}
            >
                <IconButton
                    key={"removeButton"}
                    label={"removeButton"}
                    ariaLabel={removeServerAriaLabel + item.ServerName}
                    iconProps={{
                        iconName: "Remove",
                    }}
                    role={"button"}
                    onClick={() => {
                        const serverToBeRemoved: IServerType = {
                            ServerId: item.ServerId,
                            ServerName: item.ServerName,
                            ServiceName: item.ServiceName,
                            ServiceTreeGUID: item.ServiceTreeGUID,
                            AgentLastSeenDateTime: item.AgentLastSeenDateTime, 
                            LastScheduledDateTime: item.LastScheduledDateTime,
                            NextScheduledDateTime: item.NextScheduledDateTime,
                            PatchCompliance: PatchComplianceType.Compliant,
                            VulnerabilityCount: 0,
                            OSType: item.OSType,
                        };
                        removeServer(serverToBeRemoved);
                    }
                } />
            </TooltipHost>,
        );

        return (
            <ListItemActionSet
                visibleActions={visibleActions}
            />
        );
    };

    const openJobSection = (section: SelectedJobSections) => {
        switch (section) {
            case SelectedJobSections.MainInfo: {
                SetCurrentPageSection(SelectedJobSections.MainInfo);
                SetHideMainDiv(false);
                SetHideServerDiv(true);
                SetHideReviewDiv(true);
                SetHideCloseDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case SelectedJobSections.ServerInfo: {
                SetCurrentPageSection(SelectedJobSections.ServerInfo);
                SetHideMainDiv(true);
                SetHideServerDiv(false);
                SetHideReviewDiv(true);
                SetHideCloseDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case SelectedJobSections.Review: {
                SetCurrentPageSection(SelectedJobSections.Review);
                SetHideMainDiv(true);
                SetHideServerDiv(true);
                SetHideReviewDiv(false);
                SetHideCloseDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case SelectedJobSections.Close: {
                SetCurrentPageSection(SelectedJobSections.Close);
                SetHideMainDiv(true);
                SetHideServerDiv(true);
                SetHideReviewDiv(true);
                SetHideCloseDiv(false);
                SetHideDeleteDiv(true);
                return;
            }
            case SelectedJobSections.Delete: {
                SetCurrentPageSection(SelectedJobSections.Delete);
                SetHideMainDiv(true);
                SetHideServerDiv(true);
                SetHideReviewDiv(true);
                SetHideCloseDiv(true);
                SetHideDeleteDiv(false);

                SetPrimaryButtonText("DELETE");
                SetSecondaryButtonText("Prev");
                return;
            }
        }
    };

    const panelPrimaryButtonAction = () => {
        switch (currentPanelSection) {
            case SelectedJobSections.MainInfo: {
                if (ValidateMainInputParameters().length !== 0) {
                    // invalid data
                    // do nothing
                } else {
                    openJobSection(SelectedJobSections.ServerInfo);
                    SetPrimaryButtonText("Review");
                    SetSecondaryButtonText("Prev");
                }
                return;
            }
            case SelectedJobSections.ServerInfo: {
                if (ValidateServerInputParameters().length !== 0) {
                    // invalid params
                    // do nothing
                } else {
                    openJobSection(SelectedJobSections.Review);
                    SetPrimaryButtonText("Submit");
                }
                return;
            }
            case SelectedJobSections.Review: {
                saveJobData().then((data: string) => {
                    if (data === "Success") {
                        SetSubmitResult("Submit Successful. Please Close.");
                    } else {
                        SetSubmitResult(data);
                    }

                    postSave();
                });

                openJobSection(SelectedJobSections.Close);
                SetPrimaryButtonText("Close");
                return;
            }
            case SelectedJobSections.Close: {
                dismissPanel();
                props.refreshDataGrid();
                resetPanelData();
                return;
            }
            case SelectedJobSections.Delete: {
                if (primaryButtonText === "DELETE") {
                    deleteOK();
                } else {
                    SetDeleteJobSubtext("Are you sure you want to delete this job?");
                    dismissPanel();
                    props.refreshDataGrid();
                    resetPanelData();
                }
                return;
            }
            default: {
                return;
            }
        }
    };

    const panelSecondaryButtonAction = async () => {
        switch (currentPanelSection) {
            case SelectedJobSections.MainInfo: {
                // no secondary button
                return;
            }
            case SelectedJobSections.ServerInfo: {
                openJobSection(SelectedJobSections.MainInfo);
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                return;
            }
            case SelectedJobSections.Review: {
                openJobSection(SelectedJobSections.ServerInfo);
                SetPrimaryButtonText("Review");
                SetSecondaryButtonText("Back");
                return;
            }
            case SelectedJobSections.Close: {
                return;
            }
            case SelectedJobSections.Delete: {
                openJobSection(SelectedJobSections.MainInfo);
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                return;
            }
            default: {
                return;
            }
        }
    };

    // helper function for panel type (add vs edit)
    const isAdd = () => {
        if (props.requestType === OpenPanelType.Add) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div>
            {/* Link doesn't open panel is text is "Loading" or another panel is open */}
            {/* Edit link or Add Link, depending on where the Panel is loaded */}
            {!isAdd() ? 
                (
                    <Link 
                        id={panelLinkIdText} 
                        aria-label={panelLinkIdText} 
                        onClick={(editLinkText === "Loading" || isOpen) ? () => {} : openPanelData}
                    >
                            {editLinkText}
                    </Link>
                ) : 
                (
                    <PrimaryButton 
                        id={panelLinkIdText}
                        aria-label={panelLinkIdText}
                        onClick={isOpen ? () => {} : openPanelData}
                    >
                        {editLinkText}
                    </PrimaryButton>
                )
            }
            {/* Only call Panel element when it is opened by the user */}
            {isOpen &&
                <CoherencePanel
                    titleText={isAdd() ? "Create New Job" : jobName}
                    headerText={jobName}
                    isOpen={isOpen}
                    hasCloseButton={true}
                    onDismiss={() => {
                        let closeIt: boolean = true;

                        if (currentPanelSection === SelectedJobSections.Close && confirmationTextHidden) {
                            closeIt = false;
                        }

                        if (currentPanelSection === SelectedJobSections.Delete && !jobDeleted && secondaryButtonText !== "DELETE") {
                            closeIt = false;
                        }

                        if (closeIt) {
                            closePanel();
                            if (currentPanelSection === SelectedJobSections.Close && !confirmationTextHidden) {
                                props.refreshDataGrid();
                                resetPanelData();
                            }
                        }
                    }}
                    onLightDismissClick={() => {
                        let closeAndRefresh: boolean = false;

                        if (currentPanelSection === SelectedJobSections.Close && !confirmationTextHidden) {
                            closeAndRefresh = true;
                        }

                        if (currentPanelSection === SelectedJobSections.Delete && jobDeleted) {
                            closeAndRefresh = true;
                        }

                        if (closeAndRefresh) {
                            closePanel();
                            props.refreshDataGrid();
                            resetPanelData();
                        }
                    }}
                    closeButtonAriaLabel="Close"
                    onRenderFooter={{
                        primaryButton: {
                            text: primaryButtonText,
                            onAction: (() => {
                                panelPrimaryButtonAction();
                            }),
                            disabled: (currentPanelSection === SelectedJobSections.Close && confirmationTextHidden),
                        },
                        secondaryButton: {
                            text: secondaryButtonText,
                            onAction: (() => {
                                panelSecondaryButtonAction();
                            }),
                            disabled: (currentPanelSection === SelectedJobSections.Close),
                        },
                    }}
                    isFooterAtBottom={true}
                    panelSize={CoherencePanelSize.medium}
                >
                <main>
                    <div style={{ padding: "0px" }} hidden={hideMainDiv} id="mainDiv">
                        <div style={{ textAlign: "right"}}>
                            <TooltipHost
                                content="Delete Job"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <ActionButton
                                    iconProps={{ iconName: "Delete" }}
                                    text={ "Delete"}
                                    hidden={isAdd()}
                                    disabled={isAdd()}
                                    onClick={() => openJobSection(SelectedJobSections.Delete)}
                                    ariaLabel={"Delete Job"}
                                    ariaDescription={"Delete Job"}
                                />
                            </TooltipHost>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Custom Job Name"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TextField required
                                    label="Custom Job Name"
                                    styles={textStyles}
                                    ariaLabel={jobNameErrMsg === "" ? "Custom Job Name" : jobNameErrMsg}
                                    onChange={onJobNameChange}
                                    value={jobName}
                                    maxLength={54}
                                    placeholder={"Job Name"}
                                    componentRef={jobNameRef}
                                    id={"jobNameTextField"}
                                />
                            </TooltipHost>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {jobNameErrMsg}
                            </p>
                        </div>

                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Week of the Patching Cycle: 1 = Week of Patch Tuesday  2 = Week after Patch Tuesday 3 = Two weeks after Patch Tuesday 4 = Three Weeks after Patch Tuesday"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <Dropdown required
                                    id="weekOfMonthDropdown"
                                    placeholder="Select Week"
                                    label="Week of the Patching Cycle"
                                    ariaLabel={weekofMonthErrMsg === "" ? "Week of the Patching Cycle" : weekofMonthErrMsg}
                                    aria-required="false"
                                    options={props.panelOptions.weekOfMonthOptions}
                                    styles={dropdownStyles}
                                    selectedKey={weekOfTheMonth ? weekOfTheMonth : "default"}
                                    onChange={onWeekOfMonthChange}
                                    componentRef={weekRef}
                                />
                            </TooltipHost>
                        </div>
                        <div>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {weekofMonthErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select a day of the week you want your server(s) to patch"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <Dropdown required
                                    id="dayOfWeekDropdown"
                                    placeholder="Select Day of the Week"
                                    label="Day of the Week"
                                    ariaLabel={weekDayErrMsg === "" ? "Day of the Week" : weekDayErrMsg}
                                    aria-required="false"
                                    options={daysOptions}
                                    styles={dropdownStyles}
                                    selectedKey={dayOfTheWeek ? dayOfTheWeek : "default"}
                                    onChange={onDayOptionChange}
                                    componentRef={dayRef}
                                />
                            </TooltipHost>
                        </div>
                        <div>
                            <p role="alert" aria-live="polite" style={{ color: "#4D00C3", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {weekDayErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select the start time for your patch window"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TimePicker
                                    value={dayjs.duration({ hours, minutes })}
                                    label={timeZoneLabel}
                                    required={true}
                                    onChanged={(value) => onTimePickerChange(value)}
                                    componentRef={jobLagRef}
                                />
                            </TooltipHost>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {jobTimeErrMsg}
                            </p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <p style={{ marginLeft: "auto" }}>Next Job Date: {nextScheduledLabel}</p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select how long you want the patch window to run"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <Dropdown required
                                    id="durationDropdown"
                                    placeholder="Select Window Duration"
                                    label="Window Duration (Hours)"
                                    ariaLabel={windowDurationErrMsg === "" ? "Window Duration" : windowDurationErrMsg}
                                    aria-required="false"
                                    options={props.panelOptions.durationOptions}
                                    styles={dropdownStyles}
                                    onChange={onDurationOptionChange}
                                    selectedKey={duration ? duration : "default"}
                                    componentRef={durationRef}
                                />
                            </TooltipHost>
                        </div>
                        <div>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {windowDurationErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "5px" }}>
                            <TooltipHost
                                content={rebootToolTip}
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <ChoiceGroup 
                                    defaultSelectedKey={ reboot ? "0" : alwaysReboot ? "1" : "2" }
                                    options={rebootOptions} 
                                    onChange={onRebootChange} 
                                    label="Reboot Options"
                                    required={true} 
                                />
                            </TooltipHost>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Recurring Patching is every month same as Primary window"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <div style={{ padding: "5px" }}>
                                    <Checkbox styles={dropdownStyles}
                                        label="Recurring?"
                                        ariaLabel="Recurring?"
                                        aria-describedby={tooltipId}
                                        aria-required="false"
                                        onChange={onRecurringChange}
                                        checked={recurring}
                                        disabled={recurringDisabled}
                                    />
                                </div>
                            </TooltipHost>
                        </div>
                    </div>
                    <div style={{ padding: "0px" }} hidden={hideServerDiv} id="ServerDiv">
                        <div style={{ padding: "0px" }}>
                            <div hidden={!isAdd()}>
                                <OnboardedServicesDropdown
                                    id="serviceDropdownPanel"
                                    serviceTreeGuidFromParam={props.serviceTreeGUID}
                                    onSelectService={filterByService}
                                    disabled={selectedServers.length > 0 || isAllServerSelectedChecked}
                                />
                                <p>*Jobs are service specific.*</p>
                            </div>
                            <div hidden={isAdd()}>
                                <p><b>Service</b>: {serviceName}</p>
                                <p>*Jobs are service specific. Please create a new job if you'd like to select a different service.*</p>
                            </div>
                            <div>
                                <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {serverErrMsg}
                                </p>
                            </div>
                            <Stack>
                                <div hidden={!serviceSelected}>
                                    <Checkbox styles={dropdownStyles}
                                        id="alwaysIncludeAllServersCheckBox"
                                        onChange={onAllServersCheckboxChecked}
                                        label="Always Include All Servers From This Service"
                                        ariaLabel="Always Include All Servers From This Service Button"
                                        aria-required="false"
                                        checked={isAllServerSelectedChecked}
                                    />
                                </div>
                            </Stack>
                            <div hidden={isAllServerSelectedChecked || !serviceSelected}>
                                    <Stack horizontal tokens={stackTokens} verticalAlign="center">
                                        <span>
                                            <h1>Add more servers for this job:</h1>
                                        </span>
                                        <TooltipHost
                                            content="Add All Servers"
                                            id={tooltipId}
                                            calloutProps={calloutProps}
                                        >
                                            <DefaultButton
                                                ariaLabel={"Button Add All Servers to this job"}
                                                onClick={addAllServersFromService}
                                                text="Add All Servers"
                                                aria-live="assertive"
                                                role="button"
                                            />
                                            {announcedServersAddedAlert}
                                            </TooltipHost>
                                    </Stack>
                                    <TextField
                                        className={ChildClass}
                                        label="Search by Server Name:"
                                        ariaLabel="TextField Search by Server Name:"
                                        onChange={onFilterServerName}
                                        styles={textFieldStyles}
                                        placeholder={"Server Name"}
                                    />
                                {announcedServersFilter}
                                <div style={{ position: "relative", height: "75%" }}>
                                    <ScrollablePane style={{ height: "200px", position: "relative" }}>
                                        <DetailsList
                                            columns={serversColumns}
                                            items={availableServers}
                                            compact={true}
                                            onRenderDetailsHeader={RenderStickyHeader}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                            onRenderItemColumn={renderItemColumn.bind(this)}
                                        />
                                    </ScrollablePane>
                                </div>
                                <br />
                                <Stack horizontal tokens={stackTokens} verticalAlign="center">
                                    <span>
                                        <h1>Selected Servers:</h1>
                                    </span>
                                    <span>
                                        <DefaultButton
                                            onClick={removeAllServersFromJob}
                                            text="Remove All Servers"
                                            aria-label={"Remove All Servers"}
                                            ariaLabel={"Button Remove All Servers"}
                                        />
                                        {announcedServersRemovedAlert}
                                    </span>
                                </Stack>
                                <div hidden={isAllServerSelectedChecked} style={{ position: "relative", height: "75%" }}>
                                    <ScrollablePane style={{ height: "200px", position: "relative" }}>
                                        <DetailsList
                                            columns={selectedServersColumns}
                                            items={selectedServers}
                                            compact={true}
                                            onRenderDetailsHeader={RenderStickyHeader}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                            onRenderItemColumn={renderItemColumn.bind(this)}
                                        />
                                    </ScrollablePane>
                                </div>
                                <div hidden={!isAllServerSelectedChecked}>
                                    <span>All servers in this service are selected for this job.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "0px" }} hidden={hideReviewDiv} id="ReviewDiv">
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="New Job Name"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                Custom Job Name
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {jobName}
                                </label>
                            </TooltipHost>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {jobNameErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Week of the Patching Cycle: 1 = Week of Patch Tuesday  2 = Week after Patch Tuesday 3 = Two weeks after Patch Tuesday 4 = Three Weeks after Patch Tuesday"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                    Week of the Patching Cycle
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {weekOfTheMonth?.toString() ? weekOfTheMonth.toString() : "default"}
                                </label>
                            </TooltipHost>
                        </div>
                        <div>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {weekofMonthErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select a day of the week you want your server(s) to patch"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                    Day of the Week
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                {dayOfTheWeek ? dayOfTheWeek : "default"}
                                </label>

                            </TooltipHost>
                        </div>
                        <div>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {weekDayErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select the start time for your patch window"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                    {timeZoneLabel}
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {reviewTimeText}
                                </label>
                            </TooltipHost>
                        </div>
                        <div>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {startHoursErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select how long you want the patch window to run"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                    Window Duration(in hours)
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {duration ? duration : "default"}
                                </label>
                            </TooltipHost>
                        </div>
                        <div>
                            <p role="alert" aria-live="polite" style={{ color: Colors.VisualIndicators.RedDark, paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                {windowDurationErrMsg}
                            </p>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                Reboot?
                            </label>
                            <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                { reboot ? "Reboot if required" : alwaysReboot ? "Always reboot" : "Do not reboot" }
                            </label>
                        </div>
                        <div style={{ padding: "0px" }}>
                        <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                Recurring?
                            </label>
                            <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                { recurring ? "Recurring job" : "Nonrecurring job" }
                            </label>
                        </div>
                        <br />
                        <div style={{ position: "relative", height: "75%" }}>
                            <ScrollablePane style={{ height: "175px", position: "relative" }}>
                                <DetailsList
                                    columns={selectedROServersColumns}
                                    items={isAllServerSelectedChecked ? availableServers : selectedServers}
                                    compact={true}
                                    onRenderDetailsHeader={RenderStickyHeader}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                />
                            </ScrollablePane>
                        </div>
                    </div>
                    <div style={{ padding: "0px" }} hidden={hideCloseDiv} id="CloseDiv">
                        <span hidden={!confirmationTextHidden}>
                            <Spinner label="Submitting job..." ariaLive="assertive" labelPosition="bottom" />
                        </span>
                        <span hidden={confirmationTextHidden}>
                            <p role="alert" aria-live="assertive"><b>Results</b>: {submitResult}</p>
                        </span>
                    </div>
                    <div style={{ padding: "0px" }} hidden={hideDeleteDiv} id="DeleteDiv">
                        <p role="alert" aria-live="assertive">{deleteJobSubtext}</p>
                    </div>
                </main>
            </CoherencePanel>}
        </div>
    );

    // Filter by Server name
    function onFilterServerName(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        let newAvailableServers: IServerType[] = [];
        const allOrFilteredServers: IServerType[] = allServerDataFiltered
            .filter((a) => selectedServers.every((s) => s.ServerName !== a.ServerName));

        newAvailableServers = allOrFilteredServers;

        if (newValue) {
            const serversByComma: string[] = newValue.replaceAll(/[;\n]/g, ",").split(",");
            newAvailableServers = [];

            for (const s of serversByComma) {
                const serverNoWhiteSpace: string = s.trim();
                const searchResults: IServerType[] = serverNoWhiteSpace ? allOrFilteredServers.filter((a) => a.ServerName?.toLowerCase().includes(s.toLowerCase())) : [];
                newAvailableServers = newAvailableServers.concat(searchResults);
            }

            if (newAvailableServers.length > 0) {
                SetAnnounceServersFiltered(<Announced message={`Showing ${newAvailableServers.length} Results for the server name`} aria-live="assertive" />);
            } else {
                SetAnnounceServersFiltered(<Announced message="No Matching records found" aria-live="assertive" />);
            }
        }

        SetAvailableServers(newAvailableServers.sort((s1, s2) => s1.ServerName.localeCompare(s2.ServerName)));
    }

    function filterByService(serviceSelection: IServiceDataType) {
        if (serviceSelection.ServiceTreeGUID !== "") {
            const data: IServerType[] = props.panelOptions.serverOptions.filter((i: IServerType) => i.ServiceTreeGUID.toString() == serviceSelection.ServiceTreeGUID);

            if (data?.length > 0) {
                SetAnnounceServersFiltered(<Announced message={"Showing " + data.length + " Results for the server name"} aria-live="assertive" />);
            } else {
                SetAnnounceServersFiltered(<Announced message={"No Matching records found"} aria-live="assertive" />);
            }

            SetSelectedServiceName(serviceSelection.ServiceName);
            SetSelectedServiceTreeGUID(serviceSelection.ServiceTreeGUID);
            SetAllServerDataFiltered(data.sort((s1, s2) => {
                if (s1.ServerName > s2.ServerName) { return 1; }
                if (s2.ServerName > s1.ServerName) { return -1; }
                return 0;
            }));

            const uniqueRemainingServers: IServerType[] = data.filter(({ ServerId: id1 }) => !selectedServers.some(({ ServerId: id2 }) => id2 === id1));

            SetAvailableServers(uniqueRemainingServers.sort((s1, s2) => {
                if (s1.ServerName > s2.ServerName) { return 1; }
                if (s2.ServerName > s1.ServerName) { return -1; }
                return 0;
            }));
            
            SetServiceSelected(true);
        }
    }

    function addAllServersFromService() {
        // add to selected
        const currentSelectedServers: IServerType[] = selectedServers;

        for (const s of availableServers) {
            currentSelectedServers.push(s);
        }

        SetSelectedServers(currentSelectedServers.sort((s1, s2) => {
            if (s1.ServerName > s2.ServerName) { return 1; }
            if (s2.ServerName > s1.ServerName) { return -1; }
            return 0;
        }));

        // remove from available
        SetAvailableServers([]);
        SetAnnounceAllServersAddedAlert(<Announced message={"All Servers Added To Job"} aria-live="assertive" />);
    }

    function removeAllServersFromJob() {
        // add to available
        const currentAvailableServers: IServerType[] = availableServers;

        for (const s of selectedServers) {
            currentAvailableServers.push(s);
        }

        SetAvailableServers(currentAvailableServers.sort((s1, s2) => {
            if (s1.ServerName > s2.ServerName) { return 1; }
            if (s2.ServerName > s1.ServerName) { return -1; }
            return 0;
        }));

        // remove from selected
        SetSelectedServers([]);
        SetAnnounceAllServersRemovedAlert(<Announced message={"All Servers Removed From Job"} aria-live="assertive" />);
    }

    function ValidateServerInputParameters() {
        const status: string[] = [];
        if ((selectedServers !== undefined && selectedServers.length === 0) && !isAllServerSelectedChecked) {
            SetServerErrMsg(noServersMessage);
            status.push(noServersMessage);
        }

        return status;
    }

    function ValidateMainInputParameters() {
        const status: string[] = [];

        if (jobName !== undefined && jobName?.trim().toString().length === 0) {
            SetJobNameErrMsg(blankJobMessage);
            status.push(blankJobMessage);
        } else if (jobName !== undefined && jobName?.trim().toString().length > 54) {
            SetJobNameErrMsg(jobNameTooLongMessage);
            status.push(jobNameTooLongMessage);
        } else {
             
            const regExFirstChar = new RegExp("^[a-zA-Z0-9]");//first char validation
            const regExMiddleChar = new RegExp("^[a-zA-Z0-9_-]+$"); //middle char validation
            const regExLastChar = new RegExp("[a-zA-Z0-9_]$");//last char validation  
            const resFirst = regExFirstChar.test(jobName);
            const resMiddle = regExMiddleChar.test(jobName);
            const resLast = regExLastChar.test(jobName);

            let invalidMsg: string = "";
            if (!resFirst) {
                invalidMsg += firstchar ;
            }
            if (!resMiddle) {
                invalidMsg += middlechars;

            }
            if (!resLast) {
                invalidMsg += lastchar;
            }
            if (invalidMsg.length > 0) {
                SetJobNameErrMsg(invalidMsg);
                status.push(invalidMsg);
            }
            else
            {
               SetJobNameErrMsg("");
            } 
        }

        if (dayOfTheWeek !== undefined && dayOfTheWeek.toString().length === 0) {
            SetWeekDayErrMsg(dayCycleMessage);
            status.push(dayCycleMessage);
        }

        if (weekOfTheMonth !== undefined) {
            if (weekOfTheMonth !== undefined && weekOfTheMonth === 0) {
                SetWeekofMonthErrMsg(weekCycleMessage);
                status.push(weekCycleMessage);
            }
        }

        if (duration !== undefined && duration.length === 0) {
            SetWindowDurationErrMsg(blankDurationMessage);
            status.push(blankDurationMessage);
        }

        if (jobOutsideOfLagTimeWindow) {
            status.push(jobLagTimeMessage);
        }

        FocusOnErrors();

        return status;
    }

    function FocusOnErrors() {
        if (jobNameErrMsg !== "") {
            jobNameRef.current?.focus();
        } else if (weekofMonthErrMsg !== "" && weekofMonthErrMsg !== weekFiveMessage) {
            weekRef.current?.focus();
        } else if (weekDayErrMsg !== "") {
            dayRef.current?.focus();
        } else if (windowDurationErrMsg !== "") {
            durationRef.current?.focus();
        } else if (jobLagTimeMessage !== "") {
            jobLagRef.current?.focus();
        }
    }

    function ClearPanelErrors() {
        SetJobNameErrMsg("");
        SetServerErrMsg("");
        SetWeekDayErrMsg("");
        SetWeekofMonthErrMsg("");
        SetStartHoursErrMsg("");
        SetWindowDurationErrMsg("");
        SetJobTimeErrMsg("");
    }

    function renderItemColumn(item?: IServerType, index?: number, column?: IColumn) {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IServerType];
            switch (column.fieldName) {
                case "Add": {
                    return renderActionRowAddServer(item);
                }
                case "Remove": {
                    return renderActionRowRemoveServer(item);
                }
                default:
                    return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{fieldContent.toString()}</span>;
            }
        }
    }
};
