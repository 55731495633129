import { MessageBar, MessageBarType, mergeStyles, Text } from "@fluentui/react";
import React from "react";
import { RootContext } from "../Stores/RootStore";
import { useAppInsights } from "../AppInsights/AppInsights";
import { getAdminMessages } from "../../services/ApiService/Requests";
import { DataActionEnum } from "../../services/DataContext/DataActions";

export interface IAdminMessage {
    id: string;
    type: MessageBarType;
    text: string;
    enabled: boolean;
    pages: string[];
}

export interface IAdminMessageResponse {
    data: IAPIResponse;
}

interface IAPIResponse {
    message: any
    values: IAdminMessage[];
}

export interface IAdminMessageBannerProps {
    page: string;
}

export const AdminMessageBanner: React.FunctionComponent<IAdminMessageBannerProps> = (props: IAdminMessageBannerProps) => {
    const [adminMessages, SetAdminMessages] = React.useState<IAdminMessage[]>([]);
    const { state, dispatch } = React.useContext(RootContext);

    const appInsights = useAppInsights();
    
    // useEffect 'cleaup' component to supress warning: "Can't perform a React state update on an unmounted component"
    React.useEffect(() => {
        return () => {};
      }, []);

    React.useEffect(() => {
        if (state.AuthStore.IsLoggedIn) {
            updateMessages();
        }
    }, [state]);

    const updateMessages = () => {
        if (state.DataStore.AdminMessages.length == 0) {
            getAdminMessages(state, appInsights).then((response: IAdminMessageResponse) => {
                if (response?.data) {
                    const enabledMessages: IAdminMessage[] = response?.data.values;
                    SetAdminMessages(enabledMessages);
                    
                    dispatch({
                        type: DataActionEnum.UPDATE_ADMIN_MESSAGES,
                        adminMessages: enabledMessages,
                    });
                }
            }).catch((reason: any) => {
                console.log(reason);
            });
        } else {
            SetAdminMessages(state.DataStore.AdminMessages);
        }
    }

    return (
        <div>
            {adminMessages!.filter(x => x.pages?.includes(props.page) && x.enabled).map((message: IAdminMessage, index: number) => {
                return (
                    <MessageBar
                        key={index}
                        messageBarType={message.type}
                        isMultiline={true}
                        className = {mergeStyles({})}
                        >
                            <Text variant="medium">
                                {message.text}
                            </Text>
                    </MessageBar>
                );
            })}
        </div>
    );
}