import { AccountInfo } from "@azure/msal-browser";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { trackException } from "../../components/AppInsights/LoggingHelper";
import { prodAuthProvider } from "./AuthProvider";
import { IAuthState } from "./AuthReducer";

export const AuthToken = async (userAccount: AccountInfo, scope: string[], authState: IAuthState, appInsights: ApplicationInsights) => {
    try {
         const scopeVal = scope[0];
         const authToken = await prodAuthProvider.acquireTokenSilent({
            scopes: [`${scopeVal}`],
            account: userAccount,
        });
         const token = "Bearer " + authToken.accessToken;

         return token.toString();
    } catch (err: any) {
        trackException(appInsights, err, SeverityLevel.Error, "FetchAuthToken", "FetchAuthToken", err.response?.status, authState, {});
        if (err.response?.status !== 404) {
            console.error("token error: " + err);
        }
    }
};
