import { CheckboxVisibility, DefaultButton, DirectionalHint, Dropdown, IColumn, 
    IconButton, IDropdownOption, ITextFieldStyles,
    PrimaryButton, ScrollablePane, ShimmeredDetailsList, TextField, TooltipHost } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React, { Dispatch, SetStateAction } from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { buttonStyles,
    dropdownStylesServiceAdmin,
} from "../../Pages/ServiceAdministrationPage";
import HttpService from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { ListItemActionSet } from "../Shared/ListItemActionSet";
import { RootContext } from "../Stores/RootStore";
import { ChildClass } from "../../Styles/Page.styles";

// Tenant = Service
// Tenant is nomenclature in IcMDataWarehouse
// Service is more commonly used elsewhere
// Both are the same in this context
// In this document, Tenant is used when referencing a team assignment
//      Service is used when referencing an onboarded service related to a job

export interface IAssignIcMTeamProps {
    selectedServiceName: string;
    selectedServiceTreeGUID: string;
    teamsDisabled: boolean;
    selectedTeam: IServiceTeamsType;
    allServicesData: IServiceType[];
    SetTeamsDisabled: Dispatch<SetStateAction<boolean>>;
    SetSelectedTeam: Dispatch<SetStateAction<IServiceTeamsType>>;
}

export interface IServiceTeamsType {
    TenantName: string;
    TenantId: string;
    TeamName: string;
    TeamId: string;
    PublicTeamId: string;
}

export interface IServiceType {
    TenantName: string;
    TenantId: string;
    PublicTenantId: string;
}

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: "300px" } };

export const AssignDCIcmTeam: React.FunctionComponent<IAssignIcMTeamProps> = (props: IAssignIcMTeamProps) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [allServicesData] = React.useState<IServiceType[]>(props.allServicesData);
    const [allServicesDataFiltered, SetAllServicesDataFiltered] = React.useState<IServiceType[]>(props.allServicesData);
    const [serviceDisplayed, SetServiceDisplayed] = React.useState<IServiceType>();
    const [saveButtonText, SetSaveButtonText] = React.useState<string>("Save");
    const [clearButtonText, SetClearButtonText] = React.useState<string>("Clear IcM Team");
    const [saveButtonDisabled, SetSaveButtonDisabled] = React.useState<boolean>(true);
    const [currentlySaving, SetCurrentlySaving] = React.useState<boolean>(false);
    const [currentlyClearing, SetCurrentlyClearing] = React.useState<boolean>(false);
    const [teams, SetTeams] = React.useState<IDropdownOption[]>([{ key: "", text: "" }]);
    const defaultServiceTeamInfo: IServiceTeamsType = { TenantId: "", TenantName: "", TeamId: "", PublicTeamId: "", TeamName: "" };
    const [selectedTeam, SetSelectedTeam] = React.useState<IServiceTeamsType>(defaultServiceTeamInfo);
    const [savedTextHidden, SetSavedTextHidden] = React.useState<boolean>(true);
    const [clearTextHidden, SetClearTextHidden] = React.useState<boolean>(true);
    const [savedText, SetSavedText] = React.useState<string>("IcM Team Saved!");
    const [clearText, SetClearText] = React.useState<string>("IcM Team Cleared.");

    // All data from IcM query, not currently displayed in its entirety.
    const [serviceTeamsData, SetServiceTeamsData] = React.useState<IServiceTeamsType[]>([]);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    // New Onboarded Service Selected
    React.useEffect(() => {
        // reset values
        SetTeams([{ key: "", text: "" }]);
        SetSavedTextHidden(true);
        SetSavedText("IcM Team Saved!");
        SetClearText("IcM Team Cleared.");
    }, [props.selectedServiceTreeGUID]);

    // Update teams dropdown disable/enable
    React.useEffect(() => {
        props.SetTeamsDisabled(props.teamsDisabled);
    }, [props.teamsDisabled]);

    // Set first Teams option in dropdown
    React.useEffect(() => {
        if (teams != undefined && teams.length > 0) {
            SetSelectedTeam(serviceTeamsData[0]);
        }
    }, [teams]);

    // DataGrid Columns
    const serviceColumns: IColumn[] = [
        {
            key: "TenantId",
            name: "ID",
            fieldName: "TenantId",
            minWidth: 30,
            maxWidth: 80,
            isResizable: true,
        },
        {
            key: "TenantName",
            name: "Service",
            fieldName: "TenantName",
            isResizable: true,
            minWidth: 200,
            maxWidth: 300,
        },
        {
            key: "actions",
            name: "Search for IcM Team",
            onRender: (item: IServiceType) =>  searchTenant(item),
            minWidth: 30,
            maxWidth: 100,
        },
    ];

    const searchTenant = (item: any) => {
        const visibleActions: React.ReactNode[] = [];
        const addServerAriaLabel = "click here to add server to be offboarded: ";
        visibleActions.push(
            <TooltipHost
                key={"Search"}
                aria-label={addServerAriaLabel + item.server}
                content={addServerAriaLabel + item.server}
                styles={{ root: { marginLeft: "10px" } }}
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 }}
            >
                <IconButton
                    key={"Search"}
                    label={"Search"}
                    ariaLabel={addServerAriaLabel + item.server}
                    iconProps={{
                        iconName: "Search",
                    }}
                    role={"button"}
                    onClick={() => {
                        SetServiceDisplayed({
                            TenantName: item.TenantName,
                            TenantId: item.TenantId,
                            PublicTenantId: item.PublicTenantId
                        });
                        updateTeamsData(item);
                    }}
                />
            </TooltipHost>,
        );

        return (
            <ListItemActionSet
                visibleActions={visibleActions}
            />
        );
    };

    const clearIcMTeam = () => {
        SetCurrentlyClearing(true);
        SetClearButtonText("Clearing...");

        const clearIcMTeam = httpService.delete({
            url: `api/ServiceAdministration/clearDCIcMTeam/${props.selectedServiceTreeGUID}`,
            token: state.AuthStore.Token,
            params: {},
        });

        clearIcMTeam.then((resp: any) => {
            SetCurrentlyClearing(false);
            SetClearButtonText("Clear IcM Team");
            SetClearTextHidden(false);

            const newTeam: IServiceTeamsType = {
                TenantId: "UNASSIGNED",
                TenantName: "UNASSIGNED",
                TeamId: "UNASSIGNED",
                PublicTeamId: "UNASSIGNED",
                TeamName: "UNASSIGNED",
            };

            props.SetSelectedTeam(newTeam);
            SetSelectedTeam(newTeam);
        }).catch((reason: any) => {
            SetClearText("Something went wrong");
            SetCurrentlyClearing(false);
            SetClearButtonText("Clear IcM Team");
            SetClearTextHidden(false);
            trackException(appInsights, reason, SeverityLevel.Error, "ServiceAdministration", "Clear IcM Team", "clearIcMTeam", state.AuthStore, {});
        });
    }

    // Save IcM Team selection to Azure Storage
    const saveIcMTeamAssignment = () => {
        SetCurrentlySaving(true);
        SetSaveButtonText("Saving...");

        const saveIcMTeam = httpService.post({
            url: "api/ServiceAdministration/assignDCIcMTeam",
            token: state.AuthStore.Token,
            data: {
                ServiceTreeGUID: props.selectedServiceTreeGUID,
                TeamId: selectedTeam?.TeamId,
                PublicTeamId: selectedTeam?.PublicTeamId,
                TeamName: selectedTeam?.TeamName,
                TenantId: selectedTeam?.TenantId,
                PublicTenantId: serviceDisplayed?.PublicTenantId,
                TenantName: selectedTeam?.TenantName,
            },
        });

        saveIcMTeam.then((resp: any) => {
            SetCurrentlySaving(false);
            SetSaveButtonText("Save");
            SetSavedTextHidden(false);

            const newTeam: IServiceTeamsType = {
                TeamId: selectedTeam?.TeamId,
                PublicTeamId: selectedTeam?.PublicTeamId,
                TeamName: selectedTeam?.TeamName,
                TenantId: selectedTeam?.TenantId,
                TenantName: selectedTeam?.TenantName,
            };

            props.SetSelectedTeam(newTeam);
            SetSelectedTeam(newTeam);
        }).catch((reason: any) => {
            SetSavedText("Something went wrong");
            SetCurrentlySaving(false);
            SetSaveButtonText("Save");
            SetSavedTextHidden(false);
            trackException(appInsights, reason, SeverityLevel.Error, "ServiceAdministration", "Assign IcM Team", "saveIcMTeamAssignment", state.AuthStore, {});
        });
    }

    return (
        <div>
            <div>
                <p>
                    Current IcM Team Assignment: <b>{props.selectedTeam?.TenantName != "UNASSIGNED" ? props.selectedTeam?.TenantName : ""} {(props.selectedTeam?.TenantName?.length > 0 && props.selectedTeam?.TenantName != "UNASSIGNED" ) ? "/" : ""} {props.selectedTeam?.TeamName != "UNASSIGNED" ? props.selectedTeam?.TeamName : ""}</b>
                </p>
                <DefaultButton onClick={currentlyClearing ? () => {} : clearIcMTeam}>
                        {clearButtonText}
                </DefaultButton>
                <p hidden={clearTextHidden} style={{ color: "#00CC00"}}>{clearText}</p>
                <p><b>1. Search for a service by name and use the magnifying glass icon in the data grid to view that service's teams</b></p>
                <p>Service Selected: <b>{serviceDisplayed?.TenantName}</b></p>
            </div>
            <div>
                <TextField
                    className={ChildClass}
                    label="Search by Service Name:"
                    ariaLabel="TextField Search by Service Name:"
                    onChange={onFilterTenantName}
                    styles={textFieldStyles}
                />
            </div>
            <div style={{ position: "relative", height: "75%" }}>
                <ScrollablePane style={{ height: "300px", position: "relative" }}>
                    <ShimmeredDetailsList
                        columns={serviceColumns}
                        items={allServicesDataFiltered}
                        compact={true}
                        enableShimmer={allServicesData.length == 0}
                        onRenderDetailsHeader={RenderStickyHeader}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </ScrollablePane>
            </div>
            <div>
                <p><b>2. Select a team from the dropdown</b></p>
            </div>
            <div>
                <Dropdown
                    className={ChildClass}
                    label="IcM Teams:"
                    aria-label="IcM Teams:"
                    onChange={updateTeamSelection}
                    styles={dropdownStylesServiceAdmin}
                    selectedKey={selectedTeam?.TeamId ? selectedTeam.TeamId : undefined}
                    ariaLabel={selectedTeam?.TeamId ? selectedTeam.TeamId : undefined}
                    options={teams}
                    role="listbox"
                    disabled={props.teamsDisabled}
                />
            </div>
            <div>
                <p hidden={savedTextHidden} style={{ color: "#00CC00"}}>{savedText}</p>
            </div>
            <div>
                <PrimaryButton
                    secondaryText={saveButtonText}
                    onClick={currentlySaving ? () => {} : saveIcMTeamAssignment}
                    text={saveButtonText}
                    styles={buttonStyles}
                    disabled={saveButtonDisabled}
                />
            </div>
        </div>
    );

    // Filter by Tenant (Service) name
    function onFilterTenantName(vent: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        if (newValue !== "" && newValue !== null && newValue !== undefined) {
            const data = newValue ? allServicesData.filter((i) => i.TenantName?.toString().toLowerCase().indexOf(newValue.toString().toLowerCase()) > -1) : allServicesData;
            SetAllServicesDataFiltered(data);
        } else {
            SetAllServicesDataFiltered(allServicesData);
        }
    }

    // Update Teams dropdown when selecting a Tenant from the Data Grid
    //  (clicking magnifying glass)
    function updateTeamsData(item: IServiceType) {
        SetTeams([{ key: "Loading...", text: "Loading..." }]);
        SetSelectedTeam({
            TeamId: "Loading...",
            PublicTeamId: "Loading...",
            TeamName: "Loading...",
            TenantId: item.TenantId,
            TenantName: item.TenantName,
        });

        props.SetTeamsDisabled(true); // disable Teams dropdown
        SetSaveButtonDisabled(true);

        const fetchTeamsData = (fServiceId: string) => httpService.get({
            url: "api/IcM/fetchTeamsByServiceId",
            token: state.AuthStore.Token,
            params: { tenantId: fServiceId },
        });

        fetchTeamsData(item.TenantId).then((response: any) => {
            const jsonData = JSON.stringify(response?.data);
            let teamData: IServiceTeamsType[] = [];
            let teamsDD: IDropdownOption[] = [];

            for (const d of JSON.parse(jsonData)) {
                teamData = [...teamData, {
                    TenantName: d.tenantName,
                    TenantId: d.tenantId,
                    TeamName: d.teamName,
                    TeamId: d.teamId,
                    PublicTeamId: d.publicTeamId,
                }];

                if (!teamsDD.some(({key}) => key === d.teamId)) {
                    teamsDD = [...teamsDD, { key: d.teamId, text: d.teamName }];
                } else {
                    // console.log("key: " + d.teamId + " already exists.");
                }
            }

            if (teamsDD.length !== 0) {
                SetServiceTeamsData(teamData);
                SetTeams(teamsDD);
                props.SetTeamsDisabled(false);
                SetSaveButtonDisabled(false);
            } else {
                teamsDD = [{
                    key: "No Teams Available for this Service",
                    text: "No Teams Available for this Service",
                }];
                SetServiceTeamsData(teamData);
                SetTeams(teamsDD);
                // Teams dropdown is not enabled
            }
        }).catch((reason: any) => {
            // undefined load
            const failedToLoadData: IServiceTeamsType[] = [{
                TenantName: "",
                TenantId: item.TenantId,
                TeamName: "Failed To Load Team Data",
                TeamId: "",
                PublicTeamId: "",
            }];

            SetServiceTeamsData(failedToLoadData);
            SetTeams([{ key: "Failed to Load Data", text: "Failed to Load Data" }]);
            // Teams dropdown is not enabled
            trackException(appInsights, reason, SeverityLevel.Error, "ServiceAdministration", "Assign Icm Team", "fetchTeamsByServiceId", state.AuthStore, {});
        });
    }

    // Update team selection in dropdown
    function updateTeamSelection(event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number) {
        if (item !== undefined && index != null ) {
            SetSelectedTeam(serviceTeamsData[index]);
        }
    }
};
