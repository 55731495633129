import { mergeStyleSets } from '@fluentui/merge-styles';
import { IColumn } from '@fluentui/react/lib/DetailsList';


export interface ServerForOnboarding {
    key: number;
    Errors: string; 
    Name: string; 
    NetworkDomain: string; 
    OSType: string; 
    ServerId: number; 
    ServiceName: string; 
    ServiceTreeGUID: string; 
    Valid: boolean;
  }
  

export interface SortableColumn extends IColumn {
  fieldName: keyof ServerForOnboarding;
}


export interface SortResults {
    newColumns: IColumn[];
    newItems : ServerForOnboarding[];
  }


  export const sortByColumn = (column: IColumn, allColumns: IColumn[], allItems: ServerForOnboarding[]): SortResults => {
    
     
    //----- setColumnSorted
    const newColumns: IColumn[] = allColumns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
   
    //--- sort items 
    const newItems = _copyAndSort(allItems, currColumn.fieldName!, currColumn.isSortedDescending);
    return { newItems, newColumns} as SortResults;
  };

export function _copyAndSort<ServerForOnboarding>(items: ServerForOnboarding[], columnKey: string, isSortedDescending?: boolean): ServerForOnboarding[] {
  const key = columnKey as keyof ServerForOnboarding;
  return items.slice(0).sort((a: ServerForOnboarding, b: ServerForOnboarding) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export function _getKey(item: any, index?: number): string {
  return item.key;
}


export const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
      padding: 0,
      fontSize: '16px',
    },
    fileIconCell: {
      textAlign: 'center',
      selectors: {
        '&:before': {
          content: '.',
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '100%',
          width: '0px',
          visibility: 'hidden',
        },
      },
    },
    controlWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    selectionDetails: {
      marginBottom: '20px',
    },
  });
  const controlStyles = {
    root: {
      margin: '0 30px 20px 0',
      maxWidth: '300px',
    },
  };