import { CoherenceNav, INavLink, INavLinkGroup } from "@coherence-design-system/controls";
import { CoherenceTheme } from "@coherence-design-system/styles";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { UserPermission } from "../../services/AuthenticationService/AuthReducer";
import { AppName } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";

// Interfaces //
interface INavProps {
    onNavCollapsed: (isCollapsed: boolean) => void;
    onNavItemClicked: () => void;
}

// Page Names
const home: string = "Home";
const onboardServers: string = "Onboard Servers";
const offboardServers: string = "Offboard Servers";
const manageServers: string = "Manage Servers";
const manageJobs: string = "Manage Jobs";
const jobHistory: string = "Job History";
const serviceadministration: string = "Service Admin";
const admin: string = "Admin";
const admindc: string = "Admin DC";

export const Nav: React.FunctionComponent<INavProps> = (props: INavProps) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = React.useState<string>("");
    const { state } = React.useContext(RootContext);

    const initialLinks: INavLink[] = [{
        name: home,
        key: "homeKey",
        ariaLabel: "Link " + home,
        icon: home,
        "data-id": "automation_id_22230",
        target: "_blank",
        onClick: () => {
            setCurrentPage(home);
            props.onNavItemClicked();
            navigate("/");
        },
        isSelected: state.DataStore.CurrentPage == "home" ? true : false,
    },
    {
        name: manageServers,
        id: "manageServersNavButton",
        key: "manageServersKey",
        ariaLabel: "Link " + manageServers,
        icon: "Server",
        target: "_blank",
        onClick: () => {
            setCurrentPage(manageServers);
            navigate("/manageservers");
            props.onNavItemClicked();
        },
        isSelected: state.DataStore.CurrentPage === "manageservers"? true : false,
    },
    {
        name: manageJobs,
        id: "manageJobsNavButton",
        key: "manageJobsKey",
        ariaLabel: "Link " + manageJobs,
        icon: "ServerProcesses",
        target: "_blank",
        onClick: () => {
            setCurrentPage(manageJobs);
            navigate("/managejobs");
            props.onNavItemClicked();
        },
        isSelected: state.DataStore.CurrentPage === "managejobs" ? true : false,
    },
    {
        name: offboardServers,
        id: "offboardingNavButton",
        key: "offboardingKey",
        ariaLabel: "Link " + offboardServers,
        icon: "WebComponents",
        "data-id": "automation_id_22232",
        target: "_blank",
        onClick: () => {
            setCurrentPage(offboardServers);
            navigate("/offboarding");
            props.onNavItemClicked();
        },
        isSelected: state.DataStore.CurrentPage === "offboarding" ? true : false,
    },
    {
        name: serviceadministration,
        id: "serviceadministrationNavButton",
        key: "serviceadministrationKey",
        ariaLabel: "Link " + serviceadministration,
        icon: "UserEvent",
        "data-id": "automation_id_22235",
        target: "_blank",
        onClick: () => {
            setCurrentPage(serviceadministration);
            navigate("/serviceadministration");
            props.onNavItemClicked();
        },
        isSelected: state.DataStore.CurrentPage === "serviceadministration" ? true : false,
    }];

    const adminLinks: INavLink[] = [{
        name: onboardServers,
        id: "onboardingNavButton",
        key: "onboardingKey",
        ariaLabel: "Link " + onboardServers,
        icon: "AddIn",
        "data-id": "automation_id_22231",
        target: "_blank",
        onClick: () => {
            setCurrentPage(onboardServers);
            props.onNavItemClicked();
            navigate("/onboarding", {replace: true, state: { reload: (currentPage == onboardServers) }});
        },
        isSelected: state.DataStore.CurrentPage === "onboarding" ? true : false,
    },{
        name: admin,
        id: "adminNavButton",
        key: "adminKey",
        ariaLabel: "Link " + admin,
        icon: "Admin",
        "data-id": "automation_id_22236",
        target: "_blank",
        onClick: () => {
            setCurrentPage(admin);
            navigate("/admin");
            props.onNavItemClicked();
        },
        isSelected: state.DataStore.CurrentPage === "admin" ? true : false,
    }];

    const admindclinks: INavLink[] = [{
        name: admindc,
        key: "admindcKey",
        ariaLabel: "Link " + admindc,
        icon: "Admin",
        "data-id": "automation_id_22237",
        target: "_blank",
        onClick: () => {
            setCurrentPage(admin);
            navigate("/admindc");
            props.onNavItemClicked();
        },
        isSelected: state.DataStore.CurrentPage === "admindc" ? true : false,
    }];

    const jobHistoryLinks: INavLink[] = [{
        name: jobHistory,
        id: "jobHistoryNavButton",
        key: "jobHistoryKey",
        ariaLabel: "Link " + jobHistory,
        icon: "DateTime",
        target: "_blank",
        onClick: () => {
            setCurrentPage(jobHistory);
            navigate("/jobHistory");
            props.onNavItemClicked();
        },
        isSelected: state.DataStore.CurrentPage === "jobhistory" ? true : false,
    }];

    const navGoupLinks: INavLinkGroup[] = [
        { key: "Left Nav Menu",
        links: state.AuthStore.isAdmin ? initialLinks.concat(jobHistoryLinks, adminLinks, admindclinks) : initialLinks,
    }];

    return (
        <ThemeProvider theme={CoherenceTheme}>
            {state.AuthStore.UserPermitted === UserPermission.Permitted && !state.AuthStore.UserTimeOut &&
                <div id="leftNav">
                    <CoherenceNav
                        appName={AppName}
                        groups={navGoupLinks}
                        onNavCollapsed={props.onNavCollapsed}
                        styles = {{}}
                    />
                </div>
            }
        </ThemeProvider>
    );
};
