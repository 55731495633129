import { IShimmeredDetailsListProps, IColumn, ShimmeredDetailsList, CheckboxVisibility, SelectionMode } from "@fluentui/react";
import React, { ReactNode } from "react";
import { markColumnSorted, sortItems, isKeyOfInterface } from "./DetailsListUtils";

interface IBasicDetailsListProps{
    detailsListProps :IShimmeredDetailsListProps;
    columns: IColumn[];
    onRenderItemColumn?(): (item?: any, index?: number | undefined, column?: IColumn | undefined) => ReactNode;
}

const BasicDetailsList: React.FunctionComponent<IBasicDetailsListProps> = (props: IBasicDetailsListProps) => {
   
    // const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    //     Set_columns(prevColumns => markColumnSorted<any>(column, prevColumns));        
    //     Set_items( prevItems => sortItems(prevItems, column.key, column.isSortedDescending));
    // }
    
    // const defaultColumnProps = {
    //     minWidth: 50,
    //     maxWidth: 60,
    //     isResizable: true,
    //     onColumnClick: _onColumnClick
    // }

   
    // const onRenderItemColumn = (item?: any, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
    //     if (item !== undefined && column !== undefined) {
    //         const fieldContent = item[column.fieldName||""] as any | any[];
    //         return <span>{fieldContent}</span>
    //     }
    // }

    const [_items, Set_items] = React.useState<any[]>(props.detailsListProps.items);    
    const [_columns, Set_columns] = React.useState<IColumn[]>(props.columns);
    
    React.useEffect(() => {Set_items(props.detailsListProps.items);}, [props.detailsListProps.items])

    return <ShimmeredDetailsList
        columns={_columns}
        items={_items}
        compact={true}
        // onRenderDetailsHeader={RenderStickyHeader}
        checkboxVisibility={CheckboxVisibility.onHover}
        onRenderItemColumn={props.onRenderItemColumn}
        selectionMode={SelectionMode.none}
        enableShimmer={props.columns?.length == 0}
        />
}



export { BasicDetailsList }