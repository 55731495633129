import { AccountInfo } from "@azure/msal-browser";
import { TimeZone } from "../../components/Layout/Header";
import { AuthActionEnum, AuthActions } from "./AuthActions";

// Referenced in AuthReducer for user login
export enum RenderingStatus {
    Waiting,
    Warning,
    Ready,
    Error,
    Success,
}

export enum UserPermission {
    Validating,
    Denied,
    Permitted,
}

export interface IAuthState {
    Name: string;
    Email: string;
    IsLoggedIn: boolean;
    LoginStatus: RenderingStatus;
    Account: AccountInfo;
    Token: any;
    isAdmin: boolean;
    toggleAdmin: boolean;
    timezone: TimeZone | undefined;
    UserPermitted: UserPermission;
    UserTimeOut: boolean;
    APIEndpoint: string;
}

export const AuthInitialState = {
    Name: "",
    Email: "",
    IsLoggedIn: false,
    LoginStatus: RenderingStatus.Ready,
    Account: { homeAccountId: "", environment: "", tenantId: "", username: "", localAccountId: "", name: "", idTokenClaims: undefined },
    Token: "",
    isAdmin: false,
    toggleAdmin: false,
    timezone: undefined,
    UserPermitted: UserPermission.Validating,
    UserTimeOut: false,
    APIEndpoint: "",
};

export const AuthReducer = (state: IAuthState, action: AuthActions): IAuthState => {
    switch (action.type) {
        case AuthActionEnum.START_LOGIN:
            return { ...state, LoginStatus: RenderingStatus.Waiting };
        case AuthActionEnum.LOG_IN:
            return { ...state, IsLoggedIn: true, Name: action.name, Email: action.email, LoginStatus: RenderingStatus.Success, Account: action.account, Token: action.token };
        case AuthActionEnum.LOG_OUT:
            return { ...state, IsLoggedIn: false, LoginStatus: RenderingStatus.Success };
        case AuthActionEnum.UPDATE_TOKEN:
            return { ...state, Token: action.token };
        case AuthActionEnum.SET_USER_ADMIN:
            return { ...state, isAdmin: action.isAdmin, toggleAdmin: action.toggleAdmin };
        case AuthActionEnum.SET_TOGGLE_ADMIN:
            return { ...state, toggleAdmin: action.toggleAdmin };
        case AuthActionEnum.SET_TIMEZONE:
            return { ...state, timezone: action.timezone };
        case AuthActionEnum.SET_USER_PERMISSION:
            return { ...state, UserPermitted: action.userPermitted};
        case AuthActionEnum.SET_USER_TIMEOUT:
            return { ...state, UserTimeOut: action.userTimeOut};
        case AuthActionEnum.SET_API_ENDPOINT:
            return { ...state, APIEndpoint: action.apiEndpoint};
        default:
            return state;
    }
};
