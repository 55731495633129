import { DefaultButton, FontIcon, PrimaryButton, ShimmeredDetailsList, TooltipHost } from '@fluentui/react';
import { DetailsListLayoutMode, IColumn, Selection, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import * as React from 'react';
import { ServerForOnboarding, SortResults, SortableColumn, _getKey, classNames, sortByColumn } from './OnboardingModels';


// -------------------------
// Function component props
// -------------------------
interface IDetailsListAvailableServersProps {
  onServerSelectionChanged?: Function;
  items: any;
  triggerOnboarding: Function;
  serversLoaded: boolean;
}

// ------------------
// Function component to show servers available for onboarding
// ------------------
export const DetailsListAvailableServers = (props:IDetailsListAvailableServersProps) => {
  
    const _selection = new Selection({
      canSelectItem: boolean => { return true; },
      onSelectionChanged: () => { if(props.onServerSelectionChanged) {
        props.onServerSelectionChanged(_selection)
        SetSelectedItemsCount(_selection.getSelectedCount());
      }}
    });
  
    const [selection] = React.useState<Selection>(_selection);
    const [selectedItemsCount, SetSelectedItemsCount] = React.useState<number>(0);
    const [items, SetItems] = React.useState<ServerForOnboarding[]>(props.items);
 
    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
      let r:SortResults = sortByColumn(column, columns, items);
      SetColumns(r.newColumns);
      SetItems(r.newItems);
    }
    
    let colOSType: SortableColumn;
    let colName: SortableColumn;
    let colDomain: SortableColumn;
    let colServerId: SortableColumn;
  
  
      colOSType  = {
        key: 'column1',
        name: 'OS Type',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Operating System',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'OSType',
        minWidth: 16,
        maxWidth: 16,
        onColumnClick: _onColumnClick,
        onRender: (item: ServerForOnboarding) => (
        <TooltipHost content={`${item.OSType}`}>
            {
                item.OSType.toLowerCase().includes("windows")
                ? <FontIcon aria-label="Windows" iconName="WindowsLogo" className={mergeStyles({ color: "rgba(0, 120, 212, 1)" })} />
                : <FontIcon aria-label="Windows" iconName="InfoSolid" className={mergeStyles({ color: "rgba(0, 0, 0, 1)" })} />
            }
          </TooltipHost>
        ),
      }
  
      colName = {
        key: 'column2',
        name: 'Name',
        fieldName: 'Name',
        ariaLabel: 'Server Name',
        minWidth: 30,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: _onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: ServerForOnboarding) => {
          return <span x-class="availableServerName">{item.Name}</span>;
        },
      }
  
      colDomain = {
        key: 'column4',
        name: 'Domain',
        fieldName: 'NetworkDomain',
        ariaLabel: 'Domain',
        minWidth: 30,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: _onColumnClick,
        onRender: (item: ServerForOnboarding) => {
          return <span x-class="availableServerDomain">{item.NetworkDomain}</span>;
        },
      }
  
      colServerId = {
        key: 'column5',
        name: 'Server Id',
        fieldName: 'ServerId',
        ariaLabel: 'Server Id',
        minWidth: 30,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
        onColumnClick: _onColumnClick,
        onRender: (item: ServerForOnboarding) => {
          return <span x-class="availableServerId">{item.ServerId}</span>;
        },
      };
  
    const [columns, SetColumns] = React.useState<IColumn[]>([colOSType, colName, colDomain, colServerId]);
  
    return (<div id={"availableServersDetailsList"}>
  
        <div className={classNames.selectionDetails}>{selectedItemsCount} {` selected (${props.items?.length - selectedItemsCount} Available)`} </div>
          <PrimaryButton
              role="button"
              text="Onboard Servers"
              ariaLabel="Onboard Servers"
              disabled={selectedItemsCount == 0}
              onClick={() => {props.triggerOnboarding()}}/>
  
          <DefaultButton
              className={mergeStyles({marginLeft: "10px"})}
              role="button"
              text="Reset"
              ariaLabel="Reset Selection"
              disabled={selectedItemsCount == 0}
              onClick={() => { 
                selection?.setAllSelected(false);
              }}/>
  
          <ShimmeredDetailsList
              items={items}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              //getKey={_getKey}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection = {_selection}
              selectionPreservedOnEmptyClick={true}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={!props.serversLoaded}
            />
    </div>)
  };
  