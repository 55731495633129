import { ApplicationInsights, IPageViewTelemetry, SeverityLevel } from "@microsoft/applicationinsights-web";
import { useEffect } from "react";
import { IAuthState } from "../../services/AuthenticationService/AuthReducer";

// Custom Event Hook for use in Functional Components
const useCustomEvent = (reactPlugin: any, eventName: any, eventData: any) => {
    useEffect(() => {
        reactPlugin.trackEvent({ name: eventName }, eventData);
    }, [reactPlugin, eventName, eventData]);
};

// Standard Helper for use in Class Components and Basic Functions
export const trackEvent = (appInsights: ApplicationInsights, pageName: string, action: string, info: string, authState: IAuthState, customDimensions: any, executionTime: number = 0) => {
    if (appInsights) {
        customDimensions.name = action === "API" ? pageName : "Event";
        customDimensions.itemType = action === "API" ? "API" : "Event";
        customDimensions.operation_Name = action;
        customDimensions.properties = {};
        customDimensions.properties.ComponentName = "Patching Portal UI";
        customDimensions.properties.EnvironmentName = String(process.env.REACT_APP_ENV);
        customDimensions.properties.ServiceLine = "Frictionless Devices";
        customDimensions.properties.ServiceOfferings = "Disruption Free Updates";
        customDimensions.properties.PageName = pageName;
        customDimensions.properties.Action = action;
        customDimensions.properties.Info = info;
        customDimensions.properties.Email = authState ? authState.Email : "";
        customDimensions.properties.Admin = authState ? authState.isAdmin.toString() : false;
        customDimensions.properties.ExecutionTime = executionTime;
        appInsights.trackEvent(customDimensions);
    }
};

export const trackException = (appInsights: ApplicationInsights, error: any, severityLevel: SeverityLevel, pageName: string, action: string, info: string, authState: IAuthState, customDimensions: any) => {
    if (appInsights) {
        customDimensions.properties = {};
        customDimensions.properties.ComponentName = "Patching Portal UI";
        customDimensions.properties.EnvironmentName = String(process.env.REACT_APP_ENV);
        customDimensions.properties.ServiceLine = "Frictionless Devices";
        customDimensions.properties.ServiceOfferings = "Disruption Free Updates";
        customDimensions.properties.PageName = pageName;
        customDimensions.properties.UserAction = action;
        customDimensions.properties.Info = info;
        customDimensions.properties.Email = authState ? authState.Email : "";
        customDimensions.properties.Admin = authState ? authState.isAdmin.toString() : false;
        appInsights.trackException({
            error: new Error(error),
            severityLevel,
            properties: customDimensions,
        });
    }
};

export const trackPageView = (appInsights: ApplicationInsights, telemetry: any) => {
    if (appInsights) {
        try {
            appInsights.trackPageView(telemetry);
        }
        catch (reason: any) {
            console.log(reason);
        }
    }
}

export const buildPageViewTelemetry = (
    pageName: string,
    pageUri: string,
    currentPageUri: string,
    email: string,
    isAdmin: boolean,
  ): IPageViewTelemetry => {
    return {
        name: pageName,
        uri: pageUri,
        refUri: currentPageUri,
        isLoggedIn: true,
        properties: {
            ServiceOfferings : "Disruption Free Updates",
            ServiceLine : "Frictionless Devices",
            ComponentName : "Patching Portal UI",
            EnvironmentName : String(process.env.REACT_APP_ENV),
            Email: email,
            Admin: isAdmin?.toString(),
        },
    };
  };

export default useCustomEvent;
