import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import {
    CheckboxVisibility,
    DetailsList,
    FontSizes,
    IColumn,
    Label,
    Link,
    MessageBar, MessageBarType, PrimaryButton, ScrollablePane, Spinner, Text, Toggle,
    mergeStyles
} from "@fluentui/react";
import { Guid } from "guid-typescript";
import React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import HttpService from "../../services/HttpService/HttpService";
import { RootContext } from "../Stores/RootStore";
import { IDetailsListAutoEnrollItem } from "./DetailsListAutoEnroll";


export interface IRequestAccessPanelProps {
    clickedRow: IDetailsListAutoEnrollItem; // row that was clicked
    isOpen: boolean; // setting to true will show panel
    onDismiss: Function; // expect parent to pass a function to inform parent when panel is closed by user
    rootContext: any;
}

export interface PatchingJob {
    eTag: string;
    jobId: Guid;
    jobName: string;
    dayOfTheWeek: string;
    weekOfTheMonth: number;
    startTimeHours: number;
    startTimeMinutes: number;
    duration: string;
    recurring: boolean;
    reboot: boolean;
    alwaysReboot: boolean;
    serviceName: string;
    serviceTreeGUID: string;
    serversCount: number;
    isAllServerSelected: boolean;
    lastScheduledDateTime: Date;
    nextScheduledDateTime: Date;
}


export interface IRequestAccessPanelState {
        title: string, // Panel Title
        isOpen: boolean, // Is panel open
        mode: PANELMODES, // Which mode is the panel in ?
        onDismiss: Function, // (callback) On panel dismissed
        isAutoEnrollEnabled: boolean, 
        autoEnrollAPIStatus: AUTOENROLL_API_STATUS, // API call status
        jobs: PatchingJob []
}

enum AUTOENROLL_API_STATUS {
    NONE = "N/A",
    PENDING = "Pending",
    SUCCESS = "Success",
    FAILED = "Failed"
}

enum PANELMODES {
    INPUT, // for user input
    CONFIRM, // for user to confirm
    RESULT // final result
}

export class AddEditOUPanel extends React.Component<IRequestAccessPanelProps, IRequestAccessPanelState> {

    public static contextType: React.Context<any> = RootContext;
    private _httpService = HttpService();
    private rootContext:any;
    
    

    constructor(props: IRequestAccessPanelProps) {
        super(props);
        this.rootContext = props.rootContext;
        this.state = this.initialState();
        this.fetchJobsWithIsAllServerSelected();
    }

    initialState = ():IRequestAccessPanelState => {
        
        return {
                title: "Auto Enroll Devices",
                isOpen: this.props.isOpen,
                onDismiss: this.props.onDismiss,
                mode: PANELMODES.INPUT,
                isAutoEnrollEnabled: this.props.clickedRow.isEnabled,
                autoEnrollAPIStatus: AUTOENROLL_API_STATUS.NONE,
                jobs: []
            }
    }


    fetchJobsWithIsAllServerSelected() {
        // get a list of jobs with isAllServerSelected is checked
        var apicall = this._httpService.get({
            url: "api/PatchingJob/fetchJobsWithAllServerSelected",
            token: this.rootContext.state.AuthStore.Token,
            params: {
                serviceTreeGuid: this.props.clickedRow.serviceTreeGuid,
            },
        })

        return Promise.all([apicall])
        .then((resp: any[])=>{
            if(resp[0].data !=null){
                this.setState({
                    jobs: resp[0].data,
                }, () => {console.log(this.state.jobs)})
            }
        })
    }

    AddOrUpdateAutoEnroll() {
        // set status pending
        this.setState({
            autoEnrollAPIStatus: AUTOENROLL_API_STATUS.PENDING,
        })


        // make api call
        var apicall = this._httpService.post({
            url: "api/ServiceAdministration/addUpdateAutoEnroll",
            token: this.rootContext.state.AuthStore.Token,
            data: {
                organizationalUnit : this.props.clickedRow.organizationalUnit,
                serviceTreeGUID: this.props.clickedRow.serviceTreeGuid,
                isEnabled: this.state.isAutoEnrollEnabled,
                // id: this.props.clickedRow.autoEnrollId
              },
        })        
        .then((resp: any)=>{
            console.debug(resp);
            if(resp.data["organizationalUnit"] != null){
                this.setState({autoEnrollAPIStatus: AUTOENROLL_API_STATUS.SUCCESS})
            }else{
                this.setState({autoEnrollAPIStatus: AUTOENROLL_API_STATUS.FAILED})
            }
        }).catch(err => {
            console.log(err);
            this.setState({autoEnrollAPIStatus: AUTOENROLL_API_STATUS.FAILED})
        });
    }

   

    _onToggleChanged = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        this.setState({
            isAutoEnrollEnabled: checked || false
        })
    }
      

    // input validation
    validateUserInput = (): boolean => {

        var hasErrors = false;
        // return true if validated ok
        return !hasErrors;
        
    }

    // utility function to check null / empty strings
    isEmpty = (str :string|null) :boolean => {
        return str == null || str.length == 0;
    }

    // utility function for showing result to user
    resultMessage(): string{
        
        switch(this.state.autoEnrollAPIStatus){
            case AUTOENROLL_API_STATUS.SUCCESS: {
                return "Request completed successfully."
            }
            case AUTOENROLL_API_STATUS.PENDING:{
                return ""
            }
            case AUTOENROLL_API_STATUS.FAILED: {
                return "Request Failed. Please try again."
            }
        }

        return "";
        
    }

    jobsColumns: IColumn[] = [
        {
            key: "jobName",
            name: "Job Name",
            fieldName: "jobName",
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
        },
    ];

    renderItemColumn = (item?: PatchingJob, index?: number, column?: IColumn) => {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof PatchingJob];
            switch (column.fieldName) {
                case "jobName": {
                    return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{fieldContent !== undefined ? fieldContent?.toString() : ""}</span>;
                }
                default: {
                    break;
                }
            }
        }
    }

    public render(): JSX.Element {

    return (<div> 
    
        
        {this.state.isOpen 
        && 
        <CoherencePanel
            titleText={this.state.title}
            isOpen={this.state.isOpen}
            hasCloseButton={true}
            onDismiss={() => { this.state.onDismiss() }}
            panelSize={CoherencePanelSize.medium}
        >
            {/* Panel in INPUT MODE */}
            { this.state.mode == PANELMODES.INPUT && 
            
            <div>
                <p>
                    <Text variant="medium"> 
                        {/* Placeholder for information about this panel */}
                    </Text>
                </p>

                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    Service
                </Label>
                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.props.clickedRow.serviceName}
                </Label>

                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold"}}>
                    Organizational Unit
                    </Label>
                <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.props.clickedRow.organizationalUnit}
                </Label>

                <div style={{ marginTop: "15px"}}>
                    <Toggle label="Auto Enroll Devices" inlineLabel onText="Enabled" offText="Disabled" onChange={this._onToggleChanged} checked={this.state.isAutoEnrollEnabled == true} />
                </div>

                <div>
                    <PrimaryButton
                        className={mergeStyles({marginTop: "15px"})}
                        text="Next"
                        ariaLabel="Next Button"
                        onClick={() =>  {
                            if(this.validateUserInput()) {
                                this.setState({
                                    mode : PANELMODES.CONFIRM
                                })
                            } 
                        }}
                    />
                </div>
            </div>
            }

            {/* Panel in CONFIRMATION MODE - Let user confirm before proceeeding*/}
            { this.state.mode == PANELMODES.CONFIRM && 

                <div>
                <p>
                    <MessageBar 
                        messageBarType={MessageBarType.info}
                        style={{fontSize: FontSizes.medium}}
                        isMultiline={true}>
                            Please verify and confirm your request details
                    </MessageBar>
                </p>

                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    Service
                    </Label>
                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.props.clickedRow.serviceName}
                </Label>

                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold"}}>
                    Organizational Unit
                </Label>
                <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.props.clickedRow.organizationalUnit}
                </Label>

                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold"}}>
                    Auto Enrollment 
                </Label>
                <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.state.isAutoEnrollEnabled?"Enabled": "Disabled"}
                </Label>

                {
                    
                    <div> 
                        <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold"}}>
                            Jobs to add enrolled servers
                        </Label>

                        {
                            // show table if jobs found
                            this.state.jobs != null
                            && this.state.jobs.length > 0
                            && 
                            <div style={{ position: "relative", height: "75%" }}>
                                <ScrollablePane style={{ height: "300px", position: "relative" }}>
                                    <DetailsList
                                        columns={this.jobsColumns}
                                        items={this.state.jobs}
                                        compact={true}
                                        onRenderDetailsHeader={RenderStickyHeader}
                                        checkboxVisibility={CheckboxVisibility.hidden}
                                        onRenderItemColumn={this.renderItemColumn}
                                    />
                                </ScrollablePane>
                            </div>
                        }

                        {
                            // show message if no jobs found
                            (this.state.jobs == null
                            || this.state.jobs.length == 0 )
                            && 
                            <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                                No Jobs have "Select All Servers" enabled. <br/> Visit <Link href="managejobs"> Manage Jobs </Link> to add jobs or edit existing jobs.
                            </Label>
                        }
                    </div>
                }
                
                
                <div>
                <PrimaryButton
                    style={{marginTop: "20px"}}
                    text="Confirm"
                    ariaLabel="Confirm Button"
                    onClick={() =>  {
                        this.AddOrUpdateAutoEnroll();
                        this.setState ({ mode :PANELMODES.RESULT});
                    }}
                />
                </div>
                </div>
            
            }

            {/* Panel in RESULT MODE - Show user result of Create IcM API call*/}
            { this.state.mode == PANELMODES.RESULT && 

                <div>
                <p>
                    <MessageBar
                            messageBarType={
                                this.state.autoEnrollAPIStatus == AUTOENROLL_API_STATUS.SUCCESS ? MessageBarType.success : 
                                this.state.autoEnrollAPIStatus == AUTOENROLL_API_STATUS.FAILED ? MessageBarType.error : 
                                MessageBarType.info
                            }
                            isMultiline={true}
                            style={{fontSize: FontSizes.medium}}
                        >
                        { 
                            (this.state.autoEnrollAPIStatus == AUTOENROLL_API_STATUS.PENDING 
                            && <Spinner label="Submitting request..." ariaLive="assertive" labelPosition="left"/>) 
                            || this.resultMessage()
                        }
                    </MessageBar>
                </p>


                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                    Service
                    </Label>
                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.props.clickedRow.serviceName}
                </Label>

                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold"}}>
                    Organizational Unit
                </Label>
                <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.props.clickedRow.organizationalUnit}
                </Label>

                <Label style={{ marginTop: "15px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold"}}>
                    Auto Enrollment 
                </Label>
                <Label style={{ wordBreak: "break-all", margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "normal" }}>
                    {this.state.isAutoEnrollEnabled?"Enabled": "Disabled"}
                </Label>


                <div>
                <PrimaryButton
                    text="Close"
                    ariaLabel="Close Button"
                    onClick={() =>  {
                        this.props.onDismiss();
                    }}
                    disabled = {this.state.autoEnrollAPIStatus == AUTOENROLL_API_STATUS.PENDING}
                />
                </div>
                </div>
            
            }

           
        </CoherencePanel>
    }

    </div>);
    }
}

