import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { ActionButton, DetailsList, IColumn, ScrollablePane, Spinner } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Guid } from "guid-typescript";
import * as React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import HttpService from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { RootContext } from "../Stores/RootStore";
import { IDetailsListManageJobsItem } from "./DetailsListManageJobs";

const deleteText = "DELETE";

export interface IDeleteJobsPanelProps {
    refreshDataGrid: () => void;
    jobsToBeDeleted: IDetailsListManageJobsItem[];
}

export interface IDeleteJob {
    JobIds: Guid[];
    ServiceTreeGUID: String;
}

interface IDeleteJobsPanelType {
    JobID: Guid;
    JobName: string;
    ServiceName: string;
    ServiceTreeGUID: string;
}

enum PanelSection {
    Delete,
    Results,
}

export const DeleteJobsPanel: React.FunctionComponent<IDeleteJobsPanelProps> = (props: IDeleteJobsPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Delete);
    const [currentlyDeletingJobs, SetCurrentlyDeletingJobs] = React.useState<boolean>(false);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>(deleteText);
    const [dataGridData, SetDataGridData] = React.useState<IDeleteJobsPanelType[]>([]);
    const [deleteJobsMap, SetDeleteJobsMap] = React.useState<Map<string, IDeleteJobsPanelType[]>>(new Map());
    const [deleteJobSubtext, SetDeleteJobSubtext] = React.useState("Are you sure you want to delete these jobs?");
    const { state } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        let jobData: IDeleteJobsPanelType[] = [];
        const jobMap: Map<string, IDeleteJobsPanelType[]> = new Map();

        props.jobsToBeDeleted.forEach((job: IDetailsListManageJobsItem) => {
            if (jobMap.has(job.serviceTreeGUID)) {
                const jobs: IDeleteJobsPanelType[] = jobMap.get(job.serviceTreeGUID)!;
                jobs.push({
                    JobID: job.jobId,
                    JobName: job.jobName,
                    ServiceName: job.serviceName,
                    ServiceTreeGUID: job.serviceTreeGUID,
                });

                jobMap.set(job.serviceTreeGUID, jobs);
            } else {
                jobMap.set(job.serviceTreeGUID, [{
                    JobID: job.jobId,
                    JobName: job.jobName,
                    ServiceName: job.serviceName,
                    ServiceTreeGUID: job.serviceTreeGUID,
                }]);
            }

            jobData = [...jobData, {
                JobID: job.jobId,
                JobName: job.jobName,
                ServiceName: job.serviceName,
                ServiceTreeGUID: job.serviceTreeGUID,
            }];
        });

        SetDeleteJobsMap(jobMap);
        SetDataGridData(jobData);
    }, [props.jobsToBeDeleted]);

    async function clickToDeleteJobs() {
        SetDeleteJobSubtext("Processing...");
        SetPrimaryButtonText("");

        const deleteJobsRequests: Array<Promise<any>> = [];
        const trackingString: Set<string> = new Set<string>();

        for (const serviceTreeGUID of deleteJobsMap.keys()) {
            const jobGUIDs: Guid[] = deleteJobsMap.get(serviceTreeGUID)?.map((job: IDeleteJobsPanelType) => job.JobID)!;
            trackingString.add(jobGUIDs.join(","));
            const validJobGUIDs: Guid[] = jobGUIDs.filter((id: Guid) => id.toString() !== "00000000-0000-0000-0000-000000000000");

            const deleteJob: IDeleteJob = {
                JobIds: validJobGUIDs,
                ServiceTreeGUID: serviceTreeGUID
            }

            const deleteRequest = await httpService.delete<any>({
                url: "api/PatchingJob/delete",
                token: state.AuthStore.Token,
                params: deleteJob,
            });

            deleteJobsRequests.push(deleteRequest);
        }

        Promise.all(deleteJobsRequests).then((resp: any) => {
            trackEvent(appInsights, "Manage Jobs", "Delete Job", "Deleted jobs: " + Array.from(trackingString).join(","), state.AuthStore, {});
            SetDeleteJobSubtext("Job(s) deleted. Please click COMPLETE");
            SetPrimaryButtonText("COMPLETE");
        }).catch((error: any) => {
            SetDeleteJobSubtext("Something went wrong");
            trackException(appInsights, error, SeverityLevel.Error, "Manage Jobs", "Delete Job", "Clicking Delete Job(s)", state.AuthStore, {});
        });
    }

    const clickToOpenPanel = () => {
        SetDeleteJobSubtext("Are you sure you want to delete these jobs?");
        SetCurrentPanel(PanelSection.Delete);
        openPanel();
    };

    const clickToClosePanel = () => {
        props.refreshDataGrid();
        SetCurrentlyDeletingJobs(false);
        SetCurrentPanel(PanelSection.Delete);
        SetPrimaryButtonText(deleteText);
        dismissPanel();
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    const resultsColumns: IColumn[] = [
        {
            key: "JobName",
            name: "Job Name",
            fieldName: "JobName",
            minWidth: 70,
            maxWidth: 250,
            isResizable: true,
        },
        {
            key: "Service",
            name: "Service",
            fieldName: "ServiceName",
            minWidth: 70,
            maxWidth: 135,
            isResizable: true,
        },
    ];

    return (
        <div>
            <ActionButton
                iconProps={{ iconName: "Delete" }}
                disabled={props.jobsToBeDeleted.length === 0}
                onClick={clickToOpenPanel}
                ariaLabel={"Delete"}
                ariaDescription={"Delete"}
                text={"Delete"} />
            <CoherencePanel
                titleText="Delete Jobs"
                title="Delete Jobs"
                headerText="Delete Jobs"
                isOpen={isOpen}
                hasCloseButton={true}
                onDismiss={clickToClosePanel}
                onLightDismissClick={lightDismissPanel}
                closeButtonAriaLabel="Close"
                onRenderFooter={{
                    primaryButton: {
                        text: primaryButtonText,
                        onAction: (() => {
                            if (primaryButtonText === deleteText) {
                                clickToDeleteJobs();
                            } else {
                                if (!currentlyDeletingJobs) {
                                    clickToClosePanel();
                                }
                            }
                        }),
                        disabled: (dataGridData?.length === 0),
                    },
                }}
                panelSize={CoherencePanelSize.medium}
            >
                <main>
                    <div hidden={currentPanel !== PanelSection.Delete}>
                        <div style={{ position: "relative", height: "75%" }}>
                            <ScrollablePane style={{ height: "400px", position: "relative" }}>
                                <DetailsList
                                    columns={resultsColumns}
                                    items={dataGridData}
                                    compact={true}
                                    onRenderDetailsHeader={RenderStickyHeader}
                                />
                            </ScrollablePane>
                        </div>
                        <p>{deleteJobSubtext}</p>
                    </div>
                    <div hidden={currentPanel !== PanelSection.Results}>
                        <h3>Job Deletion Results</h3>
                        <div hidden={!currentlyDeletingJobs}>
                            <Spinner label="Deleting Jobs..." ariaLive="assertive" labelPosition="bottom" />
                        </div>
                        <div hidden={currentlyDeletingJobs}>
                            <div style={{ position: "relative", height: "75%" }}>
                                <ScrollablePane style={{ height: "400px", position: "relative" }}>
                                    <DetailsList
                                        columns={resultsColumns}
                                        items={dataGridData}
                                        compact={true}
                                        onRenderDetailsHeader={RenderStickyHeader}
                                    />
                                </ScrollablePane>
                            </div>
                        </div>
                    </div>
                </main>
            </CoherencePanel>
        </div>
    );
};
