import { Depths, ScrollablePane } from "@fluentui/react";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackPageView } from "../components/AppInsights/LoggingHelper";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { DataActionEnum } from "../services/DataContext/DataActions";
import HttpService from "../services/HttpService/HttpService";
import { AdminDCIcm } from "../components/Admin/AdminDCIcm";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";
import { PAGE } from "../App";

const errorText: string = "You must be an administrator to view this page.";
const pagetitle = "Admin DC";

export const AdminDCPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const { state, dispatch } = React.useContext(RootContext); // auth token context

    const appInsights = useAppInsights();

        // HttpService
        const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        document.title = "Admin";

        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "admindc"
        });

        const telemetry = buildPageViewTelemetry(
            pagetitle,
            "/admindc",
            "/admindc",
            state.AuthStore.Email,
            state.AuthStore.isAdmin,
        );
        
        trackPageView(appInsights, telemetry);

    }, []);


    if (state.AuthStore.isAdmin) {
        return (
            <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                    <AdminMessageBanner page={PAGE.ADMINDC} />
                </div>
                <div className={_Styles.rootDiv}>
                    <h1 id="admintitle">{ReactHtmlParser(pagetitle)}</h1>
                    <br />
                    <AdminDCIcm />
                    <br />
                    <BackToTopButton />
                </div>
            </ScrollablePane >
        );
    } else  {
        return (
            <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                <h1>{ReactHtmlParser(pagetitle)}</h1>
                <p>{errorText}</p>
                <BackToTopButton />
                <PagePermissionsComponent />
            </ScrollablePane>
        );
    }
};
