import { IServiceDataType } from "../../App";
import { IAdminMessage } from "../../components/Home/AdminMessageBanner";

export enum DataActionEnum {
    UPDATE_ONBOARDED_SERVICES = "UPDATE_ONBOARDED_SERVICES",
    UPDATE_WEEKS = "UPDATE_WEEKS",
    UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE",
    UPDATE_ADMIN_MESSAGES = "UPDATE_ADMIN_MESSAGES",
}

export type DataActions = |
{
    type: DataActionEnum.UPDATE_ONBOARDED_SERVICES,
    onboardedServices: IServiceDataType[],
} | {
    type: DataActionEnum.UPDATE_WEEKS,
    weeks: number[],
} | {
    type: DataActionEnum.UPDATE_CURRENT_PAGE,
    currentPage: string,
} | {
    type: DataActionEnum.UPDATE_ADMIN_MESSAGES,
    adminMessages: IAdminMessage[],
};
