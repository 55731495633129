import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { CheckboxVisibility, DefaultButton, DetailsList, IColumn, 
    PrimaryButton, ScrollablePane, Spinner, TooltipHost } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { CSVLink } from "react-csv";
import { CSVReader } from "react-papaparse";
import { IServiceDataType } from "../../App";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { invalidCharArray, invalidCharSet } from "../../Pages/ManageJobsPage";
import HttpService from "../../services/HttpService/HttpService";
import { breakWord, calloutProps, hostStyles } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { RootContext } from "../Stores/RootStore";

export interface IBulkAddJobsPanelProps {
    refreshDataGrid: () => void;
}

interface IBulkAddJobsType {
    JobName: string;
    Service: string;
    Duration: string;
    StartHour: string;
    Day: string;
    Week: number;
    Reboot: boolean;
    Remarks: string;
    ServiceTreeGUID: string;
}

enum PanelSection {
    Upload,
    Results,
}

export const BulkAddJobsPanel: React.FunctionComponent<IBulkAddJobsPanelProps> = (props: IBulkAddJobsPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Upload);
    const [currentlyAddingJobs, SetCurrentlyAddingJobs] = React.useState<boolean>(false);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>("Add Jobs");
    const [dataGridData, SetDataGridData] = React.useState<IBulkAddJobsType[]>([]);
    const [showIncorrectFileTypeMessage, SetShowIncorrectFileTypeMessage] = React.useState<boolean>(false);
    const [services, SetServices] = React.useState<IServiceDataType[]>();
    const { state } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        SetServices(state.DataStore.OnboardedServices);
    }, [])

    const linkText: string = "Bulk Add";
    const csvFileName = "bulkAddJobs.csv";
    const exportCsvFileName = "bulkAddJobs";

    const csvHeaders = [
        { label: "JobName", key: "JobName" },
        { label: "Service", key: "Service" },
        { label: "Duration", key: "Duration" },
        { label: "Start Hour", key: "StartHour" },
        { label: "Day", key: "Day" },
        { label: "Week", key: "Week" },
        { label: "Reboot", key: "Reboot" },
    ];
    const csvData = [
        { JobName: "" },
        { Service: "" },
        { Duration: "" },
        { StartTime: "" },
        { Day: "" },
        { Week: "" },
        { Reboot: "" },
    ];

    function csvError(data: any) {
        console.log(data);
    }

    const validateJobName = (jobName: string): boolean => {
        let result = true;
        const chars = [...jobName];

        chars.forEach((c) => {
            if (invalidCharSet.has(c)) {
                result = false;
            }
        });

        return result;
    };

    const validateService = (service: string): boolean => {
        return services?.some((x: IServiceDataType) => service === x.ServiceName) ?? false;
    };

    const validateDuration = (duration: string): boolean => {
        if (duration.length < 5) {
            duration = "0" + duration;
        }
        const regEx = new RegExp("[0][2-6]:[0][0]");
        return regEx.test(duration);
    };

    const validateStartHour = (hour: string): boolean => {
        if (hour.length < 5) {
            hour = "0" + hour;
        }
        const regEx = new RegExp("([01]?[0-9]|2[0-3]):[0-9][0-9]");
        const ex: RegExpExecArray = regEx.exec(hour)!;

        return regEx?.test(hour) && ex?.index === 0;
    };

    const validateDayWeek = (day: string, week: string): string => {
        const dayLowerCase: string = day.toLowerCase();
        if (week === "1") {
            if (dayLowerCase === "monday" || dayLowerCase === "tuesday") {
                return "During week 1, jobs cannot be scheduled for Monday or Tuesday. ";
            }
        }

        const regEx = new RegExp("/[1-4]/g");
        if (regEx.test(week)) {
            return "Week value cannot be greater than 4. ";
        }

        switch (dayLowerCase) {
            case "monday":
            case "tuesday":
            case "wednesday":
            case "thursday":
            case "friday":
            case "saturday":
            case "sunday":
                return "";
            default: {
                return day + " is not a valid day of the week. ";
            }
        }
    };

    const validateBoolean = (r: string): boolean => {
        const val = r.toString().toLowerCase();
        return val === "true" || val === "false";
    };

    function uploadCSVData(data: any[], file: any) {
        SetShowIncorrectFileTypeMessage(false);

        if (file.name.split(".").slice(-1)[0].toLowerCase() !== "csv") {
            SetShowIncorrectFileTypeMessage(true);
            trackException(appInsights, new Error(), SeverityLevel.Warning, "Manage Jobs", "Upload CSV", "User attempted to upload non-csv file for Bulk Add Jobs", state.AuthStore, {});
            return;
        }

        /*
            Need to validate for:
            Valid Service
            Valid Day for Week (cannot be 1 or 2 for week 1)
            Valid "Recurring" and "Reboot" (must be "true" or "false")
            Valid time for Start Hour
            Valid Duration (format: "00:00")
        */

        let uploadedJobData: IBulkAddJobsType[] = [];

        for (const d of data) {
            if (d.data[0] === "JobName" || d.data[0] === "") {
                // skip header or empty row
            } else {
                let dataIsValid: boolean = true;
                let invalidDescription: string = "";
                const jobName: string = d.data[0].toString();
                const service: string = d.data[1].toString();
                const duration: string = d.data[2].toString();
                const startHour: string = d.data[3].toString();
                const day: string = d.data[4].toString();
                const week: string = d.data[5].toString();
                const reboot: string = d.data[6].toString();
                let serviceTreeGUID: string  = "";

                if (!validateJobName(jobName)) {
                    invalidDescription += jobName  + " job names cannot contain the following: " + invalidCharArray.join("") + " ";
                    dataIsValid = false;
                }

                if (!validateService(service)) {
                    invalidDescription += service  + " is an invalid service. ";
                    dataIsValid = false;
                } else {
                    serviceTreeGUID = services?.filter((x: IServiceDataType) => x.ServiceName === service)[0].ServiceTreeGUID!;
                }

                if (!validateDuration(duration)) {
                    invalidDescription += duration  + " is an invalid duration format. ";
                    dataIsValid = false;
                }

                if (!validateStartHour(startHour)) {
                    invalidDescription += startHour  + " is an invalid start time format. ";
                    dataIsValid = false;
                }

                const dayWeekValidation: string = validateDayWeek(day, week);

                if (dayWeekValidation !== "") {
                    invalidDescription += dayWeekValidation;
                    dataIsValid = false;
                }

                if (!validateBoolean(reboot)) {
                    invalidDescription += reboot  + " is an invalid reboot format (must be 'true' or 'false'). ";
                    dataIsValid = false;
                }

                if (dataIsValid) {
                    uploadedJobData = [...uploadedJobData, {
                        JobName: jobName,
                        Service: service,
                        Duration: duration,
                        StartHour: startHour,
                        Day: day,
                        Week: parseInt(week),
                        ServiceTreeGUID: serviceTreeGUID,
                        Reboot: reboot.toString().toLowerCase() === "true",
                        Remarks: "VALID",
                    }];
                } else {
                    uploadedJobData = [...uploadedJobData, {
                        JobName: jobName,
                        Service: service,
                        Duration: duration,
                        StartHour: startHour,
                        Day: day,
                        Week: parseInt(week),
                        ServiceTreeGUID: serviceTreeGUID,
                        Reboot: reboot.toString().toLowerCase() === "true",
                        Remarks: "INVALID: " + invalidDescription,
                    }];
                }
            }

            SetDataGridData(uploadedJobData);
        }
    }

    // File selection dialog
    const buttonRef: React.RefObject<any> = React.createRef();
    const handleOpenDialog = (e: any) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const addJobs = async (): Promise<any> => {
        const d = new Date();
        const timezoneOffset = (state.AuthStore.timezone?.offset !== undefined && state.AuthStore.timezone?.standardName !== null) 
                                ? - state.AuthStore.timezone?.offset 
                                : d.getTimezoneOffset();
        const currOffset = timezoneOffset;
        const jobArray: any[] = [];

        for (const d of dataGridData) {
            if (d.Remarks === "VALID") {
                const newJob = httpService.post({
                    url: "api/PatchingJob/add",
                    token: state.AuthStore.Token,
                    data: {
                        Reboot: d.Reboot,
                        Recurring: false,
                        JobName: d.JobName,
                        WeekOftheMonth: d.Week,
                        DayOftheWeek: d.Day,
                        StartTimeHours: Number(d.StartHour.split(":")[0]),
                        StartTimeMinutes: Number(d.StartHour.split(":")[1]),
                        Duration: d.Duration,
                        PatchingServerRequest: [],
                        ServiceTreeGUID: d.ServiceTreeGUID,
                        Offset: currOffset,
                        IsAllServerSelected: true,
                        LastUpdatedTimeZone: state.AuthStore.timezone?.standardName,
                    },
                }).then((resp: any) => {
                    return resp;
                }).catch((error: any) => {
                    trackException(appInsights, error, SeverityLevel.Error, "Manage Jobs", "Bulk Adding Jobs", "SaveJobData", state.AuthStore, {});
                });

                jobArray.push(newJob);
            }
        }

        return Promise.all(jobArray).then((response) => {
            return response;
        }).catch((error: any) => {
            trackException(appInsights, error, SeverityLevel.Error, "Manage Jobs", "Bulk Adding Jobs", "SaveJobData", state.AuthStore, {});
        });
    };

    const addJobsBulk = async () => {
        SetCurrentPanel(PanelSection.Results);
        SetCurrentlyAddingJobs(true);
        SetPrimaryButtonText("Close");

        await addJobs().then((x) => {
            // console.log(x);
        });

        SetCurrentPanel(PanelSection.Results);
        SetCurrentlyAddingJobs(false);
        SetPrimaryButtonText("Close");
    };

    const clickToOpenPanel = () => {
        SetCurrentPanel(PanelSection.Upload);
        openPanel();
    };

    const clickToClosePanel = () => {
        SetCurrentlyAddingJobs(false);
        SetDataGridData([]);
        SetCurrentPanel(PanelSection.Upload);
        SetPrimaryButtonText("Add Jobs");
        dismissPanel();
    };

    const resultsColumns: IColumn[] = [
        {
            key: "JobName",
            name: "Job Name",
            fieldName: "JobName",
            minWidth: 70,
            maxWidth: 135,
            isResizable: true,
        },
        {
            key: "Service",
            name: "Service",
            fieldName: "Service",
            minWidth: 70,
            maxWidth: 135,
            isResizable: true,
        },
        {
            key: "Duration",
            name: "Duration",
            fieldName: "Duration",
            minWidth: 70,
            maxWidth: 55,
            isResizable: true,
        },
        {
            key: "StartHour",
            name: "StartHour",
            fieldName: "StartHour",
            minWidth: 70,
            maxWidth: 50,
            isResizable: true,
        },
        {
            key: "Day",
            name: "Day",
            fieldName: "Day",
            minWidth: 70,
            maxWidth: 45,
            isResizable: true,
        },
        {
            key: "Week",
            name: "Week",
            fieldName: "Week",
            minWidth: 70,
            maxWidth: 45,
            isResizable: true,
        },
        {
            key: "Reboot",
            name: "Reboot",
            fieldName: "Reboot",
            minWidth: 70,
            maxWidth: 50,
            isResizable: true,
        },
        {
            key: "Remarks",
            name: "Remarks",
            fieldName: "Remarks",
            minWidth: 70,
            isResizable: true,
            // status: (x: any) => {
            //     switch (x.Remarks) {
            //         case "VALID":
            //             return "Positive1";
            //         default:
            //             return "AlertDoNotDisturb";
            //     }
            // },
        },
    ];

    // tool tip consts

    const onRenderUploadResultsRow = (props: any, defaultRender?: any) => {
        return (
            <div>
                <TooltipHost
                    content={props?.item?.Remarks}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                >
                    {defaultRender!(props)}
                </TooltipHost>
            </div>
        );
    };

    return (
        <div>
            <DefaultButton onClick={clickToOpenPanel}>{linkText}</DefaultButton>
            <CoherencePanel
                titleText="Bulk Adding Jobs"
                title="Bulk Adding Jobs"
                headerText="Bulk Adding Jobs"
                isOpen={isOpen}
                onDismiss={clickToClosePanel}
                closeButtonAriaLabel="Close"
                hasCloseButton={true}
                onRenderFooter={{
                    primaryButton: {
                        text: primaryButtonText,
                        onAction: (() => {
                            if (primaryButtonText === "Add Jobs") {
                                addJobsBulk();
                            } else {
                                if (!currentlyAddingJobs) {
                                    props.refreshDataGrid();
                                    clickToClosePanel();
                                }
                            }
                        }),
                        disabled: (dataGridData?.length === 0),
                    },
                }}
                panelSize={CoherencePanelSize.large}
            >
            <main>
                <div hidden={currentPanel !== PanelSection.Upload}>
                    <b>*</b>this feature cannot be used to schedule recurring jobs
                    <h3>Add New Jobs In Bulk</h3>
                    <ol>
                        <li className={breakWord}>Download pre-formated <CSVLink aria-label={"Click here to download " + csvFileName} filename={csvFileName} title={"Click here to download " + csvFileName} data={csvData} headers={csvHeaders}>{csvFileName}</CSVLink> file.</li>
                        <li>Each job uploaded by bulk will have <b>all servers in the defined service</b> applied to the job. You can edit the job after uploading.</li>
                        <li>Upload completed CSV file.</li>
                    </ol>
                    <div hidden={!showIncorrectFileTypeMessage}>
                        <b className={breakWord}>Only CSV files are acceptable.</b>
                    </div>
                    <div>
                        <h3>Acceptable Data</h3>
                        <ol>
                            <li><b>Job Name</b>: Any string of text <b>not</b> including the following characters: {invalidCharArray.join(" ")}</li>
                            <li><b>Service</b>: Name of service, i.e. "Artifact Service" (Must match exactly to Service Tree)</li>
                            <li><b>Duration</b>: Acceptable durations: 02:00, 03:00, 04:00, 05:00, 06:00. Valid time format (00:00).</li>
                            <li><b>Start Hour</b>: Valid time format (XX:XX) 00:00-23:59.</li>
                            <li><b>Day</b>: Monday, Tuesday, etc.</li>
                            <li><b>Week</b>: 1, 2, 3, or 4 are acceptable week values. <b>*</b>Jobs cannot start on Monday or Tuesday if they are assigned for Week 1</li>
                            <li><b>Reboot</b>: "true" or "false"</li>
                        </ol>
                    </div>
                    <CSVReader
                        ref={buttonRef}
                        onFileLoad={uploadCSVData}
                        onError={csvError}
                        noClick
                        noDrag
                        >
                        {({ file }: any) => (
                            <aside
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: 10,
                            }}
                            >
                            <PrimaryButton onClick={handleOpenDialog}>
                                Browse
                            </PrimaryButton>
                            <div
                                style={{
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: "#ccc",
                                    lineHeight: 2,
                                    marginTop: 0,
                                    marginBottom: 0,
                                    paddingLeft: 13,
                                    paddingTop: 0,
                                    width: "60%",
                                }}
                            >
                                {file && file.name}
                            </div>
                            </aside>
                        )}
                    </CSVReader>
                    <div style={{ position: "relative", height: "75%" }}>
                        <ScrollablePane style={{ height: "350px", position: "relative" }}>
                            <DetailsList
                                columns={resultsColumns}
                                items={dataGridData}
                                compact={true}
                                onRenderDetailsHeader={RenderStickyHeader}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                onRenderRow={onRenderUploadResultsRow}
                            />
                        </ScrollablePane>
                    </div>
                </div>
                <div hidden={currentPanel !== PanelSection.Results}>
                    <h3>Job Addition Results</h3>
                    <div hidden={!currentlyAddingJobs}>
                        <Spinner label="Adding Jobs..." ariaLive="assertive" labelPosition="bottom" />
                    </div>
                    <div hidden={currentlyAddingJobs}>
                        <div style={{ position: "relative", height: "75%" }}>
                            <ScrollablePane style={{ height: "350px", position: "relative" }}>
                                <DetailsList
                                    columns={resultsColumns}
                                    items={dataGridData}
                                    compact={true}
                                    onRenderDetailsHeader={RenderStickyHeader}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                />
                            </ScrollablePane>
                        </div>
                        <CSVLink 
                            aria-label={"Export Data"} 
                            filename={exportCsvFileName} 
                            title={"Export Data"} 
                            data={dataGridData}>
                                Export Data
                        </CSVLink>
                    </div>
                </div>
            </main>
            </CoherencePanel>
        </div>
    );
};
