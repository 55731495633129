import { Dropdown, DropdownMenuItemType, IDropdown, IDropdownOption, IRenderFunction, SearchBox, Spinner, SpinnerSize, mergeStyles } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React from "react";
import { IServiceDataType } from "../../App";
import { ChildClass, defaultDropdownStyles } from "../../Styles/Page.styles";
import { getDCServices } from "../../services/ApiService/Requests";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { RootContext } from "../Stores/RootStore";

export interface DCServicesDropdownProps {
    id: string;
    serviceTreeGuidFromParam: string | undefined;
    onSelectService: (service: IServiceDataType) => void;
    disabled?:  boolean;
}

export const DCServicesDropdown: React.FunctionComponent<DCServicesDropdownProps> = (props: DCServicesDropdownProps) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [selectedDCService, SetSelectedDCService] = React.useState<IServiceDataType>({ServiceName: "", ServiceTreeGUID: props.serviceTreeGuidFromParam && props.serviceTreeGuidFromParam.length > 0 ? props.serviceTreeGuidFromParam : ""});
    const [options, Setoptions] = React.useState<IDropdownOption[]>([]);
    const [filteredServices, SetFilteredServices] = React.useState<IServiceDataType[]>([]);
    const [initialServices, SetInitialServices] = React.useState<IServiceDataType[]>([]);

    const appInsights = useAppInsights();
    
    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            
            getDCServices(state, appInsights)
            .then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined) {
                    const dropdownOptions: IDropdownOption[] = [];
                    const fetchedServices: IServiceDataType[] = [];
                    let matchedService: IServiceDataType | null = null;

                    for (const { serviceName, serviceTreeGUID } of data) {
                        if (serviceTreeGUID && !fetchedServices.some(s => s.ServiceTreeGUID === serviceTreeGUID)) {
                            fetchedServices.push({ 
                                ServiceName: serviceName, 
                                ServiceTreeGUID: serviceTreeGUID 
                            });

                            dropdownOptions.push({
                                key: serviceTreeGUID,
                                text: serviceName,
                            });

                            // match URL parameter with API response
                            if (props.serviceTreeGuidFromParam !== null && serviceTreeGUID == props.serviceTreeGuidFromParam){
                                matchedService = { 
                                    ServiceName : serviceName, 
                                    ServiceTreeGUID: serviceTreeGUID
                                }
                            }
                        }   
                    }

                    // if servicetreeguid passed as URL matches one in API response, set it as the selected service
                    // this is equivalent to the user selecting one from the dropdown
                    if (matchedService != null) {
                        props.onSelectService(matchedService);
                    }

                    SetFilteredServices(fetchedServices);
                    SetInitialServices(fetchedServices);
                }
            }).catch((reason: any) => {
                trackException(appInsights, reason, SeverityLevel.Error, "Fetch DC Services", "Fetch DC Services", "api/ServiceAdministration/fetchDCServices", state.AuthStore, {});
            });
          
        }
    }, [state.AuthStore.Token])
    

    React.useEffect(() => {
        var filteredOptions:IDropdownOption[] = 
        filteredServices.map((d) => {
            return { 
                key: d.ServiceTreeGUID,
                text: d.ServiceName,
            }});
        let headerOption = {key:"header", disabled: true, itemType: DropdownMenuItemType.Header, text: "Search"  } as IDropdownOption;
        let dividerOption = {key:"divider", disabled: true, itemType: DropdownMenuItemType.Divider, text: "Divider"  } as IDropdownOption;
        Setoptions([headerOption,dividerOption].concat(filteredOptions));
    }, [filteredServices])


    const onSearchTextChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        SetFilteredServices(newValue == undefined 
            ? initialServices 
            : initialServices.filter( x => x.ServiceName.toLowerCase().includes(newValue.toLowerCase())))
    }

    const onRenderOption:IRenderFunction<IDropdownOption> = (option: IDropdownOption|undefined): JSX.Element => {
        return (
            <div>
            {
                option?.itemType == DropdownMenuItemType.Header
                ?(<div className={mergeStyles({paddingTop: "2px"})}> 
                        <SearchBox 
                            placeholder="Search" 
                            onChange={onSearchTextChange}
                            maxLength={25}
                        /> 
                    </div>)
                :(<span>{option?.text}</span>)}
            </div>
        );
    };

    const onDropdownDismiss = () => {
        SetFilteredServices(initialServices)
    }
      
    return (
        <div>
            {initialServices.length > 0 ? (
                <Dropdown
                    id={props.id}
                    className={ChildClass}
                    label={"Select a Service"}
                    ariaLabel={"Select a Service"}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
                        const service: IServiceDataType = {
                            ServiceName: option?.text.toString()!,
                            ServiceTreeGUID: option?.key.toString()!
                        }

                        props.onSelectService(service);
                        SetSelectedDCService(service);
                    }}
                    styles={defaultDropdownStyles}
                    selectedKey={selectedDCService.ServiceTreeGUID ? selectedDCService.ServiceTreeGUID : undefined}
                    options={options}
                    disabled={props.disabled}
                    onRenderOption={onRenderOption}
                    onDismiss={onDropdownDismiss}
                />
            ) : (
                <div
                    style={{
                        position: "absolute", left: "50%", top: "40%",
                        transform: "translate(-50%, -50%)",
                    }}>
                    <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                </div>
            )}
        </div>
    );

}