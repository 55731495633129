import HttpService from "../HttpService/HttpService";
import { IRootState } from "../../components/Stores/RootStore";
import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization";

export const getOnboardedServices = (rootState: IRootState, appInsights?: Initialization) => {
    const httpService = HttpService(appInsights, rootState);

    return httpService.get({
        url: "api/ServiceAdministration/fetchOnboardedServices",
        token: rootState.AuthStore.Token,
        params: {},
    });
}

export const getDCServices = (rootState: IRootState, appInsights?: Initialization) => {
    const httpService = HttpService(appInsights, rootState);

    return httpService.get({
        url: "api/ServiceAdministration/fetchDCServices",
        token: rootState.AuthStore.Token,
        params: {},
    });
}

export const getAdminMessages = (rootState: IRootState, appInsights?: Initialization) =>  {
    const httpService = HttpService(appInsights, rootState);

    return httpService.get<any>({
        url: "api/AdminMessages",
        token: rootState.AuthStore.Token,
        params: {},
    });
}