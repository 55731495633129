import { Depths, ScrollablePane, Spinner, SpinnerSize, mergeStyles } from "@fluentui/react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackException, trackPageView } from "../components/AppInsights/LoggingHelper";
import { DetailsListManageServers,
    IManageServersProps } from "../components/ManageServers/DetailsListManageServers";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { UserPermission } from "../services/AuthenticationService/AuthReducer";
import { DataActionEnum } from "../services/DataContext/DataActions";
import HttpService from "../services/HttpService/HttpService";
import { breakWord, _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { IPanelOptions, weekDaysOptions } from "./ManageJobsPage";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";
import { PAGE } from "../App";

export enum ServerPanelType {
    Servers,
    Jobs,
}

const pageTitle: string = "Manage Servers";

export const ManageServersPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const [refreshData, SetRefreshData] = React.useState<number>(0);

    const [durationLoaded, SetDurationLoaded] = React.useState<boolean>(false);
    const [weeksLoaded, SetWeeksLoaded] = React.useState<boolean>(false);

    // for job panel
    const [defaultDurationOptions, SetDurationOptions] = React.useState<IDropdownOption[]>([]);
    const [defaultWeekOfMonthOptions, SetWeekOfMonthOptions] = React.useState<IDropdownOption[]>([]);

    const { state, dispatch } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        document.title = pageTitle;

        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "manageservers"
        });

        const telemetry = buildPageViewTelemetry(
            pageTitle,
            "/manageservers",
            "/manageservers",
            state.AuthStore.Email,
            state.AuthStore.isAdmin,
        );
        
        trackPageView(appInsights, telemetry);
    }, []);

    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            const calls: Promise<any>[] = [];

            const fetchDuration = httpService.get({
                url: "api/duration",
                token: state.AuthStore.Token,
                params: {},
            }).then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined) {
                    let options: IDropdownOption[] = [];

                    for (const d of data) {
                        options = [ ...options, { key: d.duration, text: d.duration }];
                    }

                    SetDurationOptions(options);
                    SetDurationLoaded(true);
                }
            }).catch((reason: any) => {
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Servers", "Fetch Page Data", "api/duration", state.AuthStore, {});
            });

            calls.push(fetchDuration);

            const fetchWeekOfMonth = httpService.get({
                url: "api/weekofmonth",
                token: state.AuthStore.Token,
                params: {},
            }).then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined) {
                    let options: IDropdownOption[] = [];

                    for (const d of data) {
                        options = [ ...options, { key: d.weekNumber , text: d.weekNumber }];
                    }

                    SetWeekOfMonthOptions(options);
                    SetWeeksLoaded(true);
                }
            }).catch((reason) => {
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Servers", "Fetch Page Data", "api/weekofmonth", state.AuthStore, {});
            });

            calls.push(fetchWeekOfMonth);

            const fetchAllData = async () => {
                await Promise.all(calls);
            };

            fetchAllData();
        }
    }, [state.AuthStore.Token]);

    const loadedPanelOptions: IPanelOptions = {
        daysOptions: weekDaysOptions,
        durationOptions: defaultDurationOptions,
        weekOfMonthOptions: defaultWeekOfMonthOptions,
        serverOptions: [], // XXX Loaded per service
    };

    const manageServersProps: IManageServersProps = {
        refreshData,
        timezone: state.AuthStore.timezone,
        panelOptions: loadedPanelOptions,
        appInsights: appInsights,
    };

    return (
        <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
            <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                <AdminMessageBanner page={PAGE.MANAGESERVERS} />
            </div>
            <div className={_Styles.rootDiv}>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Permitted || state.AuthStore.UserTimeOut}>
                    <h1 id="manageserverstitle" className={breakWord}>
                        {ReactHtmlParser(pageTitle)}
                    </h1>
                    {weeksLoaded && durationLoaded && state.AuthStore.Token !== "" ? (
                        <div>
                            <DetailsListManageServers {...manageServersProps}  />
                        </div>
                    ) : (
                    <div
                        style={{
                            position: "absolute", left: "50%", top: "40%",
                            transform: "translate(-50%, -50%)",
                        }}>
                        <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                    </div>
                    )}
                </div>
                <PagePermissionsComponent />
                <BackToTopButton />
            </div>
        </ScrollablePane>
    );
};
