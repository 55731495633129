import { Announced, DetailsList, IColumn, ITextFieldStyles, Link, mergeStyles, ScrollablePane, SelectionMode, TextField } from "@fluentui/react";
import React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IJobHistoryPanelData } from "./JobHistoryPanel";
import { ChildClass } from "../../Styles/Page.styles";

export interface IJobHistoryJobDetailsListProps {
    jobDetailsData: IJobHistoryPanelData[];
    openServerDetailsSection: (serverName: string) => void;
}

// Styles //

const textFieldStyles: Partial<ITextFieldStyles> = { root: { minWidth: "230px", maxWidth: "400px" } };

export const JobDetailsList: React.FunctionComponent<IJobHistoryJobDetailsListProps> = (props: IJobHistoryJobDetailsListProps)=> {
    const [dataGridData, SetDataGridData] = React.useState<IJobHistoryPanelData[]>(props.jobDetailsData);
    const [filteredData, SetFilteredData] = React.useState<IJobHistoryPanelData[]>(props.jobDetailsData);

    // column sorting
    const [columnIsSortedDescending, SetColumnIsSortedDescending] = React.useState<boolean>(false);
    const [sortedColumn, SetSortedColumn] = React.useState<string>("ServerName");
    const [descendingColumn, SetDescendingColumn] = React.useState<string>("ServerName");

    // Annoucements
    const [announcedServersFilter, SetAnnounceServersFiltered] = React.useState<JSX.Element | undefined>(undefined);

    React.useEffect(() => {
        SetDataGridData(props.jobDetailsData);
        SetFilteredData(props.jobDetailsData);
    },[props]);

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            const initialSorting: boolean = !currColumn.isSortedDescending;
            SetDescendingColumn(column.key);
            SetColumnIsSortedDescending(!initialSorting);
            SetSortedColumn(column.key);
          }
        });

        const newItems: IJobHistoryPanelData[] = copyAndSort(filteredData, currColumn.key!, isSortedDescending(column.key));

        SetFilteredData(newItems);
    }

    const copyAndSort = (items: IJobHistoryPanelData[], columnKey: string, isSortedDescending?: boolean): IJobHistoryPanelData[] => {
        let key = "";

        switch (columnKey) {
            case "serverName": {
                key = "FQDN";
                return items.slice(0).sort((a: IJobHistoryPanelData, b: IJobHistoryPanelData) => ((isSortedDescending ? a.FQDN < b.FQDN : a.FQDN > b.FQDN) ? 1 : -1));
            }
            case "details": {
                key = "JobResults";
                return items.slice(0).sort((a: IJobHistoryPanelData, b: IJobHistoryPanelData) => ((isSortedDescending ? a.JobResults.length < b.JobResults.length  : a.JobResults.length  > b.JobResults.length) ? 1 : -1));
            }
            default: {
                return items.slice(0);
            }
        }
    }
    
    const isSortedDescending = (column: string): boolean => {
        if (column === descendingColumn) {
            return columnIsSortedDescending;
        } else {
            return true;
        }
    }

    const isSorted = (column: string): boolean => {
        return column === sortedColumn;
    }

    const columns: IColumn[] = [
        {
            key: "serverName",
            name: "Server Name",
            fieldName: "ServerName",
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("serverName") ? false : true,
            isSorted: isSorted("serverName"),
        },
        {
            key: "details",
            name: "Update Status",
            fieldName: "Details",
            minWidth: 70,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("details") ? false : true,
            isSorted: isSorted("details"),
        },
    ];

    const RenderItemColumn = (item?: IJobHistoryPanelData, index?: number, column?: IColumn) => {
        if (item !== undefined && column !== undefined) {
            const serverName: string = item.FQDN.split(".")[0];
            switch (column.fieldName) {
                case "Details":
                    return item.JobResults.length > 0 
                                ? <Link onClick={() => props.openServerDetailsSection(serverName)}>{"Details (" + item.JobResults.length + ")" }</Link> 
                                : <span>{"No Recent Updates"}</span>;
                case "ServerName":
                    return serverName;
                default:
                    return <span>{item[column.key as keyof IJobHistoryPanelData] as string}</span>;
            }
        }
    }

    // Filter by Server name
    const onFilterServerName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        let servers: IJobHistoryPanelData[] = []

        if (newValue !== "" && newValue !== null && newValue !== undefined) {
            const serversByComma: string[] = newValue.replaceAll(" ", ",").replaceAll(";", ",").replaceAll("\n", ",").split(",");

            for (const s of serversByComma) {
                // remove white space
                const searchResults: IJobHistoryPanelData[] = dataGridData.filter((a) => a.FQDN?.toLowerCase().indexOf(s.toLowerCase()) > -1);
                servers = servers.concat(searchResults);
            }

            if (servers?.length > 0) {
                SetAnnounceServersFiltered(<Announced message={"Showing " + servers.length + " Results for the server name"} aria-live="assertive" />);
            } else {
                SetAnnounceServersFiltered(<Announced message={"No Matching records found"} aria-live="assertive" />);
            }

            SetFilteredData(servers.sort((s1, s2) => {
                if (s1.FQDN > s2.FQDN) { return 1; }
                if (s2.FQDN > s1.FQDN) { return -1; }
                return 0;
            }));
        } else {
            SetFilteredData(dataGridData);
        }
    }

    return (
        <>
            {announcedServersFilter}
            <TextField
                className={ChildClass}
                label="Search by Server Name:"
                ariaLabel="TextField Search by Server Name:"
                onChange={onFilterServerName}
                styles={textFieldStyles}
                placeholder={"Server Name"}
            />
            <ScrollablePane style={{ height: "55vh", position: "relative" }}>
                <DetailsList
                    columns={columns}
                    items={filteredData}
                    compact={true}
                    onRenderDetailsHeader={RenderStickyHeader}
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={RenderItemColumn}
                />
            </ScrollablePane>
        </>
    );
}