import { DefaultPalette, IStyle, mergeStyleSets } from "@fluentui/react";
import { Colors } from "../../Styles/Colors";
import { Fonts } from "../../Styles/Fonts";

export default mergeStyleSets({
    listPageRoot: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        minWidth: 0,
        flexGrow: 1,
        fontFamily: Fonts.Application.Primary,
        color: Colors.Text.Primary,
        backgroundColor: DefaultPalette.white,
        paddingLeft: "40px",
        paddingTop: "36px",
        selectors: {
            "@media screen and (max-width: 450px)": {
                paddingLeft: "0px",
                paddingTop: "11px",
            },
            "@media screen and (max-height: 450px)": {
                paddingLeft: "0px",
                paddingTop: "11px",
                height: "200%",
            },
        },
    } as IStyle,
    listPageHeaderContainer: {
        fontSize: "18px",
        position: "relative",
        display: "flex",
        paddingBottom: "7px",
    } as IStyle,
    listPageTitle: {
        fontWeight: "600",
        paddingRight: "15px",
        paddingLeft: "7px",
        fontSize: "18px",
        margin: "0px",
    } as IStyle,
    listPageCommandBar: { width: "110px" } as IStyle,
    listPageCommandBarAction: {
        height: "40px",
        backgroundColor: "transparent",
    } as IStyle,
    listPageCommandBarActionIcon: { color: Colors.Icon.PrimaryIcon } as IStyle,
    listPageSearchBox: {
        flexGrow: 0,
        flexShrink: 0,
        overflowX: "hidden",
        overflowY: "hidden",
        WebkitOverflowScrolling: "touch",
        margin: "10px",
        selectors: {
            "@media screen and (max-width: 320px)": { width: "180px" },
            "@media screen and (min-width: 321px)": { width: "250px" },
        },
    } as IStyle,
    listPageListItemActionIcon: {
        fontSize: "14px",
        color: Colors.Icon.PrimaryIcon,
    } as IStyle,
    listPageListItemActionsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
    } as IStyle,
    listPageListItemActionsRoot: {
        padding: "0px 10px",
        minWidth: "15px",
        width: "15px",
        height: "16px",
        backgroundColor: "transparent",
    } as IStyle,
    listPageContent: {
        display: "flex",
        flexDirection: "row-reverse",
        minHeight: 0,
        minWidth: 0,
        paddingTop: "15px",
        height: "100%",
    } as IStyle,
    listPageList: {
        position: "relative",
        minHeight: 0,
        minWidth: 0,
        flexGrow: 1,
    } as IStyle,
    paginationContainer: {
        display: "flex",
        flexDireaction: "row",
        alignItems: "center",
        justifySelf: "stretch",
        justifyContent: "flex-end",
    } as IStyle,
});
