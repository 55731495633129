import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/utilities";
import { CheckboxVisibility, DefaultButton, FontIcon, FontSizes, IColumn, IDropdownOption, IGroup, IShimmeredDetailsListProps, MessageBar, MessageBarType, PrimaryButton, Selection, SelectionMode, ShimmeredDetailsList, Stack, Text, TextField, Toggle, TooltipHost, mergeStyles } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { IServiceDataType } from "../../App";
import { calloutProps } from "../../Styles/Page.styles";
import { CreateHttpClient } from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { IServiceTeamsType, IServiceType } from "../ServiceAdministration/AssignIcMTeam";
import { BasicDetailsList } from "../Shared/BasicDetailsList";
import { DCServicesDropdown } from "../Shared/DCServicesDropdown";
import { isKeyOfInterface, markColumnSorted, sortItems } from "../Shared/DetailsListUtils";
import { RootContext } from "../Stores/RootStore";
import { AssignDCIcmTeam } from "./AssignDCIcMTeam";
import { Link } from "react-router-dom";

export interface IAdminDCIcmProps {

}


export interface IAdminDCIcmPanel_teamsProps {
    isPanelOpen: boolean;
    onDismissPanel(): any;
    fetchAllServicesFromIcM(): Promise<any>;
}

////-- select icm team panel --////
export const AdminDCIcmPanel_teams: React.FunctionComponent<IAdminDCIcmPanel_teamsProps> = (props: IAdminDCIcmPanel_teamsProps) => {

    const { state } = React.useContext(RootContext);
    const appInsights = useAppInsights();
    const [httpClient] = React.useState(CreateHttpClient(appInsights, state));
    const [teams, SetTeams] = React.useState<IDropdownOption[]>([{ key: "", text: "" }]);
    const [selectedServiceTreeGUID, SetSelectedServiceTreeGUID] = React.useState<string>("");
    const [selectedServiceName, SetSelectedServiceName] = React.useState<string>("");
    const [teamsDisabled, SetTeamsDisabled] = React.useState<boolean>(true);
    const [selectedTeam, SetSelectedTeam] = React.useState<IServiceTeamsType>({ TenantId: "", TenantName: "", TeamId: "", PublicTeamId: "", TeamName: "" });
    const [allServicesData, SetAllServicesData] = React.useState<IServiceType[]>([{ TenantId: "", TenantName: "", PublicTenantId: "" }]);

    const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(props.isPanelOpen);
    React.useEffect(() => { if (props.isPanelOpen) { openPanel() } else { dismissPanel() } }, [props.isPanelOpen])

    const onSelectService = (service: IServiceDataType) => {

        SetSelectedServiceTreeGUID(service.ServiceTreeGUID);
        SetSelectedServiceName(service.ServiceName);
        httpClient.getWithAuth<any>({
            url: "api/ServiceAdministration/fetchDCIcMTeamData",
            params: {
                serviceTreeGUID: service.ServiceTreeGUID,
            },
        })
            .then(response => {
                const jsonData = JSON.stringify(response?.data);
                const icmData = JSON.parse(jsonData);

                if (icmData?.length !== 0 && icmData[0].tenantName && icmData[0].tenantName !== "") {
                    SetSelectedTeam({
                        TenantId: icmData[0].tenantId,
                        TenantName: icmData[0].tenantName,
                        TeamId: icmData[0].teamId,
                        TeamName: icmData[0].teamName,
                        PublicTeamId: icmData[0].publicTeamId
                    });
                } else {
                    SetSelectedTeam({
                        TenantId: "UNASSIGNED",
                        TenantName: "UNASSIGNED",
                        TeamId: "UNASSIGNED",
                        PublicTeamId: "UNASSIGNED",
                        TeamName: "UNASSIGNED",
                    });
                }
            })
    }

    const fetchAllServices = () => {
        props.fetchAllServicesFromIcM()
            .then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined) {
                    let allServicesArray: IServiceType[] = [];

                    for (const d of data) {
                        allServicesArray = [...allServicesArray, {
                            TenantId: d.tenantId, TenantName: d.tenantName, PublicTenantId: d.publicTenantId
                        }];
                    }

                    SetAllServicesData(allServicesArray.sort((s1, s2) => {
                        if (s1.TenantName > s2.TenantName) { return 1; }
                        if (s2.TenantName > s1.TenantName) { return -1; }
                        return 0;
                    }));
                }
            }).catch((reason: any) => {
                console.log(reason);
            });
    }


    const onDismissPanel = () => {
        SetTeams([{ key: "", text: "" }]);
        SetSelectedServiceTreeGUID("");
        SetSelectedServiceName("");
        SetTeamsDisabled(true);
        SetSelectedTeam({ TenantId: "", TenantName: "", TeamId: "", PublicTeamId: "", TeamName: "" });
    }

    React.useEffect(() => { fetchAllServices() }, [])
    return (
        <div>
            { 
                <CoherencePanel
                    titleText="Team Selection"
                    title={"Team Selection"}
                    headerText={"Team Selection"}
                    isOpen={isPanelOpen}
                    hasCloseButton={true}
                    onDismiss={() => { onDismissPanel(); props.onDismissPanel(); }}
                    closeButtonAriaLabel="Close"
                    panelSize={CoherencePanelSize.medium}
                >
                    <div>
                        <DCServicesDropdown
                            id={"serviceDropdown"}
                            serviceTreeGuidFromParam={selectedServiceTreeGUID}
                            onSelectService={onSelectService}
                        />
                    </div>

                    {
                        allServicesData != null
                        && allServicesData.length > 0
                        && selectedServiceTreeGUID
                        &&
                        <AssignDCIcmTeam
                            selectedServiceTreeGUID={selectedServiceTreeGUID}
                            selectedServiceName={selectedServiceName}
                            teamsDisabled={false}
                            SetTeamsDisabled={SetTeamsDisabled}
                            selectedTeam={selectedTeam}
                            allServicesData={allServicesData}
                            SetSelectedTeam={SetSelectedTeam}
                        />

                    }

                </CoherencePanel>
            }
        </div>
    )
}

////-- send icm panel --////
export interface IAdminDCIcmPanel_sendicmProps {
    isPanelOpen: boolean;
    onDismissPanel(): any;
    serviceMap: ServiceNameAndId[];
}

enum ICM_CREATE_STATUS {
    NONE = "N/A",
    PENDING = "Pending",
    SUCCESS = "Success",
    FAILED = "Failed"
}

export const AdminDCIcmPanel_sendicm: React.FunctionComponent<IAdminDCIcmPanel_sendicmProps> = (props: IAdminDCIcmPanel_sendicmProps) => {

    

    const defaultIcMTitleText: string = "COMPLIANCE REQUIRED - Please address the below machines and their vulnerabilities";
    
    let defaultHeaderText: string = "";

    defaultHeaderText += "Please DO NOT change the Severity of this IcM."
    defaultHeaderText += "\nThis was created a Severity 3 because this service is below 95% compliance, post the compliance deadline.   Only place it into a mitigated state once the machines have been patched.";
    defaultHeaderText += "\nThe below machines are a reflection of this service being below 95% compliance + vulnerabilities that have been present for >30 days, thus out-of-SLA."
    defaultHeaderText += "\nHere is a list of machines & the vulnerabilities that are currently present:"

    let defaultFooterText: string = "";

    defaultFooterText += "ACTION:"
    defaultFooterText += "\nPlease patch & reboot these machines ASAP.  We reserve the right to raise this severity should the above machines remain non-compliant."
    defaultFooterText += "\n"
    defaultFooterText += "\nRESOURCES:"
    defaultFooterText += "\n<a href='https://aka.ms/dcpatch'>MsD PowerBI Report</a>"
    defaultFooterText += "\n*****If you do not have access to the above report, <a href='https://aka.ms/msdpatching'>here</a> is a Wiki article that details the proper way to request access"
    defaultFooterText += "\nDC Patching Team can be contacted using <a href='https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=l1T2v3'>this IcM queue</a> if you have a question about a vulnerability."

    const sendicms = () => {
        httpClient.postWithAuth({
            url: "api/DCCompliance/dcsendicm",
            data: {
                icmtitle: icmTitle,
                header: header,
                footer: footer,
                servicetreeguids: props.serviceMap.map(d => d.serviceGuid),
                severity: 3
            },
        })
        .then((resp: any)=>{
            console.debug(resp);
            if(resp?.data == true){
                SetIcmCreateStatus(ICM_CREATE_STATUS.SUCCESS)
            }else{
                SetIcmCreateStatus(ICM_CREATE_STATUS.FAILED)
            }
        }).catch(err => {
            console.log(err);
            SetIcmCreateStatus(ICM_CREATE_STATUS.FAILED)
        })
    }


    const onDismissPanel = () => {

    }

    const tooltipId = useId("tooltip");
    const { state } = React.useContext(RootContext);
    const appInsights = useAppInsights();
    const [httpClient] = React.useState(CreateHttpClient(appInsights, state));

    const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(props.isPanelOpen);
    const [isSendButtonEnabled, SetIsSendButtonEnabled] = React.useState<boolean>(false);
    const [isAllIcMTeamsAssigned, SetIsAllIcMTeamsAssigned] = React.useState<boolean>(false);
    const [header, SetHeader] = React.useState<string>(defaultHeaderText);
    const [footer, SetFooter] = React.useState<string>(defaultFooterText);
    const [icmTitle, SetIcmTitle] = React.useState<string>(defaultIcMTitleText);
    const [icmCreateStatus, SetIcmCreateStatus] = React.useState<ICM_CREATE_STATUS>(ICM_CREATE_STATUS.NONE);
    
    React.useEffect(() => { if (props.isPanelOpen) { openPanel() } else { dismissPanel() } }, [props.isPanelOpen])
    React.useEffect(() => { SetIsAllIcMTeamsAssigned(props.serviceMap.every(x => x.icmTeam != null && x.icmTeam.length>0))}, [props.serviceMap])

    return (
        <div>
            { // props.isPanelOpen && 
                <CoherencePanel
                    titleText="Send IcM"
                    title={"Send IcM"}
                    headerText={"Send IcM"}
                    isOpen={isPanelOpen}
                    hasCloseButton={true}
                    onDismiss={() => { onDismissPanel(); props.onDismissPanel(); }}
                    closeButtonAriaLabel="Close"
                    panelSize={CoherencePanelSize.large}
                >
                    {/* Success / Fail message bar */}
                    {icmCreateStatus == ICM_CREATE_STATUS.SUCCESS 
                        && 
                        <MessageBar
                            messageBarType={ MessageBarType.success}
                            isMultiline={true}
                            style={{fontSize: FontSizes.medium}}>
                            Request to send below IcMs was received. These will be processed in the background. Check IcM portal for created IcMs
                        </MessageBar>}
                    {icmCreateStatus == ICM_CREATE_STATUS.FAILED 
                        && 
                        <MessageBar
                            messageBarType={ MessageBarType.error}
                            isMultiline={true}
                            style={{fontSize: FontSizes.medium}}>
                            Request to send below IcMs failed.
                        </MessageBar>}

                    {/* Alert Title */}
                    <div style={{ padding: "0px" }}>
                        <TooltipHost
                            content="Descriptive Text for IcM Ticket"
                            id={tooltipId}
                            calloutProps={calloutProps}
                        >
                            <TextField required
                                label="Text"
                                ariaLabel="Text"
                                onChange={React.useCallback(
                                    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
                                        if (newValue != undefined) {SetIcmTitle(newValue);}
                                    }, [],
                                )}
                                value={icmTitle}
                                maxLength={100}
                            />
                        </TooltipHost>
                    </div>

                    {/* Alert Header Text */}
                    <div style={{ padding: "0px" }}>
                        <TooltipHost
                            content="Header for IcM Ticket"
                            id={tooltipId}
                            calloutProps={calloutProps}
                        >
                            <TextField required
                                label="Header"
                                ariaLabel="Header"
                                value={header}
                                onChange={React.useCallback(
                                    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
                                        if (newValue != undefined) {SetHeader(newValue);}
                                    }, [],
                                )}
                                multiline
                            />
                        </TooltipHost>
                    </div>

                    {/* Alert Footer Text*/}
                    <div style={{ padding: "0px" }}>
                        <TooltipHost
                            content="Footer for IcM Ticket"
                            id={tooltipId}
                            calloutProps={calloutProps}
                        >
                            <TextField required
                                label="Footer"
                                ariaLabel="Footer"
                                value={footer}
                                onChange={React.useCallback(
                                    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
                                        if (newValue != undefined) {SetFooter(newValue);}
                                    }, [],
                                )}
                                multiline
                            />

                        </TooltipHost>
                    </div>

                    {/* Table : Service Name + Vuln Count*/}
                    <BasicDetailsList
                        columns={[
                            {
                                minWidth: 50,
                                maxWidth: 250,
                                isResizable: true,
                                key: "Service",
                                name: "Service",
                                fieldName: isKeyOfInterface<ServiceNameAndId>("serviceName"),
                            }, {
                                minWidth: 50,
                                maxWidth: 50,
                                isResizable: true,
                                key: "Vuln Count",
                                name: "Vuln Count",
                                fieldName: isKeyOfInterface<ServiceNameAndId>("vulnCount"),
                            }, {
                                minWidth: 50,
                                maxWidth: 150,
                                isResizable: true,
                                key: "IcM Team",
                                name: "IcM Team",
                                fieldName: isKeyOfInterface<ServiceNameAndId>("icmTeam"),
                                onRender: (item?: ServiceNameAndId, index?: number, column?: IColumn) => (
                                    <TooltipHost
                                        content={item?.icmTeam}
                                        id={tooltipId}
                                        calloutProps={calloutProps}>
                                    {
                                        item?.icmTeam != null && item?.icmTeam?.length > 0 
                                        ?<span> {item?.icmTeam}</span>                                        
                                        :<FontIcon aria-label="IcM team assigned" iconName="Warning" className={mergeStyles({ color: "#e50000", fontSize: "22px" })}/>
                                    }
                                    </TooltipHost>
                                    )
                            }]}
                        detailsListProps={{ items: props.serviceMap }}/>

                    {/* Enable send button */}
                    <Toggle 
                        label="All services above must be assigned IcM Teams to enable sending IcMs"
                        onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => { SetIsSendButtonEnabled(checked || false) }}
                        role="checkbox"
                        disabled={!isAllIcMTeamsAssigned}/>
                    
                    {/* Send Button */}
                    <PrimaryButton disabled={!isSendButtonEnabled} onClick={() => sendicms()}>{"Send IcMs"}</PrimaryButton>

                </CoherencePanel>
            }
        </div>
    )
}

export interface IAdminDCIcmPanel_servicevulnsProps {
    isPanelOpen: boolean;
    onDismissPanel(): any;
    SelectedRow: SummaryRow|undefined;
}

export const AdminDCIcmPanel_servicevulns: React.FunctionComponent<IAdminDCIcmPanel_servicevulnsProps> = (props: IAdminDCIcmPanel_servicevulnsProps) => {

    const { state } = React.useContext(RootContext);
    const appInsights = useAppInsights();
    const [httpClient] = React.useState(CreateHttpClient(appInsights, state));
    const [Vulns, SetVulns] = React.useState<SingleVulnRow[]>([]);
    
    const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(props.isPanelOpen);
    React.useEffect(() => { if (props.isPanelOpen) { openPanel() } else { dismissPanel() } }, [props.isPanelOpen])

    

    const fetchAllServices = () => {
        console.log("fetching vulns for service " + props.SelectedRow?.serviceName)
        httpClient.getWithAuth<any>({
            url: "api/DCCompliance/fetchResults",
            params: {servicetreeguid: props.SelectedRow?.serviceGuid},
        })
            .then(response => {
                SetVulns(response.data as SingleVulnRow[])
            })
    }


    const onDismissPanel = () => {
        SetVulns([])
    }

    React.useEffect(() => { fetchAllServices() }, [])
    return (
        <div>
            { 
                <CoherencePanel
                    titleText="Team Selection"
                    title={"Team Selection"}
                    headerText={"Team Selection"}
                    isOpen={isPanelOpen}
                    hasCloseButton={true}
                    onDismiss={() => { onDismissPanel(); props.onDismissPanel(); }}
                    closeButtonAriaLabel="Close"
                    panelSize={CoherencePanelSize.large}
                >
                    <div>
                    <Text className={mergeStyles({fontWeight: "bold", padding: "10px"})}> Service: </Text> {props.SelectedRow?.serviceName}
                    <br/>
                    <Text className={mergeStyles({fontWeight: "bold", padding: "10px"})}> ServiceTreeGuid: </Text> {props.SelectedRow?.serviceGuid}
                    
                    </div>

                    <AdminDCServiceVulnsDetailsList items={Vulns} />

                </CoherencePanel>
            }
        </div>
    )
}

interface IAdminDCIcmDetailsListProps extends IShimmeredDetailsListProps{
    SetClickedRow: Function;
    openPanel_servicevulns : Function;
    SetSelectedServicesSendIcM: Function;
    
}
////-- all vulns details list --////
export const AdminDCIcmDetailsList: React.FunctionComponent<IAdminDCIcmDetailsListProps> = (props: IAdminDCIcmDetailsListProps) => {

    const _selection = new Selection({
        onSelectionChanged: () => {props.SetSelectedServicesSendIcM(_selection.getSelection() as SummaryRow[]);}
   });
    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        Set_columns(prevColumns => markColumnSorted<any>(column, prevColumns));
        Set_items(prevItems => sortItems(prevItems, column.key, column.isSortedDescending));
    }
    const defaults = {
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        onColumnClick: _onColumnClick
    }

    const columns: IColumn[] = [
        
        {
            ...defaults,
            key: "serviceName",
            name: "Service Name",
            fieldName: isKeyOfInterface<SummaryRow>("serviceName"),
            minWidth: 50,
            maxWidth: 200
        },
        {
            ...defaults,
            key: "VulnCount",
            name: "Vuln Count",
            fieldName: isKeyOfInterface<SummaryRow>("vulnCount"),
        },
        {
            ...defaults,
            key: "outOfSLACount",
            name: "Out Of SLA",
            fieldName: isKeyOfInterface<SummaryRow>("outOfSLACount"),
        },
        {
            ...defaults,
            key: "inSLAPercent",
            name: "In SLA (%)",
            fieldName: isKeyOfInterface<SummaryRow>("inSLAPercent"),
        },
        {
            ...defaults,
            key: "InSLACount",
            name: "In SLA",
            fieldName: isKeyOfInterface<SummaryRow>("inSLACount"),
        },        
        {
            ...defaults,
            key: "TotalCount",
            name: "Total Count",
            fieldName: isKeyOfInterface<SummaryRow>("totalCount"),
        },
        {
            ...defaults,
            key: "icMTeam",
            name: "IcM Team",
            fieldName: isKeyOfInterface<SingleVulnRow>("icMTeamPublicId"),
        },
        {
            ...defaults,
            key: "icMTenant",
            name: "IcM Tenant",
            fieldName: isKeyOfInterface<SingleVulnRow>("icMTenantPublicId"),
        }
    ];


    const tooltipid = useId("tooltip")
    const onRenderItemColumn = <T,>(item?: T, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== null && item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof T] as any | any[];
            {
                switch (column.fieldName) {
                    case "vulnCount": {
                        return <div> 
                                    <span onClick={() => { props.SetClickedRow(item); props.openPanel_servicevulns()}}>
                                        {   
                                            <Link to="#">{fieldContent}</Link> 
                                        }
                                    </span>
                                </div>;

                    }
                    default:
                        return <TooltipHost
                        content={fieldContent}
                        id={tooltipid}
                        calloutProps={calloutProps}>   
                            <span>{fieldContent}</span>
                        </TooltipHost>

                }
            }
            
        }
        return <></>
    }

    const [_items, Set_items] = React.useState<any[]>(props.items);
    const [_columns, Set_columns] = React.useState<IColumn[]>(columns);

    React.useEffect(() => { Set_items(props.items); }, [props.items])

    return (
        <div> 
        <p/>    
        { props.items && props.items.length > 0 
        &&  <Text variant="medium" className={mergeStyles({fontWeight: "normal"})}> Services below 95% SLA : 
                <Text variant="medium" className={mergeStyles({fontWeight: "bold"})}> {props.items.length} </Text> 
            </Text> } 
            
        <ShimmeredDetailsList
            columns={_columns}
            items={_items}
            compact={true}
            checkboxVisibility={CheckboxVisibility.onHover}
            onRenderItemColumn={onRenderItemColumn}
            selectionMode={SelectionMode.multiple}
            selection={_selection}
            enableShimmer={props.items == null || props.items.length == 0}
            />
        </div>
    )
}

export const AdminDCServiceVulnsDetailsList: React.FunctionComponent<IShimmeredDetailsListProps> = (props: IShimmeredDetailsListProps) => {


    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        Set_columns(prevColumns => markColumnSorted<any>(column, prevColumns));
        Set_items(prevItems => sortItems(prevItems, column.key, column.isSortedDescending));
    }
    const defaults = {
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        onColumnClick: _onColumnClick
    }

    const columns: IColumn[] = [
        {
            ...defaults,
            key: "serverName",
            name: "Server Name",
            fieldName: isKeyOfInterface<SingleVulnRow>("serverName"),
            minWidth: 123,
            maxWidth: 123
        },        
        {
            ...defaults,
            key: "VulnName",
            name: "Vuln Name",
            fieldName: isKeyOfInterface<SingleVulnRow>("vulnName"),
            minWidth: 50,
            maxWidth: 250,
        },       
        {
            ...defaults,
            key: "outOfSLADate",
            name: "Out Of SLA Date",
            fieldName: isKeyOfInterface<SingleVulnRow>("outOfSLADate"),
        },
        {
            ...defaults,
            key: "azureSubscriptionId",
            name: "Azure Subscription",
            fieldName: isKeyOfInterface<SingleVulnRow>("azureSubscriptionId"),
        },
        {
            ...defaults,
            key: "MissingKBs",
            name: "MissingKBs",
            fieldName: isKeyOfInterface<SingleVulnRow>("missingKBs"),
        },
        {
            ...defaults,
            key: "diskPaths",
            name: "Disk Paths",
            fieldName: isKeyOfInterface<SingleVulnRow>("diskPaths"),
        },
        {
            ...defaults,
            key: "RegPaths",
            name: "Registry Paths",
            fieldName: isKeyOfInterface<SingleVulnRow>("registryPaths"),
        }
        
    ];


    const tooltipid = useId("tooltip")
    const onRenderItemColumn = (item?: SingleVulnRow, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== null && item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof SingleVulnRow] as any | any[];
            switch (column.fieldName){
                case "vulnName":
                    var cont:JSX.Element = <ul> {[`KB: ${item.missingKBs}`, `Disk: ${item.diskPaths}`, `Reg: ${item.registryPaths}`].map(d => <li key={d}> { d }</li>)} </ul>
                    return <TooltipHost
                    content={cont}
                    id={tooltipid}
                    calloutProps={calloutProps}>   
                        <span>{fieldContent}</span>
                    </TooltipHost>
                    
                default: 
                    return <TooltipHost
                    content={fieldContent}
                    id={tooltipid}
                    calloutProps={calloutProps}>   
                        <span>{fieldContent}</span>
                    </TooltipHost>
            }
        }
        return <></>
    }

    const [_items, Set_items] = React.useState<any[]>(props.items);
    const [_columns, Set_columns] = React.useState<IColumn[]>(columns);

    React.useEffect(() => { Set_items(props.items); }, [props.items])

    return (
        <div> 
        { props.items && props.items.length > 0 &&  <Text className={mergeStyles({fontWeight: "bold", padding: "10px", marginTop: 5})}>  Total Vuln Count: {props.items.length} </Text> } 
        <ShimmeredDetailsList
            columns={_columns}
            items={_items}
            compact={true}
            checkboxVisibility={CheckboxVisibility.onHover}
            onRenderItemColumn={onRenderItemColumn}
            selectionMode={SelectionMode.none}
            enableShimmer={props.items == null || props.items.length == 0}
            />
        </div>
    )
}


interface SummaryRow {
    checksum: string
    icMTeamPublicId: string
    icMTenantPublicId: string
    inSLACount: Number
    inSLAPercent: Number
    outOfSLACount: Number
    serviceGuid: string
    serviceName: string
    totalCount: Number
    vulnCount: Number
}

interface SingleVulnRow {
    name: string;
    serverId: Number;
    serverName: string;
    serviceGuid: string;
    serviceName: string;
    vulnName: string;
    outOfSLADate: string;
    missingKBs: string;
    diskPaths: string;
    registryPaths: string;
    azureSubscriptionId: string;
    icMTeamPublicId: string;
    icMTenantPublicId: string;
}

interface ServiceNameAndId {
    serviceGuid: string;
    serviceName: string;
    vulnCount: number;
    icmTeam: string;
}


export const extractServices = (vulns: SummaryRow[]): ServiceNameAndId[] => {
    var serviceMap: any = {};
    vulns.forEach(x => {
        if (!serviceMap[x.serviceGuid]) {
            serviceMap[x.serviceGuid] = { name: x.serviceName, vulnCount: x.vulnCount, icmTeam: x.icMTeamPublicId };
        } 
    })

    var list: ServiceNameAndId[] = [];
    for (var entry in serviceMap) {
        list.push({
            serviceGuid: entry,
            serviceName: serviceMap[entry]["name"],
            vulnCount: serviceMap[entry]["vulnCount"],
            icmTeam: serviceMap[entry]["icmTeam"]
        })
    }
    return list;
}
export const AdminDCIcm: React.FunctionComponent<IAdminDCIcmProps> = (props: IAdminDCIcmProps) => {

    const { state } = React.useContext(RootContext);
    const appInsights = useAppInsights();
    const [httpClient] = React.useState(CreateHttpClient(appInsights, state));
    const [Vulns, SetVulns] = React.useState<SingleVulnRow[]>([]);
    const [ClickedRow, SetClickedRow] = React.useState<SummaryRow>();
    const [ServiceMap, SetServiceMap] = React.useState<ServiceNameAndId[]>([]);
    const [SelectedServicesSendIcM, SetSelectedServicesSendIcMs] = React.useState<SummaryRow[]>([]);
    

    //-- panel -- 
    const [isPanelOpen_teams, SetIsPanelOpen_teams] = React.useState<boolean>(false);
    const [isPanelOpen_sendicm, SetIsPanelOpen_sendicm] = React.useState<boolean>(false);
    const [isPanelOpen_servicevulns, SetIsPanelOpen_servicevulns] = React.useState<boolean>(false);

    React.useEffect(() => {
        SetServiceMap(extractServices(SelectedServicesSendIcM))
        
    }, [SelectedServicesSendIcM]);

    React.useEffect(() => {
        fetchResults();
    }, []);

    function fetchResults() {
        SetVulns([]);
        httpClient.getWithAuth({
            url: "api/DCCompliance/fetchResults",
            params: {summary: true},
        }).then((response: any) => {
            SetVulns(response.data as SingleVulnRow[])
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "DC Admin", "api/DCCompliance/fetchResults", "", state.AuthStore, {});
        });
    }

    const fetchAllServicesFromIcM = () =>
        httpClient.getWithAuth({
            url: "api/icm/fetchAllServices",
            params: {},
        });


    const openPanel_teams = () => { SetIsPanelOpen_teams(true); }
    const openPanel_sendicm = () => { SetIsPanelOpen_sendicm(true); }
    const openPanel_servicevulns = () => { SetIsPanelOpen_servicevulns(true); }

    return (
        <div>

            {/* Top row buttons */}
            <Stack horizontal tokens={{ childrenGap: "20px" }}>
                <PrimaryButton onClick={() => fetchResults()}>{"Fetch Results"}</PrimaryButton>
                <DefaultButton onClick={() => openPanel_teams()}>{"Select IcM Team"}</DefaultButton>
                <DefaultButton onClick={() => openPanel_sendicm()}>{"Send IcM"}</DefaultButton>
            </Stack>

            
            {/* Details List */}
            <AdminDCIcmDetailsList 
                items={Vulns} 
                SetClickedRow={SetClickedRow} 
                openPanel_servicevulns={openPanel_servicevulns}
                SetSelectedServicesSendIcM = {SetSelectedServicesSendIcMs}
                /> 


            {
            isPanelOpen_servicevulns
            && <AdminDCIcmPanel_servicevulns
                isPanelOpen={isPanelOpen_servicevulns}
                onDismissPanel={() => { SetIsPanelOpen_servicevulns(false) }}
                SelectedRow = {ClickedRow}
                />
            }

            {/* Team selection Panel */}
            <AdminDCIcmPanel_teams
                isPanelOpen={isPanelOpen_teams}
                onDismissPanel={() => { SetIsPanelOpen_teams(false) }}
                fetchAllServicesFromIcM={fetchAllServicesFromIcM} />
            
            {/* Send IcM Panel */}
            <AdminDCIcmPanel_sendicm
                isPanelOpen={isPanelOpen_sendicm}
                onDismissPanel={() => { SetIsPanelOpen_sendicm(false) }}
                serviceMap={ServiceMap}/>

        </div>);
}