import { IServiceDataType } from "../../App";
import { IAdminMessage } from "../../components/Home/AdminMessageBanner";
import { DataActionEnum, DataActions } from "./DataActions";

export interface IDataState {
    OnboardedServices: IServiceDataType[];
    Weeks: number[];
    CurrentPage: string;
    AdminMessages: IAdminMessage[];
}

export const DataInitialState = {
    OnboardedServices: [],
    Weeks: [],
    CurrentPage: "",
    AdminMessages: []
};

export const DataReducer = (state: IDataState, action: DataActions): IDataState => {
    switch (action.type) {
        case DataActionEnum.UPDATE_ONBOARDED_SERVICES:
            return { ...state, OnboardedServices: action.onboardedServices };
        case DataActionEnum.UPDATE_WEEKS:
            return { ...state, Weeks: action.weeks };
        case DataActionEnum.UPDATE_CURRENT_PAGE:
            return { ...state, CurrentPage: action.currentPage };
        case DataActionEnum.UPDATE_ADMIN_MESSAGES:
            return { ...state, AdminMessages: action.adminMessages };
        default:
            return state;
    }
};
