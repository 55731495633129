import { AccountInfo } from "@azure/msal-browser";
import { prodAuthProvider } from "./AuthenticationService/AuthProvider";

export const fetchGraphAPIAuthToken = async (userAccount: AccountInfo) => {
    const authToken = await prodAuthProvider.acquireTokenSilent({
        account: userAccount,
        scopes: ["https://graph.microsoft.com/.default"],
    });
    const token = "Bearer " + authToken.accessToken;
    return token;
};
