import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { CheckboxVisibility, DetailsList, DirectionalHint, IColumn,
    Link, mergeStyles, ScrollablePane, TooltipHost } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import _ from "lodash";
import * as React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IPanelOptions } from "../../Pages/ManageJobsPage";
import { ServerPanelType } from "../../Pages/ManageServersPage";
import { OpenPanelType } from "../../Styles/Page.types";
import { IDetailsListManageJobsItem } from "../ManageJobs/DetailsListManageJobs";
import { IManageJobPanelProps, ManageJobPanel } from "../ManageJobs/ManageJobPanel";
import { ListItemActionSet } from "../Shared/ListItemActionSet";
import { RootContext } from "../Stores/RootStore";

// Styles //

enum SelectedServerSections {
    ServerInfo,
}

// Interfaces //

export interface IManageServerPanelProps {
    serverName: string;
    refreshDataGrid: () => void;
    requestType: ServerPanelType;
    linkText: string;
    jobs: Set<IDetailsListManageJobsItem>;
    panelOptions: IPanelOptions;
}

export const ManageServerPanel: React.FunctionComponent<IManageServerPanelProps> = (props: IManageServerPanelProps) => {
    // Panel Data
    const [serverName, SetServerName] = React.useState<string | undefined | "">(props.serverName);

    // Panel Operation
    const [serverLinkText] = React.useState<string>(props.linkText);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string | undefined | "">("Close");
    const [secondaryButtonText, SetSecondaryButtonText] = React.useState<string | undefined | "">("");

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const { state } = React.useContext(RootContext); // auth token context

    // Panel Sections
    const [currentPanelSection, SetCurrentPageSection] = React.useState<SelectedServerSections>(SelectedServerSections.ServerInfo);
    const [hideServerDiv, SetHideServerDiv] = React.useState<boolean>(false);

    const viewJobAriaLabel = "click here to view job: ";

    // Updates on Panel load
    React.useEffect(() => {

    }, [state]);

    React.useEffect(() => {
        SetServerName(props.serverName);
    }, [isOpen]);

    // DataGrid Columns
    const jobsColumns: IColumn[] = [
        {
            key: "actions",
            name: "Edit",
            fieldName: "Edit",
            ariaLabel: "click this link to Edit job",
            minWidth: 50,
            maxWidth: 100,
            isIconOnly: false,
            isResizable: true,
        },
        {
            key: "jobName",
            name: "Jobs",
            fieldName: "jobName",
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
        },

    ];

    // Functions for handling data in panel
    const renderActionRowViewJob = (item: IDetailsListManageJobsItem): React.ReactNode => {
        const visibleActions: React.ReactNode[] = [];
        const jobData = Array.from(props.jobs).filter((x) => x.jobId === item.jobId)[0];

        if (jobData !== undefined) {
            const panelJobOptions: IPanelOptions = {
                daysOptions: props.panelOptions.daysOptions,
                durationOptions: props.panelOptions.durationOptions,
                weekOfMonthOptions: props.panelOptions.weekOfMonthOptions,
                serverOptions: props.panelOptions.serverOptions,
            };

            const manageJobPanelProps: IManageJobPanelProps = {
                eTag: jobData.eTag,
                jobID: item.jobId,
                jobName: item.jobName,
                dayOfTheWeek: jobData.dayOfTheWeek,
                weekOfTheMonth: jobData.weekOfTheMonth,
                startTimeHours: jobData.startTimeHours,
                startTimeMinutes: jobData.startTimeMinutes,
                duration: jobData.duration,
                serviceName: jobData.serviceName,
                serviceTreeGUID: jobData.serviceTreeGUID,
                reboot: jobData.reboot,
                alwaysReboot: jobData.alwaysReboot,
                recurring: jobData.recurring,
                allServersSelected: jobData.isAllServersSelected,
                requestType: OpenPanelType.Edit,
                panelOptions: panelJobOptions,
                hideDialog: true,
                refreshDataGrid: () => props.refreshDataGrid(),
                lastScheduledDateTime: jobData.lastScheduledDateTime,
                nextScheduledDateTime: jobData.nextScheduledDateTime,
                linkText: "Edit",
            };

            visibleActions.push(
                <TooltipHost
                    key={"viewButton"}
                    aria-label={viewJobAriaLabel + item.jobName}
                    role="tooltip"
                    content={viewJobAriaLabel + item.jobName}
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 }}
                >
                    <ManageJobPanel {...manageJobPanelProps} />
                </TooltipHost>,
            );
            
            return (
                <ListItemActionSet
                    visibleActions={visibleActions}
                />
            );
        } else {
            return (<span></span>);
        }
    };

    const clickToClosePanel = () => {
        dismissPanel();
        resetPanelData();
        props.refreshDataGrid();
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    // Open panel -- fetch data if editing an existing job
    const openPanelData = async () => {
        openPanel();
    };

    const resetPanelData = () => {
        SetServerName("");
        SetSecondaryButtonText("");
        SetPrimaryButtonText("");
        SetCurrentPageSection(SelectedServerSections.ServerInfo);
        SetHideServerDiv(false);
    };

    const panelPrimaryButtonAction = () => {
        switch (currentPanelSection) {
            case SelectedServerSections.ServerInfo: {
                clickToClosePanel();
                return;
            }
            default: {
                return;
            }
        }
    };

    const panelSecondaryButtonAction = async () => {
        switch (currentPanelSection) {
            case SelectedServerSections.ServerInfo: {
                // no secondary button
                return;
            }
            default: {
                return;
            }
        }
    };

    return (
        <div>
            {/* Link doesn't open panel is text is "Loading" or another panel is open */}
            {/* Edit link or Add Link, depending on where the Panel is loaded */}
            <Link id={"view jobs for server: " + props.serverName} color="#1979c3" aria-label={"view jobs for server: " + props.serverName} onClick={(serverLinkText === "Loading" || isOpen) ? () => {} : openPanelData}>{serverLinkText}</Link>
            {/* Only call Panel element when it is opened by the user */}
            {isOpen &&
                <CoherencePanel
                    titleText={serverName}
                    headerText={serverName}
                    isOpen={isOpen}
                    onDismiss={clickToClosePanel}
                    onLightDismissClick={lightDismissPanel}
                    hasCloseButton={true}
                    closeButtonAriaLabel="Close"
                    onRenderFooter={{
                        primaryButton: {
                            text: primaryButtonText,
                            onAction: (() => {
                                panelPrimaryButtonAction();
                            }),
                        },
                        secondaryButton: {
                            text: secondaryButtonText,
                            onAction: (() => {
                                panelSecondaryButtonAction();
                            }),
                            disabled: (currentPanelSection === SelectedServerSections.ServerInfo),
                        },
                    }}
                    isFooterAtBottom={true}
                    panelSize={CoherencePanelSize.medium}
                >
                <main hidden={hideServerDiv} id="mainDiv">
                    <div style={{ position: "relative", height: "75%" }}>
                        <ScrollablePane style={{ height: "600px", position: "relative" }}>
                            <DetailsList
                                columns={jobsColumns}
                                items={Array.from(props.jobs) as IDetailsListManageJobsItem[]}
                                compact={true}
                                onRenderDetailsHeader={RenderStickyHeader}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                onRenderItemColumn={renderItemColumn.bind(this)}
                            />
                        </ScrollablePane>
                    </div>
                </main>
            </CoherencePanel>}
        </div>
    );

    function renderItemColumn(item?: IDetailsListManageJobsItem, index?: number, column?: IColumn) {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IDetailsListManageJobsItem];
            switch (column.fieldName) {
                case "Edit": {
                    return renderActionRowViewJob(item);
                }
                case "jobName": {
                    return <span id="jobName" className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{fieldContent !== undefined ? fieldContent?.toString() : ""}</span>;
                }
                default: {
                    break;
                }
            }
        }
    }
};
