import { Card } from "@coherence-design-system/controls";
import { CheckboxVisibility, FontIcon, IColumn,
    IDropdownOption, mergeStyles,ScrollablePane,
    SelectionMode,ShimmeredDetailsList } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import HttpService from "../../services/HttpService/HttpService";
import { getHeader } from "../../Styles/Page.styles";
import { OpenPanelType } from "../../Styles/Page.types";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { RootContext } from "../Stores/RootStore";
import { IRulePanelProps, RulePanel } from "./RulePanel";

export interface IRuleProps {
    emailTemplateOptions: IDropdownOption[];
}

export interface IKustoFunctionDataType {
    Name: string;
    Parameters: string;
    Body: string;
    DocString: string;
}

export interface IRulePanelOptions {
    AlertFunctions : IKustoFunctionDataType[];
    AlertFunctionOptions: IDropdownOption[];
    SeverityOptions: IDropdownOption[];
    ScheduleOptions: IDropdownOption[];
    DelayOptions: IDropdownOption[];
    ActionOptions: IDropdownOption[];
    EmailTemplateOptions: IDropdownOption[];
}

export interface IRuleType {
    Id: string;
    Name: string;
    Text: string;
    HeaderText: string;
    FooterText: string;
    KustoFunctionArgs: string;
    Schedule: number;
    Delay: number;
    KustoFunction: string;
    PrimaryActions: string[];
    SecondaryActions: string[];
    DaysOfWeek: string[];
    WeeksOfMonth: string[];
    Enabled: boolean;
    EmailTemplate: string;
    Automatic: boolean;
}

const cardSize = mergeStyles({
    width: "70vw",
    height: "35vh",
});

export const Rules: React.FunctionComponent<IRuleProps> = (props: IRuleProps) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [dataGridData, SetDataGridData] = React.useState<IRuleType[]>([]);
    const [rulesLoaded, SetRulesLoaded] = React.useState<boolean>(false);

    // Panel Options
    const [alertFunctions, SetAlertFunctions] = React.useState<IKustoFunctionDataType[]>([]);
    const [severityOptions, SetSeverityOptions] = React.useState<IDropdownOption[]>([]);
    const [scheduleOptions, SetScheduleOptions] = React.useState<IDropdownOption[]>([]);
    const [delayOptions, SetDelayOptions] = React.useState<IDropdownOption[]>([]);
    const [actionOptions, SetActionOptions] = React.useState<IDropdownOption[]>([]);
    const [alertFunctionOptions, SetAlertFunctionOptions] = React.useState<IDropdownOption[]>([]);

    const defaultPanelOptions: IRulePanelOptions = {
        AlertFunctions: [],
        AlertFunctionOptions: [],
        SeverityOptions: [],
        ScheduleOptions: [],
        DelayOptions: [],
        ActionOptions: [],
        EmailTemplateOptions: [],
    }
    const [rulePanelOptions, SetRulePanelOptions] = React.useState<IRulePanelOptions>(defaultPanelOptions);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        loadRules();

        // Panel Options
        fetchKustoFunctionOptions();
        fetchSeverityOptions();
        fetchScheduleOptions();
        fetchDelayOptions();
        fetchActionOptions();
    }, []);

    React.useEffect(()=> {
        SetRulePanelOptions({
            AlertFunctions: alertFunctions,
            AlertFunctionOptions: alertFunctionOptions,
            SeverityOptions: severityOptions,
            ScheduleOptions: scheduleOptions,
            DelayOptions: delayOptions,
            ActionOptions: actionOptions,
            EmailTemplateOptions: props.emailTemplateOptions,
        });
    }, [alertFunctions, alertFunctionOptions, severityOptions, scheduleOptions, delayOptions, actionOptions, props.emailTemplateOptions]);

    const loadRules = () => {
        httpService.get({
            url: "api/ComplianceRule/fetchRules",
            token: state.AuthStore.Token,
            params: {},
          }).then((resp: any) => {
            if (resp !== undefined && resp.data !== undefined) {
                const respData = resp.data;             
                const rules: IRuleType[] = [];

                for(const r of respData) {
                    rules.push({
                        Id: r.id,
                        Name: r.name,
                        Text: r.text,
                        HeaderText: r.headerText,
                        FooterText: r.footerText,
                        KustoFunctionArgs: r.kustoFunctionArgs,
                        PrimaryActions: r.primaryActions,
                        DaysOfWeek: r.daysOfWeek?.toString().split(","),
                        WeeksOfMonth: r.weeksOfMonth?.toString().split(","),
                        Schedule: r.schedule,
                        SecondaryActions: r.secondaryActions,
                        Delay: r.delay,
                        KustoFunction: r.kustoFunction,
                        Enabled: r.enabled,
                        EmailTemplate: r.emailTemplate,
                        Automatic: r.automatic
                    });
                }
                
                SetDataGridData(rules);
                SetRulesLoaded(true);
            }
          }).catch((error: any) => {
            console.error(error);
            const rules: IRuleType[] = [];

            rules.push({
                Id: "",
                Name: "Something Went Wrong",
                Text: "",
                HeaderText: "",
                FooterText: "",
                KustoFunctionArgs: "",
                PrimaryActions: [""],
                DaysOfWeek: [""],
                WeeksOfMonth: [""],
                Schedule: 0,
                SecondaryActions: [""],
                Delay: 0,
                KustoFunction: "",
                Enabled: false,
                EmailTemplate: "",
                Automatic: false
            });
        
            SetDataGridData(rules);

            SetRulesLoaded(true);
            trackException(appInsights, error, SeverityLevel.Error, "Rules", "Offboard Stale Servers", "Offboard Stale Servers", state.AuthStore, {});
          });
    }

    const fetchSeverityOptions = () => {
        const severities: number[] = [1,2,3,4];
        let options: IDropdownOption[] = [];

        for (const d of severities) {
            options = [ ...options, { key: d , text: d.toString() }];
        }

        SetSeverityOptions(options);
    }

    const fetchScheduleOptions = () => {
        // const frequencies: number[] = [1,2,3,4,5,6,7];
        const frequencies: number[] = [1,7];
        let options: IDropdownOption[] = [];

        // for (const d of frequencies) {
            options = [
                { key: 1 , text: "Daily" },
                { key: 7 , text: "Weekly" },
            ];
        // }

        SetScheduleOptions(options);
    }

    const fetchActionOptions = () => {
        httpService.get({
            url: "api/ComplianceRule/fetchActionOptions",
            token: state.AuthStore.Token,
            params: {},
        }).then((response: any) => {
            let options: IDropdownOption[] = [];

            for (const d of response?.data) {
                options = [ ...options, { key: d.action , text: d.action }];
            }
    
            SetActionOptions(options);
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Admin", "Fetch Compliance Actions", "", state.AuthStore, {});
        });
    }

    const fetchDelayOptions = () => {
        const delays: number[] = [1,2,3,4,5,6,7,14];
        let options: IDropdownOption[] = [];

        for (const d of delays) {
            options = [ ...options, { key: d , text: d.toString() }];
        }

        SetDelayOptions(options);
    }

    const fetchKustoFunctionOptions = () => {
        httpService.get({
            url: "api/Kusto/fetchAlertFunctions",
            token: state.AuthStore.Token,
            params: {}
        }).then((resp: any) => {
            if (resp !== undefined && resp.data !== undefined) {
                const options: IDropdownOption[] = [];
                const kustoFunctionsResp: IKustoFunctionDataType[] = [];

                for (const d of resp?.data) {
                    options.push({ key: d.Name , text: `${d.Name} ${d.parameters!=="()"?d.Parameters:""}`  });
                    kustoFunctionsResp.push({
                        Name: d.Name,
                        Parameters: d.Parameters,
                        Body: d.Body,
                        DocString: d.DocString,
                    });
                }
        
                SetAlertFunctions(kustoFunctionsResp);
                SetAlertFunctionOptions(options);
            }
          }).catch((error: any) => {
            trackException(appInsights, error, SeverityLevel.Error, "Rules", "Fetching Kusto Functions", error, state.AuthStore, {});
          });
    }

    const rulesColumns: IColumn[] = [
        {
            key: "Edit",
            name: "Edit",
            fieldName: "Edit",
            minWidth: 50,
            maxWidth: 60,
            isResizable: false,
        },
        {
            key: "Name",
            name: "Name",
            fieldName: "Name",
            minWidth: 130,
            maxWidth: 140,
            isResizable: true,
        },
        {
            key: "Enabled",
            name: "Enabled",
            fieldName: "Enabled",
            minWidth: 70,
            maxWidth: 135,
            isResizable: true,
        },
        {
            key: "Text",
            name: "Text",
            fieldName: "Text",
            minWidth: 140,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "PrimaryActions",
            name: "Primary Actions",
            fieldName: "PrimaryActions",
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
        },
        // {
        //     key: "Schedule",
        //     name: "Schedule",
        //     fieldName: "Schedule",
        //     minWidth: 90,
        //     maxWidth: 90,
        //     isResizable: true,
        // },
        {
            key: "DaysOfWeek",
            name: "Days",
            fieldName: "DaysOfWeek",
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
        },
        // {
        //     key: "WeeksOfMonth",
        //     name: "Weeks",
        //     fieldName: "WeeksOfMonth",
        //     minWidth: 60,
        //     maxWidth: 90,
        //     isResizable: true,
        // },
        {
            key: "SecondaryActions",
            name: "Secondary Actions",
            fieldName: "SecondaryActions",
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
        },
        {
            key: "Delay",
            name: "Delay",
            fieldName: "Delay",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
        },
        {
            key: "KustoFunction",
            name: "Function",
            fieldName: "KustoFunction",
            minWidth: 110,
            maxWidth: 135,
            isResizable: true,
        },
        {
            key: "KustoFunctionArgs",
            name: "Function Args",
            fieldName: "KustoFunctionArgs",
            minWidth: 110,
            maxWidth: 135,
            isResizable: true,
        }
    ];

    const refreshDataGrid = () => {
        SetRulesLoaded(false);
        loadRules();
    };

    const onRenderItemColumn = (item?: IRuleType, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IRuleType] as any | any[];
            const currentRule: IRuleType = {
                Id: item.Id,
                Name: item.Name,
                Text: item.Text,
                HeaderText: item.HeaderText,
                FooterText: item.FooterText,
                KustoFunctionArgs: item.KustoFunctionArgs,
                PrimaryActions: item.PrimaryActions,
                DaysOfWeek: item.DaysOfWeek,
                WeeksOfMonth: item.WeeksOfMonth,
                Schedule: item.Schedule,
                SecondaryActions: item.SecondaryActions,
                Delay: item.Delay,
                KustoFunction: item.KustoFunction,
                Enabled: item.Enabled,
                EmailTemplate: item.EmailTemplate,
                Automatic: item.Automatic
            }

            switch (column.fieldName) {
                case "Edit" : {
                   
                    const ruleEditProps: IRulePanelProps = {
                        refreshDataGrid: refreshDataGrid,
                        requestType: OpenPanelType.Edit,
                        ruleInfo: currentRule,
                        linkText: "Edit",
                        ruleOptions: rulePanelOptions,
                    }

                    return <RulePanel {...ruleEditProps} />
                }
                case "DaysOfWeek": {
                    return (currentRule.Schedule == 1 ? "Daily" : fieldContent)
                }
                case "WeeksOfMonth": {
                    return (fieldContent as string[])?.join(", ")
                }
                case "PrimaryActions": {
                    return (fieldContent as string[])?.join(", ")
                }
                case "SecondaryActions": {
                    return (fieldContent as string[])?.join(", ")
                }
                case "Enabled" : {
                    return <div> {
                        fieldContent   
                        ? ( <span><FontIcon aria-label="Enabled Rule" iconName="StatusCircleCheckmark" className={mergeStyles({ color: "green" })} /> Enabled </span>  ) 
                        : (<span> <FontIcon aria-label="Disabled Rule" iconName="StatusCircleErrorX" className={mergeStyles({ color: "red" })} /> Disabled </span>  )
                        }
                        </div>;
                }
                default:
                    return <span>{fieldContent}</span>;
            }
        }
    };
    
    const addRuleProps: IRulePanelProps = {
        refreshDataGrid: refreshDataGrid,
        requestType: OpenPanelType.Add,
        linkText: "+ Add New Rule",
        ruleInfo: undefined,
        ruleOptions: rulePanelOptions,
    }

    return (
        <div className={cardSize}>
            <Card header={getHeader("IcM Alert Rules", "left")}>
                <RulePanel {...addRuleProps} />
                <ScrollablePane style={{ height: "25vh", position: "relative" }}>
                    <ShimmeredDetailsList
                        columns={rulesColumns}
                        items={dataGridData}
                        compact={true}
                        onRenderDetailsHeader={RenderStickyHeader}
                        checkboxVisibility={CheckboxVisibility.onHover}
                        onRenderItemColumn={onRenderItemColumn}
                        selectionMode={SelectionMode.none}
                        enableShimmer={!rulesLoaded}
                    />
                </ScrollablePane>
            </Card>
        </div>
    );
};
