import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import HttpClient, { UserCallResult } from "./HttpClient";
import { IRootState } from "../../components/Stores/RootStore";

let callResult: UserCallResult = UserCallResult.Start;

const updateCallResult = (res: UserCallResult) => {
    if (callResult !== UserCallResult.Error && callResult !== res) { // stay error if error found
        callResult = res;
    }
};

export const HttpService  = (appInsights?: ApplicationInsights, rootState?: IRootState) :HttpClient => {
    const httpClient = new HttpClient(updateCallResult, appInsights!, rootState!);
    return httpClient;
};

export const CreateHttpClient  = (appInsights: ApplicationInsights, rootState: IRootState) :HttpClient => {
    const httpClient = new HttpClient(updateCallResult, appInsights, rootState);
    return httpClient;
};

export const getCallResult = (): UserCallResult => {
    return callResult;
};

export default HttpService;
