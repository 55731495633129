import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { Label, Link } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as React from "react";
import { IJobResult } from "./DetailsListJobHistory";
import { JobDetailsList } from "./JobDetailsList";
import { ServerDetailsList } from "./ServerDetailsList";
import { CSVLink } from "react-csv";

const backText = "Back";
const closeText = "Close";
const exportText = "Export";
const exportCsvFileName = "jobDetails";

export interface IJobHistoryPanelProps {
    jobName: string;
    jobData: IJobHistoryPanelType;
    buttonText: string;
}

export interface IJobHistoryPanelType {
    JobId: string;
    JobName: string;
    ServiceName: string;
    ServiceTreeGUID: string;
    ServerIds: string[];
    FQDNs: string[];
    JobResults: IJobResult[];
}

export interface IJobHistoryPanelData {
    FQDN: string;
    JobResults: IJobResult[];
}

enum PanelSection {
    JobDetails,
    ServerDetails,
    Results,
}

export const JobHistoryPanel: React.FunctionComponent<IJobHistoryPanelProps> = (props: IJobHistoryPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.JobDetails);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>(closeText);
    const [secondaryButtonText, SetSecondaryButtonText] = React.useState<string>("");
    const [jobDetailsData, SetJobDetailsData] = React.useState<IJobHistoryPanelData[]>([]);
    const [serverDetailsData, SetServerDetailsData] = React.useState<IJobResult[]>([]);
    const [jobResultsMap, SetJobResultsMap] = React.useState<Map<string, IJobResult[]>>(new Map());
    const [selectedServer, SetSelectedServer] = React.useState<string>();

    React.useEffect(() => {
        const serverData: IJobHistoryPanelData[] = [];
        const jobMap: Map<string, IJobResult[]> = new Map();

        for(const job of props.jobData.JobResults) {
            if (!jobMap.has(job.serverName)) {
                const newJobs: IJobResult[] = [];
                newJobs.push(job);
                jobMap.set(job.serverName, newJobs);
            } else {
                const currentJobs: IJobResult[] = jobMap.get(job.serverName)!;
                const newJobResults: IJobResult[] = currentJobs?.concat(job);

                jobMap.set(job.serverName, newJobResults);
            }
        }

        for(let i = 0; i < props.jobData.FQDNs.length; i++) {
            const serverName: string = props.jobData.FQDNs[i].split(".")[0];

            serverData.push({
                FQDN: props.jobData.FQDNs[i],
                JobResults: jobMap.has(serverName) ? jobMap.get(serverName) ?? [] : [],
            });
        }

        SetJobResultsMap(jobMap);
        SetJobDetailsData(serverData);
    }, [isOpen, props]);

    const clickToOpenPanel = () => {
        SetCurrentPanel(PanelSection.JobDetails);
        openPanel();
    };

    const clickToClosePanel = () => {
        SetCurrentPanel(PanelSection.JobDetails);
        dismissPanel();
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    const openServerDetailsSection = (server: string) => {
        SetCurrentPanel(PanelSection.ServerDetails);
        SetSelectedServer(server);   
        SetServerDetailsData(jobResultsMap.has(server) ? jobResultsMap.get(server)! : [])
        SetPrimaryButtonText(backText);
    }

    const openJobDetailsSection = () => {
        SetCurrentPanel(PanelSection.JobDetails);
        SetSelectedServer("");   
        SetPrimaryButtonText(closeText);
    }

    const primaryButtonAction = () => {
        switch (primaryButtonText) {
            case backText:
                openJobDetailsSection();
                break;
            case closeText:
                clickToClosePanel();
                break;
            default:
                break;
        }
    }

    const secondaryButtonAction = () => {}

    return (
        <div>
            <Link onClick={clickToOpenPanel}>
                {props.buttonText}
            </Link>
            {isOpen && 
                <CoherencePanel
                    titleText={currentPanel == PanelSection.JobDetails ? "Job Details" : "Server Details"}
                    title={currentPanel == PanelSection.JobDetails ? "Job Details" : "Server Details"}
                    headerText={currentPanel == PanelSection.JobDetails ? "Job Details" : "Server Details"}
                    isOpen={isOpen}
                    hasCloseButton={true}
                    onDismiss={clickToClosePanel}
                    onLightDismissClick={lightDismissPanel}
                    closeButtonAriaLabel="Close"
                    onRenderFooter={{
                        primaryButton: {
                            text: primaryButtonText,
                            onAction: primaryButtonAction,
                        },
                        secondaryButton: {
                            text: secondaryButtonText,
                            onAction: secondaryButtonAction,
                        }
                    }}
                    panelSize={CoherencePanelSize.large}
                >
                    <main>
                        <div>
                            <Label>Job Name: </Label> {props.jobName}
                            <Label>Service: </Label> {props.jobData.ServiceName}
                        </div>
                        <div hidden={currentPanel !== PanelSection.JobDetails}>
                            <Label>Server Count:</Label> {props.jobData.FQDNs.length}
                            <JobDetailsList 
                                jobDetailsData={jobDetailsData}
                                openServerDetailsSection={openServerDetailsSection}
                            />
                        </div>
                        <div hidden={currentPanel !== PanelSection.ServerDetails}>
                            <Label>Server: </Label> {selectedServer}
                            <ServerDetailsList
                                serverDetailsData={serverDetailsData}
                            />
                            <CSVLink 
                                aria-label={"Export Results"} 
                                filename={exportCsvFileName} 
                                title={"Export Results"} 
                                data={serverDetailsData}>
                                    Export Data
                            </CSVLink>
                        </div>
                        <div hidden={currentPanel !== PanelSection.Results}>
                            <h3>Results</h3>
                        </div>
                    </main>
                </CoherencePanel>
            }
        </div>
    );
};
