import { Depths, IDropdownStyles,
    ITextFieldStyles, mergeStyleSets, ScrollablePane,
    Spinner} from "@fluentui/react";
import { Collapsible } from "@m365-admin/collapsible";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackException, trackPageView } from "../components/AppInsights/LoggingHelper";
import { AssignIcmTeam,
    IServiceTeamsType,
    IServiceType,
} from "../components/ServiceAdministration/AssignIcMTeam";
import { DelegatePermission,
    IDelegatedPermissionsType,
} from "../components/ServiceAdministration/DelegatePermission";
import { DetailsListAutoEnroll } from "../components/ServiceAdministration/DetailsListAutoEnroll";
import { EmailContact } from "../components/ServiceAdministration/EmailContact";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { UnauthorizedMessage } from "../components/Shared/AppConstants";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { UserPermission } from "../services/AuthenticationService/AuthReducer";
import { fetchGraphAPIAuthToken } from "../services/FetchGraphAPIAuthToken";
import HttpService from "../services/HttpService/HttpService";
import { breakWord, _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { IServiceDataType, PAGE } from "../App";
import { useParams } from "react-router";
import { DataActionEnum } from "../services/DataContext/DataActions";
import { OnboardedServicesDropdown } from "../components/Shared/OnboardedServicesDropdown";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";
import { Guid } from "guid-typescript";

// Styles //

export const serviceGridHeight = mergeStyleSets({
    oneAssetGridHeight: {
        height: "200px",
        overflow: "auto",
    },
    dataGridHeightCollapsed: {
        height: "250px",
        overflow: "auto",
    },
});

export const buttonStyles: Partial<ITextFieldStyles> = { root: { maxWidth: "50px" } };
export const dropdownStylesServiceAdmin: Partial<IDropdownStyles> = {
    root: { maxWidth: "300px" },
    label: { display: "none" },
};

// Interfaces //

export interface IAdminDataType {
    ServiceName: string;
    DevOwner: string;
    PMOwner: string;
    FeatureTeamAlias: string;
    Admin: string;
    ServiceTreeGUID: string;
}

// Seperator

const ColoredLine = () => (
    <div>
        <hr
            style={{
                backgroundColor: "black",
                color: "black",
                height: 1,
            }}
        />
    </div>
);

// consts
const dataDidNotLoadString: string = "Data did not load properly, please restart application.";
const dataDidNotLoad: IDelegatedPermissionsType[] = [{
    ServiceTreeGUID: dataDidNotLoadString,
    User: "",
    Role: "",
    DelegatedBy: "",
}];

const loadingData: IDelegatedPermissionsType[] = [{
    ServiceTreeGUID: "Loading...",
    User: "Loading...",
    Role: "",
    DelegatedBy: "",
}];

const pageTitle: string = "Service Administration";
const couldNotQueryServiceTreeText: string = "Could Not Query Service Tree Data";

export const ServiceAdministrationPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const { state, dispatch } = React.useContext(RootContext); // auth token context

    // Selection Data
    const [teamsDisabled, SetTeamsDisabled] = React.useState<boolean>(true);

    // Delegation Elements (buttons, etc.)
    const [serviceAdmins, SetServiceAdmins] = React.useState<string[]>([]);
    const [adminDataDisplayed, SetAdminDataDisplayed] = React.useState<IAdminDataType[]>([]);
    const [delegatedUserList, SetDelegatedUserList] = React.useState<string[]>([]);
    const [delegatedPermissionsDisplayed, SetDelegatedPermissionsDisplayed] = React.useState<IDelegatedPermissionsType[]>([]);

    // Assign IcM Data
    const [allServicesData, SetAllServicesData] = React.useState<IServiceType[]>([{ TenantId: "", TenantName: "", PublicTenantId: "" }]);

    // read "serviceTreeGuid" from url param using useParams() hook
    const { serviceTreeGuid: serviceTreeGuidFromParam } = useParams();     
    const [isServiceSelected] = React.useState<boolean>(serviceTreeGuidFromParam && serviceTreeGuidFromParam.length > 0 ? true : false);
    const [selectedService, SetSelectedService] = React.useState<IServiceDataType>({ServiceName: "", ServiceTreeGUID: serviceTreeGuidFromParam && serviceTreeGuidFromParam.length > 0 ? serviceTreeGuidFromParam : ""});
    
    const [selectedTeam, SetSelectedTeam] = React.useState<IServiceTeamsType>({TenantId: "", TenantName: "", TeamId: "", PublicTeamId: "", TeamName: "" });
    const [emailContactsDisplayed, SetEmailContactsDisplayed] = React.useState<string>("");
    const [hideServiceAdminData, SetHideServiceAdminData] = React.useState<boolean>(true);
    const [hideMakeChanges, SetHideMakeChanges] = React.useState<boolean>(true);
    const [loadingServiceData, SetLoadingServiceData] = React.useState<boolean>(false);

    // Collapsible Exapnded Flags
    const [icmTeamCollapsibleExpanded, SetIcmTeamCollapsibleExpanded] = React.useState<boolean>(false);
    const [delegatedCollapsibleExpanded, SetDelegatedCollapsibleExpanded] = React.useState<boolean>(false);
    const [emailCollapsibleExpanded, SetEmailCollapsibleExpanded] = React.useState<boolean>(false);
    const [autoEnrollCollapsibleExpanded, SetAutoEnrollCollapsibleExpanded] = React.useState<boolean>(false);

    // MSFT Graph API Token for People Picker
    const [graphToken, SetGraphToken] = React.useState<any>("");

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    // useEffect 'cleaup' component to supress warning: "Can't perform a React state update on an unmounted component"
    React.useEffect(() => {
        return () => {};
        }, []);

    React.useEffect(() => {
        if (selectedService.ServiceTreeGUID && selectedService.ServiceTreeGUID.length > 0) {
            updateAdminData(selectedService.ServiceTreeGUID);
        }
    }, [selectedService.ServiceName, selectedService.ServiceTreeGUID]);

    // Load service data
    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {  
            document.title = pageTitle;

            if (isServiceSelected) {
                updateAdminData(serviceTreeGuidFromParam!)
            }
    
            dispatch({
                type: DataActionEnum.UPDATE_CURRENT_PAGE,
                currentPage: "serviceadministration"
            });
    
            const telemetry = buildPageViewTelemetry(
                pageTitle,
                "/serviceadministration",
                "/serviceadministration",
                state.AuthStore.Email,
                state.AuthStore.isAdmin,
            );
            
            trackPageView(appInsights, telemetry);

            if (graphToken === "") {
                fetchGraphAPIAuthToken(state.AuthStore.Account).then((graphAuthResponse: any) => {
                    SetGraphToken(graphAuthResponse);
                }).catch((reason: any) => {
                    trackException(appInsights, reason, SeverityLevel.Error, "ServerAdministration", "fetchGraphAPIAuthToken", "Necessary for PeoplePicker", state.AuthStore, {});
                });
            }

            httpService.get({
                url: "api/icm/fetchAllServices",
                token: state.AuthStore.Token,
                params: {},
            }).then((response: any) => {
                const data: any = response?.data;
                    if (data !== "" && data !== undefined) {
                    let allServicesArray: IServiceType[] = [];

                    for (const d of data) {
                        allServicesArray = [...allServicesArray, {
                            TenantId: d.tenantId, TenantName: d.tenantName, PublicTenantId: d.publicTenantId
                        }];
                    }

                    SetAllServicesData(allServicesArray.sort((s1, s2) => {
                        if (s1.TenantName > s2.TenantName) { return 1; }
                        if (s2.TenantName > s1.TenantName) { return -1; }
                        return 0;
                    }));
                }
            }).catch((reason: any) => {
                const status = reason.response?.status ? reason.response.status.toString() : "unknown status";
                const statusText = reason.response?.statusText ? reason.response.statusText.toString() : "unknown status text";
                const responseURL = reason?.response?.request?.responseURL ? reason.response.request.responseURL.toString() : "unknown URL";
            
                const reasonString: string = status + " " + statusText + " : " + responseURL;
                trackException(appInsights, reason, SeverityLevel.Error, "ServerAdministration", "fetchAllServices", reasonString, state.AuthStore, {});
            });
        }
    }, []);

    const toggleIcMCollaspsible = () => {
        SetIcmTeamCollapsibleExpanded(!icmTeamCollapsibleExpanded);
        SetDelegatedCollapsibleExpanded(false);
        SetEmailCollapsibleExpanded(false);
        SetAutoEnrollCollapsibleExpanded(false);
    };

    const toggleDelegateCollaspsible = () => {
        SetIcmTeamCollapsibleExpanded(false);
        SetDelegatedCollapsibleExpanded(!delegatedCollapsibleExpanded);
        SetEmailCollapsibleExpanded(false);
        SetAutoEnrollCollapsibleExpanded(false);
    };

    const toggleEmailCollaspsible = () => {
        SetIcmTeamCollapsibleExpanded(false);
        SetDelegatedCollapsibleExpanded(false);
        SetEmailCollapsibleExpanded(!emailCollapsibleExpanded);
        SetAutoEnrollCollapsibleExpanded(false);
    };

    const toggleAutoEnrollCollapsible = () => {
        SetIcmTeamCollapsibleExpanded(false);
        SetDelegatedCollapsibleExpanded(false);
        SetEmailCollapsibleExpanded(false);
        SetAutoEnrollCollapsibleExpanded(!autoEnrollCollapsibleExpanded);
    };

    const fetchServiceTreeData = (serviceTreeGUIDFilter: string) => httpService.get({
        url: "api/ServiceAdministration/fetchServiceTreeDetails",
        token: state.AuthStore.Token,
        params: { 
            service: Guid.isGuid(serviceTreeGUIDFilter) ? serviceTreeGUIDFilter : Guid.EMPTY,
        },
    });

    const loadIcmTeamData = (icmServiceId: string) => httpService.get({
        url: "api/ServiceAdministration/fetchIcMTeamData",
        token: state.AuthStore.Token,
        params: {
            serviceTreeGUID: icmServiceId,
        },
    });

    const handleIcMTeamData = (data: any) => {
        const jsonData = JSON.stringify(data);
        const icmData = JSON.parse(jsonData);

        if (icmData[0].emailContact !== null && icmData[0].emailContact != "" && icmData[0].emailContact !== "null") {
            SetEmailContactsDisplayed(icmData[0].emailContact);
        } else {
            SetEmailContactsDisplayed("");
        }

        if (icmData?.length !== 0 && icmData[0].tenantName && icmData[0].tenantName !== "") {
            SetSelectedTeam({
                TenantId: icmData[0].tenantId,
                TenantName: icmData[0].tenantName,
                TeamId: icmData[0].teamId,
                TeamName: icmData[0].teamName,
                PublicTeamId: icmData[0].publicTeamId
            });
        } else {
            SetSelectedTeam({
                TenantId: "UNASSIGNED",
                TenantName: "UNASSIGNED",
                TeamId: "UNASSIGNED",
                PublicTeamId: "UNASSIGNED",
                TeamName: "UNASSIGNED",
            });
        }

        SetTeamsDisabled(true);
    }

    const loadDelegatedDataDisplayed = (uServiceId: string) => httpService.get({
        url: "api/ServiceAdministration/delegatedUsers",
        token: state.AuthStore.Token,
        params: {
            serviceTreeGUID: uServiceId,
        },
    });

    const handleDelegatedData = (data: any) => {
        const jsonData = JSON.stringify(data);
        const delegateData = JSON.parse(jsonData);

        let delegationData: IDelegatedPermissionsType[] = [];
        let delegatedUsers: string[] = [];

        if (delegateData?.length === 0) {
            SetDelegatedUserList([]);
            SetDelegatedPermissionsDisplayed([]);
        } else {
            for (const d of delegateData) {
                delegationData = [...delegationData, {
                        ServiceTreeGUID: d.serviceTreeGUID,
                        User: d.user,
                        Role: d.role,
                        DelegatedBy: d.delegatedBy,
                    }];

                delegatedUsers = [...delegatedUsers, d.user];
            }

            SetDelegatedUserList(delegatedUsers);
            SetDelegatedPermissionsDisplayed(delegationData);
        }
    }

    const updateAdminData = async (serviceTreeGuid: string): Promise<void> => {
        SetLoadingServiceData(true);
        SetHideServiceAdminData(true);
        SetHideMakeChanges(true);

        if (serviceTreeGuid !== "Loading...") {
            if (serviceTreeGuid) {
                // Reset displayed delegated data
                SetDelegatedUserList([]);
                SetDelegatedPermissionsDisplayed(loadingData);

                // reset data
                SetEmailContactsDisplayed("");
                SetSelectedTeam({
                    TenantId: "",
                    TenantName: "",
                    TeamId: "",
                    PublicTeamId : "",
                    TeamName: "",
                });

                await Promise.all([fetchServiceTreeData(serviceTreeGuid), loadIcmTeamData(serviceTreeGuid), loadDelegatedDataDisplayed(serviceTreeGuid)]).then((responseArray: any[]) => {
                    let i: number = 0;

                    responseArray.forEach((response: any) => {
                        const data: any = response?.data;
                        if (data !== "" && data !== undefined && data !== UnauthorizedMessage) {
                            try {
                                switch (i) {
                                    case 0: {
                                        if (data.length !== 0) {
                                            const serviceTreeData: IAdminDataType = {
                                                ServiceName: data[0].ServiceName,
                                                DevOwner: data[0].DevOwner,
                                                PMOwner: data[0].PMOwner,
                                                FeatureTeamAlias: data[0].FeatureTeamAlias,
                                                Admin: data[0].AdminAliases,
                                                ServiceTreeGUID: data[0].ServiceTreeGUID,
                                            };
                                
                                            // Update Delegated Data from Azure
                                            const queriedServiceData: IServiceDataType = {
                                                ServiceName: serviceTreeData.ServiceName,
                                                ServiceTreeGUID: serviceTreeData.ServiceTreeGUID,
                                            };
                                            
                                            SetSelectedService(queriedServiceData);
                                            SetServiceAdmins(serviceTreeData ? serviceTreeData.Admin.split(";") : []);
                                            SetAdminDataDisplayed(serviceTreeData ? [serviceTreeData] : []);
                                        } else {
                                            const couldNotQueryST: IServiceDataType = {
                                                ServiceName: couldNotQueryServiceTreeText,
                                                ServiceTreeGUID: serviceTreeGuid,
                                            };
                                            
                                            SetSelectedService(couldNotQueryST);
                                        }

                                        break;
                                    }
                                    case 1: {
                                        handleIcMTeamData(data);
                                        break;
                                    }
                                    case 2: {
                                        handleDelegatedData(data)
                                        break;
                                    }
                                    default: {
                                        break;
                                    }
                                }
                            } catch (ex: any) {
                                trackException(appInsights, new Error(), SeverityLevel.Error, "ServerAdministration", "fetchServersInfoData", ex?.message!, state.AuthStore, {});
                            }
                        } else {
                            // undefined load
                            const failedToLoadData: IDelegatedPermissionsType[] = [{
                                ServiceTreeGUID: "",
                                User: "Delegation data unavailable",
                                Role: UnauthorizedMessage,
                                DelegatedBy: "",
                            }];
            
                            SetTeamsDisabled(true);
                            SetDelegatedUserList([UnauthorizedMessage]);
                            SetDelegatedPermissionsDisplayed(failedToLoadData);
                            
                            trackException(appInsights, new Error(), SeverityLevel.Error, "ServerAdministration", "bad data", "", state.AuthStore, {});
                        }

                        i++;
                    });
                }).catch((reason) => {
                    const reasonString: string = (reason.response?.status).toString() + " " + (reason.response?.statusText).toString() + " : " + (reason?.response?.request?.responseURL).toString();
                    trackException(appInsights, new Error(), SeverityLevel.Error, "ServerAdministration", "fetchServersInfoData", reasonString, state.AuthStore, {});
                }).finally(() =>{
                    // Unhide data after loading
                    SetLoadingServiceData(false);
                    SetHideServiceAdminData(false);
                    SetHideMakeChanges(false);
                });
            } else {
                SetHideServiceAdminData(false);

                // Reset displayed delegated data
                SetDelegatedUserList([]);

                const dataDidNotLoadST: IServiceDataType = {
                    ServiceName: dataDidNotLoadString,
                    ServiceTreeGUID: serviceTreeGuid,
                };
                
                SetSelectedService(dataDidNotLoadST);
                SetDelegatedPermissionsDisplayed(dataDidNotLoad);
            }
        }
    };

    const onSelectService = (service: IServiceDataType) => {
        SetSelectedService(service);
    }

    return (
        <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
            <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                <AdminMessageBanner page={PAGE.SERVICEADMINISTRATION} />
            </div>
            <div className={_Styles.rootDiv}>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Permitted || state.AuthStore.UserTimeOut}>
                    <h1 id="serviceadministrationtitle" className={breakWord}>
                        {ReactHtmlParser(pageTitle)}
                    </h1>
                    <OnboardedServicesDropdown
                        id={"serviceDropdown"} 
                        serviceTreeGuidFromParam={serviceTreeGuidFromParam}
                        onSelectService={onSelectService}             
                    />
                    <div>
                        <div hidden={!loadingServiceData}>
                            <Spinner
                                label={"Loading " + selectedService.ServiceName + " Data..."}
                                ariaLive="assertive"
                                labelPosition="left" />
                        </div>
                        <div hidden={hideServiceAdminData}>
                            <h2 id="serviceNameTitle">{selectedService.ServiceName}</h2>
                            <p>Selected IcM Team: <b className={breakWord}>{selectedTeam.TenantName ? selectedTeam.TenantName : ""}</b> / <b>{selectedTeam.TeamName ? selectedTeam.TeamName : ""}</b></p>
                            <p>Delegated Admins: <b className={breakWord}>{delegatedUserList.join(", ")}</b></p>
                            <p>Additional Email Contact(s): <b className={breakWord}>{emailContactsDisplayed}</b></p>
                            <h3>ServiceTree Information</h3>
                            <p>Dev Owner: <b className={breakWord}>{adminDataDisplayed[0]?.DevOwner.toString()}</b></p>
                            <p>PM Owner: <b className={breakWord}>{adminDataDisplayed[0]?.PMOwner.toString()}</b></p>
                            <p>Feature Team Alias: <b className={breakWord}>{adminDataDisplayed[0]?.FeatureTeamAlias.toString()}</b></p>
                            <p>Admin: <b className={breakWord}>{adminDataDisplayed[0]?.Admin.toString()}</b></p>
                            <ColoredLine />
                            <div hidden={hideMakeChanges}>
                                <h2>Make Changes:</h2>
                                <Collapsible
                                    onToggle={toggleIcMCollaspsible}
                                    isExpanded={icmTeamCollapsibleExpanded}
                                    title="Assign IcM Team"
                                    id="assignIcmTeamCollapsible"
                                >
                                    <AssignIcmTeam
                                        selectedServiceTreeGUID={selectedService.ServiceTreeGUID}
                                        selectedServiceName={selectedService.ServiceName}
                                        teamsDisabled={teamsDisabled}
                                        SetTeamsDisabled={SetTeamsDisabled}
                                        selectedTeam={selectedTeam}
                                        allServicesData={allServicesData}
                                        SetSelectedTeam={SetSelectedTeam}
                                    />
                                </Collapsible>
                                <Collapsible
                                    onToggle={toggleDelegateCollaspsible}
                                    isExpanded={delegatedCollapsibleExpanded}
                                    title="Delegate Permission"
                                >
                                    <DelegatePermission
                                        serviceAdmins={serviceAdmins}
                                        selectedServiceTreeGUID={selectedService.ServiceTreeGUID}
                                        delegatedUserList={delegatedUserList}
                                        delegatedPermissionsDisplayed={delegatedPermissionsDisplayed}
                                        graphToken={graphToken}
                                        SetTeamsDisabled={SetTeamsDisabled}
                                        updateDelegatedDataDisplayed={updateDelegatedDataDisplayed}
                                    />
                                </Collapsible>
                                <Collapsible
                                    onToggle={toggleEmailCollaspsible}
                                    isExpanded={emailCollapsibleExpanded}
                                    title="Additional Email Contact"
                                >
                                    <EmailContact
                                        selectedServiceTreeGUID={selectedService.ServiceTreeGUID}
                                        emailContactsDisplayed={emailContactsDisplayed}
                                        graphToken={graphToken}
                                        updateIcmTeamData={updateIcmTeamData}
                                    />
                                </Collapsible>
                                
                                <Collapsible
                                    onToggle={toggleAutoEnrollCollapsible}
                                    isExpanded={autoEnrollCollapsibleExpanded}
                                    title="Auto Enroll Devices"
                                >
                                    <DetailsListAutoEnroll 
                                        appInsights={appInsights} 
                                        selectedServiceTreeGuid={selectedService.ServiceTreeGUID} 
                                        selectedServiceName={selectedService.ServiceName} />
                                </Collapsible>
                            </div>
                        </div>
                    </div>
                </div>
                <BackToTopButton />
                <PagePermissionsComponent />
            </div>
        </ScrollablePane>
    );

    function updateIcmTeamData(icmServiceId: string) {
        // reset data
        SetEmailContactsDisplayed("");
        SetSelectedTeam({
            TenantId: "",
            TenantName: "",
            TeamId: "",
            PublicTeamId: "",
            TeamName: "",
        });

        loadIcmTeamData(icmServiceId).then((IcMResponse: any) => {
            const data: any = IcMResponse?.data;
            if (data !== "" && data !== undefined && data !== UnauthorizedMessage) {
                handleIcMTeamData(data);
            }
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "ServerAdministration", "fetchIcMTeamData", icmServiceId, state.AuthStore, {});
        });
    }
    

    function updateDelegatedDataDisplayed(uServiceId: string) {
        loadDelegatedDataDisplayed(uServiceId).then((delegatedResponse: any) => {
            const data: any = delegatedResponse?.data;
            if (data !== "" && data !== undefined && data !== UnauthorizedMessage) {
                handleDelegatedData(data);
            } else {
                // undefined load
                const failedToLoadData: IDelegatedPermissionsType[] = [{
                    ServiceTreeGUID: "",
                    User: "Delegation data unavailable",
                    Role: UnauthorizedMessage,
                    DelegatedBy: "",
                }];

                SetTeamsDisabled(true);
                SetDelegatedUserList([UnauthorizedMessage]);
                SetDelegatedPermissionsDisplayed(failedToLoadData);
            }
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "ServerAdministration", "fetchDelegatedPermissionsData", reason?.response?.statusCode.toString(), state.AuthStore, {});
        });
    }
};
