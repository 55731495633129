import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { trackException } from "../../components/AppInsights/LoggingHelper";
import HttpService from "../HttpService/HttpService";
import { IRootState } from "../../components/Stores/RootStore";

export interface RBACResult {
    isAdmin: boolean;
    isPermitted: boolean;
}

export const validateUserRBAC = (rootState: IRootState, appInsights: ApplicationInsights): any => {
    if (rootState.AuthStore.Account.username !== "") {
      return IsUserAdmin(rootState, appInsights).then(async (adminResponse: any) => {
        if (adminResponse.status === 200) {
            const userIsAdmin: boolean = adminResponse.data;

            if (userIsAdmin) {
                return {
                    isAdmin: true,
                    isPermitted: true,
                };
            } else {
                return UserNotAdmin(rootState, appInsights);
            }
        } else {
            trackException(appInsights, undefined, SeverityLevel.Error, "RBACServices", "validateGroupMembership", "Check if user is in admin group failed: " + process.env.REACT_APP_PORTAL_ADMINS, rootState.AuthStore, {});

            return UserNotAdmin(rootState, appInsights);
        }
      }).catch((reason: any) => {
        trackException(appInsights, reason, SeverityLevel.Error, "RBACServices", "validateGroupMembership", "Check if user is in admin group failed: " + process.env.REACT_APP_PORTAL_ADMINS, rootState.AuthStore, {});

        return UserNotAdmin(rootState, appInsights);
      });
    } else {
        return {
            isAdmin: false,
            isPermitted: false,
        };
    }
};

const UserNotAdmin = (rootState: IRootState, appInsights: ApplicationInsights): any => {
    return UpdateUserRBACServicesParallel(rootState, appInsights).then((updateRBACResponse: any) => {
        return {
            isAdmin: false,
            isPermitted: true,
        };
    }).catch((err: any) => {
        trackException(appInsights, err, SeverityLevel.Error, "RBACServices", "UpdateUserRBACServicesParallel", "Fetch User RBAC Information", rootState.AuthStore, {});
        return {
            isAdmin: false,
            isPermitted: false,
        };
    });
};

const UpdateUserRBACServicesParallel = (rootState: IRootState, appInsights: ApplicationInsights): any => {
    const httpService = HttpService(appInsights, rootState);
    return httpService.get({
        url: "api/UserRBAC/UpdateUserRBACServicesParallel",
        token: rootState.AuthStore.Token,
        params: {},
    });
};

const IsUserAdmin = (rootState: IRootState, appInsights: ApplicationInsights): any => {
    const httpService = HttpService(appInsights, rootState);
    return httpService.get({
        url: "api/UserRBAC/IsUserAdmin",
        token: rootState.AuthStore.Token,
        params: {},
    });
};