import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { List, Text } from "@fluentui/react";
import React from "react";
import { Link } from "react-router-dom";
import { IDetailsListMyServiceItem } from "./DetailsListMyServices";


export interface IServersWithNoJobsPanelProps {
    clickedRow: IDetailsListMyServiceItem; // row that was clicked
    isOpen: Boolean; // setting to true will show panel
    onDismiss: Function; // expect parent to pass a function to inform parent when panel is closed by user
}


export class ServersWithNoJobsPanel extends React.Component<IServersWithNoJobsPanelProps, any> {
    constructor(props: IServersWithNoJobsPanelProps) {
        super(props);

        // make a list of serverids 
        let items = Array.from(props.clickedRow.servers).sort().map(s => s);

        this.state = {
            title: props.clickedRow.serviceName,
            isOpen: props.isOpen,
            items: items,
            onDismiss: props.onDismiss,
            clickedItem: props.clickedRow
        };
    }

    public render(): JSX.Element {
        return <CoherencePanel
            titleText={this.state.clickedItem.serviceName}
            isOpen={this.state.isOpen}
            hasCloseButton={true}
            onDismiss={this.state.onDismiss}
            panelSize={CoherencePanelSize.small}
        >
            <div>
                <p>
                    <Text variant="medium"> These servers have no jobs configured and may not be patched. Please visit <Link to={{ pathname: "/managejobs/" + this.state.clickedItem.serviceTreeGuid }}>  Manage Jobs </Link> to add them to existing jobs or to create new jobs.</Text>
                </p>
                <List
                    items={this.state.items}
                    onRenderCell={obj => <Text variant="medium"> {obj["serverName"]} </Text>} />
            </div>
        </CoherencePanel>;
    }
}
