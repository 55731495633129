import { Announced, IconButton } from "@fluentui/react";
import React from "react";
import "./../../../src/custom.css"

interface IBackToTopButtonProps {}

export const BackToTopButton: React.FunctionComponent<IBackToTopButtonProps> = (props: IBackToTopButtonProps) => {
    const [announce, SetAnnounce] = React.useState<string>("");

    const backToTop = async () => {
        await AnnounceBackToTop();
        await focusOnHeader();
        await scrollToTop();
    }

    const scrollToTop = () => {
        const title = document.getElementById("title");
        title?.scrollIntoView({ behavior: "smooth", block: "start" });
        
        return new Promise(function (resolve, reject) {
            resolve('scrollToTop');
        });
    }

    const focusOnHeader = () => {
        // focus on the header
        let firstHeaderElement = null;
        let header = document.querySelectorAll<HTMLElement>("[class^='ms-Link root-']");

        if (header.length > 0) {
            firstHeaderElement = header[0] as HTMLElement;
            firstHeaderElement?.focus();
        } else { // in case this class name is updated by Coherence, focus on header
            document.getElementById("header")?.focus();
        }

        return new Promise(function (resolve, reject) {
            resolve('focusOnHeader');
        });
    }

    const AnnounceBackToTop = () => {
        SetAnnounce("navigated to top of page");

        return new Promise(function (resolve, reject) {
            resolve('AnnounceBackToTop');
        });
    }

    return (
        <>
            <IconButton 
                iconProps={{iconName: "Up" }}
                allowDisabledFocus
                id="back_to_top"
                className="back-to-top"
                aria-label="Back to top"
                onClick={() => backToTop()} />
            <Announced message={announce} />
        </>
    );
}