import { IColumn, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { IOffboardingItem, IOffboardingResults } from "../../Pages/OffboardingPage";
import { calloutProps, hostStyles } from "../../Styles/Page.styles";

export interface IToBeOffboardedType {
    ServerName: string;
    Domain: string;
    ServerId: number;
    ServiceTreeGUID: string;
}

export interface IOffboardingAPIResult {
    ServerName: number; // really this is ServerId
    Remarks: string;
}

export const toBeOffboardedcolumns: IColumn[] = [
    {
        key: "serverName",
        name: "Server Name",
        fieldName: "ServerName",
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "domain",
        name: "Domain",
        fieldName: "Domain",
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "serverId",
        name: "Server ID",
        fieldName: "ServerId",
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
    },
];

export const resultsColumns: IColumn[] = [
    {
        key: "server",
        name: "Server",
        fieldName: "server",
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "remarks",
        name: "Remarks",
        fieldName: "remarks",
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
    },
];

export const onRenderOffboardingExportRow = (props: any, defaultRender?: any) => {
    return (
        <div>
            <TooltipHost
                content={props?.item?.Notes}
                calloutProps={calloutProps}
                styles={hostStyles}
            >
                {defaultRender!(props)}
            </TooltipHost>
        </div>
    );
};

export const convertAPIResultsToMap = async (allItems: IOffboardingItem[], respResults: IOffboardingAPIResult[], allServersMap: Map<string, IOffboardingResults>) => {
    // have to translate ID to Name for results
    for (const r of respResults) {
        if (r.Remarks !== "Success") {
            const serverItem: IOffboardingItem = allItems.filter((x: IOffboardingItem) => x.serverID === r.ServerName)[0];
            allServersMap.set(serverItem.server, {
                server: serverItem.server,
                remarks: r.Remarks,
            });
        }
    }
};
