import { useAccount, useMsal } from "@azure/msal-react";
import { CoherenceHeader, CoherencePanelSize, initializeOBFeedback } from "@coherence-design-system/controls";
import { CoherenceTheme } from "@coherence-design-system/styles";
import {
    ComboBox, Dialog, DialogType, FontSizes, FontWeights, IComboBox, IComboBoxOption,
    IDropdownStyles, IStyle, Link, mergeStyles,
    mergeStyleSets, SelectableOptionMenuItemType, Text, ThemeProvider, Toggle
} from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { AuthActionEnum } from "../../services/AuthenticationService/AuthActions";
import { prodAuthProvider } from "../../services/AuthenticationService/AuthProvider";
import { UserPermission } from "../../services/AuthenticationService/AuthReducer";
import { AuthToken } from "../../services/AuthenticationService/FetchAuthToken";
import { RBACResult, validateUserRBAC } from "../../services/AuthenticationService/RBACValidation";
import { fetchUserProfileImg } from "../../services/FetchUserImage";
import HttpService from "../../services/HttpService/HttpService";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { RootContext } from "../Stores/RootStore";
import packageJson from '../../../package.json';
import { useAppInsights } from "../AppInsights/AppInsights";
import { AppName } from "../Shared/AppConstants";

// Interfaces //

export interface IHeaderProps { }

export interface TimeZone {
    standardName: string;
    offset: number;
}

export interface BuildInfo {
    buildId: string;
    buildNumber: string;
    branch: string;
    lastCommit: string;
    lastCommitAuthor: string;
    lastCommitMessage: string;
    pipeline: string;
}

const commonTimeZones: Set<string> = new Set<string>();
commonTimeZones.add("Pacific Standard Time");
commonTimeZones.add("India Standard Time");
commonTimeZones.add("Eastern Standard Time");
commonTimeZones.add("Central Standard Time");
commonTimeZones.add("UTC");

const defaultTimeZoneText: string = "Default (Your PC)";

export const Header: React.FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {
    const [env, SetEnv] = React.useState<string>(""); // Environment (Dev, UAT) displayed on header
    const [buildInfo, SetBuildInfo] = React.useState<BuildInfo>(); // Build Details displayed on header
    const [showBuildInfoDialog, SetShowBuildInfoDialog] = React.useState<boolean>(false); // Build Details dialog
    const [userImage, SetUserImage] = React.useState<string | undefined>("");
    const [ocvButtonIsFocused, SetOcvButtonIsFocused] = React.useState<boolean>(false); // set focus back to OCV button
    const [allTimeZones, SetAllTimeZones] = React.useState<TimeZone[]>([]);
    const [apiRegion, SetAPIRegion] = React.useState<string>("");
    const [timeZoneOptions, SetTimeZoneOptions] = React.useState<IComboBoxOption[]>([]);
    const [apiEndpointOptions, SetApiEndpointOptions] = React.useState<IComboBoxOption[]>([]);
    const [apiEndpoint, SetApiEndpoint] = React.useState<string>(process.env.REACT_APP_API_URL?.toString()!);
    const [timeZoneOverride, SetTimeZoneOverride] = React.useState<TimeZone>();
    const { state, dispatch } = React.useContext(RootContext);
    const { accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0]);
    const [appVersion] = React.useState(packageJson.version);
    const [toggleAdminSetting, SetToggleAdminSetting] = React.useState<boolean>(state.AuthStore.isAdmin);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        try {
            if (account !== null) { // Login successful
                AuthToken(account, [`${process.env.REACT_APP_CLIENT_ID}/.default`], state.AuthStore, appInsights).then((authResponse) => {
                    // save login data
                    dispatch({
                        type: AuthActionEnum.LOG_IN,
                        name: account?.name,
                        email: account?.username,
                        account,
                        token: authResponse,
                    });

                    const currentDate = new Date();
                    if (accounts.length > 0) {
                        trackEvent(appInsights, "Header", "User Logged In", "User " + account?.name + " logged in at " + currentDate.toDateString(), state.AuthStore, {});

                        // Environment text located in Azure App Config
                        httpService.get({
                            url: "api/Env",
                            token: authResponse,
                            params: {},
                        }).then((response: any) => {
                            SetEnv(response?.data);
                        });

                        httpService.get({
                            url: "api/Env/buildinfo",
                            token: authResponse,
                            params: {},
                        }).then((response: any) => {
                            SetBuildInfo(response?.data);
                        });

                        httpService.get({
                            url: "api/Env/region",
                            token: authResponse,
                            params: {},
                        }).then((response: any) => {
                            SetAPIRegion(response?.data);
                        });

                        httpService.get({
                            url: "api/Env/AvailableAPIEndpoints",
                            token: authResponse,
                            params: {},
                        }).then((response: any) => {
                            const data = response?.data;
                            const apiEndpoints: IComboBoxOption[] = [];
                            for (const d of data) {
                                apiEndpoints.push({ key: d, text: d });
                            }

                            SetApiEndpointOptions(apiEndpoints);
                        }).catch((reason: any) => {

                        });

                        // Load all timezones
                        httpService.get({
                            url: "api/UserSettings/allTimeZones",
                            token: authResponse,
                            params: {},
                        }).then((response: any) => {
                            const jsonData = response.data;
                            let timeZones: TimeZone[] = [];
                            let timeZoneOptionsCommon: IComboBoxOption[] = [];
                            let timeZoneOptionsLoaded: IComboBoxOption[] = [];

                            for (const d of jsonData) {
                                let time: string = parseInt(d.hours) > 0 ? "+" + d.hours : d.hours;

                                if (d.minutes !== 0) {
                                    time = time + ":" + d.minutes;
                                }

                                timeZones = [...timeZones, {
                                    standardName: d.standardName,
                                    offset: d.totalMinutes!,
                                }];

                                timeZones = [...timeZones, {
                                    standardName: d?.daylightName,
                                    offset: d.totalMinutes!,
                                }];

                                if (commonTimeZones.has(d.id)) {
                                    timeZoneOptionsCommon = [...timeZoneOptionsCommon, {
                                        key: d.standardName,
                                        text: d.standardName + " (" + time + ")",
                                    }];
                                } else {
                                    timeZoneOptionsLoaded = [...timeZoneOptionsLoaded, {
                                        key: d.standardName,
                                        text: d.standardName + " (" + time + ")",
                                    }];
                                }
                            }

                            SetAllTimeZones(timeZones);

                            const addDefaultOptions: IComboBoxOption[] = [{
                                key: defaultTimeZoneText,
                                text: defaultTimeZoneText
                            }];

                            const addHeader: IComboBoxOption[] = addDefaultOptions.concat(timeZoneOptionsCommon).concat({
                                key: "Header1",
                                text: "-",
                                itemType: SelectableOptionMenuItemType.Header,
                            });

                            const allTimeZoneOptions: IComboBoxOption[] = addHeader.concat(timeZoneOptionsLoaded);

                            SetTimeZoneOptions(allTimeZoneOptions);

                            // Set timezone if user has selected an override
                            httpService.get({
                                url: "api/UserSettings",
                                token: authResponse,
                                params: {},
                            }).then((response: any) => {
                                if (response !== undefined) {
                                    const jsonData = JSON.stringify(response.data.values);
                                    const d = JSON.parse(jsonData);

                                    if (d.userAlias) {
                                        const timezoneStandardName = d.standardName !== null && d.standardName !== ""
                                            ? d.standardName
                                            : currentDate.toString().substr(currentDate.toString().indexOf("(")).replace('(', '').replace(')', '');

                                        const findStandardName: TimeZone[] = timeZones.filter((x) => x.standardName === timezoneStandardName);

                                        const userTimeZone: TimeZone = {
                                            standardName: timezoneStandardName,
                                            offset: findStandardName.length > 0 ? findStandardName[0].offset : 0,
                                        };

                                        trackEvent(appInsights, "Header", "User Timezone", "Timezone: " + userTimeZone.standardName, state.AuthStore, {});
                                        SetTimeZoneOverride(userTimeZone);

                                        dispatch({
                                            type: AuthActionEnum.SET_TIMEZONE,
                                            timezone: userTimeZone,
                                        });
                                    } else {
                                        const currentDate = new Date();
                                        const timezoneStandardName = (state.AuthStore.timezone?.offset !== undefined && state.AuthStore.timezone?.standardName !== null)
                                            ? "(" + state.AuthStore.timezone?.standardName + ")"
                                            : currentDate.toString().substr(currentDate.toString().indexOf("(")).replace('(', '').replace(')', '');

                                        const findStandardName: TimeZone[] = timeZones.filter((x) => x.standardName !== null && x.standardName === timezoneStandardName);

                                        const userTimeZone: TimeZone = {
                                            standardName: timezoneStandardName,
                                            offset: findStandardName.length > 0 ? findStandardName[0].offset : 0,
                                        };

                                        dispatch({
                                            type: AuthActionEnum.SET_TIMEZONE,
                                            timezone: userTimeZone,
                                        });
                                    }
                                }
                            }).catch((error: any) => {
                                trackException(appInsights, error, SeverityLevel.Error, "Header", "UserSettings", "Fetching User Settings", state.AuthStore, {});
                            });
                        });

                        // only fetch user image if account is found.
                        if (account && account.username) {
                            fetchUserProfileImg(account, state.AuthStore, appInsights).then((res) => {
                                SetUserImage(res);
                            });

                            const currentTime: Date = new Date();

                            // TODO: This is deprecated. Use new coherence header.
                            // initializeOBFeedback(
                            //   2507,
                            //   String(process.env.REACT_APP_OCV_ENV), // Int is for test and Prod is for Production,
                            //   "/scripts/officebrowserfeedback.min.js",
                            //   "/styles/officebrowserfeedback_vertical.min.css",
                            //   "/intl/",
                            //   "http://go.microsoft.com/fwlink/?LinkID=786935",
                            //   state.AuthStore.Email,
                            //   currentTime.toISOString(),
                            //   undefined,
                            //   "1.0.0",
                            //   "#1b6ec2",
                            //   "#1b6ec2",
                            //   "officebrowserfeedbackstrings.js",
                            //   undefined,
                            //   (error: string) => {
                            //     // set focus back to OCV button
                            //     const ocvButton = (document.getElementById("coher-header-ocv-button-id"));
                            //     if (ocvButton) {
                            //       ocvButton.focus();
                            //     }
                            //     trackException(appInsights, error, SeverityLevel.Error, "Header", "OCV Feedback", "Not Submitted", state.AuthStore, {});
                            //     SetOcvButtonIsFocused(false);
                            //   },
                            //   undefined,
                            //   undefined,
                            //   undefined,
                            //   false,
                            //   (submitted: boolean) => {
                            //     // set focus back to OCV button
                            //     const ocvButton = (document.getElementById("coher-header-ocv-button-id"));
                            //     if (ocvButton) {
                            //       ocvButton.focus();
                            //     }
                            //     trackEvent(appInsights,"Header", "OCV Feedback", "Submitted", state.AuthStore, {});
                            //     SetOcvButtonIsFocused(false);
                            //   },
                            //   false,
                            //   true,
                            //   [],
                            //   false,
                            //   false,
                            //   false,
                            // );
                        }
                    } else if (inProgress === "login") {
                        // console.log("Login is currently in progress!");
                    } else {
                        // console.log("No users logged in!");
                    }
                });
            }
        } catch (error) {
            trackException(appInsights, error, SeverityLevel.Error, "Header", "Setting Up Header", "", state.AuthStore, {});
        }
    }, []);

    // Validate User Permission to utilize portal
    React.useEffect(() => {
        if (state.AuthStore.Account.username !== "") {
            validateUserRBAC(state, appInsights).then((resp: RBACResult) => {
                if (resp.isPermitted) {
                    dispatch({
                        type: AuthActionEnum.SET_USER_PERMISSION,
                        userPermitted: UserPermission.Permitted,
                    });
                    if (resp.isAdmin) {
                        trackEvent(appInsights, "Header", "ValidateUserRBAC", "User is Admin", state.AuthStore, {});
                        dispatch({
                            type: AuthActionEnum.SET_USER_ADMIN,
                            isAdmin: true,
                            toggleAdmin: true,
                        });
                        SetToggleAdminSetting(true);
                    }
                } else {
                    trackEvent(appInsights, "Header", "ValidateUserRBAC", "User Denied", state.AuthStore, {});
                    dispatch({
                        type: AuthActionEnum.SET_USER_PERMISSION,
                        userPermitted: UserPermission.Denied,
                    });
                }
            }).catch((error: any) => {
                trackException(appInsights, error, SeverityLevel.Error, "Header", "ValidateUserRBAC", "Validating User Permission", state.AuthStore, {});
                dispatch({
                    type: AuthActionEnum.SET_USER_PERMISSION,
                    userPermitted: UserPermission.Denied,
                });
            });
        }
    }, [state.AuthStore.Account.username]); // updates with a new username (logging in)

    // Styles //

    // header settings
    const settingsStyles = mergeStyleSets({
        root: {
            marginLeft: "5px",
            marginRight: "5px",
        } as IStyle,
    });

    const ChildClass = mergeStyles({
        display: "block",
        marginBottom: "10px",
    });

    const helpSampleLinkStyles = mergeStyleSets(
        {
            helpSampleLink: {
                margin: "8px 0",
                display: "block",
                fontSize: FontSizes.size14,
                fontWeight: FontWeights.semibold,
            } as IStyle,
            helpSampleList: {
                listStyle: "none",
                paddingLeft: "0",
            } as IStyle,
        },
    );

    const dropdownStyles: Partial<IDropdownStyles> = {
        subComponentStyles: {
            label: {
                root: {
                    // fontWeight: "bold",
                    padding: "5px",
                },
            },
            multiSelectItem: {},
            panel: {},
        },
    };

    // Components //

    const helpBody: JSX.Element = (
        <div>
            <ul className={helpSampleLinkStyles.helpSampleList}>
                
                <li>
                    <Link href="https://aka.ms/mdpatchonboard" target="_blank" rel="noopener noreferrer" aria-label="Onboarding Help" name="Onboarding Help" aria-describedby="Onboarding Help" className={helpSampleLinkStyles.helpSampleLink}>
                        Onboarding Wiki
                    </Link>
                </li>
                <li>
                    <Link href="https://aka.ms/mdpatchoffboard" target="_blank" rel="noopener noreferrer" aria-label="Offboarding Help" name="Offboarding Help" aria-describedby="Offboarding Help" className={helpSampleLinkStyles.helpSampleLink}>
                        Offboarding Wiki
                    </Link>
                </li>
                <li>
                    <Link href="https://aka.ms/mdpatchservers" target="_blank" rel="noopener noreferrer" aria-label="Manage Servers Help" name="Manage Servers Help" aria-describedby="Manage Servers Help" className={helpSampleLinkStyles.helpSampleLink}>
                        Manage Servers Wiki
                    </Link>
                </li>
                <li>
                    <Link href="https://aka.ms/mdpatchjobs" target="_blank" rel="noopener noreferrer" aria-label="Manage Jobs Help" name="Manage Jobs Help" aria-describedby="Manage Jobs Help" className={helpSampleLinkStyles.helpSampleLink}>
                        Manage Jobs Wiki
                    </Link>
                </li>
                <li>
                    <Link href="https://aka.ms/MDPatchHistory" target="_blank" rel="noopener noreferrer" aria-label="Job History Help" name="Job History Help" aria-describedby="Job History Help" className={helpSampleLinkStyles.helpSampleLink}>
                        Job History Wiki
                    </Link>
                </li>
                <li>
                    <Link href="https://aka.ms/mdpatchserviceadmin" target="_blank" rel="noopener noreferrer" aria-label="Service Administration Help" name="Service Administration Help" aria-describedby="Service Administration Help" className={helpSampleLinkStyles.helpSampleLink}>
                        Service Administration Wiki
                    </Link>
                </li>
                <li>
                    <Link href="https://aka.ms/mlspatch" target="_blank" aria-label="MLS Power BI Compliance Report" name="MLS Power BI Compliance Report" aria-describedby="MLS Power BI Compliance Report" className={helpSampleLinkStyles.helpSampleLink}>
                        MLS Power BI Compliance Report
                    </Link>
                </li>
            </ul>
            <hr />
            <ul className={helpSampleLinkStyles.helpSampleList}>
                <li>
                    <Link href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" className={helpSampleLinkStyles.helpSampleLink}>
                        Privacy & Cookies
                    </Link>
                </li>
                <li>
                    <Link href="https://www.microsoft.com/en-us/legal/terms-of-use" target="_blank" className={helpSampleLinkStyles.helpSampleLink}>
                        Terms of Use
                    </Link>
                </li>
                <li>
                    <Link href="https://privacy.microsoft.com/en-US/data-privacy-notice" target="_blank" className={helpSampleLinkStyles.helpSampleLink}>
                        Data Protection Notice
                    </Link>
                </li>
            </ul>
            <hr />
            <p>UI: <span id={"uiVersion"}>{appVersion}</span></p>
            {
                <div>
                    <p> API: <span id={"apiVersion"}>{buildInfo?.buildNumber}</span> {state.AuthStore.isAdmin && buildInfo?.buildId && <Link onClick={() => { SetShowBuildInfoDialog(true) }}> (More Info)</Link>}
                    </p>

                    <Dialog
                        hidden={!showBuildInfoDialog}
                        onDismiss={() => { SetShowBuildInfoDialog(!showBuildInfoDialog) }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Build Info',
                            closeButtonAriaLabel: 'Close',
                            isMultiline: true
                        }}
                    >
                        <Text variant="small"> 
                            <b>Branch:</b> {buildInfo?.branch}
                            <br />
                            <b>Last Commit:</b> <Link href={`https://microsoftit.visualstudio.com/OneITVSO/_git/FDV-DFU-ehpat-MLSPatchingPortal/commit/${buildInfo?.lastCommit}`} target="_blank" rel="noopener noreferrer" aria-label="Last Commit" name="Last Commit" aria-describedby="Last Commit">
                                {buildInfo?.lastCommit}
                            </Link>
                            <br />
                            <b>Last Commit Author:</b> {buildInfo?.lastCommitAuthor}
                            <br />
                            <b>Last Commit Message:</b> {buildInfo?.lastCommitMessage}
                        </Text>
                    </Dialog>
                </div>
            }
        </div>
    );

    const overrideTimeZone = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined): void => {
        if (option !== undefined) {
            const findTimeZone = allTimeZones.filter((x) => x.standardName === option.key.toString());

            // checking to see if timezone name exists
            // if not, they are resetting their timezone (i.e. "Default")
            if (findTimeZone.length > 0) {
                const newTimeZone = findTimeZone[0];

                SetTimeZoneOverride(newTimeZone);
                dispatch({
                    type: AuthActionEnum.SET_TIMEZONE,
                    timezone: newTimeZone,
                });

                httpService.post({
                    url: "api/UserSettings/UpdateUserTimeZone",
                    token: state.AuthStore.Token,
                    data: {
                        StandardName: newTimeZone.standardName,
                    },
                }).then((resp: any) => {
                    trackEvent(appInsights, "Header", "UserSettings", "UserSettings Update TimeZone: ", state.AuthStore, {});
                }).catch((error: any) => {
                    trackException(appInsights, error, SeverityLevel.Error, "Header", "UserSettings", "UserSettings Update TimeZone", state.AuthStore, {});
                });
            } else {
                SetTimeZoneOverride(undefined); // default
                dispatch({
                    type: AuthActionEnum.SET_TIMEZONE,
                    timezone: undefined, // default
                });

                httpService.post({
                    url: "api/UserSettings/ResetUserTimeZone",
                    token: state.AuthStore.Token,
                    data: {},
                }).then((resp: any) => {
                    trackEvent(appInsights, "Header", "UserSettings", "ResetUserTimeZone: ", state.AuthStore, {});
                }).catch((error: any) => {
                    trackException(appInsights, error, SeverityLevel.Error, "Header", "UserSettings", "ResetUserTimeZone", state.AuthStore, {});
                });
            }
        }
    };

    const overrideAPIEndpoint = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined): void => {
        if (option !== undefined) {

            dispatch({
                type: AuthActionEnum.SET_API_ENDPOINT,
                apiEndpoint: option.text,
            });

            SetApiEndpoint(option.text);
        }
    };

    const toggleAdmin = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
        dispatch({
            type: AuthActionEnum.SET_TOGGLE_ADMIN,
            toggleAdmin: checked,
        });

        SetToggleAdminSetting(checked!);
    };

    const settingsBody: JSX.Element = (
        <div className={settingsStyles.root}>
            <p><b>Override Timezone: </b></p>
            <ComboBox
                className={ChildClass}
                label="Select a Timezone:"
                aria-label="Select a Timezone:"
                onChange={overrideTimeZone}
                styles={dropdownStyles}
                selectedKey={timeZoneOverride?.standardName ? timeZoneOverride?.standardName : timeZoneOptions[0]?.key}
                options={timeZoneOptions}
                role="listbox"
            />

            {state.AuthStore.isAdmin &&
                <div>
                    <p><b>Admin Controls:</b></p>
                    <p><b>API Region:</b> {apiRegion}</p>
                    <ComboBox
                        className={ChildClass}
                        label="Select an API Endpoint:"
                        aria-label="Select an API Endpoint:"
                        onChange={overrideAPIEndpoint}
                        styles={dropdownStyles}
                        selectedKey={apiEndpoint}
                        options={apiEndpointOptions}
                        role="listbox"
                    />

                    <Toggle
                        label="Admin Toggle"
                        defaultChecked={toggleAdminSetting}
                        onText="On"
                        offText="Off"
                        onChange={toggleAdmin} />
                </div>}
        </div>
    );

    return (
        <ThemeProvider theme={CoherenceTheme}>
            <div tabIndex={0} id={"header"} />
            <CoherenceHeader
                headerLabel={"header"}
                appNameSettings={{
                    label: AppName + " " + env,
                    linkUrl: "/",
                }}
                farRightSettings={{
                    additionalItems: [
                    ],
                    helpSettings: {
                        titleText: "Help",
                        body: helpBody,
                        panelSettings: {
                            panelSize: CoherencePanelSize.small,
                            titleText: "Help",
                            body: helpBody,
                        },
                        telemetryHook: undefined, // TelemetryService.telemetryHook,
                    },
                    // TODO: Commenting feedback settings to disable feedback button from header.
                    // feedbackSettings: {
                    //   buttonSettings: {
                    //     title: "Feedback",
                    //     ariaLabel: "Feedback",
                    //   },
                    //   titleText: "OCV Feedback",
                    //   ocvButtonIsFocused: ocvButtonIsFocused ?? false,
                    //   onClick: () => {
                    //     SetOcvButtonIsFocused(true);
                    //     return true;
                    //   },
                    //   launchOptions: {
                    //     categories: {
                    //         show: false,
                    //         customCategories: ["Dashboard", "Feed", "Catalog", "Vision", "Hearing", "Mobility", "Cognitive"],
                    //     },
                    //   },
                    //   panelSettings: {
                    //     panelSize: CoherencePanelSize.small,
                    //     titleText: "OCV Feedback",
                    //     ocvButtonIsFocused: ocvButtonIsFocused ?? false,
                    //     onClick: () => {
                    //       SetOcvButtonIsFocused(true);
                    //       return true;
                    //     },
                    //     launchOptions: {
                    //       categories: {
                    //           show: true,
                    //           customCategories: ["Dashboard", "Feed", "Catalog", "Vision", "Hearing", "Mobility", "Cognitive"],
                    //       },
                    //     },
                    //   },
                    // },
                    settingsSettings: {
                        isLightDismiss: true,
                        telemetryHook: undefined, // TelemetryService.telemetryHook,
                        titleText: "Settings",
                        body: settingsBody,
                        panelSettings: {
                            panelSize: CoherencePanelSize.small,
                            isLightDismiss: true,
                            telemetryHook: undefined, // TelemetryService.telemetryHook,
                            titleText: "Settings",
                            body: settingsBody,
                        },
                    },
                    profileSettings: {
                        buttonSettings: {
                            title: "Profile",
                            ariaLabel: "Profile",
                        },
                        titleText: "Profile",
                        fullName: state.AuthStore.Name,
                        emailAddress: state.AuthStore.Email,
                        imageUrl: userImage,
                        viewAccountLink: "https://myaccount.microsoft.com",
                        onViewAccount: () => window.open("https://myaccount.microsoft.com", "_blank"),
                        panelSettings: {
                            panelSize: CoherencePanelSize.small,
                            titleText: "Profile",
                            fullName: state.AuthStore.Name,
                            emailAddress: state.AuthStore.Email,
                            imageUrl: userImage,
                            viewAccountLink: "https://myaccount.microsoft.com",
                            onViewAccount: () => window.open("https://myaccount.microsoft.com", "_blank"),
                            onLogOut: () => {
                                prodAuthProvider.logoutRedirect();
                            },
                        },
                        telemetryHook: undefined, // TelemetryService.telemetryHook,
                    },
                } as any}
            />
            {/* </div> */}
        </ThemeProvider>
    );
};
