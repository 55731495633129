import { Card } from "@coherence-design-system/controls";
import { IStackTokens, mergeStyles, PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router";
import { getHeader } from "../../Styles/Page.styles";

interface HomeNavProps {}

const onboardText = "Onboard Servers";
const serversText = "Manage Servers";
const jobsText = "Manage Jobs";
const adminText = "Service Administration";

const stackTokens: IStackTokens = {
    childrenGap: 50 + " " + 50, // vertical gap + ' ' + horizontal gap,
};

const centerStyles = mergeStyles({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const cardSize = mergeStyles({
    width: "300px",
    height: "180px",
});

const HomeNav: React.FunctionComponent<HomeNavProps> = () => {
    const navigate = useNavigate();
    return (
        <div id="homeNav">
            <Stack wrap horizontal horizontalAlign="space-evenly" tokens={stackTokens}>
                <Stack.Item>
                    <div className={cardSize}>
                        <Card header={getHeader(onboardText, "center")}>
                            <p>Onboard your devices to ensure they are compliant.</p>
                            <div className={centerStyles}>
                                <PrimaryButton
                                    text={onboardText}
                                    label={onboardText}
                                    onClick={() => navigate("/onboarding")}
                                />
                            </div>
                        </Card>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <div className={cardSize}>
                        <Card header={getHeader(serversText, "center")}>
                            <p>Manage your devices and view patching data.</p>
                            <div className={centerStyles}>
                                <PrimaryButton
                                    text={serversText}
                                    label={serversText}
                                    onClick={() => navigate("/manageservers")}
                                />
                            </div>
                        </Card>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <div className={cardSize}>
                        <Card header={getHeader(jobsText, "center")}>
                            <p>Create new patching jobs and edit current jobs.</p>
                            <div className={centerStyles}>
                                <PrimaryButton
                                    text={jobsText}
                                    label={jobsText}
                                    onClick={() => navigate("/managejobs")}
                                />
                            </div>
                        </Card>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <div className={cardSize}>
                        <Card header={getHeader(adminText, "center")}>
                            <p>Assign IcM team, set email contact, delegate users.</p>
                            <div className={centerStyles}>
                                <PrimaryButton
                                    text={adminText}
                                    label={adminText}
                                    onClick={() => navigate("/serviceadministration")}
                                />
                            </div>
                        </Card>
                    </div>
                </Stack.Item>
            </Stack>
        </div>
    );
};

export default HomeNav;
