import { v4 as uuid } from "uuid";
import { TimeZone } from "../components/Layout/Header";

export const GenerateGuid = () => uuid();

/**
	 * Converts input properties of { string : object } pair to flat array
	 * @param inputProps input properties of { string : object } pair
	 */
export const FlatProperties = (inputProps: { [key: string]: unknown } | null | undefined): string[] | null | undefined => {
		if (!inputProps) {
				return inputProps;
		}

		const retVal: string[] = [];
		Object.keys(inputProps).forEach(function(key: string, index: number) {
				retVal[index] = key + ":" + String(inputProps[key]);
		});

		return retVal;
};

export const arrayRemove = (arr: any[], value: any) => {
	return arr.filter((ele) => {
			return ele !== value;
	});
};

export const PatchingDayOfTheWeekFromNumber = (day: number) => {
		let patchDayoftheMonth: number = 1;

		switch (day) {
			case 0:
				patchDayoftheMonth += 2;
				break;
			case 1:
				patchDayoftheMonth += 1;
				break;
			case 2:
				patchDayoftheMonth += 0;
				break;
			case 3:
				patchDayoftheMonth += 6;
				break;
			case 4:
				patchDayoftheMonth += 5;
				break;
			case 5:
				patchDayoftheMonth += 4;
				break;
			case 6:
				patchDayoftheMonth += 3;
				break;
			default:
				break;
		}

		return patchDayoftheMonth;
};

export const PatchingDayOfTheWeekFromName = (day: string) => {
	let patchDayoftheMonth: number = 1;

	switch (day) {
		case "Sunday":
			patchDayoftheMonth += 2;
			break;
		case "Monday":
			patchDayoftheMonth += 1;
			break;
		case "Tuesday":
			patchDayoftheMonth += 0;
			break;
		case "Wednesday":
			patchDayoftheMonth += 6;
			break;
		case "Thursday":
			patchDayoftheMonth += 5;
			break;
		case "Friday":
			patchDayoftheMonth += 4;
			break;
		case "Saturday":
			patchDayoftheMonth += 3;
			break;
		default:
			break;
	}

	return patchDayoftheMonth;
};

export const GetNextPatchingDate = (dayOfTheWeek: string, day: number) => {
		let dayOfNextMonth: number = day;

		switch (dayOfTheWeek) {
			case "Sunday":
				dayOfNextMonth += 5;
				break;
			case "Monday":
				dayOfNextMonth -= 1;
				break;
			case "Tuesday":
				dayOfNextMonth += 0;
				break;
			case "Wednesday":
				dayOfNextMonth += 1;
				break;
			case "Thursday":
				dayOfNextMonth += 2;
				break;
			case "Friday":
				dayOfNextMonth += 3;
				break;
			case "Saturday":
				dayOfNextMonth += 4;
				break;
			default:
				break;
		}

		return dayOfNextMonth;
};

export const GetNextScheduledDateTime = (weekOfTheMonth: number, day: string, hours: number, minutes: number): Date => {
	// Last week of "previous" month could be during current month, since first day starts on patching tuesday (2nd Tuesday of the month)
	const prevSchedule: Date = CalculateNextScheduledDateTime(-1, weekOfTheMonth, day, hours, minutes);
	const currSchedule: Date = CalculateNextScheduledDateTime(0, weekOfTheMonth, day, hours, minutes);
	const nextSchedule: Date = CalculateNextScheduledDateTime(1, weekOfTheMonth, day, hours, minutes);
	
	const now: Date = new Date();

	if (prevSchedule.getTime() > now.getTime()) {
		return prevSchedule;
	} else if (currSchedule.getTime() > now.getTime()) {
		return currSchedule;
	} else {
		return nextSchedule;
	}
};

const CalculateNextScheduledDateTime = (month: number, weekOfTheMonth: number, day: string, hours: number, minutes: number): Date => {
	const now: Date = new Date();

	const startDateOfTheNextMonth: Date = new Date(now.getFullYear(), now.getMonth() + month, 1);

	let patchDayOfTheNextMonth: number = PatchingDayOfTheWeekFromNumber(startDateOfTheNextMonth.getDay()) + 7;

	if (weekOfTheMonth > 1) {
			patchDayOfTheNextMonth = patchDayOfTheNextMonth + (7 * (weekOfTheMonth - 1));
	}

	const dateForNextPatchingCyle: number = GetNextPatchingDate(day, patchDayOfTheNextMonth);

	// next month, dateForNextPatchingCyle day
	const nextDate: Date = new Date();

	// need to set date initially to 1, as new Date() uses the current date.
	// if the current date is greater than the total number of days in the next month
	// i.e. scheduling a job on January 30th, as Feb only has 28 days.
	// it's possible that the following date will be an extra month away.

	nextDate.setDate(1);

	const newMonth = nextDate.getMonth() + month;

	nextDate.setMonth(newMonth);
	nextDate.setHours(hours);
	nextDate.setMinutes(minutes);
	nextDate.setDate(dateForNextPatchingCyle);

	return nextDate;
};

const PatchTuesday = (month: Date = new Date()): Date => {
	const day: number = 2; // Tuesday
	const nth: number = 2; // second Tuesday of the month

	let d = new Date(month.getFullYear(), month.getMonth());
	d.setDate(1 + (7 - d.getDay() + day) % 7 + (nth - 1) * 7);

	return d;
}

const PreviousMonthPatchingCycleWeek = (): number => {
	const today: Date = new Date();

	 // Note: in the case that the month is '0' (January), typescript is smart enough to know '-1' is December of previous year.
	const patchTuesday: Date = PatchTuesday(new Date(today.getFullYear(), today.getMonth() - 1));

	const week: number = Math.ceil(((today.getTime() - patchTuesday.getTime()) / (1000 * 60 * 60 * 24)) / 7 + 1);

	return week;
}

export const CurrentPatchingCycleWeek = (): number => {
	const today: Date = new Date();
	const patchTuesday: Date = PatchTuesday();

	if (today.getTime() > new Date(patchTuesday.setDate(patchTuesday.getDate() - 2)).getTime()) { // patch tuesday in this month
		return Math.floor((today.getDate() - patchTuesday.getDate()) / 7) + 1;
	} else { // patch tuesday in previous month
		return PreviousMonthPatchingCycleWeek();
	}
}

export const isDateValid = (date: any) => {
		return (date && new Date(date).toString() !== "Invalid Date");
}

export const daylightSavingsConversion = (date: Date, currentTimeZone: TimeZone, savedTimeZone: TimeZone) => {
	if (currentTimeZone.offset != savedTimeZone.offset) {
		const differenceInMinutes: number = currentTimeZone.offset - savedTimeZone.offset;
		const currentMinutes: number = date.getMinutes();
		
		date.setMinutes(currentMinutes + differenceInMinutes);
	}

	return date;
}

export const changeTimeToUTC = (timeString: string): string => {
	if (timeString.substring(timeString.length - 1) != 'Z') {
		timeString += 'Z';
	}

	return timeString;
}

export const timeAgoFunction = (duration: number) => {
	// Hours, minutes and seconds (minutes and seconds removed by request)
	const days: number = ~~(duration / (3600 * 24)); // ~~ === Math.Floor

	if (days > 1825) { // 1825 is 5 years in days, show N/A if jobs are more than 5 years old
		return "N/A";
	}

	const hrs: number = ~~(duration / 3600) % 24;
	// const mins: number = ~~((duration % 3600) / 60);

	const retArray: string[] = [];
	let retVal: string = "";

	if (days > 0) {
		retArray.push(days + (days > 1 ? " Days" : " Day"));
	}

	if (hrs > 0) {
		if (days > 0) {
			retArray.push(" and " + hrs + (hrs > 1 ? " Hours" : " Hour"));
			retVal = retArray.join("");
		} else {
			retVal = hrs + (hrs > 1 ? " Hours" : " Hour");
		}
	} else {
		retVal = retArray.join("");
	}

	return (retVal === "" ? "Less than an hour" : retVal) + " ago";
}