import {
    FontIcon, IColumn, IDetailsList, IIconProps, IStackTokens,
    mergeStyles, ScrollablePane, SelectionMode, ShimmeredDetailsList, Stack, Text
} from "@fluentui/react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React from "react";
import { Link } from "react-router-dom";
import HttpClient from "../../services/HttpService/HttpClient";
import HttpService from "../../services/HttpService/HttpService";
import { RootContext } from "../Stores/RootStore";
import { AddEditOUPanel } from "./AddEditOUPanel";
import { ServersListPanel } from "./ServersListPanel";
import { Guid } from "guid-typescript";


export interface IMyServicesProps {
    appInsights: ApplicationInsights;
    selectedServiceTreeGuid: string;
    selectedServiceName: string;
}

const MAX_API_CALL_RETRY_COUNT = 3;
enum API_CALL_STATUS {
    // track status of API call
    PENDING="PENDING",
    COMPLETE="COMPLETE",
    NOTSTARTED="NOTSTARTED", 
    FAILED="FAILED"
}


export interface IDetailsListAutoEnrollItem {
    organizationalUnit: string;
    serviceName: string;
    serviceTreeGuid: string;
    totalCount: Number;
    onboardedCount: Number;
    validCount: Number;
    invalidCount: Number;
    isEnabled: boolean;
    // autoEnrollId?: string;
}

export interface IServerCountByOU {
    organizationalUnit: string;
    serviceName: string;
    serviceTreeGuid: string;
    totalServers: Number;
    onboardedServers: Number;
    validCount: Number;
    invalidCount: Number;
}

export interface IServerDetails {
    ServerId: Number;
    Name: string;
    NetworkDomain: string;
    Valid: boolean;
    Errors: string;
    ServiceName: string;
    OrganizationalUnit: string;
    IsOnboarded: boolean;
}

export interface IAutoEnrollmentDetails {
    OrganizationalUnit: string;
    ServiceName: string;
    ServiceTreeGUID: string;
    IsEnabled: boolean;
}

export interface IMyServicesState {
    columns: IColumn[];
    rows: IDetailsListAutoEnrollItem[];
    servers: IServerDetails[];
    selectedServiceTreeGuid: string;
    selectedServiceName: string;
    servicesMissingIcmDataCount: Number;
    apiCallStatus: API_CALL_STATUS;
    isRequestAccessPanelOpen: boolean;
    isServerListPanelOpen: boolean;
    rerenderPanelKey: Number;
    clickedRowItem?: IDetailsListAutoEnrollItem;
    httpService: HttpClient;
}

const stackTokens: IStackTokens = {
    childrenGap: 50 + " " + 50, // vertical gap + ' ' + horizontal gap,
};

const cardSize = mergeStyles({
    width: "80vw",
    height: "50vh",
});

export interface SortableAutoEnrollTableColumn extends IColumn {
    fieldName: keyof IDetailsListAutoEnrollItem;
}

export class DetailsListAutoEnroll extends React.Component<IMyServicesProps, IMyServicesState> {

    public static contextType: React.Context<any> = RootContext;
    private _columns: IColumn[];
    private _root = React.createRef<IDetailsList>();
    private OUColumn : SortableAutoEnrollTableColumn;
    private TotalServersColumns: SortableAutoEnrollTableColumn;
    private OnboardedServersColumns: SortableAutoEnrollTableColumn;
    private ValidServerCountColumn: SortableAutoEnrollTableColumn;
    private InvalidServerCountColumn: SortableAutoEnrollTableColumn;
    private EnableDisableColumn: SortableAutoEnrollTableColumn;
    

    constructor(props: IMyServicesProps){
        super(props);

        this.OUColumn = {
            key: "OrganizationalUnit", 
            name: "Organizational Unit", 
            fieldName: "organizationalUnit",
            minWidth: 60, 
            maxWidth: 600,
            isResizable: true, 
            isMultiline: true,
            targetWidthProportion: 1,
            onColumnClick: this._onColumnClick,
            ariaLabel: "Organizational Unit",
            sortAscendingAriaLabel: "Organizational Unit sorted ascending",
            sortDescendingAriaLabel: "Organizational Unit sorted descending"
        }

        

        this.ValidServerCountColumn = {
            key: "Valid", 
            name: "Available", 
            fieldName: "validCount", 
            minWidth: 80, 
            maxWidth: 120,
            targetWidthProportion: 1,
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "Valid",
            sortAscendingAriaLabel: "Valid sorted ascending",
            sortDescendingAriaLabel: "Valid sorted descending"
        }

        this.InvalidServerCountColumn = {
            key: "Invalid", 
            name: "Out Of Scope", 
            fieldName: "invalidCount",
            minWidth: 80, 
            maxWidth: 120,
            targetWidthProportion: 1,
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "Invalid",
            sortAscendingAriaLabel: "Invalid sorted ascending",
            sortDescendingAriaLabel: "Invalid sorted descending"
        }

        this.TotalServersColumns = {
            key: "Total", 
            name: "Total", 
            fieldName: "totalCount", 
            minWidth: 60, 
            maxWidth: 100,
            targetWidthProportion: 1,
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "Total",
            sortAscendingAriaLabel: "Total sorted ascending",
            sortDescendingAriaLabel: "Total sorted descending"
        }

        this.OnboardedServersColumns = {
            key: "Onboarded", 
            name: "Onboarded", 
            fieldName: "onboardedCount", 
            minWidth: 60, 
            maxWidth: 100,
            targetWidthProportion: 1,
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "Onboarded",
            sortAscendingAriaLabel: "Onboarded sorted ascending",
            sortDescendingAriaLabel: "Onboarded sorted descending"
        }


        this.EnableDisableColumn = {
            key: "Enabled", 
            name: "Enabled", 
            fieldName: "isEnabled", 
            minWidth: 60, 
            maxWidth: 100,
            targetWidthProportion: 1,
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "Enabled",
            sortAscendingAriaLabel: "Enabled sorted ascending",
            sortDescendingAriaLabel: "Enabled sorted descending"
        }
    
        this._columns=[this.OUColumn,  this.EnableDisableColumn,  this.TotalServersColumns, this.ValidServerCountColumn,  this.InvalidServerCountColumn, this.OnboardedServersColumns];

        // init state 
        this.state = {
            columns: this._columns, // table column definitions
            rows: [], // populated from http response,
            servers: [], // popualted from http response
            selectedServiceTreeGuid: "",
            selectedServiceName: "",
            apiCallStatus: API_CALL_STATUS.NOTSTARTED, // set api call status as notstarted,
            servicesMissingIcmDataCount : 0, // default to no errors
            isRequestAccessPanelOpen: false,
            isServerListPanelOpen: false,
            rerenderPanelKey: 0,
            httpService: HttpService(props.appInsights),
        }
    }

    // toggle column sort when header is clicked
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, rows } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });

        const newItems = this._copyAndSort(rows, currColumn.fieldName!, currColumn.isSortedDescending);

        this.setState({
          columns: newColumns,
          rows: newItems,
        });
    }

    // util function to sort a collection of arbitrary items.
    // collection should support fetching items by "key" 
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items
            .slice(0) // new copy
            .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    public componentDidMount() {
        console.debug("componentDidMount")
        this.refreshDataGrid();
    }

    public componentDidUpdate(prevProps: IMyServicesProps, prevState: IMyServicesState) {
        // WARNING this gets called repeatedly without waiting for completion
        console.debug("componentDidUpdate")
        if(prevProps.selectedServiceName != prevState.selectedServiceName){
            this.setState({
                selectedServiceName : this.props.selectedServiceName,
                selectedServiceTreeGuid: this.props.selectedServiceTreeGuid,
                apiCallStatus: API_CALL_STATUS.NOTSTARTED,
            })
            
            this.refreshDataGrid();
        }
    }

    
    public render(): JSX.Element {

        const { columns, rows } = this.state;
        return (<div>
                {   
                    this.state.isRequestAccessPanelOpen 
                    && this.state.clickedRowItem != undefined 
                    && <AddEditOUPanel 
                            isOpen={this.state.isRequestAccessPanelOpen} 
                            onDismiss={() => {
                                this.closeRequestAccessPanel(); 
                            }}
                            clickedRow = {this.state.clickedRowItem}
                            rootContext={this.context}> 
                        </AddEditOUPanel>
                }
                {   
                    this.state.isServerListPanelOpen 
                    && this.state.clickedRowItem != undefined 
                    && <ServersListPanel 
                            isOpen={this.state.isServerListPanelOpen} 
                            onDismiss={() => {
                                this.closeServersListPanel();
                            }}
                            clickedRow = {this.state.clickedRowItem}
                            servers = {this.state.servers}
                            > 
                        </ServersListPanel>
                }

                <div style={{ position: "relative"}}>
                    <Stack wrap horizontal horizontalAlign="space-evenly" tokens={stackTokens}>
                        <Stack.Item>
                            <div className={cardSize}>
                                    
                                    {/* Details List */}
                                    <ScrollablePane style={{ height: "45vh", position: "relative" }}>
                                        <ShimmeredDetailsList
                                            items={rows}  
                                            columns={columns}
                                            onRenderItemColumn={this._renderItemColumn.bind(this)}
                                            selectionMode={SelectionMode.none}
                                            componentRef={this._root}
                                            ariaLabelForSelectionColumn="Toggle selection"
                                            ariaLabelForShimmer="Auto Enroll data is being fetched"
                                            ariaLabelForGrid="Auto Enroll details"
                                            enableShimmer={this.state.apiCallStatus == API_CALL_STATUS.PENDING || rows.length == 0}  // grays out area where text will be populated
                                        />
                                    </ScrollablePane>
                                    
                                    { 
                                        this.state.apiCallStatus != API_CALL_STATUS.PENDING 
                                        && this.state.rows.length == 0
                                        && <Text variant="medium" className={mergeStyles({padding: "10px"})}> No Data Found </Text>
                                    }
                            </div>
                        </Stack.Item>
                    </Stack>
                </div>
                <br/>
                </div>);
    }

    // Debounce. decide whether we need to fetch data over the network or not.
    public shouldFetch(): boolean{

        switch(this.state.apiCallStatus){
            case API_CALL_STATUS.NOTSTARTED:
                return true;
            case API_CALL_STATUS.FAILED:
                return this.state.rows.length == 0 ?  true : false;
            case API_CALL_STATUS.PENDING:
                return false;
            case API_CALL_STATUS.COMPLETE:
                return false;
            default:
                return false; 
        }
    }

    // responsible for fetching data from http calls and setting state
    public async refreshDataGrid() { 

        // no token? just return
        if (!this.context.state.AuthStore.Token){
            return;
        }

        const shouldMakeAPICall = this.shouldFetch();
        console.debug("should fetch = %o. api call status = %o", shouldMakeAPICall, this.state.apiCallStatus );
        // get users's services via API call if needed
        if(shouldMakeAPICall){
            // const fetchOnboardedServices = getOnboardedServices(this.context.state.AuthStore);

            // ** emulate empty response for testing ** 
            // const fetchOnboardedServices = new Promise((resolve, reject)=>{
            //     setTimeout(()=>{ 
            //         this.setState({
            //             apiCallStatus : API_CALL_STATUS.COMPLETE
            //         })
            //         resolve({data : []})},3*1000)
            // });

            const fetchOUDetails = this.state.httpService.get({
                url: "api/ServiceAdministration/fetchServerCountByOU",
                token: this.context.state.AuthStore.Token,
                params: {service: Guid.isGuid(this.props.selectedServiceTreeGuid) ? this.props.selectedServiceTreeGuid : Guid.EMPTY},
            })

            const fetchAutoEnrollments = this.state.httpService.get({
                url: "api/ServiceAdministration/fetchAutoEnrollments",
                token: this.context.state.AuthStore.Token,
                params: {service: this.props.selectedServiceTreeGuid},
            })

            const fetchAllServers = this.state.httpService.get({
                url: "api/ManageServers/fetchServersWithOU",
                token: this.context.state.AuthStore.Token,
                params: {serviceTreeGuid: Guid.isGuid(this.props.selectedServiceTreeGuid) ? this.props.selectedServiceTreeGuid : Guid.EMPTY},
            })

             // set the api call as pending
             this.setState({
                apiCallStatus : API_CALL_STATUS.PENDING,
            })
            
            var fetchOUDetailsToRowItem = (d: any ): IDetailsListAutoEnrollItem => { return {
                // autoEnrollId: d["AutoEnrollId"],
                organizationalUnit: d["OrganizationalUnit"],
                serviceName: d["ServiceName"],
                serviceTreeGuid: d["ServiceTreeGUID"],
                totalCount: d["TotalCount"],
                invalidCount: d["InvalidCount"],
                validCount: d["ValidCount"],
                onboardedCount: d["OnboardedCount"],
                isEnabled: d["IsEnabled"] || false,
            }};

            await Promise.all([fetchOUDetails, fetchAutoEnrollments, fetchAllServers])
            .then((responseArray: any[]) => {

                // combine both api responses
                var OUItems:IServerCountByOU[] = responseArray[0].data;
                var EnrollmentItems:IAutoEnrollmentDetails[] = responseArray[1].data;
                var Servers:IServerDetails[] = responseArray[2].data;

                // map first API response 
                var combined_AutoEnroll_ServerCounts :IDetailsListAutoEnrollItem[] = OUItems.map(fetchOUDetailsToRowItem);

                // find second API response. By finding matching entries
                combined_AutoEnroll_ServerCounts = combined_AutoEnroll_ServerCounts.map((d: IDetailsListAutoEnrollItem) :any => {
                    
                    // find enrollment items by STGuid and OU
                    var entity = EnrollmentItems.find(item => {
                        return d.serviceTreeGuid == item.ServiceTreeGUID
                        && d.organizationalUnit == item.OrganizationalUnit
                    })

                    // copy props if match is found
                    if(entity != undefined){
                        // d.autoEnrollId = entity["AutoEnrollId"];
                        d.isEnabled = entity.IsEnabled;
                    }

                    return d;

                });

                // update state with response
                this.setState({
                    rows: combined_AutoEnroll_ServerCounts,
                    servers: Servers,
                    apiCallStatus : API_CALL_STATUS.COMPLETE,
                    servicesMissingIcmDataCount : 0
                });
            
            }
                , 
                () => {
                    // failure case when api calls have failed
                    this.setState({
                        apiCallStatus : API_CALL_STATUS.FAILED
                    })
                })
            .catch((reason: any) => {
                // exception case
                this.setState({
                    apiCallStatus : API_CALL_STATUS.FAILED
                })
                console.error(reason);
            });    
        }
    }

    
    setClickedRowItem(d: IDetailsListAutoEnrollItem){
        this.setState({clickedRowItem: d})
    }

    // Stylize column information by column
    private _renderItemColumn(item?: IDetailsListAutoEnrollItem, index?: number, column?: IColumn) {
        
        const warningIcon: IIconProps = { iconName: 'Warning', style: {'color': '#e50000'} };
        if (item !== undefined && column !== undefined) {
            {
                const fieldContent = item[column.fieldName as keyof IDetailsListAutoEnrollItem] as any | any[];
                {
                    switch (column.fieldName) {
                        case this.EnableDisableColumn.fieldName : {
                            return <div> 
                                    <span onClick={() => { this.openRequestAccessPanel(item);}}>
                                        {   
                                            fieldContent   
                                            ? (<span> <FontIcon aria-label={fieldContent} iconName="StatusCircleCheckmark" className={mergeStyles({ color: "green" })}/> <Link to="#">Enabled</Link> </span>) 
                                            : (<span><FontIcon aria-label={fieldContent} iconName="StatusCircleErrorX" className={mergeStyles({ color: "red" })} /> <Link to="#">Disabled </Link></span>)
                                        }
                                    </span>
                                </div>;
                        }
                        case this.TotalServersColumns.fieldName: {
                            return <div> 
                                    <span onClick={() => { this.openServerListPanel(item);}}>
                                        {   
                                            <Link to="#">{fieldContent}</Link> 
                                        }
                                    </span>
                                </div>;
                        }
                        default:
                            return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{fieldContent}</span>;
                    }
                }
            }
        }
    }

    openRequestAccessPanel(clickedRow: IDetailsListAutoEnrollItem): void{
        this.setState({
            clickedRowItem: clickedRow,
            isRequestAccessPanelOpen: true
        })
    }

    openServerListPanel(clickedRow: IDetailsListAutoEnrollItem): void{
        this.setState({
            clickedRowItem: clickedRow,
            isServerListPanelOpen: true
        })
    }

    
    closeRequestAccessPanel(): void{
        this.setState({
            isRequestAccessPanelOpen: false,
            apiCallStatus: API_CALL_STATUS.NOTSTARTED
        },this.refreshDataGrid)
    }

    closeServersListPanel(): void{
        this.setState({
            isServerListPanelOpen: false,
        })
    }
}


