import { Card } from "@coherence-design-system/controls";
import { CheckboxVisibility, IColumn, mergeStyles, ScrollablePane, SelectionMode, 
    ShimmeredDetailsList } from "@fluentui/react";
import React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { getHeader } from "../../Styles/Page.styles";
import { OpenPanelType } from "../../Styles/Page.types";
import { EmailTemplatesPanel, IEmailTemplatesPanelProps } from "./EmailTemplatesPanel";

export interface IEmailTemplatesProps {
    emailTemplates: IEmailTemplatesType[];
    fetchEmailTemplates: () => void;
}

export interface IEmailTemplatesType {
    Name: string;
    Parameters: Map<string, string>;
}

const cardSize = mergeStyles({
    width: "70vw",
    height: "35vh",
});

export const EmailTemplates: React.FunctionComponent<IEmailTemplatesProps> = (props: IEmailTemplatesProps) => {
    const [emailTemplatesLoaded, SetEmailTemplatesLoaded] = React.useState<boolean>(false);
    const [emailTemplates, SetEmailTemplates] = React.useState<IEmailTemplatesType[]>(props.emailTemplates);

    React.useEffect(() => {
        SetEmailTemplates(props.emailTemplates);
        
        if (props.emailTemplates.length > 0) {
            SetEmailTemplatesLoaded(true);
        }

    }, [props]);

    const refreshDataGrid = () => {
        SetEmailTemplatesLoaded(false);
        props.fetchEmailTemplates();
    };

    const emailTemplatesColumns: IColumn[] = [
        {
            key: "Edit",
            name: "Edit",
            fieldName: "Edit",
            minWidth: 50,
            maxWidth: 70,
            isResizable: true,
        },
        {
            key: "Name",
            name: "Name",
            fieldName: "Name",
            minWidth: 250,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: "Parameters",
            name: "Parameters",
            fieldName: "Parameters",
            minWidth: 130,
            maxWidth: 140,
            isResizable: true,
        }
    ];

    const emailTemplatePanelProps: IEmailTemplatesPanelProps = {
        emailTemplate: undefined,
        requestType: OpenPanelType.Add,
        linkText: "+ Add New Template",
        refreshDataGrid: refreshDataGrid
    }

    const onRenderItemColumn = (item?: IEmailTemplatesType, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IEmailTemplatesType] as any | any[];
    
            switch (column.fieldName) {
                case "Edit" : {
                    const emailTemplatesPanelProps: IEmailTemplatesPanelProps = {
                        emailTemplate: item,
                        requestType: OpenPanelType.Edit,
                        linkText: "Edit",
                        refreshDataGrid: refreshDataGrid
                    }
    
                    return <EmailTemplatesPanel {...emailTemplatesPanelProps} />
                }
                case "Parameters" : {
                    return <span>{Object.keys(fieldContent).join(", ")}</span>;
                }
                default:
                    return <span>{fieldContent}</span>;
            }
        }
    };

    return (
        <div className={cardSize}>
            <Card header={getHeader("MEO Email Templates", "left")}>
                <EmailTemplatesPanel {...emailTemplatePanelProps} />
                <ScrollablePane style={{ height: "25vh", position: "relative" }}>
                    <ShimmeredDetailsList
                        columns={emailTemplatesColumns}
                        items={emailTemplates}
                        compact={true}
                        onRenderDetailsHeader={RenderStickyHeader}
                        onRenderItemColumn={onRenderItemColumn}
                        checkboxVisibility={CheckboxVisibility.onHover}
                        selectionMode={SelectionMode.none}
                        enableShimmer={!emailTemplatesLoaded}
                    />
                </ScrollablePane>
            </Card>
        </div>
    );
}