import {
    Announced,
    Checkbox, CheckboxVisibility, DefaultButton, DetailsList, Dropdown, IColumn, IDropdownOption, ITextFieldStyles, Link,
    mergeStyles,
    PrimaryButton, ProgressIndicator, ScrollablePane,
    Spinner, SpinnerSize, TextField, ThemeContext, ThemeProvider, TooltipHost
} from "@fluentui/react";
import { Field, RadioGroup, Radio, RadioGroupOnChangeData, FluentProvider, webLightTheme } from '@fluentui/react-components';
import { useId } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React, { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { CSVLink } from "react-csv";
import ReactHtmlParser from "react-html-parser";
import { CSVReader } from "react-papaparse";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IOnboardingDataType } from "../../Pages/OnboardingPage";
import { getOnboardedServices } from "../../services/ApiService/Requests";
import { DataActionEnum } from "../../services/DataContext/DataActions";
import HttpService from "../../services/HttpService/HttpService";
import { breakWord, calloutProps, hostStyles } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { IJobInfo } from "../ManageServers/DetailsListManageServers";
import { linuxOnboardingStepsText, UnauthorizedMessage } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";
import { AddServersToJobsPanel, AddServersToJobsPanelProps, AddServersToJobsPanelType } from "./AddServersToJobsPanel";
import { IServiceDataType } from "../../App";
import { CoherenceTheme } from "@coherence-design-system/styles";
import { Colors, Status } from "../../Styles/Colors";
import { PeoplePicker, PeoplePickerRequestType } from "../Shared/PeoplePicker";
import { fetchGraphAPIAuthToken } from "../../services/FetchGraphAPIAuthToken";

// Interfaces //

export enum OnboardingStep {
    Prerequisites,
    ARC,
    Upload,
    Onboard,
}

export enum SubmissionStatus {
    NONE,
    PENDING,
    SUCCESS,
    FAILED,
}

interface IPrerequisitesComponentProps {
    SetCurrentStep: Dispatch<SetStateAction<OnboardingStep>>;
}

interface IARCComponentProps {
    SetCurrentStep: Dispatch<SetStateAction<OnboardingStep>>;
}

interface IUploadComponentProps {
    SetCurrentStep: Dispatch<SetStateAction<OnboardingStep>>;
    SetServerData: Dispatch<SetStateAction<IOnboardingDataType[]>>;
}

interface IOnboardComponentProps {
    serverData: IOnboardingDataType[];
    SetCurrentStep: Dispatch<SetStateAction<OnboardingStep>>;
}

export interface IFQDNValidation {
    Valid: boolean;
    Server: string;
    Domain: string;
    Error: string;
}

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: "450px" } };

// Consts //

const onboardingStatusText: string = "Onboarding Review";
const arcText: string = "Do you have ARC installed on any of these machines you plan to onboard?";
const arcSuccessText: string = "Thank you, please continue to onboard your machines";
const arcFailureText: string = "Unexpected failure during submission, please refresh page and try again.";

export const PrerequisitesComponent: React.FunctionComponent<IPrerequisitesComponentProps> = (props: IPrerequisitesComponentProps) => {
    const [prerequisitesText, SetPrerequisitesText] = React.useState<string>("Loading...");
    const [dataLoaded, SetDataLoaded] = React.useState<boolean>(false);
    const { state } = React.useContext(RootContext);
    const [confirmCheck, SetConfirmCheck] = React.useState<boolean>(true);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            const fetchPrerequisitesText = httpService.get({
                url: "api/ServerOnboarding/prerequisites",
                token: state.AuthStore.Token,
                params: {},
            });

            fetchPrerequisitesText.then((response: any) => {
                const data = response?.data;
                SetPrerequisitesText(data || "");
                SetDataLoaded(true);
            }).catch((reason: any) => {
                SetDataLoaded(true);
                trackException(appInsights, reason, SeverityLevel.Error, "Onboarding", "Prerequisites", "Prerequisites", state.AuthStore, {});
            });
        }
    }, [state.AuthStore.Token]);

    function _onChecked(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) {
        SetConfirmCheck(!isChecked);
    }

    return (
        <>
        {dataLoaded ? (
            <div>
                <h2 className={breakWord}>Prerequisites for Onboarding</h2>
                <span className={breakWord}>{ReactHtmlParser(prerequisitesText)}</span>
                <span className={breakWord}>{ReactHtmlParser(linuxOnboardingStepsText)}</span>
                <br /><br />
                <Checkbox
                    id="prerequisitesCheckBox"
                    label="Please check to confirm you have read these conditions"
                    ariaLabel="Please check to confirm you have read these conditions"
                    boxSide="end"
                    onChange={_onChecked}
                />
                <br />
                <span>
                    <PrimaryButton
                        id="continueButton"
                        role="button"
                        text="Continue"
                        aria-label="Continue Button"
                        disabled={confirmCheck}
                        allowDisabledFocus
                        onClick={() => props.SetCurrentStep(OnboardingStep.ARC)}
                    />
                </span>
            </div>
        ) : (
            <div
                style={{
                    position: "absolute", left: "50%", top: "40%",
                    transform: "translate(-50%, -50%)",
                }}>
                <Spinner size={SpinnerSize.large} ariaLive="assertive" />
            </div>
        )}
        </>
    );
};

export const ARCComponent: React.FunctionComponent<IARCComponentProps> = (props: IARCComponentProps) => {
    const { state } = React.useContext(RootContext);
    const [arcConfirm, SetArcConfirm] = React.useState<string | undefined>("");
    const [firstTimeConfirm, SetFirstTimeConfirm] = React.useState<string | undefined>("");
    const [subscriptionNameOrId, SetSubscriptionNameOrId] = React.useState<string>("");
    const [resourceGroupName, SetResourceGroupName] = React.useState<string>("");
    const [contactAlias, SetContactAlias] = React.useState<string>("");
    const [arcSubmissionStatus, SetArcSubmissionStatus] = React.useState<SubmissionStatus>(SubmissionStatus.NONE);
    const [resultMessage, SetResultMessage] = React.useState<string>(arcSuccessText);
    const [graphToken, SetGraphToken] = React.useState<string>("");

    const appInsights = useAppInsights();
    const tooltipId = useId("tooltip");

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        if (graphToken === "") {
            fetchGraphAPIAuthToken(state.AuthStore.Account).then((graphAuthResponse: any) => {
                SetGraphToken(graphAuthResponse);
            }).catch((reason: any) => {
                trackException(appInsights, reason, SeverityLevel.Error, "ServerAdministration", "fetchGraphAPIAuthToken", "Necessary for PeoplePicker", state.AuthStore, {});
            });
        }
    }, []);

    const onArcConfirm = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        SetArcConfirm(option?.text);
    }

    const onFirstTimeConfirm = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        SetFirstTimeConfirm(option?.text);
    }

    const SubmitArcQuestions = () => {
        SetArcSubmissionStatus(SubmissionStatus.PENDING);

        httpService.post({
            url: "api/IcM/onboardingArcRequest",
            token: state.AuthStore.Token,
            data: {
                subscriptionNameOrId : subscriptionNameOrId,
                resourceGroupName: resourceGroupName,
                contactAlias: contactAlias,
            },
        })        
        .then((resp: any)=>{
            console.log(resp);

            if (resp?.data.error == null) {
                // success
                SetResultMessage(arcSuccessText);
                SetArcSubmissionStatus(SubmissionStatus.SUCCESS);
            } else {
                // error
                SetResultMessage(resp?.data?.error?.message);
                SetArcSubmissionStatus(SubmissionStatus.FAILED);
            }
        }).catch(err => {
            console.log(err);

            SetResultMessage(arcFailureText);
            SetArcSubmissionStatus(SubmissionStatus.FAILED);
        });
    }

    const onChangeSubscriptionNameOrId = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        SetSubscriptionNameOrId(newValue!);
    }
    const onChangeReourceName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        SetResourceGroupName(newValue!);
    }

    const allFieldsPopulated = (): boolean => {
        return subscriptionNameOrId !== "" && resourceGroupName !== "" && contactAlias !== "" && arcConfirm !== "" && firstTimeConfirm !== "";
    }

    const disableInput = (): boolean => {
        if (arcConfirm! == "" || firstTimeConfirm! == "") {
            return true;
        }

        return arcConfirm === "No" || firstTimeConfirm === "No";
    }

    const toolTipContent = (): string => {
        if (allFieldsPopulated()) {
            return "Submit";
        }
        
        const message: string[] = [];

        if (subscriptionNameOrId === "") {
            message.push("Subscription Name/Id is blank");
        }

        if (resourceGroupName === "") {
            message.push("Resource Group Name is blank");
        }

        if (contactAlias === "") {
            message.push("Contact Alias is blank");
        }

        return message.join(", ");
    }

    const disableSubmitButton = (): boolean => {
        if (disableInput()) {
            return true;
        }

        if (!allFieldsPopulated()) {
            return true;
        }

        return false;
    }

    const disableContinueButton = (): boolean => {
        if (arcConfirm === "No") {
            return false;
        }

        if (arcConfirm! == "" || firstTimeConfirm! == "") {
            return true;
        } else if (disableInput()) {
            return false;
        } else {
            if (arcSubmissionStatus == SubmissionStatus.SUCCESS) {
                return false;
            }
        }
        
        return true;
    }

    return (
        <>
            <>
                <h2 className={breakWord}>Azure ARC</h2>
                <br />
                <Dropdown
                    label={arcText}
                    options={["Yes", "No"].map((item) => ({ key: item, text: item }))}
                    onChange={onArcConfirm}
                    styles={textFieldStyles}
                />
                <Dropdown
                    label="Is this the first time you have provided subscription information to the Microsoft Digital Patching Service?"
                    options={["Yes", "No"].map((item) => ({ key: item, text: item }))}
                    onChange={onFirstTimeConfirm}
                    styles={textFieldStyles}
                    disabled={arcConfirm !== "Yes"}
                />
                <TextField 
                    id="subscriptionName"
                    label="Please provide your Azure subscription name OR subscription Id"
                    resizable={false}
                    value={subscriptionNameOrId}
                    autoAdjustHeight
                    required
                    ariaLabel="Please provide your Azure subscription name OR subscription Id"
                    onChange={onChangeSubscriptionNameOrId}
                    styles={textFieldStyles}
                    disabled={disableInput()}
                />
                <TextField 
                    id="resourceName"
                    label="Please provide your Azure resource group name"
                    resizable={false}
                    value={resourceGroupName}
                    autoAdjustHeight
                    required
                    ariaLabel="Please provide your Azure resource group name"
                    onChange={onChangeReourceName}
                    styles={textFieldStyles}
                    disabled={disableInput()}
                />
                <PeoplePicker
                    token={graphToken}
                    account={state.AuthStore.Account}
                    defaultValue={""}
                    alias={contactAlias}
                    itemLimit={1}
                    required={true}
                    SetAlias={SetContactAlias}
                    SetAliasInput={() => {}}
                    SetNewEmail={() => {}}
                    PeoplePickerRequestTypeValue={PeoplePickerRequestType.Alias}
                    label={"Contact Alias (must have Owner permission on the Azure subscription)"}
                    ariaLabel={"Contact Alias (must have Owner permission on the Azure subscription)"}
                    styles={textFieldStyles}
                    disabled={disableInput()}
                />
                <br />
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <TooltipHost
                        content={toolTipContent()}
                        id={tooltipId}
                        calloutProps={calloutProps}
                    >
                        <DefaultButton 
                            id="submitButton"
                            key="submitButton"
                            role="button"
                            text="Submit"
                            aria-label="Submit Button"
                            allowDisabledFocus
                            disabled={disableSubmitButton()}
                            onClick={SubmitArcQuestions}
                        />
                    </TooltipHost>
                    {arcSubmissionStatus == SubmissionStatus.PENDING && (
                        <Spinner label="Submitting request..." ariaLive="assertive" labelPosition="left"/>
                    )}
                </div>
                {arcSubmissionStatus == SubmissionStatus.SUCCESS && <span style={{ color: Status.DarkGreen}}>{resultMessage}</span>}
                {arcSubmissionStatus == SubmissionStatus.FAILED && <span style={{ color: Status.Red}}>{resultMessage}</span>}
                <br />
                <br />
            </>
            <PrimaryButton
                id="continueButton"
                role="button"
                text="Continue"
                aria-label="Continue Button"
                disabled={disableContinueButton()}
                allowDisabledFocus
                onClick={() => props.SetCurrentStep(OnboardingStep.Upload)}
            />
        </>
    );
};

export const UploadDataComponent: React.FunctionComponent<IUploadComponentProps> = (props: IUploadComponentProps) => {
    const [dataGridData, SetDataGridData] = React.useState<IOnboardingDataType[]>([]);
    const [hideResults, SetHideResults] = React.useState<boolean>(true);
    const [maxOnboardingCount, SetMaxOnboardingCount] = React.useState<number>(1000);
    const [hideOverLimitMessage, SetHideOverLimitMessage] = React.useState<boolean>(true);
    const [showIncorrectFileTypeMessage, SetShowIncorrectFileTypeMessage] = React.useState<boolean>(false);
    const [columnIsSortedDescending, SetColumnIsSortedDescending] = React.useState<boolean>(false);
    const [showUploadCSVDataAlert, SetShowUploadCSVDataAlert] = React.useState<boolean>(false);
    const [descendingColumn, SetDescendingColumn] = useState<string>("Status");
    const [sortedColumn, SetSortedColumn] = useState<string>("Status");
    const { state } = React.useContext(RootContext);

    const [announceCSVUpload, SetAnnounceCSVUpload] = React.useState<JSX.Element | undefined>(undefined);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    const csvFileName = "servers.csv";
    const csvHeaders = [{ label: "FQDN", key: "FQDN" }];
    const csvData = [{ FQDN: "" }];

    const tooltipId = useId("tooltip");

    React.useEffect(() => {
        const fetchMaxOnboardingCount = httpService.get({
            url: "api/ServerOnboarding/maxOnboardingCount",
            token: state.AuthStore.Token,
            params: {},
        });

        fetchMaxOnboardingCount.then((response: any) => {
            const data: any = response?.data;
            SetMaxOnboardingCount(data || 0);
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Onboarding", "Upload Data", "fetchMaxOnboardingCount", state.AuthStore, {});
        });

        SetShowUploadCSVDataAlert(false);
    }, [state.AuthStore.Token]);

    function csvError(data: any) {
        console.log(data);
    }

    function uploadCSVData(data: any[], file: any) {
        SetShowIncorrectFileTypeMessage(false);
        SetShowUploadCSVDataAlert(false);

        if (file.name.split(".").slice(-1)[0].toLowerCase() !== "csv") {
            SetShowIncorrectFileTypeMessage(true);
            trackException(appInsights, new Error(), SeverityLevel.Warning, "Onboarding", "Upload CSV", "User attempted to upload non-csv file for Onboarding", state.AuthStore, {});
            return;
        }

        SetHideOverLimitMessage(true);
        const loadingServers: IOnboardingDataType[] = [{
            ServerId: 1,
            Status: "Loaded",
            ServiceName: "Loading...",
            ServiceTreeGUID: "",
            Server: "",
            Domain: "",
            Notes: "",
            Jobs: [],
            JobStatus: ""
        }];

        SetDataGridData(loadingServers);

        const newServerData: IOnboardingDataType[] = [];

        for (let i = 0; i < data.length; i++) {
            const fqdn: string = data[i].data[0];
            if (fqdn !== undefined && fqdn !== "" && fqdn.toLowerCase() !== "fqdn") {
                const validationResult: IFQDNValidation = validateFQDN(fqdn.toLowerCase());

                if (validationResult.Valid) {
                    newServerData.push({
                        ServerId: i,
                        Status: "Loaded",
                        ServiceName: "",
                        ServiceTreeGUID: "",
                        Server: validationResult.Server,
                        Domain: validationResult.Domain,
                        Notes: "",
                        Jobs: [],
                        JobStatus: ""
                    });
                } else {
                    newServerData.push({
                        ServerId: i,
                        Status: "FAIL",
                        ServiceName: "",
                        ServiceTreeGUID: "",
                        Server: validationResult.Server,
                        Domain: validationResult.Domain,
                        Notes: validationResult.Error,
                        Jobs: [],
                        JobStatus: ""
                    });
                }
            }
        }

        if (newServerData.length <= maxOnboardingCount) {
            SetDataGridData(newServerData);
            props.SetServerData(newServerData);
            SetHideResults(false);
        } else {
            SetHideOverLimitMessage(false);
            SetHideResults(true);
        }

        SetShowUploadCSVDataAlert(true);
        SetAnnounceCSVUpload(<Announced message="CSV Uploaded" aria-live="assertive" />);
    }

    const validateFQDN = (fqdn: string): IFQDNValidation => {
        let fqdnRegex: RegExp = /[[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+[.]+[a-zA-Z0-9]+\.corp\.microsoft\.com/gi;
        
        // trim FQDN
        fqdn = fqdn.trim();

        if (fqdnRegex.test(fqdn)) {
            return { 
                Valid: true,
                Server: fqdn.substring(0, fqdn.indexOf(".")),
                Domain: fqdn.substring(fqdn.indexOf(".") + 1, fqdn.length),
                Error: "",
            };

        } else {
            return { 
                Valid: false,
                Server: fqdn,
                Domain: "",
                Error: "FQDN is not valid",
            };
        }
    }

    // File selection dialog
    const buttonRef: React.RefObject<any> = React.createRef();
    const handleOpenDialog = (e: any) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
          buttonRef.current.open(e);
        }
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            const initialSorting: boolean = !currColumn.isSortedDescending;
            SetDescendingColumn(column.key);
            SetColumnIsSortedDescending(!initialSorting);
            SetSortedColumn(column.key);
          }
        });

        const newItems: IOnboardingDataType[] = copyAndSort(dataGridData, currColumn.fieldName!, isSortedDescending(column.key));

        SetDataGridData(newItems);
    }

    const copyAndSort = (items: IOnboardingDataType[], columnKey: string, isSortedDescending?: boolean): IOnboardingDataType[] => {
        const key = columnKey as keyof IOnboardingDataType;
        return items.slice(0).sort((a: IOnboardingDataType, b: IOnboardingDataType) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const isSortedDescending = (column: string): boolean => {
        if (column === descendingColumn) {
            return columnIsSortedDescending;
        } else {
            return true;
        }
    }

    const isSorted = (column: string): boolean => {
        return column === sortedColumn;
    }

    const columns: IColumn[] = [
        {
            key: "status",
            name: "Status",
            fieldName: "Status",
            minWidth: 70,
            maxWidth: 100,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("status") ? false : true,
            isSorted: isSorted("status"),
        },
        {
            key: "server",
            name: "Server",
            fieldName: "Server",
            minWidth: 100,
            maxWidth: 140,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("server") ? false : true,
            isSorted: isSorted("server"),
        },
        {
            key: "domain",
            name: "Domain",
            fieldName: "Domain",
            minWidth: 150,
            maxWidth: 160,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("domain") ? false : true,
            isSorted: isSorted("domain"),
        },
    ];

    return (
        <div className={mergeStyles({width:"800px"})}>
            <h2>Upload Server Data</h2>
            <ol>
                <li>Download pre-formated <CSVLink aria-label={"Click here to download " + csvFileName} filename={csvFileName} title={"Click here to download " + csvFileName} data={csvData} headers={csvHeaders}>{csvFileName}</CSVLink> file.</li>
                <li className={breakWord}>Fill out each row with full FQDN (i.e. SERVER.DOMAIN.corp.microsoft.com).<ul><li><b>Please limit data to {maxOnboardingCount} entries or fewer.</b></li></ul></li>
                <li>Upload completed CSV file</li>
            </ol>
            <br />
            <CSVReader
                ref={buttonRef}
                onFileLoad={uploadCSVData}
                onError={csvError}
                noClick
                noDrag
                >
                {({ file }: any) => (
                    <aside
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 10,
                    }}
                    >
                    <TooltipHost
                        content="Browse"
                        id={tooltipId}
                        calloutProps={calloutProps}
                    >
                        <PrimaryButton 
                            elementRef={buttonRef} 
                            onClick={handleOpenDialog}
                            ariaLabel="Click browse button to uplaod a CSV file"
                            role={"button"}
                            id={"browseButton"}
                        >
                            Browse
                        </PrimaryButton>
                    </TooltipHost>
                    <div
                        style={{
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#ccc",
                            lineHeight: 2,
                            marginTop: 0,
                            marginBottom: 0,
                            paddingLeft: 13,
                            paddingTop: 0,
                            width: "60%",
                        }}
                    >
                        {file && file.name}
                    </div>
                    </aside>
                )}
            </CSVReader>
            <div>
                {announceCSVUpload}
                <p role={"alert"} aria-live="assertive" >{showUploadCSVDataAlert ? "CSV successfully uploaded" : ""}</p>
            </div>
            <br />
            <div hidden={hideOverLimitMessage}>
                <b className={breakWord}>You have attempted to onboard more than {maxOnboardingCount} servers. Please re-upload CSV with fewer than {maxOnboardingCount}.</b>
            </div>
            <div hidden={!showIncorrectFileTypeMessage}>
                <b className={breakWord}>Only CSV files are acceptable.</b>
            </div>
            <div hidden={hideResults}>
                <div className="text-center">
                    <TooltipHost
                        content="Onboard Servers"
                        id={tooltipId}
                        calloutProps={calloutProps}
                    >
                        <PrimaryButton
                            role="button"
                            text="Onboard Servers"
                            ariaLabel="Onboard Servers"
                            onClick={() => props.SetCurrentStep(OnboardingStep.Onboard)}
                        />
                    </TooltipHost>
                </div>
                <br />
                <div style={{ position: "relative", height: "50vh", width: "60vw" }}>
                    <ScrollablePane style={{ height: "50vh", width: "60vw", position: "relative" }}>
                        <DetailsList
                            columns={columns}
                            items={dataGridData}
                            compact={true}
                            onRenderDetailsHeader={RenderStickyHeader}
                            checkboxVisibility={CheckboxVisibility.hidden}
                        />
                    </ScrollablePane>
                </div>
            </div>
        </div>
    );
};

export const OnboardComponent: React.FunctionComponent<IOnboardComponentProps> = (props: IOnboardComponentProps) => {
    const [onboardingResultsData, SetOnboardingResultsData] = React.useState<IOnboardingDataType[]>([]);
    const { state, dispatch } = React.useContext(RootContext); // auth token context
    const [onboardingStatus, SetOnboardingStatus] = React.useState<string>("Onboarding your servers...");
    const [successfulServersOnboarded, SetSuccessfulServersOnboarded] = React.useState<number>(0);
    const [showResults, SetShowResults] = React.useState<boolean>(false);
    const [hideErrorMessage, SetHideErrorMessage] = React.useState<boolean>(true);
    const [hidePermissionMessage, SetHidePermissionMessage] = React.useState<boolean>(true);
    const [columnIsSortedDescending, SetColumnIsSortedDescending] = React.useState<boolean>(false);
    const [descendingColumn, SetDescendingColumn] = useState<string>("Status");
    const [sortedColumn, SetSortedColumn] = useState<string>("Status");

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    const csvFileName = "onboarding_results";

    const tooltipId = useId("tooltip");

    React.useEffect(() => {
        if (props.serverData.length > 0) {
            const addOnboardingData = (serverFQDNs: string[], badFQDNs: IOnboardingDataType[]) => {
                httpService.post({
                    url: "api/ServerOnboarding/addServers",
                    token: state.AuthStore.Token,
                    data: {
                        FQDNs: serverFQDNs,
                    },
                }).then((resp: any) =>  {
                    const data = resp?.data;
                    if (resp?.status.toString() === "200" && data !== UnauthorizedMessage) {
                        const onboardedServersMap = new Map<string, IOnboardingDataType>();
                        let results: IOnboardingDataType[] = [];
                        const newServices: IServiceDataType[] = [];
                        
                        for (const d of data) {
                            let serverStatus: any = "Success";

                            const successRemarks = ["", "Success", "Server already Onboarded in Patching Portal"];
                            if (!successRemarks.includes(d.Remarks)) {
                                serverStatus = "FAIL";
                            }

                            const jobData: IJobInfo[] = [];

                            d.Jobs.forEach((job: any) => {
                                jobData.push({
                                    jobId: job.JobId,
                                    jobName: job.JobName,
                                });
                            });
                            
                            const serverName: string = d.FQDN.substring(0, d.FQDN.indexOf("."));
                            const domain: string = d.FQDN.substring(d.FQDN.indexOf(".") + 1, d.FQDN.length);

                            newServices.push({
                                ServiceName: d.ServiceName,
                                ServiceTreeGUID: d.ServiceTreeGUID,
                            });

                            onboardedServersMap.set(d.FQDN.toString(), {
                                ServerId: d.ServerId,
                                Status: serverStatus,
                                ServiceName: d.ServiceName,
                                ServiceTreeGUID: d.ServiceTreeGUID,
                                Server: serverName,
                                Domain: domain,
                                Notes: d.Remarks,
                                Jobs: jobData,
                                JobStatus: serverStatus !== "FAIL" ? jobData.length > 0 ? "Jobs (" + jobData.length.toString() + ")" : "Add To Job" : "",
                            });

                            results = [...results, {
                                ServerId: d.ServerId,
                                Status: serverStatus,
                                ServiceName: d.ServiceName,
                                ServiceTreeGUID: d.ServiceTreeGUID,
                                Server: serverName,
                                Domain: domain,
                                Notes: d.Remarks,
                                Jobs: jobData,
                                JobStatus: serverStatus !== "FAIL" ? jobData.length > 0 ? "Jobs (" + jobData.length.toString() + ")" : "Add To Job" : "",
                            }];
                        }

                        // add in servers that failed FQDN validation
                        // and therefore were not sent to onboarding
                        for(const d of badFQDNs) {
                            results = [...results, d];
                        }

                        updateServicesContext(newServices);
                        updateResults(results);
                    } else {
                        updateResults([{
                            ServerId: 0,
                            Status: "FAIL",
                            ServiceName: "",
                            ServiceTreeGUID: "",
                            Server: "",
                            Domain: "",
                            Notes: UnauthorizedMessage,
                            Jobs: [],
                            JobStatus: "",
                        }]);
                        SetHidePermissionMessage(false);
                        trackException(appInsights, new Error(), SeverityLevel.Warning, "Onboarding", "addOnboardingdata", "response status code: " + resp.status.toString(), state.AuthStore, {});
                    }
                }).catch((reason: any) => {
                    updateResults([{
                        ServerId: 0,
                        Status: "FAIL",
                        ServiceName: "",
                        ServiceTreeGUID: "",
                        Server: "",
                        Domain: "",
                        Notes: UnauthorizedMessage,
                        Jobs: [],
                        JobStatus: "",
                    }]);
                    SetHideErrorMessage(false);
                    trackException(appInsights, reason, SeverityLevel.Error, "Onboarding", "Onboard", "addServers", state.AuthStore, {});
                });
            };

            let FQDNArray: string[] = [];
            let badFQDNs: IOnboardingDataType[] = [];
            for (let i = 0; i < props.serverData.length; i++) {
                if (props.serverData[i]?.Status !== "FAIL") {
                    const FQDN: string = props.serverData[i]?.Server + "." + props.serverData[i].Domain;
                    FQDNArray = [...FQDNArray, FQDN];
                } else {
                    badFQDNs = [...badFQDNs, props.serverData[i]];
                }
            }

            addOnboardingData(FQDNArray, badFQDNs);
        } else {
            // no servers to be uploaded - csv is blank
            updateResults([{
                ServerId: 0,
                Status: "FAIL",
                ServiceName: "",
                ServiceTreeGUID: "",
                Server: "",
                Domain: "",
                Notes: UnauthorizedMessage,
                Jobs: [],
                JobStatus: "",
            }]);
            SetHidePermissionMessage(false);
            trackException(appInsights, new Error(), SeverityLevel.Warning, "Onboarding", "Onboard", "CSV is blank", state.AuthStore, {});
            SetOnboardingStatus("No Servers Onboarded. Please Try Again.");
        }
    }, []);

    const updateServicesContext = (services: IServiceDataType[]) => {
        if (state.DataStore.OnboardedServices!.length == 0) { // query all services and add new ones
            getOnboardedServices(state, appInsights).then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined) {
                    const onboardedServicesState: IServiceDataType[] = [];
                    const servicesSet: Set<string> = new Set<string>();
                    
                    for (const { serviceName, serviceTreeGUID } of data) {
                        if (serviceTreeGUID && !onboardedServicesState.some(s => s.ServiceTreeGUID === serviceTreeGUID)) {
                            onboardedServicesState.push({ ServiceName: serviceName, ServiceTreeGUID: serviceTreeGUID });
                        }   
                    }
                    
                    // add new services
                    for (const { ServiceName, ServiceTreeGUID } of services.filter(s => s.ServiceTreeGUID && !servicesSet.has(s.ServiceTreeGUID))) {
                        onboardedServicesState.push({ ServiceName: ServiceName, ServiceTreeGUID: ServiceTreeGUID });
                        servicesSet.add(ServiceTreeGUID);
                    }

                    dispatch({
                        type: DataActionEnum.UPDATE_ONBOARDED_SERVICES,
                        onboardedServices: onboardedServicesState,
                    });
                }
            }).catch((reason: any) => {
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Servers", "Fetch Page Data", "api/ServiceAdministration/fetchOnboardedServices", state.AuthStore, {});
            });
        } else { // add new services to current context
            const onboardedServicesState: IServiceDataType[] = state.DataStore.OnboardedServices;
            const newServices: IServiceDataType[] = services.filter(s => s.ServiceName !== "" && s.ServiceName !== null && s.ServiceName !== undefined);

            const servicesSet: Set<string> = new Set<string>(onboardedServicesState.map(s => s.ServiceTreeGUID));

            // add in new services
            for (const s of newServices) {
                if (!servicesSet.has(s.ServiceTreeGUID) && s.ServiceTreeGUID !== "" && s.ServiceTreeGUID !== null && s.ServiceTreeGUID !== undefined) {
                    onboardedServicesState.push({
                        ServiceName: s.ServiceName,
                        ServiceTreeGUID: s.ServiceTreeGUID,
                    });

                    servicesSet.add(s.ServiceTreeGUID);
                }
            }

            dispatch({
                type: DataActionEnum.UPDATE_ONBOARDED_SERVICES,
                onboardedServices: onboardedServicesState,
            });
        }
    }

    const updateResults = (allServers: IOnboardingDataType[]) => {
        SetOnboardingStatus(onboardingStatusText);

        SetOnboardingResultsData(allServers);

        // count successfully onboarded servers
        SetSuccessfulServersOnboarded(allServers.filter((s) => s.Status === "Success").length);

        trackEvent(appInsights, "Onboarding", "Onboarding Results", allServers.join(","), state.AuthStore, {});
        SetShowResults(true);
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = serversColumns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            const initialSorting: boolean = !currColumn.isSortedDescending;
            SetDescendingColumn(column.key);
            SetColumnIsSortedDescending(!initialSorting);
            SetSortedColumn(column.key);
          }
        });

        const newItems: IOnboardingDataType[] = copyAndSort(onboardingResultsData, currColumn.key!, isSortedDescending(column.key));

        SetOnboardingResultsData(newItems);
    }

    const copyAndSort = (items: IOnboardingDataType[], columnKey: string, isSortedDescending?: boolean): IOnboardingDataType[] => {
        const key = columnKey as keyof IOnboardingDataType;
        return items.slice(0).sort((a: IOnboardingDataType, b: IOnboardingDataType) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const isSortedDescending = (column: string): boolean => {
        if (column === descendingColumn) {
            return columnIsSortedDescending;
        } else {
            return true;
        }
    }
    
    const isSorted = (column: string): boolean => {
        return column === sortedColumn;
    }

    const serversColumns: IColumn[] = [
        {
            key: "status",
            name: "Status",
            fieldName: "Status",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("status") ? false : true,
            isSorted: isSorted("status"),
        },
        {
            key: "serviceName",
            name: "ServiceName",
            fieldName: "ServiceName",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("service") ? false : true,
            isSorted: isSorted("service"),
        },
        {
            key: "server",
            name: "Server",
            fieldName: "Server",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("server") ? false : true,
            isSorted: isSorted("server"),
        },
        {
            key: "domain",
            name: "Domain",
            fieldName: "Domain",
            minWidth: 70,
            maxWidth: 170,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("domain") ? false : true,
            isSorted: isSorted("domain"),
        },
        {
            key: "notes",
            name: "Notes",
            fieldName: "Notes",
            minWidth: 70,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("notes") ? false : true,
            isSorted: isSorted("notes"),
        },
        {
            key: "jobStatus",
            name: "Jobs",
            fieldName: "JobStatus",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            isMultiline: true,
            onColumnClick: onColumnClick,
            isSortedDescending: isSortedDescending("JobStatus") ? false : true,
            isSorted: isSorted("JobStatus"),
            onRender(item?: IOnboardingDataType, index?, column?) {
                if (item?.JobStatus !== undefined && item?.JobStatus !== "") {
                    const newServer: AddServersToJobsPanelType = {
                        ServerId: item?.ServerId!,
                        ServerName: item?.Server!,
                        ServiceName: item?.ServiceName!,
                        ServiceTreeGUID: item?.ServiceTreeGUID!,
                    }
                    const addServersToJobsPanelProps: AddServersToJobsPanelProps = {
                        newServer: newServer,
                        buttonText:  item?.JobStatus!
                    }
                    return <AddServersToJobsPanel {...addServersToJobsPanelProps} />;
                } else {
                    return <span>{"N/A"}</span>
                }
            },
        },
    ];

    const onRenderRow = (onRenderProps: any, defaultRender?: any) => {
        return (
            <div>
                <TooltipHost
                    content={onRenderProps?.item?.Notes}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                >
                    {defaultRender!(onRenderProps)}
                </TooltipHost>
            </div>
        );
    };

    return (
        <div>
            <h2>{onboardingStatus}</h2>
            <div hidden={showResults}>
                <ProgressIndicator label={onboardingStatus} description="Please wait..." />
            </div>
            {/* show results of onboarding after complete */}
            <div hidden={!showResults}>
                <div hidden={hidePermissionMessage}>
                    <b>Onboarding Failed. You do not have permission to onboard devices for this service. Please visit the home page to request access.</b>
                </div>
                <div hidden={hideErrorMessage}>
                    <b>Onboarding Failed. Please contact administrator.</b>
                </div>
                <div hidden={!hidePermissionMessage || !hideErrorMessage}>
                    <p>
                        {successfulServersOnboarded} / {props.serverData.length} server(s) successfully onboarded.
                    </p>
                    <Link role="link" ariaLabel={"Link to onboard more servers"}
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Click here to onboard more servers
                    </Link>
                    <br />
                    <br />
                    <ScrollablePane style={{ height: "45vh", width: "60vw", position: "relative" }}>
                        <DetailsList
                            columns={serversColumns}
                            items={onboardingResultsData}
                            compact={true}
                            onRenderDetailsHeader={RenderStickyHeader}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            onRenderRow={onRenderRow}
                        />
                    </ScrollablePane>
                    <br />
                    <div>
                        <CSVLink 
                            aria-label={"Export Results"} 
                            filename={csvFileName} 
                            title={"Export Results"} 
                            data={onboardingResultsData}>
                                Export Results
                        </CSVLink>
                    </div>
                </div>
            </div>
        </div>
    );
};



