import { CardStandardHeader, navCollapsedWidth } from "@coherence-design-system/controls";
import { FontSizes, IDropdownStyles, IStyle, ITooltipHostStyles, mergeStyles, mergeStyleSets } from "@fluentui/react";

const scrollablePaneStyles = {
    position: "fixed",
    top: 48,
    bottom: 0,
    right: 0,
};

let navScrollBarWidth = 0;
const navDefaultScrollBarWidth = 15;

// tool tip consts
export const calloutProps = { gapSpace: 0 };
export const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

export function getScrollBarWidth(isCollapsed: boolean) {
    if (navScrollBarWidth !== 0) {
      return navScrollBarWidth;
    }
    if (document.readyState === "loading") { return navDefaultScrollBarWidth; }
    // Get the browser scrollbar width because they're all different
    const scrollDiv = document.createElement("div");
    scrollDiv.setAttribute("style", "width: 100px;height: 100px;overflow: scroll;position: absolute;top: -999px;");
    const contentDiv = document.createElement("p");
    contentDiv.setAttribute("style", "width: 100px;height: 200px;");
    scrollDiv.appendChild(contentDiv);
    document.body.appendChild(scrollDiv);
    navScrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return navScrollBarWidth;
  }

export const _Styles = mergeStyleSets({
    scrollablePaneCollapsed: {
        ...scrollablePaneStyles,
        left: navCollapsedWidth
    } as IStyle,
    scrollablePaneExpand: {
        ...scrollablePaneStyles,
        left: 228
    } as IStyle,
    scrollablePaneCollapsedHome: {
        ...scrollablePaneStyles,
        left: navCollapsedWidth,
    } as IStyle,
    scrollablePaneExpandHome: {
        ...scrollablePaneStyles,
        left: 228,
    } as IStyle,
    rootDiv: {
        paddingRight: getScrollBarWidth(true) + 10,
        paddingLeft: "40px",
    } as IStyle,
    rootDivHome: {
        paddingRight: "64px",
        paddingLeft: "0px",
    } as IStyle,
    dividerLine: {
        width: "100%",
        height: "1px",
        backgroundColor: "black",
        marginBottom: "20px",
    } as IStyle,
    rowGap: {
        height: "30px",
    } as IStyle,
});

export const breakWord = mergeStyles({
    wordBreak: "break-all",
});

export const getHeader = (headerText: string, textAlign: string) => {
    return <CardStandardHeader
                cardTitle={headerText}
                styles={{
                    root: {
                    gridTemplateRows: "none",
                    gridTemplateColumns: "none",
                    },
                    cardTitle: {
                    fontSize: FontSizes.size18,
                    textAlign: textAlign,
                    padding: "0px",
                    },
      }} />;
  };

export const ChildClass = mergeStyles({
    display: "block",
    marginBottom: "10px",
});

export const defaultDropdownStyles: Partial<IDropdownStyles> = {
    root: { maxWidth: "300px", minWidth: "250px" },
    label: { display: "none" },
};