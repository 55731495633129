import { CheckboxVisibility, DirectionalHint, Dropdown, ICalloutProps, IColumn, IconButton,
    IDropdownOption, IDropdownStyles,
    IStackTokens, ITextFieldStyles, mergeStyles, PrimaryButton,
    ScrollablePane, ShimmeredDetailsList, Spinner, Stack, TextField, TooltipHost } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React, { FormEvent } from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { arrayRemove } from "../../Helpers/Utils";
import { IOffboardingItem } from "../../Pages/OffboardingPage";
import HttpService from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { ListItemActionSet } from "../Shared/ListItemActionSet";
import { RootContext } from "../Stores/RootStore";
import { AdvancedSearchPanel } from "./AdvancedSearchPanel";
import { BulkOffboardPanel } from "./BulkOffboardPanel";
import { SelectionOffboardPanel } from "./SelectionOffboardPanel";

// Interfaces //

export interface IDataGridOffboardingProps {}

export interface IOffboardingQueueItem {
    server: string;
    domain: string;
    serverID: number;
    serviceTreeGUID: string;
}

// Styles //

const ChildClass = mergeStyles({
    display: "block",
    marginBottom: "28px",
});

const calloutProps: ICalloutProps = { directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 };

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: "400px", alignSelf: "center", minWidth: "200px" } };
const sectionStackTokens: IStackTokens = {
    childrenGap: 0 + " " + 20, // vertical gap + ' ' + horizontal gap
};

const domainDropdownStyles: Partial<IDropdownStyles> = {
    root: { minWidth: "110px", maxWidth: "300px" },
    label: { display: "none" },
};

const servicesDropdownStyles: Partial<IDropdownStyles> = {
    root: { minWidth: "250px", maxWidth: "300px" },
    label: { display: "none" },
};

export const DataGridOffboarding: React.FunctionComponent<IDataGridOffboardingProps> = (props: IDataGridOffboardingProps) => {
    const [serverData, SetServerData] = React.useState<IOffboardingItem[]>([]);
    const [allServerData, SetAllServerData] = React.useState<IOffboardingItem[]>([]);
    const [serversToBeOffboardedData, SetServersToBeOffboardedData] = React.useState<IOffboardingQueueItem[]>([]);
    const [onboardedServers, SetOnboardedServers] = React.useState<IOffboardingItem[]>([]);
    const [onboardedServersDisplayed, SetOnboardedServersDisplayed] = React.useState<IOffboardingItem[]>([]);
    const [maxOffboardingCount, SetMaxOffboardingCount] = React.useState<number>(0);
    const [selectedDomain, SetSelectedDomain] = React.useState<string>("");
    const [selectedService, SetSelectedService] = React.useState<string>("");
    const [serverFilteredText, SetServerFilteredText] = React.useState<string>("");
    const [domainOptions, SetDomainOptions] = React.useState<IDropdownOption[]>([]);
    const [serviceOptions, SetServiceOptions] = React.useState<IDropdownOption[]>([]);
    const [serverDataLoaded, SetServerDataLoaded] = React.useState<boolean>(false);

    const appInsights = useAppInsights();

    const { state } = React.useContext(RootContext); // auth token context

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            const maxOnboardingCount = httpService.get({
                url: "api/ServerOnboarding/maxOnboardingCount",
                token: state.AuthStore.Token,
                params: {},
            });

            const fetchServerData = httpService.get({
                url: "api/ManageServers/fetchServerData",
                token: state.AuthStore.Token,
                params: { 
                    IncludeInActiveDevice: false,
                    timezone: state.AuthStore.timezone?.standardName
                },
            });

            const fetchAllData = async () => {
                await Promise.all([maxOnboardingCount, fetchServerData]).then((responseArray: any[]) => {
                    let i = 0;

                    responseArray.forEach((response: any) => {
                        const data: any = response?.data;
                        if (data !== "" && data !== undefined) {
                            switch (i) {
                                case 0: {
                                    SetMaxOffboardingCount(parseInt(data) || 0);
                                    break;
                                }
                                case 1: {
                                    SetServerData([]);
                                    SetServerDataLoaded(false);

                                    handleServerData(data);
                                    break;
                                }
                            }
                        }
                        i++;
                    });
                }).catch((reason: any) => {
                    trackException(appInsights, reason, SeverityLevel.Error, "Offboarding", "fetchServerData", "DataGridOffboarding", state.AuthStore, {});
                });
            };

            fetchAllData();
        }
    }, [state.AuthStore.Token]);

    const handleServerData = (data: any) => {
        let newItems: IOffboardingItem[] = [];

        for (const d of data) {
            if (!newItems.some((item) => item.key === d.ServerId)) {
                if (d.ServiceTreeGUID !== null) {
                    newItems = [...newItems, {
                        key: d.ServerId,
                        server: d.Name,
                        lastSuccessfulPatch: d.LastSuccessfulPatch,
                        domain: d.NetworkDomain,
                        serviceTreeName: d.ServiceName,
                        serviceTreeGUID: d.ServiceTreeGUID,
                        serviceTreeOwner: d.PMOwner,
                        serverID: d.ServerId,
                    }];
                }
            }
        }

        SetServerData(newItems);
        SetAllServerData(newItems);
        SetOnboardedServers(newItems);
        SetOnboardedServersDisplayed(newItems);

        let domainOptionsArray: IDropdownOption[] = [{key: "", text: "(any)"}];
        let serviceOptionsArray: IDropdownOption[] = [{key: "", text: "(any)"}];
        const domainSet: Set<string> = new Set<string>();
        const serviceSet: Set<string> = new Set<string>();

        for (const d of newItems) {
            if (!domainSet.has(d.domain) && d.domain !== "") {
                domainSet.add(d.domain);
                domainOptionsArray = [...domainOptionsArray, {
                    key: d.domain,
                    text: d.domain.split(".")[0],
                }];
            }

            if (!serviceSet.has(d.serviceTreeName) && d.serviceTreeName !== "") {
                serviceSet.add(d.serviceTreeName);
                serviceOptionsArray = [...serviceOptionsArray, {
                    key: d.serviceTreeName,
                    text: d.serviceTreeName,
                }];
            }
        }

        SetServiceOptions(serviceOptionsArray.sort((s1, s2) => {
            if (s1.text.toLowerCase() > s2.text.toLowerCase()) { return 1; }
            if (s2.text.toLowerCase() > s1.text.toLowerCase()) { return -1; }
            return 0;
        }));

        SetDomainOptions(domainOptionsArray.sort((s1, s2) => {
            if (s1.text.toLowerCase() > s2.text.toLowerCase()) { return 1; }
            if (s2.text.toLowerCase() > s1.text.toLowerCase()) { return -1; }
            return 0;
        }));

        SetServerDataLoaded(true);
    };

    const fetchAllServersData = (hardRefresh: boolean) => {
        SetServerData([]);
        SetSelectedService("");
        SetServerDataLoaded(false);

        httpService.get({
            url: "api/ManageServers/fetchServerData",
            token: state.AuthStore.Token,
            params: {
                timezone: state.AuthStore.timezone?.standardName,
                IncludeInActiveDevice: false,
                hardRefresh: hardRefresh 
             },
        }).then((response: any) => {
            if (response !== undefined) {
                const data: any = response.data;
                handleServerData(data);
            }
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Offboarding", "fetchServersInfoData", "DataGridOffboarding", state.AuthStore, {});
        });
    };

    const serversOnlyColumnsDetailsList: IColumn[] = [
        {
            key: "actions",
            name: "Remove",
            onRender: (item: IOffboardingItem) => removeServerFromToBeOffboarded(item),
            maxWidth: 250,
            minWidth: 50,
            isResizable: true
        },
        {
            key: "server",
            name: "Server",
            fieldName: "server",
            isResizable: true,
            minWidth: 50,
            maxWidth: 250,
        },
        {
            key: "domain",
            name: "Domain",
            fieldName: "domain",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            isMultiline: true,
        },
    ];

    const offboardingColumnsDetailsList: IColumn[] = [
        {
            key: "actions",
            name: "Add to Offboard",
            onRender: (item: IOffboardingItem, index?: number, column?: IColumn) => addServerToBeOffboarded(item, index!),
            minWidth: 50,
            maxWidth: 250,
            isResizable: true
        },
        {
            key: "server",
            name: "Server",
            fieldName: "server",
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            isMultiline: true,
        },
        {
            key: "domain",
            name: "Domain",
            fieldName: "domain",
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            isMultiline: true,
        },
        {
            key: "serviceTreeName",
            name: "Service Name",
            fieldName: "serviceTreeName",
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            isMultiline: true,
        },
    ];

    const addServerToBeOffboarded = (item: IOffboardingItem, index: number) => {
        const visibleActions: React.ReactNode[] = [];
        const addServerAriaLabel = "click here to add server to be offboarded: ";
        visibleActions.push(
            <TooltipHost
                key={"addButton"}
                aria-label={addServerAriaLabel + item.server}
                content={addServerAriaLabel + item.server}
                styles={{ root: { marginLeft: "10px" } }}
                calloutProps={calloutProps}
            >
                <IconButton
                    id={"addServer" + index}
                    key={"addButton" + index}
                    label={"addButton"}
                    ariaLabel={addServerAriaLabel + item.server}
                    iconProps={{
                        iconName: "AddIn",
                    }}
                    role={"button"}
                    onClick={() => {
                        addServerForOffboarding(item);
                    }}
                />
            </TooltipHost>,
        );

        return (
            <ListItemActionSet
                visibleActions={visibleActions}
            />
        );
    };

    const removeServerFromToBeOffboarded = (item: IOffboardingQueueItem) => {
        const visibleActions: React.ReactNode[] = [];
        const removeServerFromToBeOffboardedLabel = "click here to remove server to be offboarded: ";
        visibleActions.push(
            <TooltipHost
                key={"removeButton"}
                aria-label={removeServerFromToBeOffboardedLabel + item.server}
                content={removeServerFromToBeOffboardedLabel + item.server}
                styles={{ root: { marginLeft: "10px" } }}
                calloutProps={calloutProps}
            >
                <IconButton
                    key={"removeButton"}
                    label={"removeButton"}
                    ariaLabel={removeServerFromToBeOffboardedLabel + item.server}
                    iconProps={{
                        iconName: "Remove",
                    }}
                    role={"button"}
                    onClick={() => {
                        removeServerForOffboarding(item);
                    }}
                />
            </TooltipHost>,
        );

        return (
            <ListItemActionSet
                visibleActions={visibleActions}
            />
        );
    };

    const addServerForOffboarding = (item: IOffboardingItem) => {
        if (item) {
            let offboardingQueueData: IOffboardingQueueItem[] = serversToBeOffboardedData;

            // add data to offboarding queue table
            if (!serversToBeOffboardedData.some((x: IOffboardingQueueItem) => x.serverID === item.serverID)) {
                offboardingQueueData = [...offboardingQueueData, {
                    server: item.server,
                    serverID: item.serverID,
                    domain: item.domain,
                    serviceTreeGUID: item.serviceTreeGUID,
                }];
            }

            const newOnboardedServers = arrayRemove(onboardedServers, item);
            const newOnboardedServersDisplayed = arrayRemove(onboardedServersDisplayed, item);

            SetOnboardedServers(newOnboardedServers);
            SetOnboardedServersDisplayed(newOnboardedServersDisplayed);
            SetServersToBeOffboardedData(offboardingQueueData);
        }
    };

    const removeServerForOffboarding = (item: IOffboardingQueueItem) => {
        if (item) {
            const serverID: number = item.serverID;

            let offboardingQueueData: IOffboardingQueueItem[] = [];

            // remove server from offboarding queue table
            for (const d of serversToBeOffboardedData) {
                if (d.serverID !== serverID) {
                    offboardingQueueData = [...offboardingQueueData, {
                        server: d.server,
                        serverID: d.serverID,
                        domain: d.domain,
                        serviceTreeGUID: d.serviceTreeGUID,
                    }];
                }
            }

            const addBackServer: IOffboardingItem = serverData.filter((x) => x.server === item.server)[0];
            const addBackServerArray: IOffboardingItem[] = [...onboardedServers, addBackServer];

            SetOnboardedServers(addBackServerArray);

            const displyedServers: IOffboardingItem[] = selectedService === "" ? addBackServerArray : addBackServerArray.filter((x) => x.serviceTreeName === selectedService);
            SetOnboardedServersDisplayed(displyedServers);
            SetServersToBeOffboardedData(offboardingQueueData);
        }
    };

    const refreshAndResetServerData = () => {
        fetchAllServersData(true);
        SetServersToBeOffboardedData([]);
    };

    const resetFilters = () => {
        SetServerFilteredText("");
        SetSelectedDomain("");
        SetSelectedService("");
        SetOnboardedServersDisplayed(onboardedServers);
    };

    const filterDataGrid = (serverF: string, serviceF: string, domainF: string) => {
        let data: IOffboardingItem[] = [];

        data = serverF !== "" ? onboardedServers.filter((i) => i.server?.toString().toLowerCase().indexOf(serverF.toString().toLowerCase()) > -1) : onboardedServers;
        data = serviceF !== "" ? data.filter((i) => i.serviceTreeName?.toString().toLowerCase().indexOf(serviceF.toString().toLowerCase()) > -1) : data;
        data = domainF !== "" ? data.filter((i) => i.domain?.toString().toLowerCase().indexOf(domainF.toString().toLowerCase()) > -1) : data;

        SetOnboardedServersDisplayed(data);
    };

    return (
        <div>
            <Stack tokens={sectionStackTokens}>
                <Stack horizontal tokens={sectionStackTokens} verticalAlign="center">
                    <span>
                        <TextField
                            className={ChildClass}
                            label="Filter by Server:"
                            ariaLabel="TextField Search by Server:"
                            onChange={onFilterServer}
                            value={serverFilteredText}
                            styles={textFieldStyles}
                            placeholder={"Server Name"}
                            disabled={!serverDataLoaded}
                        />
                    </span>
                    <span>
                        <Dropdown
                            id="domainDropdown"
                            className={ChildClass}
                            label="Select a Domain:"
                            onChange={onFilterDomain}
                            styles={domainDropdownStyles}
                            selectedKey={selectedDomain}
                            options={domainOptions}
                            disabled={!serverDataLoaded}
                        />
                    </span>
                    <span>
                        <Dropdown
                            id="serviceDropdown"
                            className={ChildClass}
                            label="Select a Service:"
                            onChange={onFilterService}
                            styles={servicesDropdownStyles}
                            selectedKey={selectedService}
                            options={serviceOptions}
                            disabled={!serverDataLoaded}
                        />
                    </span>
                    <span>
                        <PrimaryButton id="resetFiltersButton" onClick={resetFilters} disabled={!serverDataLoaded}>
                            Reset Filters
                        </PrimaryButton>
                    </span>
                    <span hidden={!serverDataLoaded}>
                        <AdvancedSearchPanel
                            maxOffboardingCount={maxOffboardingCount}
                            allItems={allServerData}
                            refreshAndResetServerData={refreshAndResetServerData}
                        />
                    </span>
                    <span hidden={!serverDataLoaded}>
                        <BulkOffboardPanel
                            maxOffboardingCount={maxOffboardingCount}
                            allItems={allServerData}
                            refreshAndResetServerData={refreshAndResetServerData}
                        />
                    </span>
                    <span hidden={!serverDataLoaded}>
                        <TooltipHost
                                content={"Refresh Server Data"}
                                calloutProps={calloutProps}
                            >
                            <IconButton 
                                disabled={!serverDataLoaded}
                                key={"Refresh"}
                                label={"Refresh"}
                                ariaLabel={"Refresh"}
                                iconProps={{
                                    iconName: ("Refresh"),
                                }}
                                role={"button"}
                                onClick={() => fetchAllServersData(true)}
                            />
                        </TooltipHost>
                    </span>
                    <span hidden={serverDataLoaded}>
                        <Spinner ariaLive="assertive" />
                    </span>
                </Stack>
            </Stack>
                <ScrollablePane id="offboardingDetailsList" style={{ height: "40vh", position: "relative" }}>
                    <ShimmeredDetailsList
                        columns={offboardingColumnsDetailsList}
                        items={onboardedServersDisplayed}
                        compact={true}
                        enableShimmer={!serverDataLoaded}
                        onRenderDetailsHeader={RenderStickyHeader}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        ariaLabelForGrid={"Onboarded Servers Table"}
                    />
                </ScrollablePane>
            <h4>Servers to be offboarded: </h4>
            <ScrollablePane id="serversSelectedDetailsList" style={{ height: "30vh", position: "relative" }}>
                <ShimmeredDetailsList
                    columns={serversOnlyColumnsDetailsList}
                    items={serversToBeOffboardedData}
                    compact={true}
                    onRenderDetailsHeader={RenderStickyHeader}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    ariaLabelForGrid={"Servers to be Offboarded Table"}
                />
            </ScrollablePane>
            <SelectionOffboardPanel
                serverData={serversToBeOffboardedData}
                refreshAndResetServerData={refreshAndResetServerData}
            />
            <br />
        </div>
    );

    // Filter by Server name
    function onFilterServer(vent: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        const value: string = newValue!;
        SetServerFilteredText(value);

        filterDataGrid(value, selectedService, selectedDomain);
    }

    // Filter by Service
    function onFilterService(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) {
        const value: string = option?.key.toString()!;
        SetSelectedService(value);

        filterDataGrid(serverFilteredText, value, selectedDomain);
    }

    // Filter by Domain
    function onFilterDomain(event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) {
        const value: string = option?.key.toString()!;
        SetSelectedDomain(value);

        filterDataGrid(serverFilteredText, selectedService, value);
    }
};
