import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import axios, { AxiosRequestConfig } from "axios";
import { trackEvent } from "../../components/AppInsights/LoggingHelper";
import { IAuthState } from "../AuthenticationService/AuthReducer";
import { IRootState } from "../../components/Stores/RootStore";

export enum UserCallResult {
    Start,
    Success,
    Calling,
    Error,
}

export interface IHttpGETProps {
    url: string;
    token: any;
    params: any | any[];
}

export interface IHttpGETPropsWithAuth {
    url: string;
    token?: any;
    params: any | any[];
}

export interface IHttpDELETEProps {
    url: string;
    token: any;
    params: any | any[];
}

export interface IHttpPOSTProps {
    url: string;
    token: any;
    data: any | any[];
}

export interface IHttpPOSTPropsWithAuth {
    url: string;
    token?: any;
    data: any | any[];
}

export interface IHttpClient {
    updateCallResult: (x: UserCallResult) => void;
    get<T>(parameters: IHttpGETProps): Promise<T>;
    getFromUI<T>(parameters: IHttpGETProps): Promise<T>;
    getExternal<T>(parameters: IHttpGETProps): Promise<T>;
    post<T>(parameters: IHttpPOSTProps): Promise<T>;
    postWithParams<T>(parameters: IHttpPOSTProps, params: boolean): Promise<T>;
    postExternal<T>(parameters: IHttpPOSTProps): Promise<T>;
    delete<T>(parameters: IHttpDELETEProps): Promise<T>;
}

export default class HttpClient implements IHttpClient {
    public updateCallResult: (x: UserCallResult) => void;
    private _appInsights!: ApplicationInsights;
    private _apiEndpoint: string = String(process.env.REACT_APP_API_URL);
    private _account!: IAuthState;

    constructor(updateCallResult: (x: UserCallResult) => void, appInsights: ApplicationInsights, rootState: IRootState) {
        this.updateCallResult = updateCallResult;
        if (appInsights) this._appInsights = appInsights;
        if (rootState?.AuthStore.APIEndpoint && rootState?.AuthStore.APIEndpoint!.trim().length > 0) this._apiEndpoint = rootState?.AuthStore.APIEndpoint;
        if (rootState?.AuthStore) this._account = rootState.AuthStore;
    }

    public getWithAuth<T>(parameters: IHttpGETPropsWithAuth): Promise<T> {
        if(this._account == null || this._account.Token == null){
            throw Error("no token found");
        }

        return this.get({
            params: parameters.params,
            url: parameters.url,
            token: this._account.Token
        })
    }

    public postWithAuth<T>(parameters: IHttpPOSTPropsWithAuth): Promise<T> {
        if(this._account == null || this._account.Token == null){
            throw Error("no token found");
        }

        return this.post({
            data: parameters.data,
            url: parameters.url,
            token: this._account.Token
        })
    }

    public get<T>(parameters: IHttpGETProps): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const { url, token, params } = parameters;

            const startDate: number = Date.now();
            this.updateCallResult(UserCallResult.Calling);

            const options: AxiosRequestConfig = {
                headers: {
                    Authorization: token,
                },
                params,
                withCredentials: true,
            };

            axios
            .get(this._apiEndpoint + url, options)
            .then((response: any) => {
                const stopDate: number = Date.now();
                trackEvent(this._appInsights, url, "API", JSON.stringify(params), this._account!, {}, stopDate - startDate);
                this.updateCallResult(UserCallResult.Success);
                resolve(response as T);
            })
            .catch((response: any) => {
                // turning this off for now, this enables the "Oops, something went wrong message".
                // it will flag during testing and will make it difficult for us to debug for the time being.
                //this.updateCallResult(UserCallResult.Error);
                reject(response);
            });
        });
    }

    public post<T>(parameters: IHttpPOSTProps): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const { url, token, data } = parameters;

            const startDate: number = Date.now();
            this.updateCallResult(UserCallResult.Calling);

            const options: AxiosRequestConfig = {
                headers: {
                    Authorization: token,
                },
                withCredentials: true,
                timeout: 100000 // increase timeout for Core Identity during onboarding
            };

            axios
            .post(this._apiEndpoint + url, data, options)
            .then((response: any) => {
                const stopDate: number = Date.now();
                trackEvent(this._appInsights, url, "API", JSON.stringify(data), this._account!, {}, stopDate - startDate);
                this.updateCallResult(UserCallResult.Success);
                resolve(response as T);
            })
            .catch((response: any) => {
                this.updateCallResult(UserCallResult.Error);
                reject(response);
            });
        });
    }

    public delete<T>(parameters: IHttpDELETEProps): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const { url, token, params } = parameters;

            const startDate: number = Date.now();
            this.updateCallResult(UserCallResult.Calling);

            const options: AxiosRequestConfig = {
                headers: {
                    Authorization: token,
                },
                data: params,
                withCredentials: true,
            };

            axios
            .delete(this._apiEndpoint + url, options)
            .then((response: any) => {
                const stopDate: number = Date.now();
                trackEvent(this._appInsights, url, "API", JSON.stringify(params), this._account!, {}, stopDate - startDate);
                this.updateCallResult(UserCallResult.Success);
                resolve(response as T);
            })
            .catch((response: any) => {
                this.updateCallResult(UserCallResult.Error);
                reject(response);
            });
        });
    }
    

    public postWithParams<T>(parameters: IHttpPOSTProps, params:boolean = true): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const { url, token, data } = parameters;

            const startDate: number = Date.now();
            this.updateCallResult(UserCallResult.Calling);

            let options: AxiosRequestConfig = {
                headers: {
                    Authorization: token,
                },
                withCredentials: true,
            };
            
            if(params){
                options.params = data
            }

            axios
            .post(this._apiEndpoint + url, data, options)
            .then((response: any) => {
                const stopDate: number = Date.now();
                trackEvent(this._appInsights, url, "API", JSON.stringify(data), this._account!, {}, stopDate - startDate);
                this.updateCallResult(UserCallResult.Success);
                resolve(response as T);
            })
            .catch((response: any) => {
                this.updateCallResult(UserCallResult.Error);
                reject(response);
            });
        });
    }

    public getExternal<T>(parameters: IHttpGETProps): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const { url, token, params } = parameters;

            const startDate: number = Date.now();
            this.updateCallResult(UserCallResult.Calling);

            const options: AxiosRequestConfig = {
                headers: {
                    Authorization: token,
                },
                params,
                withCredentials: true,
            };

            axios
            .get(url, options)
            .then((response: any) => {
                const stopDate: number = Date.now();
                trackEvent(this._appInsights, url, "API", JSON.stringify(params), this._account!, {}, stopDate - startDate);
                this.updateCallResult(UserCallResult.Success);
                resolve(response as T);
            })
            .catch((response: any) => {
                this.updateCallResult(UserCallResult.Error);
                reject(response);
            });
        });
    }

    public postExternal<T>(parameters: IHttpPOSTProps): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const { url, token, data } = parameters;

            const startDate: number = Date.now();
            this.updateCallResult(UserCallResult.Calling);

            const options: AxiosRequestConfig = {
                headers: {
                    Authorization: token,
                },
                data,
                withCredentials: true,
            };

            axios
            .post(url, data, options)
            .then((response: any) => {
                const stopDate: number = Date.now();
                trackEvent(this._appInsights, url, "API", JSON.stringify(data), this._account!, {}, stopDate - startDate);
                this.updateCallResult(UserCallResult.Success);
                resolve(response as T);
            })
            .catch((response: any) => {
                this.updateCallResult(UserCallResult.Error);
                reject(response);
            });
        });
    }

    public getFromUI<T>(parameters: IHttpGETProps): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const { url, token, params } = parameters;

            const startDate: number = Date.now();
            this.updateCallResult(UserCallResult.Calling);

            const options: AxiosRequestConfig = {
                headers: {
                    Authorization: token,
                },
                params,
                withCredentials: true,
            };

            axios
            .get(String(process.env.REACT_APP_URL) + url, options)
            .then((response: any) => {
                const stopDate: number = Date.now();
                trackEvent(this._appInsights, url, "API", JSON.stringify(params), this._account!, {}, stopDate - startDate);
                this.updateCallResult(UserCallResult.Success);
                resolve(response as T);
            })
            .catch((response: any) => {
                this.updateCallResult(UserCallResult.Error);
                reject(response);
            });
        });
    }
}
