import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import * as React from "react";

// Msal Configurations
export const config: Configuration = {
    auth: {
        authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
        clientId: String(process.env.REACT_APP_CLIENT_ID) ?? "fc4e1c3d-b5be-443f-8c54-bdff6841d4c2",
        redirectUri: window.location.href ?? (process.env.REACT_APP_URL ?? "https://patchingserviceuifddev.azurefd.net/"),
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

// Authentication Parameters
export const authenticationParameters = {
    scopes: [
        "https://graph.microsoft.com/User.ReadBasic.All",
        "https://graph.microsoft.com/GroupMember.Read.All",
    ],
};

export const prodAuthProvider = new PublicClientApplication(config);
export const labauthenticationParameters = {
  scopes: ["${process.env.LAB_SCOPE}"],
};
