import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/utilities";
import { CheckboxVisibility, DetailsList,
    ScrollablePane, Spinner, TextField } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IOffboardingItem, IOffboardingRequest, IOffboardingResults } from "../../Pages/OffboardingPage";
import HttpService from "../../services/HttpService/HttpService";
import { breakWord } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { linuxOffboardingStepsText, UnauthorizedMessage } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";
import {
    convertAPIResultsToMap,
    IOffboardingAPIResult, IToBeOffboardedType, onRenderOffboardingExportRow,
    resultsColumns, toBeOffboardedcolumns } from "./OffboardingFunctions";
import { CSVLink } from "react-csv";

interface IAdvancedSearchPanelProps {
    maxOffboardingCount: number;
    allItems: IOffboardingItem[];
    refreshAndResetServerData: () => void;
}

enum PanelSection {
    Upload,
    Results,
}

export const AdvancedSearchPanel: React.FunctionComponent<IAdvancedSearchPanelProps> = (props: IAdvancedSearchPanelProps) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [dataGridData, SetDataGridData] = React.useState<IToBeOffboardedType[]>([]);
  const [listOfServersText, SetListOfServersText] = React.useState<string>("");
  const [justification, SetJustification] = React.useState<string>("");
  const { state } = React.useContext(RootContext); // auth token context
  const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Upload);
  const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>("Offboard");
  const [currentlyOffboarding, SetCurrentlyOffboarding] = React.useState<boolean>(true);
  const [offboardingResultsData, SetOffboardingResultsData] = React.useState<IOffboardingResults[]>([]);

  const appInsights = useAppInsights();

    // HttpService
  const [httpService] = React.useState(HttpService(appInsights, state));

  const exportCsvFileName = "offboardingResults";

  const offboardServers = () => {
        const allServersMap = new Map<string, IOffboardingResults>();

        // build offboarding request array
        const offboardingRequestArr: IOffboardingRequest[] = [];
        const serverIdServiceTreeGUIDMap: Map<string, number[]> = new Map();

        for (const d of dataGridData) {
            if (serverIdServiceTreeGUIDMap.has(d.ServiceTreeGUID)) {
                const serverIdArr: number[] = serverIdServiceTreeGUIDMap.get(d.ServiceTreeGUID) ?? [];
                serverIdArr.push(d.ServerId);
                serverIdServiceTreeGUIDMap.set(d.ServiceTreeGUID, serverIdArr);
            } else {
                const serverIdArr: number[] = [d.ServerId];
                serverIdServiceTreeGUIDMap.set(d.ServiceTreeGUID, serverIdArr);
            }

            allServersMap.set(d.ServerName, {
                server: d.ServerName,
                remarks: "Success.",
            });
        }

        for (const key of serverIdServiceTreeGUIDMap.keys()) {
            offboardingRequestArr.push({
                ServerIDs: serverIdServiceTreeGUIDMap.get(key) ?? [],
                OffboardingRemark: justification,
                ServiceTreeGUID: key,
            });
        }

        httpService.post({
            url: "api/ServerOffboarding/removeServers",
            token: state.AuthStore.Token,
            data: offboardingRequestArr,
        }).then(async (resp: any) =>  {
            const respResults: IOffboardingAPIResult[] = resp?.data;
            const trackingString: Set<string> = new Set<string>();

            for (let i = 0; i < respResults.length; ++i) {
                trackingString.add(respResults[i].ServerName + ", " + respResults[i].Remarks);
            }

            trackEvent(appInsights, "Offboarding", "Offboarding Results", Array.from(trackingString).join(","), state.AuthStore, {});

            await convertAPIResultsToMap(props.allItems, respResults, allServersMap);
            await updateResults(allServersMap);
        }).catch((reason: any) => {
            const errorResults: IOffboardingResults = {
                server: "",
                remarks: UnauthorizedMessage,
            };
            SetOffboardingResultsData([errorResults]);
            SetCurrentlyOffboarding(false);
            trackException(appInsights, reason, SeverityLevel.Error, "Offboarding", "Removing Servers", "Advanced Search", state.AuthStore, {});
        });
    };

    // SUCCESS
    // {"IsMultipleRequests":false,
    // "Requests":[{"Id":3135510,"AccountType":"Group","AccountName":"*","Action":"Modify","Domain":"redmond","NewAccountName":null,"ObjectTypeId":1,"ObjectSubTypeId":5,"Approver":"Approval Policy",
    // "Requestor":{"EmailName":"8016a280-94df-4fe3-9105-be50686c163e","FirstName":null,"LastName":null,"ManagerEmailName":null,"ManagerFirstName":null,"ManagerLastName":null,"AddressBookTitle":null,"CostCenter":null,"OrgBusiness":null,"PersonStatusCode":null},
    // "BusinessJustification":"remove-from-sg","DateCreated":"2021-05-12T19:35:39.733","Result":"Approved","LastProcStep":11,"RequestStatusId":4,"ApproveDenyBy":"Approval Policy","ApproveDenyNote":"Approval policy has been satisfied for request","IsApproved":true,
    // "RequestDetails":[{"Id":11456122,"RequestId":3135510,"Property":"Member","OldValue":"redmond\\dmx-71$","NewValue":null,
    // "Person":{"EmailName":"redmond\\dmx-71$","FirstName":null,"LastName":null,"ManagerEmailName":null,"ManagerFirstName":null,"ManagerLastName":null,"AddressBookTitle":null,"CostCenter":null,"OrgBusiness":null,"PersonStatusCode":null}}],"Approval":null}],
    // "Failed":false,"FailedActiveDirectory":false,"ErrorMessage":null,"Applied":false,"Queued":true,"IsValidationPassed":false,"PasswordLink":null,"Warnings":null,
    // "InvalidMembers":[]}

    // FAILED
    // {"IsMultipleRequests":false,"Requests":null,"Failed":false,"FailedActiveDirectory":false,
    // "ErrorMessage":"No changes submitted","Applied":false,"Queued":false,
    // "IsValidationPassed":false,"PasswordLink":null,"Warnings":null,
    // "InvalidMembers":[{"Message":"Invalid Remove Members","Members":["amn-dev1$","ankshett-dev$"]}]
    // }

  const updateResults = async (allServersMap: Map<string, IOffboardingResults>) => {
        const allServers: IOffboardingResults[] = [];

        for (const s of allServersMap.values()) {
            allServers.push(s);
        }

        SetOffboardingResultsData(allServers);
        SetCurrentlyOffboarding(false);
    };

  const offboardServersAdvanced = () => {
        SetCurrentPanel(PanelSection.Results);
        SetCurrentlyOffboarding(true);
        SetPrimaryButtonText("Close");

        offboardServers();
    };

  const clickToOpenPanel = () => {
        SetJustification("");
        SetDataGridData([]);
        SetPrimaryButtonText("Offboard");
        SetCurrentPanel(PanelSection.Upload);
        SetListOfServersText("");

        openPanel();
    };

    // Advanced Search by Server Name
  const advancedSearchServerName = (serversText: string) => {
        /*
            take in string
            remove all spaces, semicolons, and new lines with commas
            split by commas
            filter by new array of servers
        */

        const serversByComma: string[] = serversText.replaceAll(" ", ",").replaceAll(";", ",").replaceAll("\n", ",").split(",");
        let searchResults: IToBeOffboardedType[] = [];

        for (const server of serversByComma) {
            // remove white space
            const serverNoWhiteSpace = server.trim();
            const data = serverNoWhiteSpace ? props.allItems.filter((i) => i.server?.toString().toLowerCase().indexOf(serverNoWhiteSpace.toString().toLowerCase()) > -1) : [];

            // data is almost always length of 1, will not be n^2
            for (const d of data) {
                searchResults = [...searchResults, {
                    ServerId: d.key,
                    ServerName: d.server,
                    Domain: d.domain,
                    ServiceTreeGUID: d.serviceTreeGUID,
                }];
            }
        }

        SetDataGridData(searchResults);
    };

  return (
      <div>
        <DefaultButton
            secondaryText="Query Builder"
            ariaLabel="Query Builder"
            onClick={clickToOpenPanel}
            text="Query Builder"
        />
        <CoherencePanel
            titleText="Offboard Servers - Query Builder"
            title="Offboard Servers - Query Builder"
            headerText="Offboard Servers - Query Builder"
            isOpen={isOpen}
            onDismiss={dismissPanel}
            hasCloseButton={true}
            closeButtonAriaLabel="Close"
            onRenderFooter={{
                primaryButton: {
                    text: primaryButtonText,
                    onAction: (() => {
                        if (primaryButtonText == "Offboard") {
                            offboardServersAdvanced();
                        } else {
                            if (!currentlyOffboarding) {
                                props.refreshAndResetServerData();
                                dismissPanel();
                            }
                        }
                    }),
                    disabled: (justification?.length === 0 || dataGridData?.length === 0),
                },
            }}
            panelSize={CoherencePanelSize.medium}
        >
        <main aria-live="polite">
            <div hidden={currentPanel !== PanelSection.Upload}>
                <TextField
                    label="Enter a list of servers by server name (not FQDN) delimited by comma, semicolon, space, or new line."
                    multiline
                    autoAdjustHeight
                    value={listOfServersText}
                    onChange={onListOfServersTextChange}
                    ariaLabel="Enter a list of servers by server name (not FQDN) delimited by comma, semicolon, space, or new line."
                />
                <p>*Please limit list to {props.maxOffboardingCount} values or fewer.</p>
                <PrimaryButton
                    text="Search"
                    label="Search"
                    aria-label="Search"
                    onClick={() => advancedSearchServerName(listOfServersText)}
                />
                <div style={{ position: "relative", height: "75%" }}>
                    <ScrollablePane style={{ height: "350px", position: "relative" }}>
                        <DetailsList
                            columns={toBeOffboardedcolumns}
                            items={dataGridData}
                            compact={true}
                            onRenderDetailsHeader={RenderStickyHeader}
                            checkboxVisibility={CheckboxVisibility.hidden}
                        />
                    </ScrollablePane>
                </div>
                <span className={breakWord}>{ReactHtmlParser(linuxOffboardingStepsText)}</span>
                <br />
                <div>
                <TextField
                    value={justification}
                    required
                    label="Business Justification"
                    ariaLabel="Business Justification"
                    onChange={onJustificationChange}
                />
                </div>
            </div>
            <div hidden={currentPanel !== PanelSection.Results}>
                <h3>Offboarding Results</h3>
                <div hidden={!currentlyOffboarding}>
                    <Spinner label="Offboarding Servers..." ariaLive="assertive" labelPosition="bottom" />
                </div>
                <div hidden={currentlyOffboarding}>
                    <div style={{ position: "relative", height: "75%" }}>
                        <ScrollablePane style={{ height: "350px", position: "relative" }}>
                            <DetailsList
                                columns={resultsColumns}
                                items={offboardingResultsData}
                                compact={true}
                                onRenderDetailsHeader={RenderStickyHeader}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                onRenderRow={onRenderOffboardingExportRow}
                            />
                        </ScrollablePane>
                    </div>
                    <CSVLink 
                        aria-label={"Export Data"} 
                        filename={exportCsvFileName} 
                        title={"Export Data"} 
                        data={offboardingResultsData}>
                            Export Data
                    </CSVLink>
                </div>
            </div>
        </main>
        </CoherencePanel>
    </div>
    );

  function onJustificationChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        SetJustification(newValue!);
    }

  function onListOfServersTextChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        SetListOfServersText(newValue!);
    }
};
