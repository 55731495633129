import {
    css, DirectionalHint, IconButton,
    IContextualMenuItem, TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import ListPageStyles from "./ListPage.styles";

interface IOwnProps {
    /** Actions/Controls to show all the time */
    visibleActions?: React.ReactNode[];
    /** Collection of actions to show in an overflow set */
    menuActions?: IContextualMenuItem[];
}

export class ListItemActionSet extends React.Component<IOwnProps, {}> {

    public render() {
        const alwaysVisibleActions = this.renderAlwaysVisibleActions();
        const overflowActions = this.renderOverflowActions();
        if (alwaysVisibleActions || overflowActions) {
            return (
                <span className={css(ListPageStyles.listPageListItemActionsContainer)}>
                    {alwaysVisibleActions}
                    {overflowActions}
                </span>
            );
        } else {
            return null;
        }
    }

    private renderAlwaysVisibleActions() {
        if (this.props.visibleActions && this.props.visibleActions.length > 0) {
            return this.props.visibleActions;
        } else {
            return null;
        }
    }

    private renderOverflowActions() {
        if (this.props.menuActions && this.props.menuActions.length > 0) {
            return (
                <TooltipHost
                    content={"More"}
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 }}
                >
                    <IconButton
                        className={css(ListPageStyles.listPageListItemActionIcon, ListPageStyles.listPageListItemActionsRoot)}
                        ariaLabel={"More actions"}
                        menuIconProps={{ iconName: "MoreVertical" }}
                        menuProps={{ items: this.props.menuActions }}
                    />
                </TooltipHost>
            );
        } else {
            return null;
        }
    }
}
