import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { CheckboxVisibility, DetailsList, IColumn,
    ScrollablePane, Spinner, TextField } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { CSVLink } from "react-csv";
import ReactHtmlParser from "react-html-parser";
import { CSVReader } from "react-papaparse";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IOffboardingItem, IOffboardingRequest, IOffboardingResults } from "../../Pages/OffboardingPage";
import HttpService from "../../services/HttpService/HttpService";
import { breakWord } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { linuxOffboardingStepsText, ServerNotFoundText } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";
import {
    convertAPIResultsToMap,
    IOffboardingAPIResult, IToBeOffboardedType, onRenderOffboardingExportRow,resultsColumns
} from "./OffboardingFunctions";

interface IBulkOffboardingProps {
    maxOffboardingCount: number;
    allItems: IOffboardingItem[];
    refreshAndResetServerData: () => void;
}

interface IBulkUploadResults {
    FQDNorServerID: string;
}

const bulkdOffboardUploadColumns: IColumn[] = [
    {
        key: "FQDNorServerID",
        name: "FQDN or ServerID",
        fieldName: "FQDNorServerID",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
];

enum PanelSection {
    Upload,
    Results,
}

export const BulkOffboardPanel: React.FunctionComponent<IBulkOffboardingProps> = (props: IBulkOffboardingProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [dataGridData, SetDataGridData] = React.useState<IToBeOffboardedType[]>([]);
    const [bulkUploadResults, SetBulkUploadResults] = React.useState<IBulkUploadResults[]>([]);
    const [justification, SetJustification] = React.useState<string>("");
    const { state } = React.useContext(RootContext);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Upload);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>("Offboard");
    const [currentlyOffboarding, SetCurrentlyOffboarding] = React.useState<boolean>(true);
    const [offboardingResultsData, SetOffboardingResultsData] = React.useState<IOffboardingResults[]>([]);
    const [showIncorrectFileTypeMessage, SetShowIncorrectFileTypeMessage] = React.useState<boolean>(false);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    const csvFileName = "serversToOffboard.csv";
    const exportCsvFileName = "offboardingResults";
    const csvHeaders = [
        { label: "FQDN or ServerID", key: "FQDNorServerID" },
    ];
    const csvData = [{
        FQDN: "",
    }];

    function uploadCSVData(data: any[], file: any) {
        SetShowIncorrectFileTypeMessage(false);

        if (file.name.split(".").slice(-1)[0].toLowerCase() !== "csv") {
            SetShowIncorrectFileTypeMessage(true);
            trackException(appInsights, new Error(), SeverityLevel.Warning, "Offboarding", "Upload CSV", "User attempted to upload non-csv file for Bulk Add Jobs", state.AuthStore, {});
            return;
        }

        const newServerData: IToBeOffboardedType[] = [];
        const uploadedResultsData: IBulkUploadResults[] = [];

        for (let i = 0; i < data.length; i++) {
            // ignore title and last line
            if (data[i].data[0].toString().toLowerCase() !== "fqdn or serverid") {
                let serverNameFromCSV: string = "";
                let domainFromCSV: string = "";
                let serverIdFromCSV: number = 0;

                if (data[i].data[0].split(".").length > 1) {
                    serverNameFromCSV = data[i].data[0].substring(0, data[i].data[0].indexOf("."));
                    domainFromCSV = data[i].data[0].substring(data[i].data[0].indexOf(".") + 1, data[i].data[0].length);
                } else if (data[i].data[0].length === 9) { // ServerIDs are 9 chars in length
                    serverIdFromCSV = parseInt(data[i].data[0]);
                }
                if (serverNameFromCSV !== "" || serverIdFromCSV !== 0) {
                    newServerData.push({
                        ServerName: serverNameFromCSV,
                        Domain: domainFromCSV,
                        ServerId: serverIdFromCSV,
                        ServiceTreeGUID: "", // XXX not in CSV
                    });
                    uploadedResultsData.push({
                        FQDNorServerID: data[i].data[0] === "" ? parseInt(data[i].data[0]) : data[i].data[0],
                    });
                }
            }
        }

        SetDataGridData(newServerData);
        SetBulkUploadResults(uploadedResultsData);
    }

    function csvError(data: any) {
        console.log(data);
    }

    // File selection dialog
    const buttonRef: React.RefObject<any> = React.createRef();
    const handleOpenDialog = (e: any) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const offboardServers = async () => {
        const allServersMap = new Map<string, IOffboardingResults>();
        const serverIdServiceTreeGUIDMap: Map<string, number[]> = new Map();

        // collect serverID by FQDN
        // loop onboarded servers
        for (const d of dataGridData) {
            if (d.ServerName !== "") {
                const serverItem: IOffboardingItem = props.allItems.filter((x: IOffboardingItem) => x.server.toLowerCase() === d.ServerName.toLowerCase())[0];

                // check if user submitted a server Name // only attempt to offboard servers if they are onboarded
                if (serverItem) {
                    if (serverIdServiceTreeGUIDMap.has(serverItem.serviceTreeGUID)) {
                        const serverIdArr: number[] = serverIdServiceTreeGUIDMap.get(serverItem.serviceTreeGUID) ?? [];
                        serverIdArr.push(serverItem.serverID);
                        serverIdServiceTreeGUIDMap.set(serverItem.serviceTreeGUID, serverIdArr);
                    } else {
                        const serverIdArr: number[] = [serverItem.serverID];
                        serverIdServiceTreeGUIDMap.set(serverItem.serviceTreeGUID, serverIdArr);
                    }

                    allServersMap.set(d.ServerName, {
                        server: d.ServerName,
                        remarks: "Success.",
                    });
                } else { // if Server Name is not found, let's check to see if their serverID is onboarded
                    const filterForServerId: IOffboardingItem = props.allItems.filter((x: IOffboardingItem) => x.serverID == d.ServerId)[0];

                    if (filterForServerId) {
                        if (serverIdServiceTreeGUIDMap.has(filterForServerId.serviceTreeGUID)) {
                            const serverIdArr: number[] = serverIdServiceTreeGUIDMap.get(filterForServerId.serviceTreeGUID) ?? [];
                            serverIdArr.push(filterForServerId.serverID);
                            serverIdServiceTreeGUIDMap.set(filterForServerId.serviceTreeGUID, serverIdArr);
                        } else {
                            const serverIdArr: number[] = [filterForServerId.serverID];
                            serverIdServiceTreeGUIDMap.set(filterForServerId.serviceTreeGUID, serverIdArr);
                        }

                        allServersMap.set(d.ServerId.toString(), {
                            server: d.ServerId.toString(),
                            remarks: "Success.",
                        });
                    } else { // neither server name nor server id are provided
                        allServersMap.set(d.ServerName, {
                            server: d.ServerName,
                            remarks: ServerNotFoundText,
                        });
                    }
                }
            } else { // if user submitted a server ID
                const filterForServerId: IOffboardingItem = props.allItems.filter((x: IOffboardingItem) => x.serverID == d.ServerId)[0];

                if (filterForServerId) {
                    if (serverIdServiceTreeGUIDMap.has(filterForServerId.serviceTreeGUID)) {
                        const serverIdArr: number[] = serverIdServiceTreeGUIDMap.get(filterForServerId.serviceTreeGUID) ?? [];
                        serverIdArr.push(filterForServerId.serverID);
                        serverIdServiceTreeGUIDMap.set(filterForServerId.serviceTreeGUID, serverIdArr);
                    } else {
                        const serverIdArr: number[] = [filterForServerId.serverID];
                        serverIdServiceTreeGUIDMap.set(filterForServerId.serviceTreeGUID, serverIdArr);
                    }

                    allServersMap.set(d.ServerId.toString(), {
                        server: d.ServerId.toString(),
                        remarks: "Success.",
                    });
                } else { // server name is empty and server id is either empty or is not onboarded
                    allServersMap.set(d.ServerId.toString(), {
                        server: d.ServerId.toString(),
                        remarks: ServerNotFoundText,
                    });
                }
            }
        }

        // build offboarding request array
        const offboardingRequestArr: IOffboardingRequest[] = [];

        for (const key of serverIdServiceTreeGUIDMap.keys()) {
            offboardingRequestArr.push({
                ServerIDs: serverIdServiceTreeGUIDMap.get(key) ?? [],
                OffboardingRemark: justification,
                ServiceTreeGUID: key,
            });
        }

        httpService.post({
            url: "api/ServerOffboarding/removeServers",
            token: state.AuthStore.Token,
            data: offboardingRequestArr,
        }).then(async (removeServersResponse: any) =>  {
            if (removeServersResponse?.status?.toString() === "200") {
                const respResults: IOffboardingAPIResult[] = removeServersResponse.data;
                const trackingString: Set<string> = new Set<string>();

                for (let i = 0; i < respResults.length; ++i) {
                    trackingString.add(respResults[i].ServerName + ", " + respResults[i].Remarks);
                }

                trackEvent(appInsights, "Offboarding", "Offboarding Results", Array.from(trackingString).join(","), state.AuthStore, {});

                await convertAPIResultsToMap(props.allItems, respResults, allServersMap);
                await updateResults(allServersMap);
            } else {
                trackException(appInsights, new Error(), SeverityLevel.Warning, "Offboarding", "Removing Servers", "Bulk Offboarding : " + removeServersResponse?.toString(), state.AuthStore, {});
                SetOffboardingResultsData([]);
                SetCurrentlyOffboarding(false);
            }
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Offboarding", "Removing Servers", "Bulk Offboarding", state.AuthStore, {});
            SetOffboardingResultsData([]);
            SetCurrentlyOffboarding(false);
        });
    };

    const updateResults = async (allServersMap: Map<string, IOffboardingResults>) => {
        const allServers: IOffboardingResults[] = [];

        for (const s of allServersMap.values()) {
            allServers.push(s);
        }

        SetOffboardingResultsData(allServers);
        SetCurrentlyOffboarding(false);
    };

    const offboardServersBulk = () => {
        SetCurrentPanel(PanelSection.Results);
        SetCurrentlyOffboarding(true);
        SetPrimaryButtonText("Close");

        offboardServers();
    };

    const clickToOpenPanel = () => {
        SetJustification("");
        SetDataGridData([]);
        SetBulkUploadResults([]);
        SetPrimaryButtonText("Offboard");
        SetCurrentPanel(PanelSection.Upload);
        openPanel();
    };

    const clickToClosePanel = () => {
        props.refreshAndResetServerData();
        dismissPanel();
    };

    return (
      <div>
        <DefaultButton
            secondaryText="Bulk Offboard Servers"
            ariaLabel="Bulk Offboard Servers"
            onClick={clickToOpenPanel}
            text="Bulk Offboard"
        />
        <CoherencePanel
            titleText="Bulk Offboard Servers"
            title="Bulk Offboard Servers"
            headerText="Bulk Offboard Servers"
            isOpen={isOpen}
            hasCloseButton={true}
            onDismiss={clickToClosePanel}
            onLightDismissClick={clickToClosePanel}
            closeButtonAriaLabel="Close"
            onRenderFooter={{
                primaryButton: {
                    text: primaryButtonText,
                    onAction: (() => {
                        if (primaryButtonText === "Offboard") {
                            offboardServersBulk();
                        } else {
                            if (!currentlyOffboarding) {
                                clickToClosePanel();
                            }
                        }
                    }),
                    disabled: (justification?.length === 0 || dataGridData?.length === 0),
                },
            }}
            panelSize={CoherencePanelSize.large}
        >
        <main>
            <div hidden={currentPanel !== PanelSection.Upload}>
                <h3>Upload Server Data for Offboarding</h3>
                <ol>
                    <li className={breakWord}>Download pre-formated <CSVLink aria-label={"Click here to download " + csvFileName} filename={csvFileName} title={"Click here to download " + csvFileName} data={csvData} headers={csvHeaders}>{csvFileName}</CSVLink> file.</li>
                    <li className={breakWord}>Fill out each row with full FQDN (i.e. SERVER.DOMAIN.corp.microsoft.com) or a Server ID.<ul><li><b>Please limit data to {props.maxOffboardingCount} entries or fewer.</b></li></ul></li>
                    <li>Upload completed CSV file</li>
                </ol>
                <div hidden={!showIncorrectFileTypeMessage}>
                    <b className={breakWord}>Only CSV files are acceptable.</b>
                </div>
                <CSVReader
                    ref={buttonRef}
                    onFileLoad={uploadCSVData}
                    onError={csvError}
                    noClick
                    noDrag
                    >
                    {({ file }: any) => (
                        <aside
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 10,
                        }}
                        >
                        <PrimaryButton onClick={handleOpenDialog}>
                            Browse
                        </PrimaryButton>
                        <div
                            style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#ccc",
                                lineHeight: 2,
                                marginTop: 0,
                                marginBottom: 0,
                                paddingLeft: 13,
                                paddingTop: 0,
                                width: "60%",
                            }}
                        >
                            {file && file.name}
                        </div>
                        </aside>
                    )}
                </CSVReader>
                <div style={{ position: "relative", height: "75%" }}>
                    <ScrollablePane style={{ height: "350px", position: "relative" }}>
                        <DetailsList
                            columns={bulkdOffboardUploadColumns}
                            items={bulkUploadResults}
                            compact={true}
                            onRenderDetailsHeader={RenderStickyHeader}
                            checkboxVisibility={CheckboxVisibility.hidden}
                        />
                    </ScrollablePane>
                </div>
                <span className={breakWord}>{ReactHtmlParser(linuxOffboardingStepsText)}</span>
                <br />
                <div>
                <TextField
                    value={justification}
                    required
                    label="Business Justification"
                    ariaLabel="Business Justification"
                    onChange={onJustificationChange}
                />
                </div>
            </div>
            <div hidden={currentPanel !== PanelSection.Results}>
                <h3>Offboarding Results</h3>
                <div hidden={!currentlyOffboarding}>
                    <Spinner label="Offboarding Servers..." ariaLive="assertive" labelPosition="bottom" />
                </div>
                <div hidden={currentlyOffboarding}>
                    <div style={{ position: "relative", height: "75%" }}>
                        <ScrollablePane style={{ height: "350px", position: "relative" }}>
                            <DetailsList
                                columns={resultsColumns}
                                items={offboardingResultsData}
                                compact={true}
                                onRenderDetailsHeader={RenderStickyHeader}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                onRenderRow={onRenderOffboardingExportRow}
                            />
                        </ScrollablePane>
                    </div>
                    <CSVLink 
                        aria-label={"Export Data"} 
                        filename={exportCsvFileName} 
                        title={"Export Data"} 
                        data={offboardingResultsData}>
                            Export Data
                    </CSVLink>
                </div>
            </div>
        </main>
        </CoherencePanel>
    </div>
    );

    function onJustificationChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        SetJustification(newValue!);
    }
};
