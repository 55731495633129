import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { DetailsList, FontIcon, IColumn, Label, Link, mergeStyles, ScrollablePane, Selection, 
    SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Guid } from "guid-typescript";
import * as React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import HttpService from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { IDetailsListManageJobsItem } from "../ManageJobs/DetailsListManageJobs";
import { UnauthorizedMessage } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";

const closeText = "Close";

export interface AddServersToJobsPanelProps {
    newServer: AddServersToJobsPanelType;
    buttonText: string;
}

export interface AddServersToJobsPanelType {
    ServerId: number;
    ServerName: string;
    ServiceName: string;
    ServiceTreeGUID: string;
}

export interface IPatchingServerRequest {
    JobId: Guid;
    JobName: string;
    ServerId: number;
    ServerName: string;
    ServiceName: string;
    ServiceTreeGUID: string;
}

export interface IPatchingServerResponse {
    JobId: Guid;
    JobName: string;
    ServerId: number;
    ServerName: string;
    ServiceName: string;
    ServiceTreeGUID: string;
    ResponseMessage: string;
    Success: boolean;
}

enum PanelSection {
    JobAssignment,
    Results,
}

export const AddServersToJobsPanel: React.FunctionComponent<AddServersToJobsPanelProps> = (props: AddServersToJobsPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.JobAssignment);
    const [loadingJobs, SetLoadingJobs] = React.useState<boolean>(true);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>("Submit");
    const [dataGridData, SetDataGridData] = React.useState<IDetailsListManageJobsItem[]>([]);
    const [enableSubmitButton, SetEnableSubmitButton] = React.useState<boolean>(false);
    const [enableCloseButton, SetEnableCloseButton] = React.useState<boolean>(true);
    const [currentlyAddingServers, SetCurrentlyAddingServers] = React.useState<boolean>(false);
    const [addServersResultData, SetAddServersResultData] = React.useState<IPatchingServerResponse[]>([]);
    
    const [selection] = React.useState(new Selection({
        onSelectionChanged: () => {
            selection.getSelectedIndices().length > 0 ? SetEnableSubmitButton(true) : SetEnableSubmitButton(false)
        },
    }));
    
    const { state } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService, SetHttpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        // Collect jobs from service
        queryJobs();
        selection.setItems([]); // reset selection
    }, [isOpen]);

    const clickToOpenPanel = () => {
        SetCurrentPanel(PanelSection.JobAssignment);
        openPanel();
    };

    const clickToClosePanel = () => {
        SetCurrentPanel(PanelSection.JobAssignment);
        dismissPanel();
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    const addServersToPatchingJobs = (newServers: IPatchingServerRequest[]) => {
        return httpService.post({
            url: "api/PatchingJob/addServers",
            token: state.AuthStore.Token,
            data: newServers,
        });
    }

    const addServerToJobs = () => {
        SetCurrentPanel(PanelSection.Results);

        const newServers: IPatchingServerRequest[] = [];

        for (let i = 0; i < selection.getSelectedCount(); i++) {
            const myjob: IDetailsListManageJobsItem = selection.getSelection()[i] as IDetailsListManageJobsItem;

            const jobId: Guid = myjob.jobId;
            const jobName: string = myjob.jobName;

            newServers.push({
                JobId: jobId,
                JobName: jobName,
                ServerId: props.newServer.ServerId,
                ServerName: props.newServer.ServerName,
                ServiceName: props.newServer.ServiceName,
                ServiceTreeGUID: props.newServer.ServiceTreeGUID
            });
        }

        SetCurrentlyAddingServers(true);
        SetEnableCloseButton(false);
        addServersToPatchingJobs(newServers)
        .then((response: any) => {
            if (response?.data !== UnauthorizedMessage) {
                const data: any = response?.data;
                const responseData:  IPatchingServerResponse[] = [];
                for (const d of data) {
                    responseData.push({
                        JobId: Guid.parse(d.jobId),
                        JobName: d.jobName,
                        ServerId: d.serverId,
                        ServerName: d.serverName,
                        ServiceName: d.serviceName,
                        ServiceTreeGUID: d.serviceTreeGUID,
                        ResponseMessage: d.responseMessage,
                        Success: d.success
                    });
                }

                SetAddServersResultData(responseData);
            }
            SetCurrentlyAddingServers(false);
            SetEnableCloseButton(true);
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Onboarding", "Update Patching Job", "add servers to patching job(s)", state.AuthStore, {});
            SetCurrentlyAddingServers(false);
            SetEnableCloseButton(true);
        });
    }

    const queryJobs = () => {
        SetLoadingJobs(true);
        
        const d = new Date();
        const timezoneOffset: number = (state.AuthStore.timezone?.offset !== undefined && state.AuthStore.timezone?.standardName !== null) 
                                ? - state.AuthStore.timezone?.offset 
                                : d.getTimezoneOffset();
        
        httpService.get({
            url: "api/PatchingJob",
            token: state.AuthStore.Token,
            params: {
                offset: timezoneOffset,
                Service: props.newServer.ServiceTreeGUID,
                timezone: state.AuthStore.timezone?.standardName
            },
        }).then((resp: any) => {
            const data: any = resp?.data;
            const jobs: IDetailsListManageJobsItem[] = [];

            for (const d of data) {
                jobs.push({
                    eTag: d.eTag,
                    jobId: d.jobId as Guid,
                    jobName: d.jobName,
                    dayOfTheWeek: d.dayOfTheWeek,
                    weekOfTheMonth: d.weekOfTheMonth,
                    startTimeHours: d.startTimeHours ?? 0,
                    startTimeMinutes: d.startTimeMinutes ?? 0,
                    duration: d.duration,
                    recurring: Boolean(d.recurring),
                    reboot: Boolean(d.reboot),
                    alwaysReboot: Boolean(d.alwaysReboot),
                    serviceName: d.serviceName,
                    serviceTreeGUID: d.serviceTreeGUID,
                    serversCount: d.patchingServerCount,
                    isAllServersSelected: d.isAllServerSelected,
                    lastScheduledDateTime: new Date(d.lastScheduledDateTime),
                    nextScheduledDateTime: new Date(d.nextScheduledDateTime),
                });
            }

            if (jobs.length == 0) {
                jobs.push({
                    eTag: "",
                    jobId: Guid.createEmpty(),
                    jobName: "This service has no jobs, please visit the Manage Jobs page to create one.",
                    dayOfTheWeek: "",
                    weekOfTheMonth: 1,
                    startTimeHours: 0,
                    startTimeMinutes: 0,
                    duration: "",
                    recurring: false,
                    reboot: false,
                    alwaysReboot: false,
                    serviceName: "",
                    serviceTreeGUID: "",
                    serversCount: 0,
                    isAllServersSelected: false,
                    lastScheduledDateTime: new Date(),
                    nextScheduledDateTime: new Date(),
                });
            }

            SetDataGridData(jobs);
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Onboarding", "Update Patching Job", "query for available jobs", state.AuthStore, {});

            const somethingWentWrongJobs: IDetailsListManageJobsItem[] = [];
            
            somethingWentWrongJobs.push({
                eTag: "",
                jobId: Guid.createEmpty(),
                jobName: "Something Went Wrong, please reload panel",
                dayOfTheWeek: "",
                weekOfTheMonth: 1,
                startTimeHours: 0,
                startTimeMinutes: 0,
                duration: "",
                recurring: false,
                reboot: false,
                alwaysReboot: false,
                serviceName: "",
                serviceTreeGUID: "",
                serversCount: 0,
                isAllServersSelected: false,
                lastScheduledDateTime: new Date(),
                nextScheduledDateTime: new Date(),
            });

            SetDataGridData([]);
        }).finally(() => {
            SetLoadingJobs(false);
        });
    }

    const columns: IColumn[] = [
        {
            key: "jobName",
            name: "Job Name",
            fieldName: "jobName",
            minWidth: 70,
            maxWidth: 250,
            isResizable: true,
        },
        {
            key: "isAllServersSelected",
            name: "Server Automatically Included",
            fieldName: "isAllServersSelected",
            minWidth: 70,
            maxWidth: 250,
            isResizable: true,
        },
    ];

    const resultColumns: IColumn[] = [
        {
            key: "jobName",
            name: "Job Name",
            fieldName: "JobName",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
        },
        {
            key: "serverName",
            name: "Server Name",
            fieldName: "ServerName",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
        },
        {
            key: "success",
            name: "Result",
            fieldName: "Success",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
        }
    ];

    const onRenderResultItemColumn = (item?: IPatchingServerResponse, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IPatchingServerResponse] as any | any[];
            switch (column.fieldName) {
                
                case "Success" : {
                    return <div> {
                        fieldContent   
                        ? ( <span><FontIcon aria-label="Success" iconName="StatusCircleCheckmark" className={mergeStyles({ color: "green" })} /> Success </span>  ) 
                        : (<span> <FontIcon aria-label="Failed" iconName="StatusCircleErrorX" className={mergeStyles({ color: "red" })} /> Failed </span>  )
                        }
                        </div>;
                }
                default:
                    return <span>{fieldContent}</span>;
            }
        }
    };

    return (
        <div>
            <Link onClick={clickToOpenPanel}>
                {props.buttonText}
            </Link>
            {isOpen && 
                <CoherencePanel
                    titleText="Add Server to Patching Job"
                    title="Add Server to Patching Job"
                    headerText="Add Server to Patching Job"
                    isOpen={isOpen}
                    hasCloseButton={true}
                    onDismiss={clickToClosePanel}
                    onLightDismissClick={lightDismissPanel}
                    closeButtonAriaLabel="Close"
                    onRenderFooter={{
                        primaryButton: {
                            text: primaryButtonText,
                            onAction: () => currentPanel == PanelSection.Results ? clickToClosePanel() : addServerToJobs(),
                            disabled: (currentPanel == PanelSection.Results || !enableSubmitButton),
                        },
                        secondaryButton: {
                            text: closeText,
                            onAction: () => clickToClosePanel(),
                            disabled: (!enableCloseButton)
                        },
                    }}
                    panelSize={CoherencePanelSize.medium}
                >
                    <main>
                        <div hidden={currentPanel !== PanelSection.JobAssignment}>
                            <div>
                                <Label>Server: </Label> {props.newServer.ServerName}
                                <Label>Service: </Label> {props.newServer.ServiceName}
                            </div>
                            <div>
                                <h3>Add server to selected Patching jobs</h3>
                            </div>
                            {!loadingJobs ? ( 
                                <div style={{ position: "relative", height: "75%" }}>
                                    <ScrollablePane style={{ height: "400px", position: "relative" }}>
                                        <DetailsList
                                            columns={columns}
                                            items={dataGridData}
                                            compact={true}
                                            selection={selection}
                                            selectionMode={SelectionMode.multiple}
                                            onRenderDetailsHeader={RenderStickyHeader}
                                        />
                                    </ScrollablePane>
                                </div>
                            ) : (
                                <Spinner 
                                    size={SpinnerSize.large} 
                                    ariaLive="assertive" 
                                />
                            )}
                        </div>
                        <div hidden={currentPanel !== PanelSection.Results}>
                            {!currentlyAddingServers ? (
                                <div>
                                    <h3>Job Assignment Results</h3>
                                    <ScrollablePane style={{ height: "400px", position: "relative" }}>
                                        <DetailsList
                                            columns={resultColumns}
                                            items={addServersResultData}
                                            compact={true}
                                            selectionMode={SelectionMode.none}
                                            onRenderDetailsHeader={RenderStickyHeader}
                                            onRenderItemColumn={onRenderResultItemColumn}
                                        />
                                    </ScrollablePane>
                                </div>
                            ) : (
                                <Spinner label="Adding servers to jobs..." ariaLive="assertive" labelPosition="bottom" />
                            )}
                        </div>
                    </main>
                </CoherencePanel>
            }
        </div>
    );
};
