import { Announced, Pivot, PivotItem, Selection, mergeStyles } from "@fluentui/react";
import React, { Dispatch, SetStateAction } from "react";
import { IOnboardingDataType } from "../../Pages/OnboardingPage";
import { useAppInsights } from "../AppInsights/AppInsights";
import { RootContext } from "../Stores/RootStore";
import { DetailsListAvailableServers } from "./DetailsListAvailableServers";
import { DetailsListOutOfScope } from "./DetailsListOutOfScopeServers";
import { ServerForOnboarding, classNames } from "./OnboardingModels";
import { ServiceSelectionDropdown } from "./ServiceSelectionDropdown";
import { OnboardingStep } from "./OnboardingComponents";

interface ISelectServersComponentProps {
    SetCurrentStep: Dispatch<SetStateAction<OnboardingStep>>;
    SetServerData: Dispatch<SetStateAction<IOnboardingDataType[]>>;
}

export const OnboardingSelectServersComponent: React.FunctionComponent<ISelectServersComponentProps> = (props: ISelectServersComponentProps) => {
    const [selection, setSelection] = React.useState<Selection<ServerForOnboarding>>();
    const [items, SetItems] = React.useState<ServerForOnboarding[]>([]);
    const [invaliditems, SetInvalidItems] = React.useState<ServerForOnboarding[]>([]);
    const [selectedServiceName, setSelectedServiceName] = React.useState<string>();
    const [selectedServiceGuid, setSelectedServiceGuid] = React.useState<string>();
    const [serversLoaded, SetServersLoaded] = React.useState<boolean>(false);

    const rootContext = React.useContext(RootContext);

    const selectionChanged = (selection: Selection<ServerForOnboarding>) => {
        setSelection(selection);
    }

    const fromApiResponse = (d: any): ServerForOnboarding => {
        return {
            key: d["ServerId"],
            ServiceName: d["ServiceName"],
            ServerId: d["ServerId"],
            OSType: d["OSType"],
            NetworkDomain: d["NetworkDomain"],
            Errors: d["Errors"],
            Name: d["Name"],
            ServiceTreeGUID: d["ServiceTreeGUID"],
            Valid: d["Valid"]
        }
    };

    const serversChanged = (_data: any) => {
        let data = _data["True"];
        const validItems: ServerForOnboarding[] = data != null ? data.map(fromApiResponse) : [];

        data = _data["False"];
        const invalidItems: ServerForOnboarding[] = data != null ? data.map(fromApiResponse) : [];

        SetItems(validItems);
        SetInvalidItems(invalidItems);
        SetServersLoaded(true);
    }

    const serviceChanged = (selectedService: string, selectedServiceName: string) => {
        SetServersLoaded(false);
        SetItems([]);
        SetInvalidItems([]);
        setSelectedServiceGuid(selectedService);
        setSelectedServiceName(selectedServiceName);
    }

    const triggerOnboarding =
        () => {

            if (selection != null && selection.getSelectedCount() > 0) {
                var arr: ServerForOnboarding[] = selection.getSelection() as ServerForOnboarding[]
                var newServerData = arr.map(d => {
                    return {
                        ServerId: d.ServerId,
                        Status: "Loaded",
                        ServiceName: "",
                        ServiceTreeGUID: "",
                        Server: d.Name.toLowerCase(),
                        Domain: d.NetworkDomain.toLowerCase(),
                        Notes: "",
                        Jobs: [],
                        JobStatus: ""
                    } as IOnboardingDataType
                })
                props.SetServerData(newServerData);
                props.SetCurrentStep(OnboardingStep.Onboard);
            }
        }


    return (
        <div className={mergeStyles({ width: "800px" })}>
            <h2>Select Servers</h2>

            <div>

                <div >
                    {/*  the dropdown */}
                    <div>
                        <ServiceSelectionDropdown
                            serviceOptions={rootContext.state.DataStore.OnboardedServices}
                            onServiceSelected={serviceChanged}
                            onServersUpdated={serversChanged}>
                        </ServiceSelectionDropdown>
                    </div>

                    {/*  the details list */}
                    {
                        selectedServiceGuid != null &&
                        <div className={mergeStyles({ width: "800px" })}>
                            <Pivot aria-label="Select Servers for Onboarding">

                                <PivotItem headerText="Available" itemCount={items?.length} itemIcon="Emoji2">
                                    <div className={classNames.controlWrapper}>
                                        {/* 
                                        <TextField label="Filter by name:" 
                                            onChange={this._onChangeText} 
                                            styles={controlStyles} /> */}
                                        <Announced message={`Number of items after filter applied: ${items.length}.`} />
                                    </div>
                                    
                                    <DetailsListAvailableServers
                                        key={items.length}
                                        items={items}
                                        onServerSelectionChanged={selectionChanged}
                                        triggerOnboarding={triggerOnboarding} 
                                        serversLoaded={serversLoaded}/>

                                </PivotItem>

                                <PivotItem headerText="Out-of-Scope" itemCount={invaliditems?.length} itemIcon="Recent">

                                    <DetailsListOutOfScope
                                        items={invaliditems}
                                        key={invaliditems?.length} />

                                </PivotItem>

                            </Pivot>

                        </div>
                    }

                </div>


            </div>
        </div>
    );

};