import { Depths, IDropdownOption, ScrollablePane } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { EmailTemplates, IEmailTemplatesProps, IEmailTemplatesType } from "../components/Admin/EmailTemplates";
import { IRuleProps, Rules } from "../components/Admin/Rules";
import { StaleServers } from "../components/Admin/StaleServers";
import { StaleServices } from "../components/Admin/StaleServices";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackException, trackPageView } from "../components/AppInsights/LoggingHelper";
import { UnauthorizedMessage } from "../components/Shared/AppConstants";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import HttpService from "../services/HttpService/HttpService";
import { _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { DataActionEnum } from "../services/DataContext/DataActions";
import { AdminMessages }  from "../components/Admin/AdminMessages";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";
import { PAGE } from "../App";

const errorText: string = "You must be an administrator to view this page.";
const adminTitle = "Admin";

export const AdminPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const [emailTemplates, SetEmailTemplates] = React.useState<IEmailTemplatesType[]>([]);
    const [emailTemplateOptions, SetEmailTemplateOptions] = React.useState<IDropdownOption[]>([]);
    const { state, dispatch } = React.useContext(RootContext); // auth token context

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    // useEffect 'cleaup' component to supress warning: "Can't perform a React state update on an unmounted component"
    React.useEffect(() => {
        return () => {};
        }, []);

    React.useEffect(() => {
        document.title = "Admin";

        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "admin"
        });

        const telemetry = buildPageViewTelemetry(
            adminTitle,
            "/admin",
            "/admin",
            state.AuthStore.Email,
            state.AuthStore.isAdmin,
        );
        
        trackPageView(appInsights, telemetry);

    }, []);

    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            fetchEmailTemplates();
        }
    }, [state.AuthStore.Token]);

    const fetchEmailTemplates = () => {
        httpService.get({
            url: "api/Email/emailTemplates",
            token: state.AuthStore.Token,
            params: {}
        }).then((resp: any) => {
            if (resp !== undefined && resp.data !== undefined) {
                const options: IDropdownOption[] = [];
                const data = resp?.data.values;
                const emailTemplateData: IEmailTemplatesType[] = [];

                if (data != UnauthorizedMessage) {
                    for (const d of data) {
                        emailTemplateData.push({ 
                            Name: d.name,
                            Parameters: d.parameters
                         });

                        options.push({ key: d.name , text: d.name });
                    }

                    SetEmailTemplates(emailTemplateData);
                    SetEmailTemplateOptions(options);
                } else {
                    trackException(appInsights, "", SeverityLevel.Error, "Rules", "Fetching Email Templates", "User does not have permission to fetch email templates", state.AuthStore, {});
                }
            }
          }).catch((error: any) => {
            trackException(appInsights, error, SeverityLevel.Error, "Rules", "Fetching Email Templates", "Something went wrong fetching email templates", state.AuthStore, {});
          });
    }

    const ruleProps: IRuleProps = {
        emailTemplateOptions: emailTemplateOptions
    }

    const emailTemplatesProps: IEmailTemplatesProps = {
        emailTemplates: emailTemplates,
        fetchEmailTemplates: fetchEmailTemplates
    }

    if (state.AuthStore.isAdmin) {
        return (
            <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                    <AdminMessageBanner page={PAGE.ADMIN} />
                </div>
                <div className={_Styles.rootDiv}>
                    <h1 id="admintitle">{ReactHtmlParser(adminTitle)}</h1>
                    <StaleServers />
                    <br />
                    <StaleServices />
                    <br />
                    <Rules {...ruleProps}/>
                    <br />
                    <EmailTemplates {...emailTemplatesProps} />
                    <br />
                    <AdminMessages />
                    <br />
                    <BackToTopButton />
                </div>
            </ScrollablePane>
        );
    } else  {
        return (
            <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                <h1>{ReactHtmlParser(adminTitle)}</h1>
                <p>{errorText}</p>
                <BackToTopButton />
                <PagePermissionsComponent />
            </ScrollablePane>
        );
    }
};
