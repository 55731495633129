/* CacheBuster component */
import React from 'react';
import packageJson from '../../package.json';

interface ICacheBusterProps {}

const CacheBuster: React.FunctionComponent<ICacheBusterProps> = (props: ICacheBusterProps) => {
	React.useEffect(() => {
		fetch("/meta.json", {
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Acccept': 'application/json'
			}
		})
		.then((response) => response.json())
		.then((meta) => {
		  const latestVersion = meta.version;
		  const currentVersion = packageJson.version;
  
		  const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
		  if (shouldForceRefresh) {
			refreshCacheAndReload();
			console.log(`We have a new version - ${latestVersion}. Should force refresh`);
		  } else {
			console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
		  }
		}).catch((reason: any) => {
			console.log(reason);
		});
	},[])

	const semverGreaterThan = (versionA: string, versionB: string) => {
		const versionsA = versionA.split(/\./g);
		const versionsB = versionB.split(/\./g);
		
		while (versionsA.length || versionsB.length) {
		  const a = Number(versionsA.shift());
	  
		  const b = Number(versionsB.shift());
		  // eslint-disable-next-line no-continue
		  if (a === b) continue;
		  // eslint-disable-next-line no-restricted-globals
		  return a > b || isNaN(b);
		}

		return false;
	}

	const refreshCacheAndReload = () => {
		console.log('Clearing cache and hard reloading...')
		if (caches) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(function(names) {
				for (let name of names) caches.delete(name);
			});
		}
		// delete browser cache and hard reload
		window.location.reload();
	}

	return <></>;
}

export default CacheBuster;