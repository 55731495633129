import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React, { useContext } from "react";
import HttpService from "../../services/HttpService/HttpService";
import { RootContext } from "../Stores/RootStore";

const reactPlugin = new ReactPlugin();

const AppInsightsContext: React.Context<ApplicationInsights> = React.createContext(undefined as any);
export function useAppInsights() {
    return useContext(AppInsightsContext);
}

export const AppInsightsProvider: React.FC = ({children}) => {
    const [appInsights, SetAppInsights] = React.useState<ApplicationInsights>();
    const { state, dispatch } = React.useContext(RootContext);
    
    React.useEffect(() => {
        if (state.AuthStore.Token) { // {} && appInsights == undefined) {
            const httpService = HttpService();
            httpService.get({
                url: "api/Home/getAppInsights",
                token: state.AuthStore.Token,
                params: {},
            }).then((response: any) => {
                let ai = new ApplicationInsights({
                    config: {
                        instrumentationKey: response?.data.values,
                        extensions: [reactPlugin],
                    },
                });
                
                ai.loadAppInsights();
                ai.trackPageView();
                SetAppInsights(ai);
            }).catch((reason: any) =>{
                console.log(reason);
            });
        }
    },[state.AuthStore.Token])

    return (
        <AppInsightsContext.Provider value={appInsights!}>
            {children}
        </AppInsightsContext.Provider>
    );
};