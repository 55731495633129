import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React, { Dispatch, SetStateAction } from "react";
import { USER_ACTIVITY_COUNTDOWN_IN_SECONDS } from "./AppConstants";

interface IAuthExpiredDialogProps {
    userActiveCounter: number;
    SetUserActiveCounter: Dispatch<SetStateAction<number>>;
}

export const AuthExpiredDialog: React.FunctionComponent<IAuthExpiredDialogProps> = (props: IAuthExpiredDialogProps) => {
    const [hideDialog, SetHideDialog] = React.useState<boolean>(true);
    const [dialogText, SetDialogText] = React.useState<string>("Your session will expire in 5:00. Please click 'Continue' to continue working or 'Log Off' to end your session now.");

    React.useEffect(() => {
        // counter less than 5 mins, show dialog
        if (props.userActiveCounter > 0 && props.userActiveCounter < 300) {
            SetHideDialog(false);
            const minutes: number = Math.floor(props.userActiveCounter / 60);
            const seconds: number = props.userActiveCounter - minutes * 60;
            const secondsAsString: string = (seconds < 10) ? "0" + seconds.toString() : seconds.toString();
            SetDialogText("Your session will expire in " + minutes + ":" + secondsAsString + ". Please click 'Continue' to continue working or 'Log Off' to end your session now.");
         } else {
            SetHideDialog(true);
         }
    }, [props.userActiveCounter]);

    const continueSession = () => {
        props.SetUserActiveCounter(USER_ACTIVITY_COUNTDOWN_IN_SECONDS);
        SetHideDialog(true);
    };

    const closeOrLogOff = () => {
        SetHideDialog(true);
        props.SetUserActiveCounter(0);
    };

    return (
    <Dialog
        hidden={hideDialog}
        closeButtonAriaLabel="Dialog"
        onDismiss={() => {
            closeOrLogOff();
        }}
        dialogContentProps={{
            type: DialogType.normal,
            title: "Session Timeout",
            subText: dialogText,
        }}
    >
        <DialogFooter>
            <PrimaryButton
                text="Continue"
                ariaLabel={"Continue" + " Button"}
                onClick={continueSession}
            />
            <DefaultButton
                text={"Log Off"}
                ariaLabel={"Log Off" + " button"}
                onClick={closeOrLogOff}
            />
        </DialogFooter>
    </Dialog>
    );
};
