import { Card, CardStandardHeader } from "@coherence-design-system/controls";
import { ActionButton, FontIcon, FontSizes, IColumn, IDetailsList, IIconProps, IStackTokens, 
    mergeStyles, MessageBar, MessageBarType, ScrollablePane, SelectionMode, ShimmeredDetailsList, Stack, Text } from "@fluentui/react";
import React from "react";
import { Link } from "react-router-dom";
import HttpService from "../../services/HttpService/HttpService";
import { RootContext } from "../Stores/RootStore";
import { ServersWithNoJobsPanel } from "./ServersWithNoJobsPanel";
import { RequestAccessPanel } from "./RequestAccessPanel";
import { DataActionEnum } from "../../services/DataContext/DataActions";
import { getOnboardedServices } from "../../services/ApiService/Requests";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import HttpClient from "../../services/HttpService/HttpClient";
import { DOES_USER_HAVE_ANY_SERVICES } from "../../Pages/HomePage";
import { IServiceDataType } from "../../App";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";


export interface IMyServicesProps {
    appInsights: ApplicationInsights;
    setUserHasServices: Function;
}

const MAX_API_CALL_RETRY_COUNT = 3;
enum API_CALL_STATUS {
    // track status of API call
    PENDING="PENDING",
    COMPLETE="COMPLETE",
    NOTSTARTED="NOTSTARTED", 
    FAILED="FAILED"
}

export interface IServerDetails {
    serverName: string;
    serverId: string;
}
export interface IDetailsListMyServiceItem {
    serviceName: string;
    serviceTreeGuid: string;
    serverCount: Number;
    servers: IServerDetails[];
    icmTeam: boolean;
}

export interface IMyServicesState {
    columns: IColumn[];
    rows: IDetailsListMyServiceItem[];
    servicesMissingIcmDataCount: number;
    apiCallStatus: API_CALL_STATUS;
    retryCount: number;
    isPanelOpen: boolean;
    isRequestAccessPanelOpen: boolean;
    userServices: any[];
    rerenderPanelKey: Number;
    clickedRowItem?: IDetailsListMyServiceItem;
    httpService: HttpClient;
}

const stackTokens: IStackTokens = {
    childrenGap: 50 + " " + 50, // vertical gap + ' ' + horizontal gap,
};

const cardSize = mergeStyles({
    width: "50vw",
    height: "50vh",
});

const noServicesText = "You are not delegated to any services. Please contact a ServiceTree Admin.";

export class DetailsListMyServices extends React.Component<IMyServicesProps, IMyServicesState> {
    public static contextType: React.Context<any> = RootContext;
    private _columns: IColumn[];
    private _root = React.createRef<IDetailsList>();
    private serviceNameColumn : IColumn;
    private serverCountColumn : IColumn;
    private icmTeamColumn : IColumn;
    private _appInsights!: ApplicationInsights;

    constructor(props: IMyServicesProps){
        super(props);

        // Service Name column
        this.serviceNameColumn = { 
            key: "serviceName", 
            name: "Service Name", 
            fieldName: "ServiceName", 
            minWidth: 60, 
            maxWidth: 360, 
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "Service Name",
            sortAscendingAriaLabel: "Service Name sorted ascending",
            sortDescendingAriaLabel: "Service Name sorted descending"
        };

        // Server count column
        this.serverCountColumn = {
            key: "serverCount", 
            name: "Servers with no jobs", 
            fieldName: "ServerCount", 
            minWidth: 60, 
            maxWidth: 260, 
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "Servers with no jobs",
            sortAscendingAriaLabel: "Servers with no jobs sorted ascending",
            sortDescendingAriaLabel: "Servers with no jobs sorted descending"
        } 

        // Has Icm Team column
        this.icmTeamColumn = {
            key: "icmTeam", 
            name: "Icm Team", 
            fieldName: "IcMTeam", 
            minWidth: 60, 
            maxWidth: 360, 
            isResizable: true, 
            onColumnClick: this._onColumnClick,
            ariaLabel: "IcM Team",
            sortAscendingAriaLabel: "IcM Team sorted ascending",
            sortDescendingAriaLabel: "IcM Team sorted descending"
        } 
    
        this._columns=[this.serviceNameColumn, this.serverCountColumn, this.icmTeamColumn];

        // init state 
        this.state = {
            columns: this._columns, // table column definitions
            rows: [], // populated from http response,
            apiCallStatus: API_CALL_STATUS.NOTSTARTED, // set api call status as notstarted,
            servicesMissingIcmDataCount : 0, // default to no errors
            retryCount: 0,
            isPanelOpen: false,
            isRequestAccessPanelOpen: false,
            rerenderPanelKey: 0,
            userServices: [],
            httpService: HttpService(props.appInsights),
        }

        this._appInsights = props.appInsights;
    }

    // toggle column sort when header is clicked
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, rows } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = this._copyAndSort(rows, currColumn.key!, currColumn.isSortedDescending);

        this.setState({
            columns: newColumns,
            rows: newItems,
        });
    }

    // util function to sort a collection of arbitrary items.
    // collection should support fetching items by "key" 
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items
            .slice(0) // new copy
            .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    public componentDidMount() {
        console.debug("componentDidMount")
        this.refreshDataGrid();
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        // WARNING this gets called repeatedly without waiting for completion
        console.debug("componentDidUpdate")
        this.refreshDataGrid();
    }

    public getHeader(headerText: string) {
        return <CardStandardHeader
                    cardTitle={headerText}
                    styles={{
                        root: {
                        gridTemplateRows: "none",
                        gridTemplateColumns: "none",
                        },
                        cardTitle: {
                        fontSize: FontSizes.size20,
                        textAlign: "center",
                        padding: "0px",
                        },
                    }} 
                />;
      };

    
    public onPanelDismissed() {
        this.setState({isPanelOpen : false})
    }

    public render(): JSX.Element {

        this.openPanel = this.openPanel.bind(this);
        this.onPanelDismissed = this.onPanelDismissed.bind(this);
        
        // set vars from current state
        const { columns, rows } = this.state;
        return (<div>
            {this.state.clickedRowItem && this.state.isPanelOpen && <ServersWithNoJobsPanel clickedRow={this.state.clickedRowItem} isOpen={this.state.isPanelOpen} onDismiss={this.onPanelDismissed}> </ServersWithNoJobsPanel>}

            {this.state.isRequestAccessPanelOpen && <RequestAccessPanel isOpen={this.state.isRequestAccessPanelOpen} onDismiss={() => {this.closeRequestAccessPanel() }} userServices={this.state.userServices} rootContext={this.context}> </RequestAccessPanel>}
            
            <div style={{ position: "relative"}}>
                <Stack wrap horizontal horizontalAlign="space-evenly" tokens={stackTokens}>
                    <Stack.Item>
                        <div className={cardSize}>
                            <Card header={this.getHeader("My Services")}>

                                {/* Request Access Button */}
                                <div className={mergeStyles({ display: "flex", justifyContent: "right", alignItems: "right", })}>
                                    <ActionButton  
                                        className={mergeStyles({ color: "#0078d4" })} 
                                        iconProps={{ iconName: 'CircleAdditionSolid', style: {'color': '#0078d4'} }} 
                                        allowDisabledFocus 
                                        disabled={false}
                                        onClick={() => { 
                                            this.openRequestAccessPanel();
                                            }} >
                                        {"Request Access"}
                                    </ActionButton>
                                </div>

                                {/* Message bar for services missing IcM teams */}
                                { this.state.servicesMissingIcmDataCount > 0 && 
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                    >
                                        Some service(s) do not have IcM teams assigned.
                                        Visit <Link to="/serviceadministration"> Service Adminstration </Link> to assign IcM Teams.
                                    </MessageBar>
                                }
                                
                                {/* Details List of services */}
                                <ScrollablePane style={{ height: "45vh", position: "relative" }}>
                                    <ShimmeredDetailsList
                                        items={rows}  
                                        columns={columns}
                                        onRenderItemColumn={this._renderItemColumn.bind(this)}
                                        selectionMode={SelectionMode.none}
                                        componentRef={this._root}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForShimmer="Onboarded Services data is being fetched"
                                        ariaLabelForGrid="Onboarded Services details"
                                        enableShimmer={this.state.apiCallStatus == API_CALL_STATUS.PENDING}  // grays out area where text will be populated
                                        onRenderDetailsHeader={RenderStickyHeader}
                                    />
                                </ScrollablePane>
                                
                                { 
                                    this.state.apiCallStatus != API_CALL_STATUS.PENDING 
                                    && this.state.rows.length == 0
                                    && <Text variant="medium" className={mergeStyles({padding: "10px"})}> No Services Found </Text>
                                }
                            </Card>
                        </div>
                    </Stack.Item>
                </Stack>
            </div>
            <br/>
            </div>
        );
    }

    // Debounce. decide whether we need to fetch data over the network or not.
    public shouldFetch(): boolean{
        console.debug("API_CALL_STATUS=" + this.state.apiCallStatus + " rows.length = " + this.state.rows.length);
        if (this.state.retryCount > 3){
            console.debug("Retry count exceeded " + MAX_API_CALL_RETRY_COUNT)
            return false;
        }
        switch(this.state.apiCallStatus){
            case API_CALL_STATUS.NOTSTARTED:
                return true;
            case API_CALL_STATUS.FAILED:
                return this.state.rows.length == 0 ?  true : false;
            case API_CALL_STATUS.PENDING:
                return false;
            case API_CALL_STATUS.COMPLETE:
                return false;
            default:
                return false; 
        }
    }

    // responsible for fetching data from http calls and setting state
    public async refreshDataGrid() { 

        // no token? just return
        if (!this.context.state.AuthStore.Token){
            return;
        }

        const shouldMakeAPICall = this.shouldFetch();
        console.debug("should fetch = %o. api call status = %o", shouldMakeAPICall, this.state.apiCallStatus );
        // get users's services via API call if needed
        if(shouldMakeAPICall){
            const fetchOnboardedServices = getOnboardedServices(this.context.state, this._appInsights);

            const fetchServersWithNoJobs = this.state.httpService.get({
                url: "api/ManageServers/fetchServersWithNoJobs",
                token: this.context.state.AuthStore.Token,
                params: {},
            })

            const fetchServicesMissingIcMTeam = this.state.httpService.get({
                url: "api/ServiceAdministration/fetchServicesMissingIcMData",
                token: this.context.state.AuthStore.Token,
                params: {},
            })

             // set the api call as pending
             this.setState({
                apiCallStatus : API_CALL_STATUS.PENDING,
                retryCount : this.state.retryCount + 1
            })
            
            await Promise.all([fetchOnboardedServices, fetchServersWithNoJobs, fetchServicesMissingIcMTeam])
            .then((responseArray: any[]) => {
                // success case i.e. all api calls have succeeded
                
                // build map of 
                // { serviceTreeGuid -> count of servers with no jobs }
                const serversWithNoJobsData = responseArray[1];
                const serviceCountMap: Map<string, number> = new Map<string, number>();
                const serviceToServersMap: Map<string, IServerDetails[]> = new Map<string, IServerDetails[]>();
                for (const d of serversWithNoJobsData.data){
                    // serviceCountMap.set(d["ServiceTreeGUID"], d["ServerCount"])
                    
                    const existing: IServerDetails[] = serviceToServersMap.get(d["ServiceTreeGUID"]) || [];
                    existing.push({serverId: d["ServerID"], serverName: d["ServerName"]});
                    serviceToServersMap.set(d["ServiceTreeGUID"], existing);

                    let count: number = serviceCountMap.get(d["ServiceTreeGUID"]) || 0;
                    count = Number(count) + 1;
                    serviceCountMap.set(d["ServiceTreeGUID"], count);
                };
                
                // map we just built above + /fetchOnboardedServices api response
                const onboardedServicesData = responseArray[0];
                const data: any = onboardedServicesData.data;

                const missingIcmTeamData = responseArray[2];
                const missingIcMData: Set<string> = new Set<string>(missingIcmTeamData.data);
                let missingCount = 0;

                const servicesSet: Set<string> = new Set<string>();
                const items: IDetailsListMyServiceItem[] = [];
                let isAnyServerCountOverZero: boolean = false;
                const onboardedServicesState: IServiceDataType[] = [];

                for (const d of data) {
                    if (d.serviceTreeGUID && d.serviceTreeGUID !== "" && d.serviceTreeGUID !== undefined && !servicesSet.has(d.serviceTreeGUID)) {
                        const serverCount: number = serviceCountMap.get(d.serviceTreeGUID) || 0;
                        let hasIcM = true;

                        if (missingIcMData.has(d.serviceTreeGUID)) {
                            missingCount = missingCount + 1;
                            hasIcM = false;
                        }

                        items.push({
                            serviceName: d.serviceName,
                            serviceTreeGuid: d.serviceTreeGUID,
                            serverCount: serverCount,
                            servers: serviceToServersMap.get(d.serviceTreeGUID) || [],
                            icmTeam: hasIcM
                        });

                        onboardedServicesState.push({
                            ServiceName: d.serviceName,
                            ServiceTreeGUID: d.serviceTreeGUID
                        })

                        // check if any service has non-zero count
                        isAnyServerCountOverZero = serverCount > 0 ? true : isAnyServerCountOverZero;
                        servicesSet.add(d.serviceTreeGUID);
                    }
                }

                this.context.dispatch({
                    type: DataActionEnum.UPDATE_ONBOARDED_SERVICES,
                    onboardedServices: onboardedServicesState,
                });
                

                if(servicesSet.size > 0){
                    this.props.setUserHasServices(DOES_USER_HAVE_ANY_SERVICES.YES);
                }else{
                    this.props.setUserHasServices(DOES_USER_HAVE_ANY_SERVICES.NO);
                }

                let newItems = items;

                if(isAnyServerCountOverZero){
                    // sort by server count column (descending)
                    newItems = this._copyAndSort(items, "serverCount", true);
                    // and mark the grid column as sorted 
                    if(this.serverCountColumn){ this.serverCountColumn.isSorted = true; this.serverCountColumn.isSortedDescending = true;}
                } else {
                    // sort by service name
                    newItems = this._copyAndSort(items, "serviceName", false);
                    // and mark the grid column as sorted
                    if(this.serviceNameColumn){ this.serviceNameColumn.isSorted = true; this.serviceNameColumn.isSortedDescending = false;}
                }

                const noServices: IDetailsListMyServiceItem[] = [{
                    serviceName: noServicesText,
                    serviceTreeGuid: "",
                    serverCount: 0,
                    servers: [],
                    icmTeam: false,
                }];

                // update state with response
                this.setState({
                    rows: newItems.length > 0 ? newItems : noServices,
                    apiCallStatus : API_CALL_STATUS.COMPLETE,
                    servicesMissingIcmDataCount : missingCount,
                });
            }, () => {
                // failure case when api calls have failed
                this.setState({
                    apiCallStatus : API_CALL_STATUS.FAILED
                })
                })
            .catch((reason: any) => {
                // exception case
                this.setState({
                    apiCallStatus : API_CALL_STATUS.FAILED
                })
                console.error(reason);
            });      
        }
    }

    onServerCountColumnClicked(d: IDetailsListMyServiceItem){
        this.setClickedRowItem(d);
        this.openPanel();
    }

    openPanel(): void{
        this.setState({
            isPanelOpen: true
        })
    }

    closePanel(): void{
        this.setState({
            isPanelOpen: false
        })
    }

    setClickedRowItem(d: IDetailsListMyServiceItem){
        this.setState({clickedRowItem: d})
    }

    // Stylize column information by column
    private _renderItemColumn(item?: IDetailsListMyServiceItem, index?: number, column?: IColumn) {       
        const warningIcon: IIconProps = { iconName: 'Warning', style: {'color': '#e50000'} };

        if (item !== undefined && column !== undefined) {
            {
                {
                    switch (column.fieldName) {
                        case "ServiceName": {
                            const ariaLabel: string = `Row ${index! + 1}`;

                            return item.serviceName == noServicesText 
                                ? <p aria-label={ariaLabel}>{item.serviceName}</p> 
                                : <span> <Link aria-label={ariaLabel + " " + item.serviceName} to={{ pathname: "/managejobs/" + item.serviceTreeGuid}}> {item.serviceName}</Link> {!item.icmTeam ? "(No Icm Team)":""} </span>
                        }
                        case "IcMTeam": {
                            return item.serviceName == noServicesText 
                                ? <p/>
                                : <span>  {
                                            !item.icmTeam
                                            ? <Link aria-label="IcM team not assigned. Click to navigate to Service Administration" to={{ pathname: "/serviceadministration/" + item.serviceTreeGuid}}> Assign Now </Link>
                                            : <FontIcon aria-label="IcM team assigned" iconName="StatusCircleCheckmark" className={mergeStyles({ color: "green", fontSize: "22px" })}/>} 
                                  </span>
                        }
                        case "ServerCount": {
                            // show nothing if count is 0
                            if(item.serverCount == 0){
                                return <span aria-label='0'>-</span>
                            }
                            
                            // show warning sign with count
                            return <ActionButton  
                                className={mergeStyles({ color: "#e50000" })} 
                                iconProps={warningIcon} 
                                allowDisabledFocus 
                                disabled={false}
                                onClick={() => { 
                                    this.onServerCountColumnClicked(item);
                                    }} >
                                {item.serverCount}
                                </ActionButton>
                        }
                        default:
                            return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{column.fieldName}</span>;
                    }
                }
            }
        }
    }

    openRequestAccessPanel(): void{
        this.setState({
            isRequestAccessPanelOpen: true
        })
    }

    
    closeRequestAccessPanel(): void{
        this.setState({
            isRequestAccessPanelOpen: false
        })
    }
}


