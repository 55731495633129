import * as React from "react";
import { createContext, useReducer } from "react";
import { AuthActions } from "../../services/AuthenticationService/AuthActions";
import { AuthInitialState, AuthReducer, IAuthState } from "../../services/AuthenticationService/AuthReducer";
import { DataActions } from "../../services/DataContext/DataActions";
import { DataInitialState, DataReducer, IDataState } from "../../services/DataContext/DataReducer";

export interface IRootState {
    AuthStore: IAuthState;
    DataStore: IDataState;
}

export type RootActions = | AuthActions | DataActions;

const RootInitialState: IRootState = {
    AuthStore: AuthInitialState,
    DataStore: DataInitialState,
};

const RootContext = createContext<{
    state: IRootState;
    dispatch: React.Dispatch<any>;
}>({
    state: RootInitialState,
    dispatch: () => {},
});

const mainReducer = (rootstate: IRootState, action: RootActions) => ({
    AuthStore: AuthReducer(rootstate.AuthStore, action as AuthActions),
    DataStore: DataReducer(rootstate.DataStore, action as DataActions),
});

const RootProvider: React.FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, RootInitialState);

    return (
        <RootContext.Provider value={{ state, dispatch }}>
            {children}
        </RootContext.Provider>
    );
};

export { RootContext, RootProvider };
