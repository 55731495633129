import { Depths, mergeStyles, MessageBar, MessageBarType, ScrollablePane, Text } from "@fluentui/react";
import * as React from "react";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackPageView } from "../components/AppInsights/LoggingHelper";
import { DetailsListMyServices } from "../components/Home/DetailsListMyServices";
import HomeBanner from "../components/Home/HomeBanner";
import HomeNav from "../components/Home/HomeNav";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { AppName } from "../components/Shared/AppConstants";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { UserPermission } from "../services/AuthenticationService/AuthReducer";
import { _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { DataActionEnum } from "../services/DataContext/DataActions";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";
import { PAGE } from "../App";

const homeText: string = "End-to-end security patching. <br /> Maintain compliance by quickly onboarding assets, managing your patching jobs, and scheduling patching.";

export const enum DOES_USER_HAVE_ANY_SERVICES {
    DONT_KNOW_YET, // API call hasn't completed yet
    YES, 
    NO
};

export const HomePage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const { state, dispatch } = React.useContext(RootContext);
    const [userHasServices, setUserHasServices] = React.useState<DOES_USER_HAVE_ANY_SERVICES>(DOES_USER_HAVE_ANY_SERVICES.DONT_KNOW_YET);
    const appInsights = useAppInsights();

    React.useEffect(() => {
        document.title = AppName;
        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "home"
        });

        if (state.AuthStore.Token !== "") {
            const telemetry = buildPageViewTelemetry(
                "Home",
                "/Home",
                "Home" + "/",
                state.AuthStore.Email,
                state.AuthStore.isAdmin,
            );
            
            trackPageView(appInsights, telemetry);
        }
    }, []);

    return (
        <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsedHome : _Styles.scrollablePaneExpandHome}>
            <div hidden={state.AuthStore.UserPermitted !== UserPermission.Permitted || state.AuthStore.UserTimeOut}>
                <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}> 
                    <AdminMessageBanner page={PAGE.HOME} />
                </div>
                <div className={_Styles.rootDivHome}>
                    <div className={"container-fluid d-flex flex-column"}>
                        <div className="row">
                            <HomeBanner Title={AppName} Text={homeText} />
                        </div>
                        {
                            /* show a banner with links when user does not have access to any service */
                            userHasServices == DOES_USER_HAVE_ANY_SERVICES.NO
                            &&
                            /*_Styles.rootDivHome sets the root page margin to 64, need to apply the opposite for elements on page */
                            <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px", marginRight: "-64px" }}> 
                                <MessageBar
                                    messageBarType={MessageBarType.warning}
                                    isMultiline={true}
                                    className = {mergeStyles({})}
                                >
                                    <Text variant="medium"> 
                                        You currently do not have any permissions to a Service Tree service that has been onboarded in the Patching Portal.  Please visit<a href="https://microsoft.sharepoint.com/teams/MLSPatchingQA/SitePages/Learn-More.aspx" target={"_blank"}>this link</a> to understand how permissions work.                                    
                                    </Text>
                                </MessageBar>
                            </div>
                        }
                        
                    </div>
                </div>
                <div className={_Styles.rootDiv}>
                    <div className={"container-fluid d-flex flex-column"}>
                        <div className="row">
                            <div className="col">
                                <HomeNav />
                            </div>
                        </div>
                        <br /><br />
                        <div className="row">
                            <div className="col">
                                {
                                state.AuthStore.Token != null
                                && !state.AuthStore.toggleAdmin
                                && state.AuthStore.UserPermitted == UserPermission.Permitted
                                &&
                                <div>
                                    <DetailsListMyServices appInsights={appInsights} setUserHasServices={setUserHasServices}></DetailsListMyServices>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BackToTopButton />
            <PagePermissionsComponent />
        </ScrollablePane>
    );
};
