import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { mergeStyles } from '@fluentui/react';
import { prodAuthProvider } from './services/AuthenticationService/AuthProvider';
import { MsalProvider } from '@azure/msal-react';
import * as React from 'react';

// Inject some global styles
mergeStyles({
    selectors: {
        ':global(body), :global(html), :global(#app)': {
            margin: 0,
            padding: 0,
            height: '100vh'
        }
    }
});

ReactDOM.render(
    // Disabling strict mode check. The Fluent UI controls in @m365-admin have tons of deprecated things
    // like componentWillReceiveProps lifecycle method. Strict mode check will flag all these things and
    // make the console log noisy. Enable only when we want to find issues in our own components.
    // This React.StrictMode could be used individually in our own component code on a case by case basis.
    // See: https://reactjs.org/docs/strict-mode.html
    //<React.StrictMode>
        <MsalProvider instance={prodAuthProvider}>
            <App />
        </MsalProvider>,
    document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();