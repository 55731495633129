import { IBasePickerSuggestionsProps, IPersonaProps, Label, NormalPeoplePicker } from "@fluentui/react";
import * as React from "react";
import { AliasInputType } from "../ServiceAdministration/EmailContact";

// PickerPeople type
// changes return values
export enum PeoplePickerRequestType {
  Email,
  Alias,
  Group,
}

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Suggested People",
  // mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: "Press 'Enter' to search",
  loadingText: "Loading",
  showRemoveButtons: false,
  suggestionsAvailableAlertText: "People Picker Suggestions available",
  suggestionsContainerAriaLabel: "Suggested contacts",
};

const peoplePickerStyle = {
  input: {
    width: "100%",
  },
};

export function PeoplePicker(props: any) {
  let defaultValues: string[] = [];
  if (props.defaultValue) {
    if (typeof(props.defaultValue) == "string") {
      defaultValues.push(props.defaultValue);
    } else {
      defaultValues = [...props.defaultValue];
    }
  }
  const [peopleList, SetPeopleList] = React.useState<IPersonaProps[]>(props.defaultValue ? defaultValues.map((i) => ({text: i})) : []);
  const picker = React.useRef<any>(null);

  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas!);
      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const handleSelect = (user?: IPersonaProps): IPersonaProps => {
    props.selectedValue && props.selectedValue(user!.text);

    // add alias to list of selected aliases
    const alias: string = user?.text!;
    const email: string = user?.optionalText!;
    props.SetAlias(alias);
    props.SetNewEmail(email);

    return user!;
  };

  function getPeopleList(input: string) {
    let url: string = "";

    switch (props.PeoplePickerRequestTypeValue) {
      case PeoplePickerRequestType.Alias:
        url = "https://graph.microsoft.com/v1.0/users?$count=true&$filter=(startswith(displayName,'" + input + "') OR startswith(mail,'" + input + "') OR startswith(userPrincipalName,'" + input + "')) AND endsWith(mail,'microsoft.com')";
        break;
      case PeoplePickerRequestType.Group:
        url = 'https://graph.microsoft.com/v1.0/groups/?$count=true&$search="mailNickname:' + input + '"';
        break;
      default:
        url = "https://graph.microsoft.com/v1.0/users?$count=true&$filter=(startswith(displayName,'" + input + "') OR startswith(mail,'" + input + "') OR startswith(userPrincipalName,'" + input + "')) AND endsWith(mail,'microsoft.com')";
        break;
    }

    fetch(url, {
      headers: {
        "Content-type": "application/json",
        ConsistencyLevel: "eventual",
        Authorization: props.token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          const people: IPersonaProps[] = [];
          const suggestion: any[] = [];

          if (result && result.value) {
            result.value.forEach((item: any) => {
                people.push({
                    secondaryText: secondaryText(item),
                    text: resultText(item),
                    optionalText: optionalText(item),
                });
                suggestion.push({
                    ariaLabel: ariaLabel(item),
                    selected: false,
                    item: {
                        secondaryText: secondaryText(item),
                        text: resultText(item),
                        optionalText: optionalText(item),
                    },
                });
            });

            picker.current.suggestionStore.suggestions = suggestion;
            SetPeopleList(people);
          }
        },
        (error) => {
          alert(error);
        },
      );
  }

  const optionalText = (item: any): string => {
    if (item !== undefined) {
      switch (props.PeoplePickerRequestTypeValue) {
        case PeoplePickerRequestType.Alias: {
          return item.userPrincipalName;
        }
        case PeoplePickerRequestType.Email: {
          return item.userPrincipalName;
        }
        case PeoplePickerRequestType.Group: {
          return item.mail ?? item.mailNickname + "@microsoft.com";
        }
        default: {
          return item.displayName;
        }
      }
    } else {
      return "undefined";
    }
  };

  const resultText = (item: any): string => {
    if (item !== undefined) {
      switch (props.PeoplePickerRequestTypeValue) {
        case PeoplePickerRequestType.Alias: {
          return item.userPrincipalName.split("@")[0];
        }
        case PeoplePickerRequestType.Email: {
          return item.userPrincipalName;
        }
        case PeoplePickerRequestType.Group: {
          return item.mail ?? item.mailNickname + "@microsoft.com";
        }
        default: {
          return item.displayName;
        }
      }
    } else {
      return "undefined";
    }
  };

  const secondaryText = (item: any): string => {
    if (item !== undefined) {
      switch (props.PeoplePickerRequestTypeValue) {
        case PeoplePickerRequestType.Alias: {
          return item.displayName;
        }
        case PeoplePickerRequestType.Email: {
          return item.displayName;
        }
        case PeoplePickerRequestType.Group: {
          return item.displayName;
        }
        default: {
          return item.displayName;
        }
      }
    } else {
      return "undefined";
    }
  };

  const ariaLabel = (item: any): string => {
    if (item !== undefined) {
      switch (props.PeoplePickerRequestTypeValue) {
        case PeoplePickerRequestType.Alias: {
          return item.userPrincipalName.split("@")[0];
        }
        case PeoplePickerRequestType.Email: {
          return item.userPrincipalName;
        }
        case PeoplePickerRequestType.Group: {
          return item.mail;
        }
        default: {
          return item.displayName;
        }
      }
    } else {
      return "undefined";
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return peopleList.filter((item) => doesTextStartWith(item.text as string, filterText));
  };

  const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      SetPeopleList(newPeople);
    }
  };

  function doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
    return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
  }

  function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter((item) => item.text === persona.text).length > 0;
  }

  function getTextFromItem(persona: IPersonaProps): string {
    return persona.text as string;
  }

  function onInputChange(input: string): string {
    if (input !== "") {
      switch (props.PeoplePickerRequestTypeValue) {
        case PeoplePickerRequestType.Alias: {
          props.SetAliasInput(AliasInputType.Alias);
          break;
        }
        case PeoplePickerRequestType.Email: {
          props.SetAliasInput(AliasInputType.Email);
          break;
        }
        case PeoplePickerRequestType.Group: {
          props.SetAliasInput(AliasInputType.Group);
          break;
        }
        default: {
          props.SetAliasInput(AliasInputType.None);
          break;
        }
      }
    } else {
      props.SetAliasInput(AliasInputType.None);
    }

    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);
    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }
    return input;
  }

  const onChange = (items: IPersonaProps[] | undefined) => {
    if (items && items.length > 0) {
      handleSelect(items![0]);
    } else {
      props.SetAlias("");
      props.SetNewEmail("");
    }
  }

  return (
    <>
      {props.label && 
        <div className="ms-TextField is-required root-349">
          <div className="ms-TextField-wrapper">
            <Label required={props.required != null ? props.required : false} className="ms-Label root-360" disabled={props.disabled ?? false}>
              {props.label}
            </Label>
          </div>
        </div>
      }
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={"ms-PeoplePicker"}
        key={"normal"}
        onRemoveSuggestion={onRemoveSuggestion}
        removeButtonAriaLabel={"Remove"}
        defaultSelectedItems={peopleList}
        inputProps={{
          onKeyDown: (ev: React.KeyboardEvent<HTMLInputElement>) => {
            if (ev.key === "Enter") {
              const input = ev.currentTarget.defaultValue.trim();
              getPeopleList(input);
            }
          },
          "aria-label": "People Picker",
        }}
        componentRef={picker}
        onInputChange={onInputChange}
        onItemSelected={handleSelect}
        resolveDelay={300}
        onChange={onChange}
        itemLimit={props.itemLimit}
        disabled={props.disabled ?? false}
        styles={props.styles ?? peoplePickerStyle}
        aria-label={props.ariaLabel}
      />
    </>
  );
}
