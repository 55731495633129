import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { SetOnSearchDetailsView } from "@coherence-design-system/controls";
import { _InternalMessageId } from "@microsoft/applicationinsights-core-js";
import { Guid } from "guid-typescript";
import * as React from "react";
import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppInsightsProvider } from "./components/AppInsights/AppInsights";
import { Header } from "./components/Layout/Header";
import { Nav } from "./components/Layout/Nav";
import { PatchComplianceType } from "./components/ManageServers/DetailsListManageServers";
import { RootContext, RootProvider } from "./components/Stores/RootStore";
import { AdminPage } from "./Pages/AdminPage";
import { JobHistoryPage } from "./Pages/JobHistoryPage";
import { HomePage } from "./Pages/HomePage";
import { ManageJobsPage } from "./Pages/ManageJobsPage";
import { ManageServersPage } from "./Pages/ManageServersPage";
import { OffboardingPage } from "./Pages/OffboardingPage";
import { OnboardingPage } from "./Pages/OnboardingPage";
import { ServiceAdministrationPage } from "./Pages/ServiceAdministrationPage";
import { AuthActionEnum } from "./services/AuthenticationService/AuthActions";
import { authenticationParameters } from "./services/AuthenticationService/AuthProvider";
import { UserPermission } from "./services/AuthenticationService/AuthReducer";
import CacheBuster from "./services/CacheBuster";
import { UserCallResult } from "./services/HttpService/HttpClient";
import { getCallResult } from "./services/HttpService/HttpService";
import { AdminDCPage } from "./Pages/AdminDCPage";

export interface IServiceDataType {
    ServiceName: string;
    ServiceTreeGUID: string;
}

export interface IServerType {
    ServerName: string;
    ServerId: number;
    ServiceName: string;
    ServiceTreeGUID: Guid;
    AgentLastSeenDateTime: Date; 
    LastScheduledDateTime: Date;
    NextScheduledDateTime: Date;
    PatchCompliance: PatchComplianceType;
    VulnerabilityCount: number;
    OSType: string;
}

export enum PAGE {
    HOME = "home",
    ONBOARDING = "onboarding",
    OFFBOARDING = "offboarding",
    MANAGESERVERS = "manageservers",
    MANAGEJOBS = "managejobs",
    JOBHISTORY = "jobhistory",
    SERVICEADMINISTRATION = "serviceadministration",
    ADMIN = "admin",
    ADMINDC = "admindc"
};

export const App: React.FunctionComponent = () => {
    const [isNavCollapsed, SetNavCollapsed] = React.useState(false);
    const {} = useMsalAuthentication(InteractionType.Redirect, authenticationParameters);
    const isAuthenticated = useIsAuthenticated();
    const { state, dispatch } = useContext(RootContext);
    const [userCallValue, SetUserCallValue] = React.useState<UserCallResult>(UserCallResult.Start);

    React.useEffect(() => {
        dispatch({
            type: AuthActionEnum.SET_USER_PERMISSION,
            userPermitted: UserPermission.Validating,
        });
    }, []);

    React.useEffect(() => {
        const newCallResult: UserCallResult = getCallResult();
        if (newCallResult !== userCallValue) {
            SetUserCallValue(newCallResult);
        }
    }, [getCallResult()]);

    function onNavCollapsed(fIsNavCollapsed: boolean) {
        SetNavCollapsed(fIsNavCollapsed);
    }

    function goBackClicked() {
        SetOnSearchDetailsView(false);
    }

    return (
        <div>
        <BrowserRouter>
            {isAuthenticated && (<RootProvider>
                <AppInsightsProvider>
                <CacheBuster />
                <Header />
                <div hidden={userCallValue === UserCallResult.Error}>
                    <Nav
                        onNavCollapsed={onNavCollapsed}
                        onNavItemClicked={goBackClicked}
                    />
                    <main id="main" tabIndex={-1}>
                        <Routes>
                            <Route path="/" element={
                                <HomePage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.HOME} element={
                                <HomePage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.ONBOARDING} element={
                                <OnboardingPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.OFFBOARDING} element={
                                <OffboardingPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.MANAGEJOBS} element={
                                <ManageJobsPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.MANAGEJOBS + "/:serviceTreeGuid"} element={
                                <ManageJobsPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.JOBHISTORY} element={
                                <JobHistoryPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.MANAGESERVERS} element={
                                <ManageServersPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.SERVICEADMINISTRATION} element={
                                <ServiceAdministrationPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.SERVICEADMINISTRATION + "/:serviceTreeGuid"} element={
                                <ServiceAdministrationPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.ADMIN} element={
                                <AdminPage isNavCollapsed={isNavCollapsed} />
                            } />
                            <Route path={"/" + PAGE.ADMINDC} element={
                                <AdminDCPage isNavCollapsed={isNavCollapsed} />
                            } />
                        </Routes>
                    </main>
                </div>
                </AppInsightsProvider>
            </RootProvider>)}
        </BrowserRouter>
        </div>
    );
};

export default App;
