import { CoherenceTheme } from "@coherence-design-system/styles";
import { NeutralColors } from "@fluentui/react";

const Neutrals = {
    BlueDark: "#122C67",
    Blue: "#273CA1",
    BlueLight: "#418DED",
    PurpleDark: "#521C74",
    PurpleLight: "#7A81EB",
    Teal: "#00A5B0",
    RedLight: "#E64A57",
    RedDark: "#651339",
    GrayDark: "#0F2135",
};

export const Status = {
    Red: "#C5262C",
    Orange: "#F87528",
    Yellow: "#FFB900",
    LightGreen: "#41A512",
    DarkGreen: "#085108",
    Blue: "#004E8C",
};

/**
 * The sales palette is intended to show status in a more “neutral format” than the status colors, particularly when it doesn’t make sense to label data as “bad” or “good.”
 * One example: when we are displaying a chart showing revenue and potential revenue, each category is revenue--therefore although it makes sense to differentiate them, they should be neutral.
 * There is no “bad” revenue. Refer to the sales palette instead of the neutral categorical palette because that will provide more consistency in our applications for displaying sales data. */
const Sales = {
    /** #004555 */
    Line1: "#004555",
    Line2: "#00A2AD",
    Line3: "#FFB900",
};

export const Colors = {
    // Colors used for coloring indicators and icons
    Application: {
        /*
         * This is the main accent color for the application. Used in the Top Navigation Bar. #0078d4
         */
        Primary: "#0078d4",
        /*
         * This is the background color Left Navigation Bar. #e5e5e5
         */
        Secondary: "#e5e5e5",
        /*
         * This is the background color of MEXs on homepage, modals and menus. #ffffff
         */
        LightBackground: "#ffffff",
        /*
         * This is the background color of main content window. #F8F8F8
         */
        NeutralBackground: "#F8F8F8",
        /**
         * This is the color for the separator line in Card
         */
        SeperatorColor: CoherenceTheme.palette.neutralLight,
        /**
         * Color for error icon background
         */
        NeutralLighter: CoherenceTheme.palette.neutralLighter,
    },
    /*
     * These are colors leveraged for visuals and other color indicators in the application.
     */
    VisualIndicators: {
        Brown: "#8e562e",
        BlueLight: "#0078d7",
        Blue: "#004e8c",
        BlueMedium: Neutrals.BlueLight,
        BlueDark: "#122a63",
        Burgundy: "#982149",
        GreenLime: "#8cbd18",
        GreenLight: "#00ae56",
        Green: "#498205",
        GreenDark: "#ob6a0b",
        GrayLight: "#a0aeb2",
        Gray: "#69797e",
        GrayMedium: "#7a7574",
        GrayDark: "#393939",
        Magenta: "#9b0062",
        OrangeLight: "#ffaa44",
        Orange: "#f7630c",
        OrangeDark: "#ca5010",
        Pink: "#e3008c",
        PurpleLighter: "#9087e2",
        PurpleLight: "#8378de",
        Purple: "#5c2e91",
        PurpleDark: "#601e8a",
        RedLight: Neutrals.RedLight,
        Red: "#d134eb",
        RedDark: "#a4262c",
        Teal: "#00b7c3",
        TealMedium: "#00a2ad",
        Turquoise: "#038387",
        TurquoiseDark: "#005b70",
        Yellow: "#fce100",
        YellowDark: "#c19c00",
        /**
         * @deprecated Use Green
         */
        GreenIndicator: "#498205",
        /**
         * @deprecated Use Yellow
         */
        YellowIndicator: "#fce100",
        /**
         * @deprecated Use RedDark
         */
        RedIndicator: "#d1343b",
        /**
         * @deprecated Use BlueLight
         */
        BlueIndicator: "#0078d7",
        /**
         * @deprecated Use GrayDark
         */
        DarkGrayIndicator: "#393939",
        /**
         * @deprecated Use GrayMedium
         */
        GrayIndicator: "#7a7574",
        /**
         * @deprecated Use GrayLight
         */
        LightGrayIndicator: "#A0AEB2",
    },
    Text: {
        /*
         * This is the text color for card headers.
         */
        Header: NeutralColors.gray210, // '#171717'
        /**
         * This is the text color for card subtitles
         */
        SubHeader: CoherenceTheme.palette.neutralSecondary, // '#666666'
        /*
         *  This is the text color Primary Text.
         */
        Primary: CoherenceTheme.palette.neutralPrimary, // '#171717'
        /*
         *   This is the text color Secondary Text.
         */
        Secondary: CoherenceTheme.palette.neutralSecondary, // '#646464'
        /*
         * This is the text color for accents used in the hero section.
         */
        HeroAccent: "#0078D7",
        /*
         *  This is the  color for error message related content.
         */
        Error: CoherenceTheme.palette.redDark,

        White: NeutralColors.white,
    },
    Link: {
        /*
         *   This is the text color for links placed in main content sections leveraging white background.
         */
        PrimaryLink: CoherenceTheme.palette.themePrimary, // '#0078D4'
        /*
         *   This is the text color for links placed in hero content sections leveraging gray background.
         */
        HeroLink: CoherenceTheme.palette.themeDark, // '#005a9e'
    },
    List: {
        /*
         *   This is the text color for column headers displayed in smaller details lists. (i.e. Homepage and Details)
         */
        HeaderText: CoherenceTheme.palette.neutralSecondary,
        /*
         *   This is the text color for row text displayed in smaller details lists. (i.e. Homepage and Details)
         */
        RowText: CoherenceTheme.palette.neutralPrimary,

    },
    Item: {
        /*
         *   This is the hover background color for lists.
         */
        ListItemHoverBackgroundColor: CoherenceTheme.palette.neutralLighter,
        /*
         *   This is the pressed background color for lists.
         */
        ListItemPressedBackgroundColor: CoherenceTheme.palette.neutralLight,
        /*
         * This is the indicator color for items in lists.
         */
        ItemIndicator: "#0078d7",
        /*
         *   This is the line divider. #C8C8C8
         */
        LineDivider: CoherenceTheme.palette.neutralTertiaryAlt,
    },
    Icon: {
        /*
         *   This is the secondary icon color. (i.e used in no data display)
         */
        SecondaryIcon: CoherenceTheme.palette.neutralSecondary,
        /*
         *   This is the primary icon color.
         */
        PrimaryIcon: CoherenceTheme.palette.neutralPrimary,
    },
    Modal: {
        FooterDivider: CoherenceTheme.palette.neutralLight,
        DarkHeader: CoherenceTheme.palette.neutralLighterAlt, // #FAFAFA
    },
    /** Use for health info and generic statuses to grab user’s attention */
    Status: {
        /**
         * Very bad; needs immediate attention.
         * Red. #C5262C
         */
        AlertDoNotDisturb: Status.Red,
        /**
         * Pretty bad; needs attention soon.
         * Orange. #F87528
         */
        WarningCaution: Status.Orange,
        /**
         * Warning / Away / Keep an eye on this.
         * Yellow. #FFB900
         */
        WarningAway: Status.Yellow,
        /**
         * Things are good This is the more “active state” of the two greens.
         * Light Green. #41A512
        */
        Positive1: Status.LightGreen,
        /**
         * Things are good This is the more “settled state” of the two greens.
         * Dark Green. #085108
         */
        Positive2: Status.DarkGreen,
        /**
         * This is used for positive status when something is no longer active or in flight
         * Blue. #004E8C
         */
        Complete: Status.Blue,
    },
    /**
     * Use in sequence for line charts, and other graphics where your data overlaps The sequencing that is essential for accessibility in other charts does not serve our users as well on line charts.
     * The dark hues look similar, and the light ones as well. Therefore, we created this seperate palette to maximize the contrast and visual differences for colors.
     * Note: in order to meet accessibility requirements please use a 3px white line behind your 2px line.
     * This highlights points of overlap and ensures that every line is seen on a white background. More in our line chart guidance: Cards > Line chart.
     */
    Chart: {
        /** Line chart colors */
        Line: {
            /** #E64A57 */
            1: Neutrals.RedLight,
            /** #122A63 */
            2: "#122A63",
            /** #418DED */
            3: Neutrals.BlueLight,
            /** #00A2AD */
            4: "#00A2AD",
            /** #601E8A */
            5: "#601E8A",
            /** #9087E2 */
            6: "#9087E2",
            /** #982149 */
            7: "#982149",
        },
        Donut: {
            /** Red. #C5262C  */
            1: Status.Red,
            /** Yellow. #FFB900  */
            2: Status.Yellow,
            /** Light Green. #41A512 */
            3: Status.LightGreen,
            /** Blue. #004E8C */
            4: Status.Blue,
        },
        /** Status palette with extra green */
        DonutExtra: {
            /** Red. #C5262C  */
            1: Status.Red,
            /** Yellow. #FFB900  */
            2: Status.Yellow,
            /** Light Green. #41A512 */
            3: Status.LightGreen,
            /** Dark Green #085108 */
            4: Status.DarkGreen,
            /** Blue. #004E8C */
            5: Status.Blue,
        },
        /** General color palette for data visualization, contains shared colors across chart palettes for general uses */
        General: {
            Accent: "#0078D4",
            NoData: "#D9D9D9",
            Cancelled: "#888888",
            NoIntent: "#464646",
        },
    },
    /** This sequence utilizes many of the same colors as Neutral Categorical 1 but brightens up the palette in order to draw the eye. Use it only for the most important chart or data on a page.
     *  Tip: Start at the top every time. Use in this order, please. The colors are arranged in order to pass AA compliance requirements on white, and next to each other.
     */
    NeutralSequence: {
        /** Basic Sequence. Use in order to conform to accessibility standards */
        Basic: {
            1: Neutrals.BlueDark,
            2: Neutrals.BlueLight,
            3: Neutrals.PurpleDark,
            4: Neutrals.Teal,
            5: Neutrals.Blue,
        },
        /** Bright Sequence. Use in order to conform to accessibility standards */
        Bright: {
            1: Neutrals.BlueDark,
            2: Neutrals.RedLight,
            3: Neutrals.RedDark,
            4: Neutrals.BlueLight,
            5: Neutrals.PurpleDark,
            6: Neutrals.PurpleLight,
            7: Neutrals.GrayDark,
        },
        /** Sequence with Sales colors. Use in order to conform to accessibility standards */
        Sales: {
            /** #004555 */
            1: Sales.Line1,
            /** #00A2AD */
            2: Sales.Line2,
            /** #FFB900 */
            3: Sales.Line3,
        },
    },
};
