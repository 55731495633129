import {
    Fabric,
    IDetailsColumnRenderTooltipProps,
    IDetailsHeaderProps,
    IRenderFunction,
    mergeStyleSets,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
    TooltipHost,
} from "@fluentui/react";
const styles = mergeStyleSets({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        overflowY: "auto",
    },
    main: {
        flex: 1,
        position: "relative",
        overflowY: "scroll",
    },
});
export function StickyWrapper(props: any) {
    return (
        <Fabric className={styles.wrapper}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always}>
                <span style={{ background: "white" }}>{props.children}</span>
            </ScrollablePane>
        </Fabric>
    );
}

export const RenderStickyHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props || !defaultRender) {
        return null;
    }
    
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
        <TooltipHost {...tooltipHostProps} />
    );
    return defaultRender({
        ...props,
        styles: {
            root: {
                selectors: {
                    '.ms-DetailsHeader-cell': {
                        whiteSpace: 'normal',
                        textOverflow: 'clip',
                        lineHeight: 'normal',
                    },
                    '.ms-DetailsHeader-cellTitle': {
                        height: '100%',
                        alignItems: 'center',
                    },
                },
            },
        },
    })
};
