import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/utilities";
import { CheckboxVisibility, DetailsList,
    ScrollablePane, Spinner, TextField } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IOffboardingItem, IOffboardingRequest, IOffboardingResults } from "../../Pages/OffboardingPage";
import HttpService from "../../services/HttpService/HttpService";
import { breakWord } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { linuxOffboardingStepsText } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";
import { IOffboardingQueueItem } from "./DataGridOffboarding";
import {
    convertAPIResultsToMap,
    IOffboardingAPIResult, IToBeOffboardedType, onRenderOffboardingExportRow, resultsColumns, toBeOffboardedcolumns,
} from "./OffboardingFunctions";
import { CSVLink } from "react-csv";

interface ISelectionOffboardPanelProps {
    serverData: IOffboardingQueueItem[];
    refreshAndResetServerData: () => void;
}

enum PanelSection {
    Data,
    Results,
}

export const SelectionOffboardPanel: React.FunctionComponent<ISelectionOffboardPanelProps> = (props: ISelectionOffboardPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [justification, SetJustification] = React.useState<string>("");
    const { state } = React.useContext(RootContext); // auth token context
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Data);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>("Submit");
    const [currentlyOffboarding, SetCurrentlyOffboarding] = React.useState<boolean>(true);
    const [offboardingResultsData, SetOffboardingResultsData] = React.useState<IOffboardingResults[]>([]);
    const [dataGridData, SetDataGridData] = React.useState<IToBeOffboardedType[]>([]);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    const exportCsvFileName = "offboardingResults";

    React.useEffect(() => {
        const data: IToBeOffboardedType[] = [];

        for (const d of props.serverData) {
            const serverData: IToBeOffboardedType = {
                ServerName: d.server,
                Domain: d.domain,
                ServerId: d.serverID,
                ServiceTreeGUID: d.serviceTreeGUID,
            };
            data.push(serverData);
        }

        SetDataGridData(data);

    }, [props.serverData]);

    const offboardServers = () => {
        const serverData: IOffboardingQueueItem[] = props.serverData;
        let selectedData: IOffboardingItem[] = [];
        const allServersMap = new Map<string, IOffboardingResults>();
        const serverIdServiceTreeGUIDMap: Map<string, number[]> = new Map();

        // Get IDs for each server
        for (const item of serverData) {
            if (serverIdServiceTreeGUIDMap.has(item.serviceTreeGUID)) {
                const serverIdArr: number[] = serverIdServiceTreeGUIDMap.get(item.serviceTreeGUID) ?? [];
                serverIdArr.push(item.serverID);
                serverIdServiceTreeGUIDMap.set(item.serviceTreeGUID, serverIdArr);
            } else {
                const serverIdArr: number[] = [item.serverID];
                serverIdServiceTreeGUIDMap.set(item.serviceTreeGUID, serverIdArr);
            }

            selectedData = [...selectedData, {
                key: 0,
                lastSuccessfulPatch: "",
                server: item.server,
                serverID: item.serverID,
                domain: "",
                serviceTreeName: "",
                serviceTreeOwner: "",
                serviceTreeGUID: item.serviceTreeGUID,
            }];
            allServersMap.set(item.server, {
                server: item.server,
                remarks: "Success.",
            });
        }

        // build offboarding request array
        const offboardingRequestArr: IOffboardingRequest[] = [];

        for (const key of serverIdServiceTreeGUIDMap.keys()) {
            offboardingRequestArr.push({
                ServerIDs: serverIdServiceTreeGUIDMap.get(key) ?? [],
                OffboardingRemark: justification,
                ServiceTreeGUID: key,
            });
        }

        httpService.post({
            token: state.AuthStore.Token,
            url: "api/ServerOffboarding/removeServers",
            data: offboardingRequestArr,
        }).then(async (removeResponse: any) =>  {
            const respResults: IOffboardingAPIResult[] = removeResponse.data;
            const trackingString: Set<string> = new Set<string>();

            for (let i = 0; i < respResults.length; ++i) {
                trackingString.add(respResults[i].ServerName + ", " + respResults[i].Remarks);
            }

            trackEvent(appInsights, "Offboarding", "Offboarding Results", Array.from(trackingString).join(","), state.AuthStore, {});

            await convertAPIResultsToMap(selectedData, respResults, allServersMap);
            await updateResults(allServersMap);
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Offboarding", "Removing Servers", "", state.AuthStore, {});
        });
    };

    const updateResults = async (allServersMap: Map<string, IOffboardingResults>) => {
        const allServers: IOffboardingResults[] = [];

        for (const s of allServersMap.values()) {
            allServers.push(s);
        }

        SetOffboardingResultsData(allServers);
        SetCurrentlyOffboarding(false);
    };

    const offboardServersButtonClick = () => {
        SetCurrentPanel(PanelSection.Results);
        SetCurrentlyOffboarding(true);
        SetPrimaryButtonText("Close");

        offboardServers();
    };

    const clickToOpenPanel = () => {
        SetJustification("");
        SetPrimaryButtonText("Submit");
        SetCurrentPanel(PanelSection.Data);
        openPanel();
    };

    return (
      <div>
        <PrimaryButton
            id={"offboardSelectedButton"}
            secondaryText="Offboard Selected Servers"
            ariaLabel="Offboard Selected Servers"
            onClick={() => {
                clickToOpenPanel();
            }}
            text="Offboard Selected"
            disabled={props.serverData?.length === 0}
        />
        <CoherencePanel
            titleText="Selection Offboard Servers"
            title="Selection Offboard Servers"
            headerText="Selection Offboard Servers"
            isOpen={isOpen}
            onDismiss={dismissPanel}
            hasCloseButton={true}
            closeButtonAriaLabel="Close"
            onRenderFooter={{
                primaryButton: {
                    text: primaryButtonText,
                    onAction: (() => {
                        if (primaryButtonText === "Submit") {
                            offboardServersButtonClick();
                        } else {
                            if (!currentlyOffboarding) {
                                props.refreshAndResetServerData();
                                dismissPanel();
                            }
                        }
                    }),
                    disabled: (justification?.length == 0 || props.serverData?.length == 0),
                },
            }}
            panelSize={CoherencePanelSize.medium}
        >
        <div>
            <div hidden={currentPanel !== PanelSection.Data}>
                <h2>Servers to be Offboarded:</h2>
                <ScrollablePane style={{ height: "50vh", position: "relative" }}>
                    <DetailsList
                        columns={toBeOffboardedcolumns}
                        items={dataGridData}
                        compact={true}
                        onRenderDetailsHeader={RenderStickyHeader}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </ScrollablePane>
                <span className={breakWord}>{ReactHtmlParser(linuxOffboardingStepsText)}</span>
                <br />
                <TextField
                    id={"businessJustificationTextField"}
                    value={justification}
                    required
                    label="Business Justification"
                    ariaLabel="Business Justification"
                    onChange={onJustificationChange}
                />
            </div>
            <div hidden={currentPanel !== PanelSection.Results}>
                <h3>Offboarding Results</h3>
                <div hidden={!currentlyOffboarding}>
                    <Spinner label="Offboarding Servers..." ariaLive="assertive" labelPosition="bottom" />
                </div>
                <div hidden={currentlyOffboarding}>
                    <ScrollablePane style={{ height: "60vh", position: "relative" }}>
                        <DetailsList
                            columns={resultsColumns}
                            items={offboardingResultsData}
                            compact={true}
                            onRenderDetailsHeader={RenderStickyHeader}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            onRenderRow={onRenderOffboardingExportRow}
                        />
                    </ScrollablePane>
                    <CSVLink 
                        aria-label={"Export Results"} 
                        filename={exportCsvFileName} 
                        title={"Export Results"} 
                        data={offboardingResultsData}>
                            Export Data
                    </CSVLink>
                </div>
            </div>
        </div>
        </CoherencePanel>
    </div>
    );

    function onJustificationChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        SetJustification(newValue!);
    }
};
