import { IColumn } from '@fluentui/react/lib/DetailsList';

export const isKeyOfInterface = <T>(o: keyof T) => {return o};

export interface SortResults<T> {
    newColumns: IColumn[];
    newItems : T[];
  }
  
export const markColumnSorted = <T>(column: IColumn, allColumns: IColumn[]): IColumn[] => {
    //----- setColumnSorted
    const newColumns: IColumn[] = allColumns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    
    return newColumns;
  };

  export const sortItems = <T>(allItems: T[], field: keyof T, isDescending?: boolean) => {
    //--- sort items 
    const newItems = _copyAndSort(allItems, field, isDescending);
    return newItems;
  }

  function _copyAndSort<T>(items: T[], columnKey: keyof T, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }