import { Card } from "@coherence-design-system/controls";
import { CheckboxVisibility, FontIcon, IColumn, mergeStyles, MessageBarType, ScrollablePane, SelectionMode, 
    ShimmeredDetailsList } from "@fluentui/react";
import React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { getHeader } from "../../Styles/Page.styles";
import { OpenPanelType } from "../../Styles/Page.types";
import { IAdminMessage, IAdminMessageResponse } from "../Home/AdminMessageBanner";
import { useAppInsights } from "../AppInsights/AppInsights";
import { RootContext } from "../Stores/RootStore";
import { getAdminMessages } from "../../services/ApiService/Requests";
import { AdminMessagesPanel, IAdminMessagesPanelProps } from "./AdminMessagesPanel";
import { DataActionEnum } from "../../services/DataContext/DataActions";

export interface IAdminMessageProps {}

const cardSize = mergeStyles({
    width: "70vw",
    height: "35vh",
});

export const AdminMessages: React.FunctionComponent<IAdminMessageProps> = (props: IAdminMessageProps) => {
    const [adminMessagesLoaded, SetAdminMessagesLoaded] = React.useState<boolean>(false);
    const [adminMessages, SetAdminMessages] = React.useState<IAdminMessage[]>([]);
    const { state, dispatch } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    React.useEffect(() => {
        updateMessages();
    }, []);

    const updateMessages = () => {
        getAdminMessages(state, appInsights).then((response: IAdminMessageResponse) => {
            if (response?.data) {
                SetAdminMessages(response?.data.values);
                SetAdminMessagesLoaded(true);

                dispatch({
                    type: DataActionEnum.UPDATE_ADMIN_MESSAGES,
                    adminMessages: response?.data.values,
                });
            }
        }).catch((reason: any) => {
            console.log(reason);
        });
    }

    const refreshDataGrid = () => {
        SetAdminMessagesLoaded(false);
        updateMessages();
    };

    const adminMessagesColumns: IColumn[] = [
        {
            key: "Edit",
            name: "Edit",
            fieldName: "Edit",
            minWidth: 50,
            maxWidth: 70,
            isResizable: true,
        },
        {
            key: "enabled",
            name: "Enabled",
            fieldName: "enabled",
            minWidth: 250,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: "text",
            name: "Text",
            fieldName: "text",
            minWidth: 250,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            minWidth: 130,
            maxWidth: 140,
            isResizable: true,
        },
        {
            key: "pages",
            name: "Pages",
            fieldName: "pages",
            minWidth: 130,
            maxWidth: 140,
            isResizable: true,
        }
    ];

    const defaultAdminMessagesPanelProps: IAdminMessagesPanelProps = {
        requestType: OpenPanelType.Add,
        linkText: "+ Add New Message",
        refreshDataGrid: refreshDataGrid
    }

    const onRenderItemColumn = (item?: IAdminMessage, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IAdminMessage] as any | any[];

            switch (column.fieldName) {
                case "Edit" : {
                    const adminMessagesPanelProps: IAdminMessagesPanelProps = {
                        adminMessage: item,
                        requestType: OpenPanelType.Edit,
                        linkText: "Edit",
                        refreshDataGrid: refreshDataGrid
                    }
    
                    return <AdminMessagesPanel {...adminMessagesPanelProps} />
                }
                case "enabled" : {
                    return <div> {
                        fieldContent   
                        ? ( <span><FontIcon aria-label="Enabled Message" iconName="StatusCircleCheckmark" className={mergeStyles({ color: "green" })} /> Enabled </span>  ) 
                        : (<span> <FontIcon aria-label="Disabled Message" iconName="StatusCircleErrorX" className={mergeStyles({ color: "red" })} /> Disabled </span>  )
                        }
                        </div>;
                }
                case "type" : {
                    return <span>{MessageBarType[fieldContent]}</span>;
                }
                case "pages" : {
                    return <span>{fieldContent?.join(',')}</span>;
                }
                default:
                    return <span>{fieldContent}</span>;
            }
        }
    };

    return (
        <div className={cardSize}>
            <Card header={getHeader("Admin Messages (Displayed on Home Page)", "left")}>
                <AdminMessagesPanel {...defaultAdminMessagesPanelProps} />
                <ScrollablePane style={{ height: "25vh", position: "relative" }}>
                    <ShimmeredDetailsList
                        columns={adminMessagesColumns}
                        items={adminMessages}
                        compact={true}
                        onRenderDetailsHeader={RenderStickyHeader}
                        onRenderItemColumn={onRenderItemColumn}
                        checkboxVisibility={CheckboxVisibility.onHover}
                        selectionMode={SelectionMode.none}
                        enableShimmer={!adminMessagesLoaded}
                    />
                </ScrollablePane>
            </Card>
        </div>
    );
}