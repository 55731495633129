export const AppName: string = "Microsoft Digital Patching Portal";
export const UnauthorizedMessage: string = "You do not have permission to perform this operation";
export const FiveYearsAgo: string = "Over 5 years ago";
export const UserNotAccessOffboard: string = "You do not have access to offboard this server.";
export const ServerNotFoundText: string = "Server not found.";

// Auth token expires after 30 minutes (1800 seconds + 1 second to ensure new token is available)
export const TOKEN_EXPIRY_IN_SECONDS: number = 1801;

// User is prompted after being idle for 20 minutes (1200 seconds)
export const USER_ACTIVITY_COUNTDOWN_IN_SECONDS: number = 1200;

export const linuxOnboardingStepsText: string = "<b>For Linux devices,  it is required to follow these steps to <br /> complete the onboarding process: <a href='https://microsoftit.visualstudio.com/OneITVSO/_wiki/wikis/OneITVSO.wiki/29285/Opt-in-Linux-Patching-Service' target='_blank' rel='noopener noreferrer'>Opt-in Linux Patching Service</a>.</b>";
export const linuxOffboardingStepsText: string = "<b>For Linux devices,  it is required to follow these steps to <br /> complete the offboarding process: <a href='https://microsoftit.visualstudio.com/OneITVSO/_wiki/wikis/OneITVSO.wiki/29291/Opt-out-Linux-Patching-Service' target='_blank' rel='noopener noreferrer'>Opt-out Linux Patching Service</a>.</b>";