import { Depths, ScrollablePane } from "@fluentui/react";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackPageView } from "../components/AppInsights/LoggingHelper";
import { DataGridOffboarding } from "../components/Offboarding/DataGridOffboarding";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { UserPermission } from "../services/AuthenticationService/AuthReducer";
import { breakWord, _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { DataActionEnum } from "../services/DataContext/DataActions";
import { PAGE } from "../App";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";

export interface IOffboardingItem {
    key: number;
    server: string;
    lastSuccessfulPatch: string;
    domain: string;
    serviceTreeName: string;
    serviceTreeGUID: string;
    serviceTreeOwner: string;
    serverID: number;
}

export interface IOffboardingResults {
    server: string;
    remarks: string;
}

export interface IOffboardingRequest {
    ServerIDs: number[];
    OffboardingRemark: string;
    ServiceTreeGUID: string;
}

export interface IResetPendingOffboardRequest {
    serverids: number[];
}

export const domainToSecurityGroup = new Map<string, string>();

const pageTitle: string = "Offboard Servers";

export const OffboardingPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const { state, dispatch } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    React.useEffect(() => {
        document.title = pageTitle;

        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "offboarding"
        });

        const telemetry = buildPageViewTelemetry(
            pageTitle,
            "/offboarding",
            "/offboarding",
            state.AuthStore.Email,
            state.AuthStore.isAdmin,
        );

        trackPageView(appInsights, telemetry);
    }, []);

    return (
        <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
            <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                <AdminMessageBanner page={PAGE.OFFBOARDING} />
            </div>
            <div className={_Styles.rootDiv}>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Permitted || state.AuthStore.UserTimeOut}>
                    <h1 id="offboardingtitle" className={breakWord}>
                        {ReactHtmlParser(pageTitle)}
                    </h1>
                    <div className="text-center">
                        {state.AuthStore.Token !== "" &&
                            <DataGridOffboarding />
                        }
                    </div>
                </div>
                <BackToTopButton />
                <PagePermissionsComponent />
            </div>
        </ScrollablePane>
    );
};
