import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/utilities";
import { ActionButton, Dropdown, FontSizes, IDropdownOption, IDropdownStyles, IStackTokens, 
    ITextFieldStyles, Link, MessageBarType, PrimaryButton, Spinner, Stack, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React from "react";
import { calloutProps } from "../../Styles/Page.styles";
import { OpenPanelType } from "../../Styles/Page.types";
import { RootContext } from "../Stores/RootStore";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import HttpService from "../../services/HttpService/HttpService";
import { IAdminMessage } from "../Home/AdminMessageBanner";
import { PAGE } from "../../App";

export interface IAdminMessagesPanelProps {
    adminMessage?: IAdminMessage;
    requestType: OpenPanelType;
    linkText: string;
    refreshDataGrid: () => void;
}

enum PanelSection {
    Definition,
    Review,
    Results,
    Delete
}

// Consts //
const textStyles: Partial<ITextFieldStyles> = {};

// Styles //
const dropdownStyles: Partial<IDropdownStyles> = {
    subComponentStyles: {
        label: {
            root: {
                // fontWeight: "bold",
                padding: "2px",
            },
        },
        multiSelectItem: {},
        panel: {},
    },
};

const stackTokens: IStackTokens = {
    childrenGap: 50 + " " + 150, // vertical gap + ' ' + horizontal gap,
};

const deleteMessageText: string = "Are you sure you want to delete this template?";
const noTextErrorText: string = "Admin Message cannot be blank";

const defaultMessage: IAdminMessage = {
    id: "",
    type: MessageBarType.info,
    text: "",
    enabled: true,
    pages: []
}

export const AdminMessagesPanel: React.FunctionComponent<IAdminMessagesPanelProps> = (props: IAdminMessagesPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Definition);

    const [messageId, SetMessageId] = React.useState<string>(props.adminMessage?.id ? props.adminMessage.id : defaultMessage.id)
    const [messageType, SetMessageType] = React.useState<MessageBarType>(props.adminMessage ? props.adminMessage.type : defaultMessage.type);
    const [messageText, SetMessageText] = React.useState<string>(props.adminMessage ? props.adminMessage.text : defaultMessage.text);
    const [messageEnabled, SetMessageEnabled] = React.useState<boolean>(props.adminMessage ? props.adminMessage.enabled : defaultMessage.enabled);
    const [typeOptions, SetTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [pageOptions, SetPageOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedPages, SetSelectedPages] = React.useState<string[]>(props.adminMessage && props.adminMessage?.pages !== undefined ? props.adminMessage?.pages : defaultMessage.pages);

    const [panelTitle, SetPanelTitle] = React.useState<string>(props.requestType === OpenPanelType.Add ? "Create New Admin Message" : "Edit Admin Message");
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string | undefined | "">("Next");
    const [secondaryButtonText, SetSecondaryButtonText] = React.useState<string | undefined | "">("");

    const [hideDefinitionDiv, SetHideDefinitionDiv] = React.useState<boolean>(true);
    const [hideReviewDiv, SetHideReviewDiv] = React.useState<boolean>(true);
    const [hideResultsDiv, SetHideResultsDiv] = React.useState<boolean>(true);
    const [hideDeleteDiv, SetHideDeleteDiv] = React.useState<boolean>(true);

    // Results
    const [confirmationTextHidden, SetConfirmationTextHidden] = React.useState<boolean>(true);
    const [submitResult, SetSubmitResult] = React.useState<string>("");

    const [errorMessage, SetErrorMessage] = React.useState("");

    const { state } = React.useContext(RootContext);

    const linkText = props.linkText;
    const tooltipId = useId("tooltip");
    
    const appInsights = useAppInsights();

    const [httpService] = React.useState(HttpService(appInsights, state));
    
    React.useEffect(() => {
        const types: IDropdownOption[] = [];
        for (const key in MessageBarType) {
            if (isNaN(Number(key))) {
                types.push({
                    key: MessageBarType[key],
                    text: key
                });
            }
        }

        SetTypeOptions(types);

        const pages: IDropdownOption[] = [];
        for(const page of Object.values(PAGE)) {
            pages.push({
                key: page,
                text: page
            });
        }

        SetPageOptions(pages);
    }, []);

    const updateType = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item) {
            SetMessageType(Number(item?.key));
        }
    }

    const updateSelectedPages = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item) {
            console.log(item);
            console.log(selectedPages);
            SetSelectedPages(
              item.selected ? [...selectedPages, item.key as string] : selectedPages?.filter(key => key !== item.key),
            );
          }
    }

    // helper function for panel type (add vs edit)
    const isAdd = () => {
        if (props.requestType === OpenPanelType.Add) {
            return true;
        } else {
            return false;
        }
    };

    const clickToOpenPanel = () => {
        openPanelSection(PanelSection.Definition);
        openPanel();
    };

    const openPanelSection = (section: PanelSection) => {
        switch (section) {
            case PanelSection.Definition: {
                SetCurrentPanel(PanelSection.Definition);
                SetHideDefinitionDiv(false);
                SetHideReviewDiv(true);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Review: {
                SetCurrentPanel(PanelSection.Review);
                SetHideDefinitionDiv(true);
                SetHideReviewDiv(false);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Results: {
                SetCurrentPanel(PanelSection.Results);
                SetHideDefinitionDiv(true);
                SetHideReviewDiv(true);
                SetHideResultsDiv(false);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Delete: {
                SetCurrentPanel(PanelSection.Delete);
                SetHideDefinitionDiv(true);
                SetHideReviewDiv(true);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(false);
                return;
            }
        }
    };

    const clickToClosePanel = () => {
        dismissPanel();
        props.refreshDataGrid();
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    const clickDeleteButton = () => {
        SetPrimaryButtonText("Delete");
        SetSecondaryButtonText("Back");

        openPanelSection(PanelSection.Delete);
    }

    const validateMessage = () => {
        if (messageText.length == 0) {
            SetErrorMessage(noTextErrorText)
            return false;
        }

        return true;
    }

    const panelPrimaryButtonAction = () => {
        switch (currentPanel) {
            case PanelSection.Definition: {
                if (validateMessage()) {
                    SetSecondaryButtonText("Back");
                    SetPrimaryButtonText("Save");
                    openPanelSection(PanelSection.Review);
                } 

                return;
            }
            case PanelSection.Review: {
                openPanelSection(PanelSection.Results);

                addUpdateMessage().then((response: any) => {
                    SetSubmitResult("Submit Successful. Please Close.");
                }).catch((reason: any) => {
                    console.log(reason);
                    SetSubmitResult("Something went wrong.");
                }).finally(() => {
                    SetPrimaryButtonText("");
                    SetSecondaryButtonText("Close");
                    SetConfirmationTextHidden(false);
                });

                return;
            }
            case PanelSection.Delete: {
                openPanelSection(PanelSection.Results);

                deleteMessage().then((response: any) => {
                    SetSubmitResult("Deletion Successful. Please Close.");
                }).catch((reason: any) => {
                    console.log(reason);
                    SetSubmitResult("Something went wrong.");
                }).finally(() => {
                    SetPrimaryButtonText("");
                    SetSecondaryButtonText("Close");
                    SetConfirmationTextHidden(false);
                });

                return;
            }
            case PanelSection.Results: {
                return;
            }
            default: {
                return;
            }
        }
    }

    const panelSecondaryButtonAction = async () => {
        switch (currentPanel) {
            case PanelSection.Definition: {
                // no secondary button
                return;
            }
            case PanelSection.Review: {
                openPanelSection(PanelSection.Definition);
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                return;
            }
            case PanelSection.Delete: {
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                clickToClosePanel();
                return;
            }
            case PanelSection.Results: {
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                clickToClosePanel();
                return;
            }
            default: {
                return;
            }
        }
    }

    const addUpdateMessage = async () => {
        SetPrimaryButtonText("");
        SetSecondaryButtonText("");
        SetConfirmationTextHidden(true);

        return await httpService.post({
            url: "api/AdminMessages/AddUpdate",
            token: state.AuthStore.Token,
            data: {
                id: messageId,
                text: messageText,
                type: Number(messageType),
                enabled: messageEnabled,
                pages: selectedPages,
            },
        }).then((resp: any) => {
            return resp?.data;
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Admin Messages", "addUpdateMessage", "Add or Update Admin Message", state.AuthStore, {});
        });
    }

    const deleteMessage = async () => {
        SetPrimaryButtonText("");
        SetSecondaryButtonText("");
        SetConfirmationTextHidden(true);

        return await httpService.delete({
            url: "api/AdminMessages/Delete",
            token: state.AuthStore.Token,
            params: {
                id: messageId,
            },
        }).then((resp: any) => {
            return resp?.data;
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Admin Messages", "deleteMessage", "Delete Admin Message", state.AuthStore, {});
        });
    }

    const onMessageTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
        if (newValue) {
            SetErrorMessage("");
            SetMessageText(newValue);
        }
    }

    const onMessageEnabled = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined): void => {
        if (checked !== undefined) {
            SetMessageEnabled(checked);
        }
    };

    return (
        <div>
            <div hidden={isAdd()}>
                <Link onClick={(linkText === "Loading" || isOpen) ? () => { } : clickToOpenPanel}>{linkText}</Link>
            </div>
            <div hidden={!isAdd()}>
                <PrimaryButton onClick={isOpen ? () => {} : clickToOpenPanel}>{linkText}</PrimaryButton>
            </div>
            {isOpen && 
                <CoherencePanel
                    titleText={panelTitle}
                    title={panelTitle}
                    headerText={panelTitle}
                    isOpen={isOpen}
                    hasCloseButton={true}
                    onDismiss={clickToClosePanel}
                    onLightDismissClick={lightDismissPanel}
                    closeButtonAriaLabel="Close"
                    onRenderFooter={{
                        primaryButton: {
                            text: primaryButtonText,
                            onAction: (() => {
                                panelPrimaryButtonAction();
                            }),
                            disabled: false
                        },
                        secondaryButton: {
                            text: secondaryButtonText,
                            onAction: (() =>{
                                panelSecondaryButtonAction();
                            }),
                            disabled: false,
                        }
                    }}
                    panelSize={CoherencePanelSize.medium}
                >
                    <div>
                        <div hidden={hideDefinitionDiv}>
                            <Stack horizontal tokens={stackTokens}>
                                <Stack.Item align="end">
                                    <Toggle 
                                        defaultChecked={messageEnabled}
                                        inlineLabel
                                        onText="Enabled"
                                        offText="Disabled"
                                        onChange={onMessageEnabled}
                                    />
                                </Stack.Item>
                                <Stack.Item align="end">
                                    <ActionButton
                                        iconProps={isAdd() ? {} : { iconName: "Delete" }}
                                        text={isAdd() ? "" : "Delete"}
                                        hidden={isAdd()}
                                        disabled={isAdd()}
                                        onClick={() => clickDeleteButton()}
                                        ariaLabel={"Delete Message"}
                                        ariaDescription={"Delete Message"}
                                    />
                                </Stack.Item>
                            </Stack>
                            <div style={{ padding: "0px" }}>
                                <TooltipHost
                                    content="Message Text"
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <TextField required
                                        label="Message Text"
                                        styles={textStyles}
                                        ariaLabel="Message Text"
                                        onChange={onMessageTextChange}
                                        value={messageText}
                                    />
                                    <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                        {errorMessage}
                                    </p>
                                </TooltipHost>
                            </div>
                            <br />
                            <div style={{ padding: "0px" }}>
                                <Dropdown 
                                    required
                                    placeholder="Type"
                                    label="Type"
                                    ariaLabel="Type"
                                    aria-required="false"
                                    options={typeOptions}
                                    styles={dropdownStyles}
                                    selectedKey={Number(messageType)}
                                    onChange={updateType}
                                />
                            </div>
                            <div style={{ padding: "0px" }}>
                                <Dropdown 
                                    required
                                    placeholder="Pages"
                                    label="Pages to display message"
                                    ariaLabel="Pages to display message"
                                    aria-required="false"
                                    options={pageOptions}
                                    styles={dropdownStyles}
                                    selectedKeys={selectedPages}
                                    onChange={updateSelectedPages}
                                    multiSelect={true}
                                />
                            </div>
                        </div>
                        <div hidden={hideReviewDiv}>
                            <div style={{ padding: "0px" }}>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                    Message
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {messageText}
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                        Type
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {MessageBarType[messageType]}
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                        Pages
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {selectedPages?.join(', ')}
                                </label>
                            </div>
                        </div>
                        <div style={{ padding: "0px" }} hidden={hideResultsDiv} id="ResultsDiv">
                        <span hidden={!confirmationTextHidden}>
                            <Spinner label="Submitting admin message..." ariaLive="assertive" labelPosition="bottom" />
                        </span>
                        <span hidden={confirmationTextHidden}>
                            <p role="alert" aria-live="assertive"><b>Results</b>: {submitResult}</p>
                        </span>
                    </div>
                        <div hidden={hideDeleteDiv}>
                            {deleteMessageText}
                        </div>
                    </div>
                </CoherencePanel>
            }
        </div>
    );
}