import { AccountInfo } from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AxiosResponse } from "axios";
import { trackEvent, trackException } from "../components/AppInsights/LoggingHelper";
import { prodAuthProvider  } from "../services/AuthenticationService/AuthProvider";
import httpAuthService from "../services/AuthenticationService/httpAuthService";
import { IAuthState } from "./AuthenticationService/AuthReducer";

// Fetch user profile image using Microsoft Graph API
export const fetchUserProfileImg = async (userAccount: AccountInfo, authState: IAuthState, appInsights: ApplicationInsights) => {
    try {
        const authToken = await prodAuthProvider.acquireTokenSilent({
            account: userAccount,
            scopes: ["https://graph.microsoft.com/.default"],
        });
        const token = "Bearer " + authToken.accessToken;

        const url = "https://graph.microsoft.com/v1.0/me/photo/$value";
        const res: AxiosResponse<any> = await httpAuthService.get(url, {
            headers: { Authorization: token, Accept: "image/pjpeg" },
            responseType: "arraybuffer",
        });
        // convert binary data to Url
        const bloblUrl = window.URL.createObjectURL(new Blob([res.data]));
        trackEvent(appInsights, "FetchUserImage", "FetchUserImage", bloblUrl, authState, {});
        return bloblUrl;
    } catch (error: any) {
        trackException(appInsights, error, SeverityLevel.Error, "FetchUserImage", "Fetching User Image", userAccount?.username, authState, {});

        if (error?.response?.status !== 404) {
            console.error("img error: " + error);
        }
    }
};
