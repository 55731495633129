import { DefaultButton, Dropdown, IDropdownStyles, IRefObject, Label, Stack } from "@fluentui/react";
import { IDropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import dayjs from "dayjs";
import duration, { Duration }  from "dayjs/plugin/duration";
import * as React from "react";

dayjs.extend(duration);

export interface ITimePickerProps {
    className?: string;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    value: Duration; // moment.Duration;
    onChanged: (value: Duration) => void;
    componentRef: IRefObject<IDropdown>;
}

export interface ITimePickerState {
    hour: number;
    minute: number;
    ampm: boolean; // AM = false, PM = true
}

export const dropdownStylesMinutes: Partial<IDropdownStyles> = {
    root: {  minWidth: "60px", maxWidth: "100px" },
    label: { display: "none" },
};

export const TimePicker: React.FunctionComponent<ITimePickerProps> = (props: ITimePickerProps) => {
    // Current Time
    const [currentHours, SetCurrentHours] = React.useState<number>(0);
    const [currentMinutes, SetCurrentMinutes] = React.useState<number>(0);
    const [currentAmpm, SetCurrentAmpm] = React.useState<boolean>(true);

    // Dropdown Options
    const [minutesOptions, SetMinutesOptions] = React.useState<IDropdownOption[]>([]);
    const [hoursOptions, SetHoursOptions] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        const hoursOps: IDropdownOption[] = [];
        const minutesOps: IDropdownOption[] = [];

        if (hoursOps.length === 0) {
                for (let i = 1; i < 13; i++) {
                    hoursOps.push(
                        {
                            key: i === 12 ? 0 : i,
                            text: i < 10 ? ("0" + i.toString()).toString() : i.toString(),
                        },
                    );
                }
            }

        if (minutesOps.length === 0) {
                for (let i = 0; i < 60; i += 5) {
                    minutesOps.push(
                        {
                            key: i,
                            text: i < 10 ? ("0" + i.toString()).toString() : i.toString(),
                        },
                    );
                }
            }

        SetHoursOptions(hoursOps);
        SetMinutesOptions(minutesOps);
    }, []);

    React.useEffect(() => {
        if (minutesOptions.length !== 0) {
            SetCurrentHours(props.value.hours() % 12);
            SetCurrentMinutes(Math.floor(props.value.minutes() / 5) * 5);
            SetCurrentAmpm(props.value.hours() >= 12);
        }
    }, [minutesOptions]);

    const constructDuration = (hour: number, minute: number, ampm: boolean) => {
        const convertedHours: number = hour + (ampm ? 12 : 0);
        const v = dayjs.duration({ hours: convertedHours, minutes: minute });

        SetCurrentHours(hour);
        SetCurrentMinutes(minute);
        SetCurrentAmpm(ampm);
        return v;
    };

    const onHourChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
        const newHour = option?.key as number;
        props.onChanged(constructDuration(newHour, currentMinutes, currentAmpm));
    };

    const onMinuteChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
        const newMinute = option?.key as number;
        props.onChanged(constructDuration(currentHours, newMinute, currentAmpm));
    };

    const onAMPMClicked = () => {
        props.onChanged(constructDuration(currentHours, currentMinutes, !currentAmpm));
    };

    return (
        <div>
            {props.label && <Label required={props.required}>{props.label}</Label>}
            <Stack horizontal>
                <span>
                    <Dropdown required
                        disabled={props.disabled}
                        aria-label={props.label + " " + currentHours.toString()}
                        options={hoursOptions}
                        selectedKey={currentHours}
                        onChange={onHourChanged}
                    />
                </span>
                <span>
                    <Dropdown required
                        disabled={props.disabled}
                        aria-label={props.label + " " + currentMinutes.toString()}
                        options={minutesOptions}
                        selectedKey={currentMinutes}
                        styles={dropdownStylesMinutes}
                        onChange={onMinuteChanged}
                        componentRef={props.componentRef}
                    />
                </span>
                <span>
                    <DefaultButton
                        aria-label={props.label + " " + currentAmpm ? "PM" : "AM"}
                        disabled={props.disabled}
                        text={currentAmpm ? "PM" : "AM"}
                        onClick={onAMPMClicked}
                    />
                </span>
            </Stack>
        </div>
    );
};

TimePicker.defaultProps = {
    value: dayjs.duration({ hours: 1 }),
    onChanged: () => { },
    disabled: false,
    className: "",
};
