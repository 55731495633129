import React, { useContext } from "react";
import { useAppInsights } from "../../components/AppInsights/AppInsights";
import { AuthExpiredDialog } from "../../components/Shared/AuthExpiredDialog";
import { TOKEN_EXPIRY_IN_SECONDS, USER_ACTIVITY_COUNTDOWN_IN_SECONDS } from "../../components/Shared/AppConstants";
import { RootContext } from "../../components/Stores/RootStore";
import { AuthActionEnum } from "../AuthenticationService/AuthActions";
import { AuthToken } from "../AuthenticationService/FetchAuthToken";
import { UserCallResult } from "../HttpService/HttpClient";
import { getCallResult } from "../HttpService/HttpService";

export interface IActivityTokenTimeoutProps {}

export const ActivityTokenTimeout: React.FunctionComponent<IActivityTokenTimeoutProps> = (props: IActivityTokenTimeoutProps) => {

    // Token Expiry Countdown
    const [tokenExpiryCounter, SetTokenExpiryCounter] = React.useState(TOKEN_EXPIRY_IN_SECONDS);
    const [resetTokenExpiryCounter, SetResetTokenExpiryCounter] = React.useState<boolean>(false);
    const [userActiveCounter, SetUserActiveCounter] = React.useState<number>(USER_ACTIVITY_COUNTDOWN_IN_SECONDS);
    const [userCallValue, SetUserCallValue] = React.useState<UserCallResult>(getCallResult());
    const { state, dispatch } = useContext(RootContext);

    const userActiveTimer = React.useRef<any>(null);
    const tokenExpiryTimer = React.useRef<any>(null);

    const appInsights = useAppInsights();

    React.useEffect(() => {
        if (!state.AuthStore.UserTimeOut && tokenExpiryCounter > 0) {
          // logic located in App.tsx
        } else if (userActiveCounter > 0) {
          // Fetch new token
          AuthToken(state.AuthStore.Account, [`${process.env.REACT_APP_CLIENT_ID}/.default`], state.AuthStore, appInsights).then((authResponse: any) => {
              // save new auth token
              dispatch({
                  type: AuthActionEnum.UPDATE_TOKEN,
                  token: authResponse,
              });
          });

          SetTokenExpiryCounter(TOKEN_EXPIRY_IN_SECONDS);
        }
    }, [tokenExpiryCounter]);

    React.useEffect(() => {
        const newCallResult: UserCallResult = getCallResult();
        if (newCallResult !== userCallValue) {
            SetUserCallValue(newCallResult);
            if (newCallResult === UserCallResult.Success) {
                SetUserActiveCounter(USER_ACTIVITY_COUNTDOWN_IN_SECONDS);
            } else if (newCallResult === UserCallResult.Error) {
                SetUserActiveCounter(0);
            } else {}
        }
    }, [getCallResult()]);

    // Reset token when API is called
    React.useEffect(() => {
        if (resetTokenExpiryCounter === true) {
            SetTokenExpiryCounter(TOKEN_EXPIRY_IN_SECONDS);
            SetResetTokenExpiryCounter(false);
        }
    }, [resetTokenExpiryCounter]);

    // Set Token Expired and increment counters
    React.useEffect(() => {
        // ensure user has been active in the last 30 minutes
        if (!state.AuthStore.UserTimeOut && tokenExpiryCounter > 0) {
            // updates every 1 second (1000 milliseconds)
            tokenExpiryTimer.current =  setTimeout(() => SetTokenExpiryCounter(tokenExpiryCounter - 1), 1000);
        }

        return() => {
            clearTimeout(tokenExpiryTimer.current);
        };

    }, [tokenExpiryCounter]);

    React.useEffect(() => {
        if (userActiveCounter <= 0) {
            dispatch({
                type: AuthActionEnum.SET_USER_TIMEOUT,
                userTimeOut: true,
              });
        } else if (!state.AuthStore.UserTimeOut && userActiveCounter > 0) {
            // updates every 1 second (1000 milliseconds)
            userActiveTimer.current = setTimeout(() => SetUserActiveCounter(userActiveCounter - 1), 1000);
        }

        return() => {
            clearTimeout(userActiveTimer.current);
        };
    }, [userActiveCounter]);

    return (
        <div>
            <div hidden={userCallValue !== UserCallResult.Error}>
                <p>Oops! Something went wrong. Please restart the application.</p>
            </div>
            <div>
                <AuthExpiredDialog
                    userActiveCounter={userActiveCounter}
                    SetUserActiveCounter={SetUserActiveCounter} />
            </div>
        </div>
    );
};
