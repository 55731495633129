import { Announced, DetailsListLayoutMode, IColumn,
  IStackTokens, ScrollablePane, 
  SelectionMode, ShimmeredDetailsList, Spinner, Stack, mergeStyles } from "@fluentui/react";
import * as React from "react";
import HttpClient from "../../services/HttpService/HttpClient";
import HttpService from "../../services/HttpService/HttpService";
import { UnauthorizedMessage } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";
import { JobHistoryPanel, IJobHistoryPanelProps, IJobHistoryPanelType } from "./JobHistoryPanel";
import Moment from 'react-moment';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { OnboardedServicesDropdown } from "../Shared/OnboardedServicesDropdown";
import { IServiceDataType } from "../../App";
import { panelPadding } from "@m365-admin/wizard";
import { Guid } from "guid-typescript";

const stackTokens: IStackTokens = {
  childrenGap: 0 + " " + 0, // vertical gap + ' ' + horizontal gap
  padding: `0px 0px 0px 0px`,
};

const mainDivStyles = mergeStyles({
  display: "block",
  margin: "auto",
  overflow: "auto"
});

const stackItemsPadding = mergeStyles({
  padding: "0px 20px 0px 0px"
});

export interface IDetailsListJobHistoryProps {
  appInsights: ApplicationInsights;
}

export interface IDeploymentDataType {
  jobId: string;
  jobName: string;
  originalJobName: string;
  jobStatus: string;
  oSType: string;
  serverIds: string[];
  fqdns: string[];
  details: string;
  jobResults: IJobResult[];
}

export interface IJobResult {
  serverName: string;
  title: string;
  timeGenerated: Date | string;
  serviceName: string;
  serviceTreeGUID: string;
  updateRunName: string;
  installationStatus: string;
}

export interface IDetailsListBasicState {
  items: IDeploymentDataType[];
  allItems: IDeploymentDataType[];
  itemsLoaded: boolean;
  selectedServiceGUID: string;
  selectedServiceName: string;
  httpService: HttpClient;
  columns: IColumn[];
  announced?: JSX.Element;
}

export class DetailsListJobHistory extends React.Component<IDetailsListJobHistoryProps, IDetailsListBasicState> {
  public static contextType: React.Context<any> = RootContext;
  private _emptyItems: IDeploymentDataType[];
  private _columns: IColumn[];

  constructor(props: IDetailsListJobHistoryProps) {
    super(props);

    // Populate with items for demos.
    this._emptyItems = [];

    this._columns = [
      { key: "originalJobName", name: "Job Name", fieldName: "originalJobName", minWidth: 200, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick },
      { key: "jobDate", name: "Date", fieldName: "JobDate", minWidth: 70, maxWidth: 120, isResizable: true, onColumnClick: this._onColumnClick },
      { key: "oSType", name: "OS Type", fieldName: "OSType", minWidth: 50, maxWidth: 100, isResizable: true, onColumnClick: this._onColumnClick },
      { key: "details", name: "Details", fieldName: "Details", minWidth: 50, maxWidth: 100, isResizable: true },
    ];

    this.state = {
      items: this._emptyItems,
      allItems: this._emptyItems,
      itemsLoaded: true,
      selectedServiceGUID: "",
      selectedServiceName: "",
      httpService: HttpService(props.appInsights),
      columns: this._columns,
      announced: undefined,
    };

    this._renderItemColumn = this._renderItemColumn.bind(this);
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    const { items, selectedServiceGUID } = this.state;

    if (prevState.selectedServiceGUID != selectedServiceGUID) {
        this._announceNumberOfJobs(this.state.items.length);
    }
}

  private async fetchJobHistory(selectedServiceGUID: string) {
    const response = await this.state.httpService.get({
      url: "api/JobHistory",
      token: this.context.state.AuthStore.Token,
      params: {
          serviceTreeGUID: Guid.isGuid(selectedServiceGUID) ? selectedServiceGUID : Guid.EMPTY,
      },
    });

    return response;
  }

  private fetchAndPopulate = (serviceFilter: string) => {
    this.setState({
      items: [],
      itemsLoaded: false,
    });

    this.fetchJobHistory(serviceFilter.toString()).then((response: any) => {
      let newItems: IDeploymentDataType[] = [];
      let itemsMap: Map<string, IDeploymentDataType> = new Map();
      if (response?.data !== UnauthorizedMessage) {
        const data: any = response?.data;
        for (const d of data) {
          const jobResults: IJobResult[] = d.jobResults;

          if (itemsMap.has(d.jobId)) {
            const additionalServers: Set<string> = new Set(itemsMap.get(d.jobId)?.serverIds?.concat(d.serverIds?.split(","))!);
            const additionalFQDNs: Set<string> = new Set(itemsMap.get(d.jobId)?.fqdns?.concat(d.fqdn?.split(","))!);
            const combinedJobResults: IJobResult[] = jobResults.concat(itemsMap.get(d.jobId)?.jobResults!);

            const uniqueJobResults: IJobResult[] = combinedJobResults.reduce((filter: IJobResult[], current: IJobResult) => {
              var dk = filter.find((item: IJobResult) => item.title === current.title);
              if (!dk) {
                return filter.concat([current]);
              } else {
                return filter;
              }
            }, []);

            itemsMap.set(d.jobId, {
              jobId: d.jobId,
              jobName: d.jobName,
              originalJobName: d.originalJobName,
              jobStatus: d.jobStatus,
              oSType: d.osType,
              serverIds: Array.from(additionalServers),
              fqdns: Array.from(additionalFQDNs),
              details: "",
              jobResults: uniqueJobResults,
            });
          } else {
            if (d.jobId ?? "") {
              itemsMap.set(d.jobId!, {
                jobId: d.jobId,
                jobName: d.jobName,
                originalJobName: d.originalJobName,
                jobStatus: d.jobStatus,
                oSType: d.osType,
                serverIds: d.serverIds?.split(","),
                fqdns: d.fqdn?.split(","),
                details: "",
                jobResults: jobResults
              });
            }
          }

          newItems = [...newItems, {
            jobId: d.jobId,
            jobName: d.jobName,
            originalJobName: d.originalJobName,
            jobStatus: d.jobStatus,
            oSType: d.osType,
            serverIds: d.serverIds?.split(","),
            fqdns: d.fqdn?.split(","),
            details: "",
            jobResults: jobResults,
          }];
        }

        this.setState({
          items: Array.from(itemsMap.values()),
          allItems: Array.from(itemsMap.values()),
          itemsLoaded: true,
        });
      }
    }).catch((reason: any) => {
      console.log(reason);
      this.setState({
          items: [],
          itemsLoaded: true,
      });
    });
  }

  public render(): JSX.Element {
    const { items, selectedServiceGUID, itemsLoaded, announced } = this.state;

    return (
      <div className={mainDivStyles}> 
        <Stack wrap horizontal grow tokens={stackTokens} verticalAlign="end">
          <Stack.Item align="start" className={stackItemsPadding}>
            <OnboardedServicesDropdown
                id={"serviceDropdown"}
                serviceTreeGuidFromParam={undefined}
                onSelectService={this.onSelectService}
            />
          </Stack.Item>
          <Stack.Item hidden={itemsLoaded} className={stackItemsPadding}>
            <div style={{ paddingBottom: "7px"}}>
              <Spinner ariaLive="assertive" label="Collecting job data, this could take a minute..." labelPosition="right" />
            </div>
          </Stack.Item>
        </Stack>
        <div id="jobHistoryDetailsList" hidden={selectedServiceGUID === ""}>
          {announced}
          <ScrollablePane style={{ height: "65vh", position: "relative" }}>
            <ShimmeredDetailsList
              items={items}
              columns={this._columns}
              onRenderItemColumn={this._renderItemColumn}
              enableShimmer={!itemsLoaded}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn={"Toggle selection"}
              ariaLabelForSelectAllCheckbox={"Toggle selection for all items"}
              checkButtonAriaLabel={"Toggle selection for all items"}
              ariaLabelForGrid={"Job History Table"}
              selectionMode={SelectionMode.none}
            />
          </ScrollablePane>
        </div>
      </div>
    );
  }

  private _announceNumberOfJobs = (items: number) => {
    this.setState({
        announced: <Announced
            id={"jobCountAnnouncement"}
            message={"Number of Jobs: " + items}
        />}
    );
}

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    const newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);

    this.setState({
      columns: newColumns,
      items: newItems,
    });
  }

  private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }

  private onSelectService = (service: IServiceDataType) => {
    this.setState({
      items: [],
      selectedServiceGUID: service.ServiceTreeGUID,
      selectedServiceName: service.ServiceName,
    });

    this.fetchAndPopulate(service.ServiceTreeGUID);
  }
  
  private _renderItemColumn(item?: IDeploymentDataType, index?: number, column?: IColumn) {
    const { selectedServiceGUID, selectedServiceName } = this.state;
    if (item !== undefined && column !== undefined) {
      const fieldContent = item[column.key as keyof IDeploymentDataType] as string;
      switch (column.fieldName) {
        case "Details":
          const jobData: IJobHistoryPanelType = {
            JobId: item.jobId,
            JobName: item.jobName,
            ServiceName: selectedServiceName,
            ServiceTreeGUID: selectedServiceGUID,
            ServerIds: item.serverIds,
            FQDNs: item.fqdns,
            JobResults: item.jobResults,
          }

          const jobHistoryPanelProps: IJobHistoryPanelProps = {
            jobName: item.originalJobName,
            jobData: jobData,
            buttonText: "Details"
          }

          return <JobHistoryPanel {...jobHistoryPanelProps} />;
        case "JobDate": 
              if (item.jobResults.length > 0) {
                  return item.jobResults.length > 0 ? <span>{<Moment format="hh:mm MM-DD-yyyy">{item.jobResults[0].timeGenerated!.toString()}</Moment>}</span> : <span>{"-"}</span>;
              } else {
                  return "N/A";
          }
          
        default:
          return <span>{fieldContent}</span>;
      }
    }
  }
}