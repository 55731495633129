import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/utilities";
import { ActionButton, Dropdown, FontSizes, IDropdownOption, IDropdownStyles, IStackTokens, 
    ITextFieldStyles, Label, Link, PrimaryButton, Spinner, Stack, TextField, TooltipHost } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React from "react";
import { calloutProps } from "../../Styles/Page.styles";
import { OpenPanelType } from "../../Styles/Page.types";
import { IEmailTemplatesType } from "./EmailTemplates";
import { RootContext } from "../Stores/RootStore";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import HttpService from "../../services/HttpService/HttpService";

export interface IEmailTemplatesPanelProps {
    emailTemplate: IEmailTemplatesType | undefined;
    requestType: OpenPanelType;
    linkText: string;
    refreshDataGrid: () => void;
}

enum PanelSection {
    Definition,
    Review,
    Results,
    Delete
}

enum ParameterTypes {
    // basic types
    String = "string",
    Int = "int",
    Bool = "bool",
    Url = "url",
    //complex types
    Table = "table",
    List = "list",
    ObjectsTable = "objects-table",
    Html = "html"
} 

// Consts //
const textStyles: Partial<ITextFieldStyles> = {};

// Styles //
const dropdownStyles: Partial<IDropdownStyles> = {
    subComponentStyles: {
        label: {
            root: {
                // fontWeight: "bold",
                padding: "2px",
            },
        },
        multiSelectItem: {},
        panel: {},
    },
};

const stackTokens: IStackTokens = {
    childrenGap: 50 + " " + 150, // vertical gap + ' ' + horizontal gap,
};

const emailTemplateNameErrorMessageText = "Template name cannot be blank.";
const emailTemplateNameErrorMessageTextSpace = "Template name cannot contain a space.";
const emailTemplateNameErrorMessageTextTooLong = "Template name cannot be greater than 54.";
const emailTemplateNameErrorMessageTextInvalidChars="Template name can contain only characters, digits, _ and -"
const defaultParameters: Map<string, string> = new Map<string, string>([
    ["DeviceNamesList", ParameterTypes.List],
    ["ServiceName", ParameterTypes.String]
]);

export const EmailTemplatesPanel: React.FunctionComponent<IEmailTemplatesPanelProps> = (props: IEmailTemplatesPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Definition);

    const [emailTemplateName, SetEmailTemplateName] = React.useState<string>(props.emailTemplate?.Name ?? "");
    const [emailTemplateParameters, SeteEmailTemplateParameters] = React.useState<Map<string,string>>(defaultParameters);

    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string | undefined | "">("Next");
    const [secondaryButtonText, SetSecondaryButtonText] = React.useState<string | undefined | "">("");

    const [hideDefinitionDiv, SetHideDefinitionDiv] = React.useState<boolean>(true);
    const [hideReviewDiv, SetHideReviewDiv] = React.useState<boolean>(true);
    const [hideResultsDiv, SetHideResultsDiv] = React.useState<boolean>(true);
    const [hideDeleteDiv, SetHideDeleteDiv] = React.useState<boolean>(true);

    // Results
    const [confirmationTextHidden, SetConfirmationTextHidden] = React.useState<boolean>(true);
    const [submitResult, SetSubmitResult] = React.useState<string>("");

    const [emailTemplateNameErrorMessage, SetEmailTemplateNameErrorMessage] = React.useState("");
    const [deleteTemplateText, SetDeleteTemplateText] = React.useState("Are you sure you want to delete this template?");

    const { state } = React.useContext(RootContext);

    const linkText = props.linkText;
    const tooltipId = useId("tooltip");
    
    const appInsights = useAppInsights();

    const [httpService] = React.useState(HttpService(appInsights, state));

    // helper function for panel type (add vs edit)
    const isAdd = () => {
        if (props.requestType === OpenPanelType.Add) {
            return true;
        } else {
            return false;
        }
    };

    const clickToOpenPanel = () => {
        openPanelSection(PanelSection.Definition);
        openPanel();
    };

    const openPanelSection = (section: PanelSection) => {
        switch (section) {
            case PanelSection.Definition: {
                SetCurrentPanel(PanelSection.Definition);
                SetHideDefinitionDiv(false);
                SetHideReviewDiv(true);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Review: {
                SetCurrentPanel(PanelSection.Review);
                SetHideDefinitionDiv(true);
                SetHideReviewDiv(false);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Results: {
                SetCurrentPanel(PanelSection.Results);
                SetHideDefinitionDiv(true);
                SetHideReviewDiv(true);
                SetHideResultsDiv(false);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Delete: {
                SetCurrentPanel(PanelSection.Delete);
                SetHideDefinitionDiv(true);
                SetHideReviewDiv(true);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(false);
                return;
            }
        }
    };

    const clickToClosePanel = () => {
        dismissPanel();
        props.refreshDataGrid();
        SetEmailTemplateName("");
        SetEmailTemplateNameErrorMessage("");
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    const clickDeleteButton = () => {
        SetPrimaryButtonText("Delete");
        SetSecondaryButtonText("Back");

        openPanelSection(PanelSection.Delete);
    }

    const panelPrimaryButtonAction = () => {
        switch (currentPanel) {
            case PanelSection.Definition: {
                if (validateEmailTemplateDefinition()) {
                    SetSecondaryButtonText("Back");
                    SetPrimaryButtonText("Save");
                    openPanelSection(PanelSection.Review);
                } 
                return;
            }
            case PanelSection.Review: {
                openPanelSection(PanelSection.Results);

                submitTemplate().then((response: any) => {
                    SetSubmitResult("Submit Successful. Please Close.");
                }).catch((reason: any) => {
                    console.log(reason);
                    SetSubmitResult("Something went wrong.");
                }).finally(() => {
                    SetPrimaryButtonText("");
                    SetSecondaryButtonText("Close");
                    SetConfirmationTextHidden(false);
                });
                return;
            }
            case PanelSection.Delete: {
                openPanelSection(PanelSection.Results);

                deleteTemplate().then((response: any) => {
                    SetSubmitResult("Deletion Successful. Please Close.");
                }).catch((reason: any) => {
                    console.log(reason);
                    SetSubmitResult("Something went wrong.");
                }).finally(() => {
                    SetPrimaryButtonText("");
                    SetSecondaryButtonText("Close");
                    SetConfirmationTextHidden(false);
                });
                return;
            }
            case PanelSection.Results: {
                return;
            }
            default: {
                return;
            }
        }
    }

    const panelSecondaryButtonAction = async () => {
        switch (currentPanel) {
            case PanelSection.Definition: {
                // no secondary button
                return;
            }
            case PanelSection.Review: {
                openPanelSection(PanelSection.Definition);
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                return;
            }
            case PanelSection.Delete: {
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                clickToClosePanel();
                return;
            }
            case PanelSection.Results: {
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                clickToClosePanel();
                return;
            }
            default: {
                return;
            }
        }
    }

    const validateEmailTemplateDefinition = (): boolean => {
        let validData = false;
        const regExEmail = new RegExp("^[a-zA-Z0-9_  ]*$");
        const resRegExEmail = regExEmail.test(emailTemplateName);

        if (emailTemplateName == "") {
            SetEmailTemplateNameErrorMessage(emailTemplateNameErrorMessageText);
        } else if (/\s/.test(emailTemplateName)) {
            SetEmailTemplateNameErrorMessage(emailTemplateNameErrorMessageTextSpace);
        } else if (emailTemplateName.length >= 54) {
            SetEmailTemplateNameErrorMessage(emailTemplateNameErrorMessageTextTooLong);
        }
        else if (!resRegExEmail) {
            SetEmailTemplateNameErrorMessage(emailTemplateNameErrorMessageTextInvalidChars);
        }
        else {
            validData = true;
            SetEmailTemplateNameErrorMessage("");
        }

        return validData;
    }

    const onRuleTemplateNameChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
            if (newValue) {
                if (/\s/.test(newValue)) {
                    SetEmailTemplateNameErrorMessage(emailTemplateNameErrorMessageTextSpace);
                } else if (newValue.length >= 54) {
                    SetEmailTemplateNameErrorMessage(emailTemplateNameErrorMessageTextTooLong);
                } else {
                    SetEmailTemplateNameErrorMessage("");
                    SetEmailTemplateName(newValue);
                }
            }
        }, [],
    );

    const submitTemplate = async () => {
        SetPrimaryButtonText("");
        SetSecondaryButtonText("");
        SetConfirmationTextHidden(true);

        return await httpService.post({
            url: "api/Email/AddUpdateTemplate",
            token: state.AuthStore.Token,
            data: {
                Name: emailTemplateName,
                Parameters: Object.fromEntries(emailTemplateParameters)
            },
        }).then((resp: any) => {
            return resp?.data;
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Email Templates", "submitTemplate", "Add or Update Email Template", state.AuthStore, {});
        });
    }

    const deleteTemplate = async () => {
        SetPrimaryButtonText("");
        SetSecondaryButtonText("");
        SetConfirmationTextHidden(true);

        return await httpService.delete({
            url: "api/Email/DeleteTemplate",
            token: state.AuthStore.Token,
            params: {
                Name: emailTemplateName
            },
        }).then((resp: any) => {
            return resp?.data;
        }).catch((reason: any) => {
            trackException(appInsights, reason, SeverityLevel.Error, "Email Templates", "deleteTemplate", "Delete Email Template", state.AuthStore, {});
        });
    }

    return (
        <div>
            <div hidden={isAdd()}>
                <Link onClick={(linkText === "Loading" || isOpen) ? () => { } : clickToOpenPanel}>{linkText}</Link>
            </div>
            <div hidden={!isAdd()}>
                <PrimaryButton onClick={isOpen ? () => {} : clickToOpenPanel}>{linkText}</PrimaryButton>
            </div>
            {isOpen && 
                <CoherencePanel
                    titleText={isAdd() ? "Create New Email Template" : emailTemplateName}
                    title={isAdd() ? "Create New Email Template" : emailTemplateName}
                    headerText={isAdd() ? "Create New Email Template" : emailTemplateName}
                    isOpen={isOpen}
                    hasCloseButton={true}
                    onDismiss={clickToClosePanel}
                    onLightDismissClick={lightDismissPanel}
                    closeButtonAriaLabel="Close"
                    onRenderFooter={{
                        primaryButton: {
                            text: primaryButtonText,
                            onAction: (() => {
                                panelPrimaryButtonAction();
                            }),
                            disabled: false
                        },
                        secondaryButton: {
                            text: secondaryButtonText,
                            onAction: (() =>{
                                panelSecondaryButtonAction();
                            }),
                            disabled: false,
                        }
                    }}
                    panelSize={CoherencePanelSize.medium}
                >
                    <div>
                        <div hidden={hideDefinitionDiv}>
                            <Stack horizontal tokens={stackTokens}>
                                <Stack.Item align="end">
                                    <ActionButton
                                        iconProps={isAdd() ? {} : { iconName: "Delete" }}
                                        text={isAdd() ? "" : "Delete"}
                                        hidden={isAdd()}
                                        disabled={isAdd()}
                                        onClick={() => clickDeleteButton()}
                                        ariaLabel={"Delete Template"}
                                        ariaDescription={"Delete Template"}
                                    />
                                </Stack.Item>
                            </Stack>
                            {/* Email Template Name*/}
                            <div style={{ padding: "0px" }}>
                                <TooltipHost
                                    content="Email Template Name"
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                >
                                    <TextField required
                                        label="Email Template Name"
                                        styles={textStyles}
                                        ariaLabel="Email Template Name"
                                        onChange={onRuleTemplateNameChange}
                                        value={emailTemplateName}
                                        disabled={!isAdd()}
                                    />
                                    <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                        {emailTemplateNameErrorMessage}
                                    </p>
                                </TooltipHost>
                            </div>
                            <br />
                            <div style={{ padding: "0px" }}>
                                <EmailParameters 
                                    emailTemplate={props.emailTemplate}
                                    readOnly={true}
                                    textOnly={false}
                                />
                            </div>
                        </div>
                        <div hidden={hideReviewDiv}>
                            <div style={{ padding: "0px" }}>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "bold" }}>
                                    Email Template Name
                                </label>
                                <label style={{ margin: "5px", fontSize: FontSizes.size12, display: "block", fontWeight: "normal" }}>
                                    {emailTemplateName}
                                </label>
                            </div>
                            <div style={{ padding: "0px" }}>
                                <EmailParameters 
                                    emailTemplate={props.emailTemplate}
                                    readOnly={true}
                                    textOnly={true}
                                />
                            </div>
                        </div>
                        <div style={{ padding: "0px" }} hidden={hideResultsDiv} id="ResultsDiv">
                        <span hidden={!confirmationTextHidden}>
                            <Spinner label="One moment please..." ariaLive="assertive" labelPosition="bottom" />
                        </span>
                        <span hidden={confirmationTextHidden}>
                            <p role="alert" aria-live="assertive"><b>Results</b>: {submitResult}</p>
                        </span>
                    </div>
                        <div hidden={hideDeleteDiv}>
                            {deleteTemplateText}
                        </div>
                    </div>
                </CoherencePanel>
            }
        </div>
    );
}

interface IEmailParametersProps {
    emailTemplate: IEmailTemplatesType | undefined;
    readOnly: boolean;
    textOnly: boolean;
}

interface IEmailParametersType {
    Key: number;
    Name: string;
    Type: string;
}

const sectionStackTokens: IStackTokens = {
    childrenGap: 0 + " " + 20, // vertical gap + ' ' + horizontal gap
};

const EmailParameters: React.FunctionComponent<IEmailParametersProps> = (props: IEmailParametersProps) => {
    const [parameters, SetParameters] = React.useState<IEmailParametersType[]>([])
    const [parameterTypeOptions, SetParameterTypeOptions] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        const paramTypes: IDropdownOption[] = Object.entries(ParameterTypes).map(([k, v]) => ({ key: v, text: v }));

        SetParameterTypeOptions(paramTypes);

        if (props.emailTemplate !== undefined) {
            const data: IEmailParametersType[] = Object.entries(props.emailTemplate.Parameters)?.map(([name, type]) => ({ Key: Math.floor((Math.random() * 1000) + 1), Name: name, Type: type }));
            SetParameters(data);
        } else {
            if (!props.textOnly) {
                // use default parameters
                const data: IEmailParametersType[] = [];

                for (const [key, value] of defaultParameters) {
                    data.push({
                        Key: Math.floor((Math.random() * 1000) + 1),
                        Name: key,
                        Type: value
                    });
                }

                SetParameters(data);
            } else {
                // use default parameters
                const data: IEmailParametersType[] = [];

                for (const [key, value] of defaultParameters) {
                    data.push({
                        Key: Math.floor((Math.random() * 1000) + 1),
                        Name: key,
                        Type: value
                    });
                }

                SetParameters(data);
            }
        }
    }, []);

    return (
        <div>
            <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                Parameters
            </Label>
            {parameters.map((p: IEmailParametersType) => {
                return (
                    <div key={p.Key}>
                        <Stack horizontal tokens={sectionStackTokens} verticalAlign="center">
                            <Stack.Item>
                                <TextField
                                    label={"Name"}
                                    styles={textStyles}
                                    ariaLabel={"Name"}
                                    disabled
                                    value={p.Name}
                                    readOnly={props.readOnly}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Dropdown required
                                    placeholder="Type"
                                    label="Type"
                                    ariaLabel="Type"
                                    aria-required="false"
                                    options={parameterTypeOptions}
                                    styles={dropdownStyles}
                                    selectedKey={p.Type}
                                    disabled={props.readOnly}
                                />
                            </Stack.Item>
                        </Stack>
                    </div>
                )
                })
             }
        </div>
    );
}