import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { ActionButton, Dropdown, FontSizes, IDropdownOption, 
    IDropdownStyles, IStackTokens, ITextFieldStyles, Label, Link, PrimaryButton,
    Spinner, Stack, TextField, Toggle, TooltipHost} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import HttpService from "../../services/HttpService/HttpService";
import { OpenPanelType } from "../../Styles/Page.types";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { RootContext } from "../Stores/RootStore";
import { IKustoFunctionDataType, IRulePanelOptions, IRuleType } from "./Rules";

export interface IRulePanelProps {
    refreshDataGrid: () => void;
    requestType: OpenPanelType;
    ruleInfo: IRuleType | undefined;
    linkText: string;
    ruleOptions: IRulePanelOptions;
}

enum PanelSection {
    Definition,
    Results,
    Delete
}

// Consts //
const calloutProps = { gapSpace: 0 };
const textStyles: Partial<ITextFieldStyles> = {};

// Styles //
const dropdownStyles: Partial<IDropdownStyles> = {
    subComponentStyles: {
        label: {
            root: {
                // fontWeight: "bold",
                padding: "2px",
            },
        },
        multiSelectItem: {},
        panel: {},
    },
};

const stackTokens: IStackTokens = {
    childrenGap: 50 + " " + 150, // vertical gap + ' ' + horizontal gap,
};

const errorText = "Something went wrong saving this rule. Please review the rule details and try again.";
const ruleNameErrorMessageText = "Rule name cannot be blank.";
const ruleTextErrorMessageText = "Rule text cannot be blank.";
const ruleHeaderTextErrorMessageText = "Header cannot be blank.";
const ruleFooterTextErrorMessageText = "Footer cannot be blank.";
const kustoFunctionErrorMessageText = "You must select a Kusto Function to query non-compliant servers.";
const emailTemplateErrorMessageText = "You must select an email template to send emails.";
const primaryActionsErrorMessageText = "You must select at least one (1) primary action.";

export const RulePanel: React.FunctionComponent<IRulePanelProps> = (props: IRulePanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Definition);

    const [ruleEnabled, SetRuleEnabled] = React.useState<boolean>(props.ruleInfo?.Enabled!);
    const [ruleId, SetRuleId] = React.useState<string>(props.ruleInfo?.Id ?? "");
    const [ruleName, SetRuleName] = React.useState<string>(props.ruleInfo?.Name ?? "");
    const [ruleText, SetRuleText] = React.useState<string>(props.ruleInfo?.Text ?? "");
    const [ruleHeaderText, SetRuleHeaderText] = React.useState<string>(props.ruleInfo?.HeaderText ?? "");
    const [ruleFooterText, SetRuleFooterText] = React.useState<string>(props.ruleInfo?.FooterText ?? "");
    const [schedule, SetSchedule] = React.useState<number>(props.ruleInfo ? props.ruleInfo.Schedule : 1);
    const [ruleDelay, SetRuleDelay] = React.useState<number>(props.ruleInfo ? props.ruleInfo.Delay : 1);
    const [primaryActions, SetPrimaryActions] = React.useState<string[]>(props.ruleInfo?.PrimaryActions ?? []);
    const [secondaryActions, SetSecondaryActions] = React.useState<string[]>(props.ruleInfo?.SecondaryActions ?? []);
    const [daysOfWeek, SetDaysOfWeek] = React.useState<string[]>(props.ruleInfo?.DaysOfWeek ?? ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]);
    // const [weeksOfMonth, SetWeeksOfMonth] = React.useState<string[]>(props.ruleInfo?.WeeksOfMonth ?? []);
    // set all rules to run in all weeks of the month
    // keeping var for future use
    const weeksOfMonth = ["1","2","3","4","5","6"]; 
    const [kustoFunction, SetKustoFunction] = React.useState<string>(props.ruleInfo?.KustoFunction ?? "");
    const [kustoFunctionArgs, SetKustoFunctionArgs] = React.useState<string>(props.ruleInfo?.KustoFunctionArgs ?? "");
    const [selectedKustoFunction, SetSelectedKustoFunction] = React.useState<IKustoFunctionDataType>();
    const [emailTemplate, SetEmailTemplate] = React.useState<string>(props.ruleInfo?.EmailTemplate ?? "");
    const [automaticOffboard, SetAutomaticOffboard] = React.useState<boolean>(props.ruleInfo?.Automatic !== undefined ? props.ruleInfo?.Automatic : false);

    const [savingRule, SetSavingRule] = React.useState<boolean>(false);
    const [ruleDeleted, SetRuleDeleted] = React.useState<boolean>(false);

    const [hideErrorText, SetHideErrorText] = React.useState<boolean>(true);
    
    const [hideDefinitionDiv, SetHideDefinitionDiv] = React.useState<boolean>(true);
    const [hideResultsDiv, SetHideResultsDiv] = React.useState<boolean>(true);
    const [hideDeleteDiv, SetHideDeleteDiv] = React.useState<boolean>(true);

    const [ruleNameErrorMessage, SetRuleNameErrorMessage] = React.useState("");
    const [ruleTextErrorMessage, SetRuleTextErrorMessage] = React.useState("");
    const [ruleHeaderTextErrorMessage, SetRuleHeaderTextErrorMessage] = React.useState("");
    const [ruleFooterTextErrorMessage, SetRuleFooterTextErrorMessage] = React.useState("");
    const [kustoKustoFunctionArgsErrorMessage, SetKustoFunctionArgsErrorMessage] = React.useState("");
    const [kustoFunctionErrorMessage, SetKustoFunctionErrorMessage] = React.useState("");
    const [emailTemplateErrorMessage, SetEmailTemplateErrorMessage] = React.useState("");
    const [primaryActionsErrorMessage, SetPrimaryActionsErrorMessage] = React.useState("");

    const [deleteRuleSubtext, SetDeleteRuleSubtext] = React.useState("Are you sure you want to delete this rule?");

    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string | undefined | "">("Next");
    const [secondaryButtonText, SetSecondaryButtonText] = React.useState<string | undefined | "">("");

    const linkText = props.linkText;
    const { state } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    const tooltipId = useId("tooltip");
    const tooltipIdKustoFunctionArgs = useId("tooltip-function-args");

    const clickToOpenPanel = () => {
        openRuleSection(PanelSection.Definition);
        openPanel();
    };

    const resetStateVars = ():void => {
        // reset all vars to default state
        SetCurrentPanel(PanelSection.Definition);

        SetRuleEnabled(false);
        SetRuleId("");
        SetRuleName("");
        SetRuleText("");
        SetRuleHeaderText("");
        SetRuleFooterText("");
        SetSchedule(1);
        SetRuleDelay(1);
        SetPrimaryActions([]);
        SetDaysOfWeek(["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]);
        SetSecondaryActions([]);
        SetKustoFunction("");
        SetKustoFunctionArgs("");
        SetSelectedKustoFunction(undefined);
        SetEmailTemplate("");
        SetAutomaticOffboard(false);

        SetSavingRule(false);
        SetRuleDeleted(false);

        SetHideErrorText(true);
        
        SetHideDefinitionDiv(true);
        SetHideResultsDiv(true);
        SetHideDeleteDiv(true);

        SetRuleNameErrorMessage("");
        SetRuleTextErrorMessage("");
        SetRuleHeaderTextErrorMessage("");
        SetRuleFooterTextErrorMessage("");
        SetKustoFunctionArgsErrorMessage("");
        SetKustoFunctionErrorMessage("");
        SetEmailTemplateErrorMessage("");
        SetPrimaryActionsErrorMessage("");
    }

    const clickToClosePanel = () => {
        props.refreshDataGrid();
        resetStateVars();
        openRuleSection(PanelSection.Definition);
        dismissPanel();
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    // helper function for panel type (add vs edit)
    const isAdd = () => {
        if (props.requestType === OpenPanelType.Add) {
            return true;
        } else {
            return false;
        }
    };

    const onRuleNameChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
            if (newValue != undefined) {
                SetRuleName(newValue);
            }
        }, [],
    );

    const onRuleTextChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
            if (newValue != undefined) {
                SetRuleText(newValue);
            }
        }, [],
    );

    const onRuleHeaderTextChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
            if (newValue != undefined) {
                SetRuleHeaderText(newValue);
            }
        }, [],
    );

    const onRuleFooterTextChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
            if (newValue != undefined) {
                SetRuleFooterText(newValue);
            }
        }, [],
    );

    
    const onRuleKustoFunctionArgsChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string, index?: number) => {
            if (newValue != undefined) {
                SetKustoFunctionArgs(newValue);
            }
        }, [],
    );

    const onScheduleChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item?.key && Number(item.key)) {
            SetSchedule(+item.key); // "+" converts to number
        }
        resetDaysOfWeek();
    };

    const onDelayChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item?.key && Number(item.key)) {
            SetRuleDelay(+item.key); // "+" converts to number
        }
    };

    const onFunctionChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item?.key && item.key) {
            SetKustoFunction(item.key.toString());
            // get all available kusto functions
            var allFunctions = props.ruleOptions.AlertFunctions;
            // find selected function by name
            var selectedFunctionObject = allFunctions.find( d => d.Name == item.key.toString())
            // update global var
            SetSelectedKustoFunction(selectedFunctionObject);

            // if kusto function does not accept params, remove it
            var functionParams = selectedFunctionObject?.Parameters;
            var kustoFunctionAcceptsParams :boolean = functionParams !=null && functionParams != "()";
            if(!kustoFunctionAcceptsParams){
                SetKustoFunctionArgs("");
            }
        }
    };

    const onEmailTemplateChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item?.key && item.key) {
            SetEmailTemplate(item.key.toString());
        }
    };

    const onRuleEnabled = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined): void => {
        if (checked !== undefined) {
            SetRuleEnabled(checked);
        }
    };

    const onAutomaticOffboardToggle = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined): void => {
        if (checked !== undefined) {
            SetAutomaticOffboard(checked);
        }
    };

    const resetDaysOfWeek = (): void => {
        // reset if "Daily" is selected
        if(schedule == 1){
            SetDaysOfWeek(["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]);
        }
    }

    const onDaysOfWeekChanged = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item?.key) {
            SetDaysOfWeek([item?.key] as string[]);
        }
    };

    // const onWeeksOfMonthChanged = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
    //     if (item?.key) {
    //         const _: string[] = weeksOfMonth || [];
    //         if (item.selected) {              
    //             _.push(item.key.toString())
    //             SetWeeksOfMonth(_);
    //         } else {
    //             const removeIndex: number = _.indexOf(item.key.toString());

    //             if (removeIndex != -1) {
    //                 _.splice(removeIndex, 1);
    //             }

    //             SetWeeksOfMonth(_);
    //         }
    //     }
    // };


    const onPrimaryActionChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item?.key) {
            const newPrimaryActions: string[] = primaryActions || [];
            if (item.selected) {              
                newPrimaryActions.push(item.key.toString())
                SetPrimaryActions(newPrimaryActions);
            } else {
                const removeIndex: number = newPrimaryActions.indexOf(item.key.toString());

                if (removeIndex != -1) {
                    newPrimaryActions.splice(removeIndex, 1);
                }

                SetPrimaryActions(newPrimaryActions);
            }
        }
    };

    const onSecondaryActionChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        if (item?.key) {
            SetSecondaryActions(
              item.selected ? [...secondaryActions, item.key as string] : secondaryActions.filter(key => key !== item.key),
            );
          }
    };

    const openRuleSection = (section: PanelSection) => {
        switch (section) {
            case PanelSection.Definition: {
                SetCurrentPanel(PanelSection.Definition);
                SetHideDefinitionDiv(false);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Results: {
                SetCurrentPanel(PanelSection.Results);
                SetHideDefinitionDiv(true);
                SetHideResultsDiv(false);
                SetHideDeleteDiv(true);
                return;
            }
            case PanelSection.Delete: {
                SetCurrentPanel(PanelSection.Delete);
                SetHideDefinitionDiv(true);
                SetHideResultsDiv(true);
                SetHideDeleteDiv(false);
                return;
            }
        }
    };
    
    const clickDeleteButton = () => {
        SetPrimaryButtonText("Delete");
        SetSecondaryButtonText("Back");

        openRuleSection(PanelSection.Delete);
    }

    const deleteRule = (ruleToBeDeleted: IRuleType) => httpService.post({
        url: "api/ComplianceRule/removeRule",
        token: state.AuthStore.Token,
        data: {                     
            ruleId: ruleToBeDeleted.Id
        },
    });

    const saveRule = (ruleToBeSaved: IRuleType) => httpService.post({
        url: "api/ComplianceRule/addOrUpdateRule",
        token: state.AuthStore.Token,
        data: {             
            Id: ruleToBeSaved.Id,
            Name: ruleToBeSaved.Name,
            Text: ruleToBeSaved.Text,
            HeaderText: ruleToBeSaved.HeaderText,
            FooterText: ruleToBeSaved.FooterText,
            KustoFunctionArgs: ruleToBeSaved.KustoFunctionArgs,
            Schedule: ruleToBeSaved.Schedule,
            DaysOfWeek: ruleToBeSaved?.DaysOfWeek.join(","),
            WeeksOfMonth: ruleToBeSaved?.WeeksOfMonth.join(","),
            Delay: ruleToBeSaved.Delay,
            KustoFunction: ruleToBeSaved.KustoFunction,
            Enabled: ruleToBeSaved.Enabled,
            EmailTemplate: ruleToBeSaved.EmailTemplate,
            Automatic: ruleToBeSaved.Automatic,
            PrimaryActions: ruleToBeSaved.PrimaryActions,
            SecondaryActions: ruleToBeSaved.SecondaryActions
        },
    });

    const panelPrimaryButtonAction = () => {
        switch (currentPanel) {
            case PanelSection.Definition: {
                if(validateRuleDefinition()) {
                    SetSecondaryButtonText("Back");
                    SetPrimaryButtonText("Save");
                    openRuleSection(PanelSection.Results);
                }
                return;
            }
            case PanelSection.Results: {
                SetSecondaryButtonText("");
                SetSavingRule(true);

                const ruleToBeSaved: IRuleType = {
                    Id: ruleId,
                    Name: ruleName,
                    Text: ruleText,
                    HeaderText: ruleHeaderText,
                    FooterText: ruleFooterText,
                    KustoFunctionArgs: kustoFunctionArgs,
                    PrimaryActions: primaryActions,
                    DaysOfWeek: daysOfWeek,
                    WeeksOfMonth: weeksOfMonth,
                    Schedule: schedule,
                    SecondaryActions: secondaryActions,
                    Delay: ruleDelay,
                    KustoFunction: kustoFunction,
                    Enabled: ruleEnabled,
                    EmailTemplate: emailTemplate,
                    Automatic: automaticOffboard,
                }

                saveRule(ruleToBeSaved).then((resp: any) => {
                    if (resp !== undefined && resp.data !== undefined) {
                        SetSavingRule(false);
                    }
                    
                    SetPrimaryButtonText("");
                    SetSecondaryButtonText("COMPLETE");
                  }).catch((error: any) => {
                    SetSavingRule(false);
                    SetHideErrorText(false);
                    SetPrimaryButtonText("");
                    SetSecondaryButtonText("COMPLETE");
                    trackException(appInsights, error, SeverityLevel.Error, "Rules", isAdd() ? "add rule" :  "update rule", error, state.AuthStore, {});
                  });

                return;
            }
            case PanelSection.Delete: {
                SetPrimaryButtonText("");
                SetSecondaryButtonText("");
                SetDeleteRuleSubtext("Processing...");
        
                const ruleToBeDeleted: IRuleType = {
                    Id: ruleId,
                    Name: ruleName,
                    Text: ruleText,
                    HeaderText: ruleHeaderText,
                    FooterText: ruleFooterText,
                    KustoFunctionArgs: kustoFunctionArgs,
                    PrimaryActions: primaryActions,
                    DaysOfWeek: daysOfWeek,
                    WeeksOfMonth: weeksOfMonth,
                    Schedule: schedule,
                    SecondaryActions: secondaryActions,
                    Delay: ruleDelay,
                    KustoFunction: kustoFunction,
                    Enabled: ruleEnabled,
                    EmailTemplate: emailTemplate,
                    Automatic: automaticOffboard,
                }
                
                deleteRule(ruleToBeDeleted).then((resp: any) => {
                    SetRuleDeleted(true);
                    trackEvent(appInsights, "Admin", "Delete Rule", "Deleted Rule: " + ruleName + " : " + ruleId, state.AuthStore, {});
                    SetDeleteRuleSubtext("Rule deleted. Please click COMPLETE");
                    SetSecondaryButtonText("COMPLETE");
                }).catch((reason: any) => {
                    SetDeleteRuleSubtext("Something went wrong, please restart application and try again.");
                    SetSecondaryButtonText("COMPLETE");
                    trackException(appInsights, reason, SeverityLevel.Error, "Admin", "Delete Rule", "", state.AuthStore, {});
                });

                return;
            }
            default: {
                return;
            }
        }
    };

    const panelSecondaryButtonAction = async () => {
        switch (currentPanel) {
            case PanelSection.Definition: {
                // no secondary button
                return;
            }
            case PanelSection.Results: {
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                secondaryButtonText === "COMPLETE" ? clickToClosePanel() : openRuleSection(PanelSection.Definition);
                return;
            }
            case PanelSection.Delete: {
                SetPrimaryButtonText("Next");
                SetSecondaryButtonText("");
                secondaryButtonText === "COMPLETE" ? clickToClosePanel() : openRuleSection(PanelSection.Definition);
                return;
            }
            default: {
                return;
            }
        }
    };

    const validateKustoFunctionArgs = (functionName :string): boolean => {
        // check if kusto function accepts parameters 
        var allFunctions = props.ruleOptions.AlertFunctions
        var selectedFunction = allFunctions.find( d=> d.Name == functionName)
        var functionParams = selectedFunction?.Parameters;
        var isKustoParamsAccepted :boolean = functionParams !=null && functionParams != "()";

        // get user provided kusto function parameter
        var hasKustoParams = kustoFunctionArgs != null  && kustoFunctionArgs.trim().length > 0;
        
        // function does not accept params, then error if it is passed
        if (!isKustoParamsAccepted && hasKustoParams){
            SetKustoFunctionArgsErrorMessage(`Function ${selectedFunction?.Name} does not accept parameters`);
            return false;
        } else {
            SetKustoFunctionArgsErrorMessage("");
            return true;
        }
    }

    const validateRuleDefinition = (): boolean => {
        let validData: boolean = true;

        if (ruleName == "") {
            SetRuleNameErrorMessage(ruleNameErrorMessageText);
            validData = false;
        } else {
            SetRuleNameErrorMessage("");
        }

        if (ruleText == "") {
            SetRuleTextErrorMessage(ruleTextErrorMessageText);
            validData = false;
        } else {
            SetRuleTextErrorMessage("");
        }

        if (ruleHeaderText == "") {
            SetRuleHeaderTextErrorMessage(ruleHeaderTextErrorMessageText);
            validData = false;
        } else {
            SetRuleHeaderTextErrorMessage("");
        }

        if (ruleFooterText == "") {
            SetRuleFooterTextErrorMessage(ruleFooterTextErrorMessageText);
            validData = false;
        } else {
            SetRuleFooterTextErrorMessage("");
        }

        if (kustoFunction == "") {
            SetKustoFunctionErrorMessage(kustoFunctionErrorMessageText);
            validData = false;
        } else {
            SetKustoFunctionErrorMessage("");
        }
        
        const hasEmail: boolean = [...primaryActions, ...secondaryActions].some((str) => str.startsWith('Email'));

        if (emailTemplate == "" && hasEmail) {
            SetEmailTemplateErrorMessage(emailTemplateErrorMessageText);
            validData = false;
        } else {
            SetEmailTemplateErrorMessage("");
        }

        if (kustoFunction == "" || kustoFunction.length < 1) {
            SetKustoFunctionErrorMessage(kustoFunctionErrorMessageText);
        } else {
                // validateKustoFunctionArgs further checks the args and sets the error message if required
                const validKustoArgs: boolean = validateKustoFunctionArgs(kustoFunction);
                validData = validData ? validKustoArgs : validData;
        }

        if (primaryActions.length == 0) {
            SetPrimaryActionsErrorMessage(primaryActionsErrorMessageText);
            validData = false;
        } else {
            SetPrimaryActionsErrorMessage("");
        }

                return validData;
    }

    return (
        <div>
            <div hidden={isAdd()}>
                <Link onClick={(linkText === "Loading" || isOpen) ? () => {} : clickToOpenPanel}>{linkText}</Link>
            </div>
            <div hidden={!isAdd()}>
                <PrimaryButton onClick={isOpen ? () => {} : clickToOpenPanel}>{linkText}</PrimaryButton>
            </div>
            {
            isOpen && 
            <CoherencePanel
                titleText={isAdd() ? "Create New Rule" : ruleName}
                title={isAdd() ? "Create New Rule" : ruleName}
                headerText={isAdd() ? "Create New Rule" : ruleName}
                isOpen={isOpen}
                hasCloseButton={true}
                onDismiss={clickToClosePanel}
                onLightDismissClick={lightDismissPanel}
                closeButtonAriaLabel="Close"
                onRenderFooter={{
                    primaryButton: {
                        text: primaryButtonText,
                        onAction: (() => {
                            panelPrimaryButtonAction();
                        }),
                        disabled: savingRule,
                    },
                    secondaryButton: {
                        text: secondaryButtonText,
                        onAction: (() =>{
                            panelSecondaryButtonAction();
                        }),
                        disabled: false,
                    }
                }}
                panelSize={CoherencePanelSize.medium}
            >
                <div>
                    <div hidden={hideDefinitionDiv}>
                        <Stack horizontal tokens={stackTokens}>
                            <Stack.Item align="end">
                                <Toggle 
                                    defaultChecked={ruleEnabled}
                                    inlineLabel
                                    onText="Enabled"
                                    offText="Disabled"
                                    onChange={onRuleEnabled}
                                />
                            </Stack.Item>
                            <Stack.Item align="start">
                                <ActionButton
                                    iconProps={isAdd() ? {} : { iconName: "Delete" }}
                                    text={isAdd() ? "" : "Delete"}
                                    hidden={isAdd()}
                                    disabled={isAdd()}
                                    onClick={() => clickDeleteButton()}
                                    ariaLabel={"Delete Rule"}
                                    ariaDescription={"Delete Rule"}
                                />
                            </Stack.Item>
                        </Stack>
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Rule Name"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TextField required
                                    label="Name"
                                    styles={textStyles}
                                    ariaLabel="Name"
                                    onChange={onRuleNameChange}
                                    value={ruleName}
                                    maxLength={75}
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {ruleNameErrorMessage}
                                </p>
                            </TooltipHost>
                        </div>

                        {/* Alert Title */}
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Descriptive Text for IcM Ticket"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TextField required
                                    label="Title"
                                    styles={textStyles}
                                    ariaLabel="Title"
                                    onChange={onRuleTextChange}
                                    value={ruleText}
                                    maxLength={75}
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {ruleTextErrorMessage}
                                </p>
                            </TooltipHost>
                        </div>

                        {/* Alert Header Text */}
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Header for IcM Ticket"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TextField required
                                    label="Header"
                                    styles={textStyles}
                                    ariaLabel="Header"
                                    onChange={onRuleHeaderTextChange}
                                    value={ruleHeaderText}
                                    multiline
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {ruleHeaderTextErrorMessage}
                                </p>
                            </TooltipHost>
                        </div>

                        {/* Alert Footer Text*/}
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Footer for IcM Ticket"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <TextField required
                                    label="Footer"
                                    styles={textStyles}
                                    ariaLabel="Footer"
                                    onChange={onRuleFooterTextChange}
                                    value={ruleFooterText}
                                    multiline
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {ruleFooterTextErrorMessage}
                                </p>
                            </TooltipHost>
                        </div>

                        {/* Emil Template dropdown */}
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select Email Template"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <Dropdown
                                    placeholder="Select Email Template"
                                    label="Email Template"
                                    ariaLabel="Email Template"
                                    aria-required="false"
                                    options={props.ruleOptions.EmailTemplateOptions}
                                    styles={dropdownStyles}
                                    selectedKey={emailTemplate}
                                    onChange={onEmailTemplateChange}
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {emailTemplateErrorMessage}
                                </p>
                            </TooltipHost>
                        </div>

                        {/* Alert Function dropdown */}
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select Alert Function from Kusto"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <Dropdown required
                                    placeholder="Select Alert Function"
                                    label="Alert Function"
                                    ariaLabel="Alert Function"
                                    aria-required="false"
                                    options={props.ruleOptions.AlertFunctionOptions}
                                    styles={dropdownStyles}
                                    selectedKey={kustoFunction}
                                    onChange={onFunctionChange}
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {kustoFunctionErrorMessage}
                                </p>
                            </TooltipHost>
                        </div>
                        
                        {/* Function args text field */}
                        { selectedKustoFunction?.Parameters!="()" &&  // do not show when no params required
                            <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Alert function parameters. Use commas to separate multiple arguments"
                                id={tooltipIdKustoFunctionArgs}
                                calloutProps={calloutProps}
                            >
                                <TextField 
                                    label="Alert Function Parameters"
                                    styles={textStyles}
                                    ariaLabel="Alert Function Parameters"
                                    onChange={onRuleKustoFunctionArgsChange}
                                    value={kustoFunctionArgs}
                                    maxLength={75}
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {kustoKustoFunctionArgsErrorMessage}
                                </p>
                            </TooltipHost>
                        </div>
                        }
                        <div style={{ padding: "0px" }}>
                            <TooltipHost
                                content="Select Schedule for Alert"
                                id={tooltipId}
                                calloutProps={calloutProps}
                            >
                                <Dropdown required
                                    placeholder="Select Schedule"
                                    label="Schedule"
                                    ariaLabel="Schedule"
                                    aria-required="true"
                                    options={props.ruleOptions.ScheduleOptions}
                                    styles={dropdownStyles}
                                    selectedKey={schedule}
                                    onChange={onScheduleChange}
                                />
                            </TooltipHost> 
                        </div>

                        <div style={{ padding: "0px" }}>
                            {
                                schedule == 7 && 
                                <Dropdown required
                                    placeholder="On which Days of the week"
                                    label="Select Days (On which this rule should be scheduled)"
                                    ariaLabel="On which Days of the week"
                                    aria-required="false"
                                    options={[ 
                                        { key: "Mo" , text: "Mo" },
                                        { key: "Tu" , text: "Tu" },
                                        { key: "We" , text: "We" },
                                        { key: "Th" , text: "Th" },
                                        { key: "Fr" , text: "Fr" },
                                        { key: "Sa" , text: "Sa" },
                                        { key: "Su" , text: "Su" },
                                    ]}
                                    styles={dropdownStyles}
                                    defaultSelectedKeys={daysOfWeek}
                                    onChange={onDaysOfWeekChanged}
                                />
                            }
                            

                                {/* <Dropdown required
                                    placeholder="Week #"
                                    multiSelect
                                    label="Select Week # ( of each month on which this rule should be scheduled)"
                                    ariaLabel="Week #"
                                    aria-required="false"
                                    options={[ 
                                        { key: "1" , text: "1st" },
                                        { key: "2" , text: "2nd" },
                                        { key: "3" , text: "3rd" },
                                        { key: "4" , text: "4th" },
                                        { key: "5" , text: "5th" }
                                    ]}
                                    styles={dropdownStyles}
                                    defaultSelectedKeys={weeksOfMonth}
                                    onChange={onWeeksOfMonthChanged}
                                /> */}
                                
                            <Toggle 
                                label="Automatic Offboarding"
                                checked={automaticOffboard}
                                onText="On "
                                offText="Off"
                                onChange={onAutomaticOffboardToggle}
                            />
                        </div>

                        <div style={{ padding: "0px", paddingTop: "10px"}}>
                            <Stack horizontal tokens={stackTokens}>
                                <Stack.Item>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Primary Action(s)</p>
                                </Stack.Item>
                            </Stack>
                            <div style={{ padding: "5px" }}>
                                <Dropdown
                                    placeholder="Select Action(s)"
                                    label="Select Action(s)"
                                    multiSelect
                                    options={props.ruleOptions.ActionOptions}
                                    styles={dropdownStyles}
                                    defaultSelectedKeys={primaryActions}
                                    onChange={onPrimaryActionChange}
                                />
                                <p role="alert" aria-live="polite" style={{ color: "#8A0000", paddingLeft: "5px", margin: "0px", fontSize: FontSizes.size12 }}>
                                    {primaryActionsErrorMessage}
                                </p>
                               
                            </div>
                        </div>
                        <div style={{ padding: "0px" }}>
                            <Stack horizontal tokens={stackTokens}>
                                <Stack.Item>
                                    <p style={{fontSize: 14, fontWeight: 500}}>Secondary Action(s)</p>
                                </Stack.Item>
                                <Stack.Item>
                                    <TooltipHost
                                        content="Next steps if no action from service owner - Automatic Action: Take this action automatically"
                                        id={tooltipId}
                                        calloutProps={calloutProps}
                                    >
                                        
                                    </TooltipHost>
                                </Stack.Item>
                            </Stack>
                            <div style={{ padding: "5px" }}>
                                <Dropdown
                                    placeholder="Select Action(s)"
                                    label="Select Action(s)"
                                    multiSelect
                                    options={props.ruleOptions.ActionOptions}
                                    selectedKeys={secondaryActions}
                                    styles={dropdownStyles}
                                    onChange={onSecondaryActionChange}
                                />
                                {
                                    secondaryActions.length > 0 && 
                                    <TooltipHost
                                        content="Days Between Primary Action and Secondary Action"
                                        id={tooltipId}
                                        calloutProps={calloutProps}
                                    >
                                    <Dropdown required
                                        placeholder="Select Days Between Primary Action and Secondary Action"
                                        label="Days Between Primary Action and Secondary Action"
                                        ariaLabel="Days Between Primary Action and Secondary Action"
                                        aria-required="false"
                                        options={props.ruleOptions.DelayOptions}
                                        styles={dropdownStyles}
                                        selectedKey={ruleDelay}
                                        onChange={onDelayChange}
                                    />
                                    </TooltipHost>
                                }
                            </div>
                        </div>
                    </div>
                    <div hidden={hideResultsDiv}>
                        <h3>Rule Results</h3>
                        <div hidden={!savingRule}>
                            <Spinner label="Saving Rule..." ariaLive="assertive" labelPosition="bottom" />
                        </div>
                        <div hidden={savingRule}>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Enabled
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {ruleEnabled ? "true" : "false"}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Name
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {ruleName}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Text
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {ruleText}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Alert Header
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {ruleHeaderText}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Alert Footer
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {ruleFooterText}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Alert Function
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {kustoFunction}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Alert Function Parameter(s)
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {kustoFunctionArgs}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Days of Week
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {daysOfWeek?.join(",")}
                                </Label>
                            </div>
                            {/* <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Weeks of Month
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {weeksOfMonth?.join(",")}
                                </Label>
                            </div> */}
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    {"Primary Action(s)"}
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {primaryActions?.join(", ")}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Schedule
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {schedule}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    {"Secondary Action(s)"}
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {secondaryActions?.join(", ")}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Days before Secondary Action
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {ruleDelay}
                                </Label>
                            </div>
                            <div style={{ padding: "4px" }}>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "bold" }}>
                                    Automatic Offboarding
                                </Label>
                                <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "inline", fontWeight: "normal" }}>
                                    {automaticOffboard ? "true" : "false"}
                                </Label>
                            </div>
                        </div>
                        <div hidden={hideErrorText}>
                            <Label style={{ margin: "5px", fontSize: FontSizes.size14, display: "block", fontWeight: "bold" }}>
                                    {errorText}
                            </Label>
                        </div>
                    </div>
                    <div hidden={hideDeleteDiv}>
                        {deleteRuleSubtext}
                    </div>
                </div>
            </CoherencePanel>
            }
        </div>
    );
};
