import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import React, { useState } from "react";
import HttpService from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { RootContext } from "../Stores/RootStore";
import { IServiceDataType } from "../../App";
import { Guid } from "guid-typescript";

interface ISelectAServiceProps {
    serviceOptions: IServiceDataType[];
    onServiceSelected? : Function;
    onServersUpdated? : Function;
}

interface ISelectAServiceState {
    selectedServiceDropdown: IDropdownOption;
    selectedService: string;
    selectedServiceName: string;
    // serversLoaded: boolean;
}

export const ServiceSelectionDropdown: React.FunctionComponent<ISelectAServiceProps> = (props: ISelectAServiceProps) => {

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights));

    // React.useEffect(() => {
    //     switch (props.currentStep) {
    //         case 0 : SetAnnouceStep("Prerequisites Step"); break;
    //         case 1 : SetAnnouceStep("Upload Step"); break;
    //         case 2 : SetAnnouceStep("Onboarding Step"); break;
    //         default: 
    //     }
    // }, [props.currentStep])

    const [mystate, setMyState] = useState<ISelectAServiceState>();
    const { state } = React.useContext(RootContext);

    const _onFilterByService = (ev: React.FormEvent<HTMLDivElement> | undefined, item?: IDropdownOption, index?: number): void => {
        if (item?.key !== undefined) {
            const selectedServiceGUID: string = item?.key.toString();
            const selectedServiceNameValue: string = item?.text.toString();
            
            setMyState({
                selectedServiceDropdown: item,
                selectedService: selectedServiceGUID,
                selectedServiceName: selectedServiceNameValue
            });

            if(props.onServiceSelected){
                props.onServiceSelected(selectedServiceGUID, selectedServiceNameValue);
            }
            // fetchServerData(selectedServiceGUID);
            allServerData(selectedServiceGUID)
            .then( res => {
                if(props.onServersUpdated){
                    props.onServersUpdated(res.data);
                }   
            })
        }
    }

    const dropdownStyles: Partial<IDropdownStyles> = { 
        dropdown: { maxWidth: "300px", minWidth: "200px" } 
    };

    const allServerData = (serviceTreeGUID: string): Promise<any> => {
        return httpService.get({
            url: "api/ManageServers/fetchAllServersForOnboarding",
            token: state.AuthStore.Token,
            params: {
                serviceTreeGUID: Guid.isGuid(serviceTreeGUID) ? serviceTreeGUID : Guid.EMPTY,
            },
        });
    }

    return (
        <div>
            <Dropdown
                id="serviceDropdown"
                label="Select a Service"
                onChange={_onFilterByService}
                styles={dropdownStyles}
                options={props.serviceOptions.map( d => { return {key:d.ServiceTreeGUID, text:d.ServiceName} as IDropdownOption})}
                multiSelect={false}
            />
        </div>
    );
};


{/*  */}