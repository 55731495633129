import { CheckboxVisibility, DefaultButton, DetailsList, Dialog, DialogFooter,
    DialogType, DirectionalHint, IColumn, IconButton, PrimaryButton, ScrollablePane, TooltipHost } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React, { useRef } from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { buttonStyles } from "../../Pages/ServiceAdministrationPage";
import HttpService from "../../services/HttpService/HttpService";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { ListItemActionSet } from "../Shared/ListItemActionSet";
import { PeoplePicker, PeoplePickerRequestType } from "../Shared/PeoplePicker";
import { RootContext } from "../Stores/RootStore";
import { Colors, Status } from "../../Styles/Colors";

// enums //

enum AddRemoveEmail {
    Add,
    Remove,
}

export enum AliasInputType {
    Alias,
    Group,
    Email,
    None,
}

// Interfaces //

interface IEmailContact {
    selectedServiceTreeGUID: string;
    emailContactsDisplayed: string;
    graphToken: any;
    updateIcmTeamData: (serviceID: string) => void;
}

interface IEmailContactType {
    User: string;
}

export const EmailContact: React.FunctionComponent<IEmailContact> = (props: IEmailContact) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [email, SetEmail] = React.useState<string>("");
    const [group, SetGroup] = React.useState<string>("");
    const [addButtonText, SetAddButtonText] = React.useState<string>("Add");
    const [emailContactsInGrid, SetEmailContactsInGrid] = React.useState<IEmailContactType[]>([]);
    const [addButtonDisabled, SetAddButtonDisabled] = React.useState<boolean>(true);

    // Dialog
    const [userToBeDeleted, SetUserToBeDeleted] = React.useState<string>("");
    const [hideDialog, SetHideDialog] = React.useState<boolean>(true);
    const [closeCompleteButtonText, SetCloseCompleteButtonText] = React.useState<string>("Close");
    const [removeSubText, SetRemoveSubtext] = React.useState<string>("Confirm removing email contact: ");
    const [errorText, SetErrorText] = React.useState<string>("Oops, something went wrong. Please reload application.");
    const [hideErrorText, SetHideErrorText] = React.useState<boolean>(true);
    const [confirmForfeitButtonDisabled, SetConfirmForfeitButtonDisabled] = React.useState<boolean>(false);
    const [aliasInput, SetAliasInput] = React.useState<AliasInputType>(AliasInputType.None);

    // reload - used for PeoplePicker
    const [reload, SetReload] = React.useState(1);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    // focus ref for complete button
    const completeButtonRef = useRef<null | HTMLElement>(null);

    React.useEffect(() => {
        updateEmailDataGrid();
    }, [props]);

    React.useEffect(() => {
        if (reload === 0) {
            SetReload((p) => p + 1);
        }
    }, [reload]);

    // enable Add button only when user is in people picker
    React.useEffect(() => {
        if (email !== "" || group !== "") {
            SetAddButtonDisabled(false);
        } else {
            SetAddButtonDisabled(true);
        }
    }, [email, group]);

    const updateEmailDataGrid = () => {
        const emailContacts: string[] = props.emailContactsDisplayed?.split(";");
        const emailsToBeDisplayedInGrid: IEmailContactType[] = [];

        for (const e of emailContacts) {
            if (e !== "") {
                const contact: IEmailContactType = {
                    User: e,
                };
                emailsToBeDisplayedInGrid.push(contact);
            }
        }

        SetEmailContactsInGrid(emailsToBeDisplayedInGrid);
    };

    const emailContactColumns: IColumn[] = [
        {
            key: "user",
            name: "User",
            fieldName: "User",
            isResizable: true,
            maxWidth: 200,
            minWidth: 120,
        },
        {
            key: "actions",
            name: "Delete",
            maxWidth: 75,
            minWidth: 50,
            onRender: (item: IEmailContactType) => deleteEmailContact(item),
        },
    ];

    const deleteEmailContact = (item: IEmailContactType) => {
        const visibleActions: React.ReactNode[] = [];
        const deleteDelegatedUserLabel = "click here to remove delegation for: ";
        visibleActions.push(
            <TooltipHost
                key={"Remove"}
                aria-label={deleteDelegatedUserLabel + item.User}
                content={deleteDelegatedUserLabel + item.User}
                styles={{ root: { marginLeft: "10px" } }}
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter, beakWidth: 12 }}
            >
                <IconButton
                    key={"Remove"}
                    label={"Remove"}
                    ariaLabel={deleteDelegatedUserLabel + item.User}
                    iconProps={{
                        iconName: "Delete",
                    }}
                    role={"button"}
                    onClick={() => {
                        SetUserToBeDeleted(item.User);
                        SetHideDialog(false);
                    }}
                />
            </TooltipHost>,
        );

        return (
            <ListItemActionSet
                visibleActions={visibleActions}
            />
        );
    };

    function closeDialogFunctions() {
        SetHideDialog(true);
        SetConfirmForfeitButtonDisabled(false);
        SetRemoveSubtext("Confirm removing email contact: ");
        SetCloseCompleteButtonText("Close");
    }

    const saveEmailContacts = (emailContact: string, addRemove: AddRemoveEmail) => {
        let allEmailContacts: string = "";

        // adjust emails contacts (add or remove) and save new set of email contacts
        if (emailContact?.length > 0) {
            if (addRemove === AddRemoveEmail.Add) {
                SetAddButtonText("Adding...");
                const otherEmailContacts: string = props.emailContactsDisplayed === "" ? "" : ";" + props.emailContactsDisplayed;
                allEmailContacts = emailContact + otherEmailContacts;
                const allEmailContactsArray: string[] = allEmailContacts.split(";");

                // remove duplicates
                const noDuplicates: string[] = allEmailContactsArray.filter((n, i) => allEmailContactsArray.indexOf(n) === i);
                allEmailContacts = noDuplicates.join(";");
            } else {
                const emailsMinusRemoved: string[] = [];
                const currentEmails: string[] = props.emailContactsDisplayed.split(";");

                for (const e of currentEmails) {
                    if (e !== null && e !== emailContact && e !== ""  && e !== "null") {
                        emailsMinusRemoved.push(e);
                    }
                }

                allEmailContacts = emailsMinusRemoved.join(";");
            }

            httpService.post({
                url: "api/ServiceAdministration/assignEmailContact",
                token: state.AuthStore.Token,
                data: {
                    ServiceTreeGUID: props.selectedServiceTreeGUID,
                    EmailContact: allEmailContacts,
                },
            }).then((resp: any) => {
                if (resp.status.toString() !== "200") {
                    SetHideErrorText(false);
                } else {
                    SetHideErrorText(true);
                }

                SetReload(0); // reload people picker
                props.updateIcmTeamData(props.selectedServiceTreeGUID);

                if (addRemove === AddRemoveEmail.Add) {
                    SetAddButtonText("Add");
                    SetAddButtonDisabled(true);
                } else {
                    SetRemoveSubtext("Successfully removed: ");
                    SetCloseCompleteButtonText("Complete");
                    SetConfirmForfeitButtonDisabled(true);

                    // focus on Complete button
                    completeButtonRef?.current!.focus();
                }

                SetEmail("");
                SetGroup("");
                trackEvent(appInsights, "ServiceAdministration", "Email Contact", "Updated email contact(s) for: " + props.selectedServiceTreeGUID + " : " + allEmailContacts, state.AuthStore, {});
            }).catch((reason: any) => {
                SetAddButtonText("Add");
                SetAddButtonDisabled(true);
                trackException(appInsights, reason, SeverityLevel.Error,  "ServiceAdministration", "Email Contact", "saveEmailContact", state.AuthStore, {});
            });
        } else {
            // XXX email entry is blank
        }
    };

    const onClickAdd = () => {
        if (addButtonText === "Add") {
            const newContacts: string = email !== "" ? email! : group!;
            saveEmailContacts(newContacts, AddRemoveEmail.Add);
        } else {
            // ignore if "Adding"
        }
    };

    return (
        <div>
            {/* Delete Confirmation Dialog */}
            <Dialog
                hidden={hideDialog}
                closeButtonAriaLabel="Dialog"
                onDismiss={() => {
                    closeDialogFunctions();
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Confirm Deletion",
                    subText: removeSubText + userToBeDeleted,
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        text="Confirm"
                        ariaLabel="Confirm Button"
                        onClick={() =>  {
                            saveEmailContacts(userToBeDeleted, AddRemoveEmail.Remove);
                        }}
                        disabled={confirmForfeitButtonDisabled}
                    />
                    <DefaultButton
                        text={closeCompleteButtonText}
                        ariaLabel={closeCompleteButtonText === "Complete" ? removeSubText + userToBeDeleted + " button Complete" : "Close button"}
                        onClick={() => {
                            closeDialogFunctions();
                        }}
                        componentRef={completeButtonRef as any}
                    />
                </DialogFooter>
            </Dialog>

            <div id="emailDetailsList" style={{ position: "relative", height: "75%" }}>
                <ScrollablePane style={{ height: "200px", position: "relative" }}>
                <DetailsList
                    columns={emailContactColumns}
                    items={emailContactsInGrid}
                    compact={true}
                    onRenderDetailsHeader={RenderStickyHeader}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    ariaLabelForGrid="Email Details Grid"
                />
                </ScrollablePane>
            </div>
            <div style={{ fontWeight: 600, width: 150 }}>
                <fieldset id="peoplePicker">
                    <legend>(Required):</legend>
                    <div>
                        Search for an Alias<span style={{ color: Colors.VisualIndicators.RedDark}}>*</span>
                        {Boolean(reload) && (
                        <PeoplePicker
                            token={props.graphToken}
                            account={state.AuthStore.Account}
                            defaultValue={""}
                            alias={email}
                            itemLimit={1}
                            SetAlias={SetEmail}
                            SetAliasInput={SetAliasInput}
                            SetNewEmail={() => {}}
                            disabled={aliasInput === AliasInputType.Group || group !== ""}
                            PeoplePickerRequestTypeValue={PeoplePickerRequestType.Email}
                            ariaLabel={"Search for an Alias People picker combo box required collapsed has auto complete editable"}
                        /> )}
                    </div>
                    <div>-or-</div>
                    <div>
                        Search for a Group/DL<span style={{ color: Colors.VisualIndicators.RedDark}}>*</span>
                        {Boolean(reload) && (
                        <PeoplePicker
                            token={props.graphToken}
                            account={state.AuthStore.Account}
                            defaultValue={""}
                            alias={group}
                            itemLimit={1}
                            SetAlias={SetGroup}
                            SetAliasInput={SetAliasInput}
                            SetNewEmail={() => {}}
                            disabled={aliasInput === AliasInputType.Email || email !== ""}
                            PeoplePickerRequestTypeValue={PeoplePickerRequestType.Group}
                            ariaLabel={"Search for a group People picker combo box required collapsed has auto complete editable"}
                        /> )}
                    </div>
                </fieldset>
            </div>
            <br />
            <PrimaryButton
                secondaryText={addButtonText}
                onClick={onClickAdd}
                text={addButtonText}
                styles={buttonStyles}
                disabled={addButtonDisabled}
                ariaLabel={addButtonText === "Add" ? "Add: " + (email !== "" ? email : group) : "Adding: " + (email !== "" ? email : group)}
            />
            <div hidden={hideErrorText}>
                <p aria-label={errorText} style={{ color: Status.DarkGreen}}>{errorText}</p>
            </div>
        </div>
    );
};
