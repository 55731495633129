import React from "react";
import { RootContext } from "../Stores/RootStore";
import { getOnboardedServices } from "../../services/ApiService/Requests";
import { useAppInsights } from "../AppInsights/AppInsights";
import { DataActionEnum } from "../../services/DataContext/DataActions";
import { trackException } from "../AppInsights/LoggingHelper";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Dropdown, IDropdownOption, Spinner, SpinnerSize } from "@fluentui/react";
import { IServiceDataType } from "../../App";
import { ChildClass, defaultDropdownStyles } from "../../Styles/Page.styles";

export interface OnboardedServicesDropdownProps {
    id: string;
    serviceTreeGuidFromParam: string | undefined;
    onSelectService: (service: IServiceDataType) => void;
    disabled?:  boolean;
}

export const OnboardedServicesDropdown: React.FunctionComponent<OnboardedServicesDropdownProps> = (props: OnboardedServicesDropdownProps) => {
    const { state, dispatch } = React.useContext(RootContext); // auth token context
    const [selectedService, SetSelectedService] = React.useState<IServiceDataType>({ServiceName: "", ServiceTreeGUID: props.serviceTreeGuidFromParam && props.serviceTreeGuidFromParam.length > 0 ? props.serviceTreeGuidFromParam : ""});
    const [onboardedServices, SetOnboardedServices] = React.useState<IDropdownOption[]>([]);

    const appInsights = useAppInsights();
    // fetch onboarded services after token is available in root context
    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            if (state.DataStore.OnboardedServices!.length == 0) {
                getOnboardedServices(state, appInsights).then((response: any) => {
                    const data: any = response?.data;
                    if (data !== "" && data !== undefined) {
                        const loadedServices: IDropdownOption[] = [];
                        const onboardedServicesState: IServiceDataType[] = [];
                        let matchedService: IServiceDataType | null = null;

                        for (const { serviceName, serviceTreeGUID } of data) {
                            if (serviceTreeGUID && !onboardedServicesState.some(s => s.ServiceTreeGUID === serviceTreeGUID)) {
                                onboardedServicesState.push({ 
                                    ServiceName: serviceName, 
                                    ServiceTreeGUID: serviceTreeGUID 
                                });

                                loadedServices.push({
                                    key: serviceTreeGUID,
                                    text: serviceName,
                                });

                                // match URL parameter with API response
                                if (props.serviceTreeGuidFromParam !== null && serviceTreeGUID == props.serviceTreeGuidFromParam){
                                    matchedService = { 
                                        ServiceName : serviceName, 
                                        ServiceTreeGUID: serviceTreeGUID
                                    }
                                }
                            }   
                        }

                        // if servicetreeguid passed as URL matches one in API response, set it as the selected service
                        // this is equivalent to the user selecting one from the dropdown
                        if (matchedService != null) {
                            props.onSelectService(matchedService);
                        }

                        dispatch({
                            type: DataActionEnum.UPDATE_ONBOARDED_SERVICES,
                            onboardedServices: onboardedServicesState,
                        });

                        SetOnboardedServices(loadedServices);
                    }
                }).catch((reason: any) => {
                    trackException(appInsights, reason, SeverityLevel.Error, "Onboard Servers", "Fetch Page Data", "api/ServiceAdministration/fetchOnboardedServices", state.AuthStore, {});
                });
            } else {
                const loadedServices: IDropdownOption[] = [];
                let matchedService: IServiceDataType | null = null;

                for (const service of state.DataStore.OnboardedServices) {
                    loadedServices.push({
                        key: service.ServiceTreeGUID,
                        text: service.ServiceName,
                    });

                    // match URL parameter with API response
                    if (props.serviceTreeGuidFromParam !== null && service.ServiceTreeGUID == props.serviceTreeGuidFromParam) {
                        matchedService = { 
                            ServiceName : service.ServiceName, 
                            ServiceTreeGUID: service.ServiceTreeGUID
                        }
                    }
                }

                // if servicetreeguid passed as URL matches one in API response, set it as the selected service
                // this is equivalent to the user selecting one from the dropdown
                if (matchedService != null) {
                    props.onSelectService(matchedService);
                }

                SetOnboardedServices(loadedServices);
            }
        }
    }, [state.AuthStore.Token])

    return (
        <div>
            {onboardedServices.length > 0 ? (
                <Dropdown
                    id={props.id}
                    className={ChildClass}
                    label={"Select a Service"}
                    ariaLabel={"Select a Service"}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
                        const service: IServiceDataType = {
                            ServiceName: option?.text.toString()!,
                            ServiceTreeGUID: option?.key.toString()!
                        }

                        props.onSelectService(service);
                        SetSelectedService(service);
                    }}
                    styles={defaultDropdownStyles}
                    selectedKey={selectedService.ServiceTreeGUID ? selectedService.ServiceTreeGUID : undefined}
                    options={onboardedServices}
                    disabled={props.disabled}
                />
            ) : (
                <div
                    style={{
                        position: "absolute", left: "50%", top: "40%",
                        transform: "translate(-50%, -50%)",
                    }}>
                    <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                </div>
            )}
        </div>
    );

}