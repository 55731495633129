import { CoherenceTheme } from "@coherence-design-system/styles";
import {
    Depths,
    mergeStyles, Pivot, PivotItem, ScrollablePane,
    ThemeProvider
} from "@fluentui/react";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackException, trackPageView } from "../components/AppInsights/LoggingHelper";
import { IJobInfo } from "../components/ManageServers/DetailsListManageServers";
import {
    ARCComponent,
    OnboardComponent,
    OnboardingStep,
    PrerequisitesComponent,
    UploadDataComponent
} from "../components/Onboarding/OnboardingComponents";
import { OnboardingSelectServersComponent } from "../components/Onboarding/OnboardingSelectServersComponent";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { UserPermission } from "../services/AuthenticationService/AuthReducer";
import HttpClient from "../services/HttpService/HttpClient";
import { _Styles, breakWord } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { DataActionEnum } from "../services/DataContext/DataActions";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getOnboardedServices } from "../services/ApiService/Requests";
import { IServiceDataType, PAGE } from "../App";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";

// Interfaces //

export interface IOnboardingDataType {
    ServerId: number;
    Status: "Success" | "FAIL" | "unknown" | "Loaded";
    ServiceName: string;
    ServiceTreeGUID: string;
    Server: string;
    Domain: string;
    Notes: string;
    Jobs: IJobInfo[];
    JobStatus: string;
  }

export interface IOnboardingProps {
    httpClient: HttpClient;
}

// Styles //

const mainDivStyles = mergeStyles({
    display: "block",
    margin: "auto",
    overflow: "auto"
});

const pageTitle: string = "Onboard Servers";

export const OnboardingPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const { state, dispatch } = React.useContext(RootContext); // auth token context
    const [serverData, SetServerData] = React.useState<IOnboardingDataType[]>([]);
    const [currentStep, SetCurrentStep] = React.useState<OnboardingStep>(OnboardingStep.Prerequisites);

    const appInsights = useAppInsights();

    // Navigate State
    const location = useLocation();
    const locationState: any = location.state;

    // fetch onboarded services after token is available in root context
    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            if (state.DataStore.OnboardedServices!.length == 0) {
                getOnboardedServices(state, appInsights).then((response: any) => {
                    const data: any = response?.data;
                    if (data !== "" && data !== undefined) {
                        const onboardedServicesState: IServiceDataType[] = [];

                        for (const { serviceName, serviceTreeGUID } of data) {
                            if (serviceTreeGUID && !onboardedServicesState.some(s => s.ServiceTreeGUID === serviceTreeGUID)) {
                                onboardedServicesState.push({ ServiceName: serviceName, ServiceTreeGUID: serviceTreeGUID });
                            }   
                        }

                        dispatch({
                            type: DataActionEnum.UPDATE_ONBOARDED_SERVICES,
                            onboardedServices: onboardedServicesState,
                        });
                    }
                }).catch((reason: any) => {
                    trackException(appInsights, reason, SeverityLevel.Error, "Onboard Servers", "Fetch Page Data", "api/ServiceAdministration/fetchOnboardedServices", state.AuthStore, {});
                });
            }
        }
    }, [state.AuthStore.Token])

    React.useEffect(() => {
        document.title = pageTitle;

        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "onboarding"
        });      

        const telemetry = buildPageViewTelemetry(
            pageTitle,
            "/onboarding",
            "/onboarding",
            state.AuthStore.Email,
            state.AuthStore.isAdmin,
        );

        trackPageView(appInsights, telemetry);
    }, []);

    React.useEffect(() => {
        if (locationState?.reload) {
            SetCurrentStep(OnboardingStep.Prerequisites)
        }
    }, [locationState])

    return (
        <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
            <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                <AdminMessageBanner page={PAGE.ONBOARDING} />
            </div>
            <div className={_Styles.rootDiv}>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Permitted || state.AuthStore.UserTimeOut}>
                    <h1 id="onboardingtitle" className={breakWord}>
                        {ReactHtmlParser(pageTitle)}
                    </h1>
                    <div className={mainDivStyles}>
                        <ThemeProvider theme={CoherenceTheme}>
                            { state.AuthStore.Token !== "" &&
                            <div>
                                {currentStep === OnboardingStep.Prerequisites &&
                                    <PrerequisitesComponent
                                        SetCurrentStep={SetCurrentStep} 
                                    />
                                }
                                {currentStep === OnboardingStep.ARC &&
                                    <ARCComponent
                                        SetCurrentStep={SetCurrentStep} />
                                }
                                {currentStep === OnboardingStep.Upload &&
                                        <Pivot aria-label="Onboard Servers">
                                            <PivotItem headerText="Upload CSV">
                                                <div id="uploadCSVPivotItem">
                                                    <UploadDataComponent
                                                        SetCurrentStep={SetCurrentStep}
                                                        SetServerData={SetServerData} 
                                                    />
                                                </div>
                                            </PivotItem>
                                            <PivotItem headerText="Select Servers">
                                                <div id="selectServersPivotItem">
                                                    <OnboardingSelectServersComponent 
                                                        SetCurrentStep={SetCurrentStep}
                                                        SetServerData={SetServerData}
                                                    />
                                                </div>
                                            </PivotItem>
                                        </Pivot>
                                }
                                {currentStep === OnboardingStep.Onboard &&
                                    <OnboardComponent
                                        serverData={serverData}
                                        SetCurrentStep={SetCurrentStep} />
                                }
                            </div>
                            }
                        </ThemeProvider>
                    </div>
                </div>
                <BackToTopButton />
                <PagePermissionsComponent />
            </div>
        </ScrollablePane>
    );
};
