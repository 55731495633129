import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import {
    Checkbox, DetailsList, DirectionalHint, IColumn, PrimaryButton,
    ScrollablePane, SelectionMode, Spinner, TooltipHost, mergeStyles
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Guid } from "guid-typescript";
import * as React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { CurrentPatchingCycleWeek } from "../../Helpers/Utils";
import HttpService from "../../services/HttpService/HttpService";
import { calloutProps, hostStyles } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackEvent, trackException } from "../AppInsights/LoggingHelper";
import { RootContext } from "../Stores/RootStore";
import { IManageServersData } from "./DetailsListManageServers";

const patchNowText: string = "Patch Now!";
const patchNowToolTipDefaultText: string = "Click here to patch servers now";
const patchNowToolTipWeekOneText: string = "Cannot patch servers during Monday or Tuesday of week 1 of the patching cycle. Please schedule a patching job for another date.";
const patchNowToolTipServerNotSeen: string = "Cannot patch servers that have not been seen in the last 24 hours, please select a different server.";
const TwentyFourHoursInMill: number = 24 * 60 * 60 * 1000;

export interface IPatchNowPanelProps {
    refreshDataGrid: () => void;
    serversToBePatched: IManageServersData[];
}

interface IPatchNowPanelType {
    ServerName: string;
    ServerId: number;
    ServiceName: string;
    ServiceTreeGUID: string;
    Notes: string;
}

interface IPatchingServerRequest {
    ServerId: number;
    JobId: string;
    JobName: string;
    ServerName: string;
    ServiceTreeGUID: string;
    ServiceName: string;
}

enum PanelSection {
    Patch,
    Results,
}

export const PatchNowPanel: React.FunctionComponent<IPatchNowPanelProps> = (props: IPatchNowPanelProps) => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [currentPanel, SetCurrentPanel] = React.useState<PanelSection>(PanelSection.Patch);
    const [currentlyPatchingServers, SetCurrentlyPatchingServers] = React.useState<boolean>(false);
    const [patchNowDateAllowed, SetPatchNowDateAllowed] = React.useState<boolean>(false);
    const [primaryButtonText, SetPrimaryButtonText] = React.useState<string>(patchNowText);
    const [patchgNowButtonToolTip, SetPatchingNowButtonToolTip] = React.useState<string>(patchNowToolTipDefaultText);
    const [dataGridData, SetDataGridData] = React.useState<IPatchNowPanelType[]>([]);
    const [resultsData, SetResultsData] = React.useState<IPatchNowPanelType[]>([]);
    const [serversMap, SetServersMap] = React.useState<Map<string, IPatchingServerRequest[]>>(new Map());
    const [patchServersSubtext, SetPatchServersSubtext] = React.useState("Are you sure you want to patch these servers?");
    const [reboot, SetReboot] = React.useState<boolean>(true);
    const { state } = React.useContext(RootContext);

    const appInsights = useAppInsights();

    // HttpService
    const [httpService, SetHttpService] = React.useState(HttpService(appInsights, state));

    // validate patching week for "Patch Now!"
    React.useEffect(() => {
        if (validDateForPatchNow()) {
            SetPatchingNowButtonToolTip(patchNowToolTipDefaultText);
            SetPatchNowDateAllowed(true);
        } else {
            SetPatchNowDateAllowed(false);
        }
    }, [props]);

    React.useEffect(() => {
        let serverData: IPatchNowPanelType[] = [];
        const serverMap: Map<string, IPatchingServerRequest[]> = new Map();

        props.serversToBePatched.forEach((server: IManageServersData) => {
            const guidString: string = server.serviceTreeGUID.toString();

            if (server.serverLastSeen.getTime() > new Date(new Date().getTime() - TwentyFourHoursInMill).getTime()) {
                if (serverMap.has(guidString)) {
                    const servers: IPatchingServerRequest[] = serverMap.get(guidString)!;
                    servers.push({
                        ServerId: server.serverId,
                        ServerName: server.serverName,
                        ServiceName: server.serviceName,
                        ServiceTreeGUID: guidString,
                        JobId: "",
                        JobName: ""
                    });

                    serverMap.set(guidString, servers);
                } else {
                    serverMap.set(guidString, [{
                        ServerId: server.serverId,
                        ServerName: server.serverName,
                        ServiceName: server.serviceName,
                        ServiceTreeGUID: guidString,
                        JobId: "",
                        JobName: ""
                    }]);
                }
            } else {
                // server has not been seen in the last 24 hours and therefore cannot be patched
            }

            serverData = [...serverData, {
                ServerId: server.serverId,
                ServerName: server.serverName,
                ServiceName: server.serviceName,
                ServiceTreeGUID: guidString,
                Notes: server.serverLastSeen.getTime() > new Date(new Date().getTime() - TwentyFourHoursInMill).getTime() ? "" : "Not seen in 24 hours, cannot be patched."
            }];
        });

        SetServersMap(serverMap);
        SetDataGridData(serverData);
    }, [props.serversToBePatched]);

    const validDateForPatchNow = (): boolean => {
        return validWeek() && validLastSeen();
    }

    const validWeek = (): boolean => {
        const today: Date = new Date();

        // calculate week of cycle
        const currentWeek: number = CurrentPatchingCycleWeek();

        if (currentWeek === 1 && (today.getDay() == 1 || today.getDay() == 2)) {
            SetPatchingNowButtonToolTip(patchNowToolTipWeekOneText);
            return false;
        }

        return true;
    }

    const validLastSeen = (): boolean => {
        let serverSeen: boolean = false;

        props.serversToBePatched.forEach((server: IManageServersData) => {
            const currentDateTime: Date = new Date();
            const duration = currentDateTime.getTime() - (server.serverLastSeen as Date).getTime();
            const daysAgo: number = ~~(duration / (3600 * 24 * 1000)); // ~~ === Math.Floor

            // at least one server has been seen less than a day ago
            // disable if no servers have been seen in less than a day
            if (daysAgo < 1) {
                serverSeen = true;
            }
        });

        if (serverSeen) {
            return true;
        } else {
            SetPatchingNowButtonToolTip(patchNowToolTipServerNotSeen);
            return false;
        }
    }

    async function clickToPatchServers() {
        SetCurrentlyPatchingServers(true);
        SetPatchServersSubtext("Processing...");
        SetPrimaryButtonText("");

        const patchingServerRequest: IPatchingServerRequest[] = [];
        const patchServersRequests: Array<Promise<any>> = [];

        Array.from(serversMap.keys()).forEach((serviceTreeGuid: string) => {
            const servers: IPatchingServerRequest[] = serversMap.get(serviceTreeGuid)!;

            servers.forEach((x: IPatchingServerRequest) => {
                const newRequest: IPatchingServerRequest = {
                    ServerId: x.ServerId,
                    JobId: Guid.createEmpty().toString(),
                    JobName: "",
                    ServerName: x.ServerName,
                    ServiceTreeGUID: x.ServiceTreeGUID.toString(),
                    ServiceName: x.ServiceName,
                };

                patchingServerRequest.push(newRequest);
            });

            const request = httpService.post({
                url: "api/PatchingJob/addAdhocJob",
                token: state.AuthStore.Token,
                data: {
                    reboot: reboot,
                    patchingServerRequest: patchingServerRequest,
                    lastUpdatedTimeZone: state.AuthStore.timezone?.standardName
                },
            });

            patchServersRequests.push(request);
        });

        const trackingString: string = Array.from(serversMap.values()).flat().map((x: IPatchingServerRequest) => x.ServerId.toString()).join(",");

        await Promise.all(patchServersRequests).then((resp: any | any[]) => {
            trackEvent(appInsights, "Manage Jobs", "Ad Hoc Patch Servers", "Ad Hoc Patch Servers: " + trackingString, state.AuthStore, {});
            SetPatchServersSubtext("Server(s) patch requested. Please click COMPLETE");
            SetPrimaryButtonText("COMPLETE");

            const newResultsData: IPatchNowPanelType[] = [];

            patchingServerRequest.forEach((x: IPatchingServerRequest) => {
                const serverData: IPatchNowPanelType = {
                    ServerName: x.ServerName,
                    ServerId: x.ServerId,
                    ServiceName: x.ServiceName,
                    ServiceTreeGUID: x.ServiceTreeGUID,
                    Notes: "Patch Request Sent"
                };

                newResultsData.push(serverData);
            });

            SetResultsData(newResultsData);

            SetCurrentlyPatchingServers(false);
        }).catch((error: any | any[]) => {
            console.log(error);
            SetPatchServersSubtext("Something went wrong");
            trackException(appInsights, error, SeverityLevel.Error, "Manage Jobs", "Ad Hoc Patch Servers", "Clicking Ad Hoc Patch Servers", state.AuthStore, {});

            const newResultsData: IPatchNowPanelType[] = [];

            patchingServerRequest.forEach((x: IPatchingServerRequest) => {
                const serverData: IPatchNowPanelType = {
                    ServerName: x.ServerName,
                    ServerId: x.ServerId,
                    ServiceName: x.ServiceName,
                    ServiceTreeGUID: x.ServiceTreeGUID,
                    Notes: "Something went wrong - " + error,
                };

                newResultsData.push(serverData);
            });

            SetResultsData(newResultsData);

            SetCurrentlyPatchingServers(false);
        });
    }

    const clickToOpenPanel = () => {
        SetPatchServersSubtext("Are you sure you want to patch these servers?");
        SetCurrentPanel(PanelSection.Patch);
        openPanel();
    };

    const clickToClosePanel = () => {
        props.refreshDataGrid();
        SetCurrentlyPatchingServers(false);
        SetCurrentPanel(PanelSection.Patch);
        SetPrimaryButtonText(patchNowText);
        dismissPanel();
    };

    const lightDismissPanel = () => {
        dismissPanel();
    };

    const onRebootChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, ischecked?: boolean): void => {
        if (ischecked !== undefined) {
            SetReboot(ischecked);
        }
    };

    const resultsColumns: IColumn[] = [
        {
            key: "ServerName",
            name: "Server Name",
            fieldName: "ServerName",
            minWidth: 70,
            maxWidth: 150,
            isResizable: true,
        },
        {
            key: "Service",
            name: "Service",
            fieldName: "ServiceName",
            minWidth: 70,
            maxWidth: 135,
            isResizable: true,
        },
        {
            key: "Notes",
            name: "Notes",
            fieldName: "Notes",
            minWidth: 100,
            maxWidth: 135,
            isResizable: true,
            onRender(item: IPatchNowPanelType, index, column) {
                return <TooltipHost
                    content={item.Notes}
                    calloutProps={{ gapSpace: 0 }}
                >
                    <span>{item.Notes}</span>
                </TooltipHost>;
            },
        },
    ];

    const disablePatchNow = (): boolean => {
        return !(props.serversToBePatched.length > 0 && patchNowDateAllowed);
    }

    return (
        <div>
            <TooltipHost
                content={patchgNowButtonToolTip}
                calloutProps={calloutProps}
                styles={hostStyles}
            >
                <PrimaryButton
                    disabled={disablePatchNow()}
                    onClick={clickToOpenPanel}
                    ariaLabel={"Patch Selected Servers Now!"}
                    ariaDescription={"Patch Selected Servers Now!"}
                    text={patchNowText} />
            </TooltipHost>
            <CoherencePanel
                titleText={patchNowText}
                title={patchNowText}
                headerText={patchNowText}
                isOpen={isOpen}
                hasCloseButton={true}
                onDismiss={clickToClosePanel}
                onLightDismissClick={lightDismissPanel}
                closeButtonAriaLabel="Close"
                onRenderFooter={{
                    primaryButton: {
                        text: primaryButtonText,
                        onAction: (() => {
                            if (primaryButtonText === patchNowText) {
                                SetCurrentPanel(PanelSection.Results);
                                clickToPatchServers();
                            } else {
                                if (!currentlyPatchingServers) {
                                    clickToClosePanel();
                                }
                            }
                        }),
                        disabled: (dataGridData?.length === 0),
                    },
                }}
                panelSize={CoherencePanelSize.large}
            >
                <main>
                    <div hidden={currentPanel !== PanelSection.Patch}>
                        <div style={{ padding: "5px", alignSelf: "bottom" }}>
                            <TooltipHost
                                content="If selected, your server(s) will reboot during the patch window, if needed."
                                id={useId("tooltip")}
                                calloutProps={{ gapSpace: 0, directionalHint: DirectionalHint.leftCenter }}
                            >
                                <Checkbox
                                    onChange={onRebootChange}
                                    label="Allow Reboot?"
                                    ariaLabel="Allow Reboot?"
                                    aria-required="false"
                                    checked={reboot}
                                />
                            </TooltipHost>
                        </div>
                        <div style={{ position: "relative", height: "95%" }}>
                            <ScrollablePane style={{ height: "400px", position: "relative" }}>
                                <DetailsList
                                    columns={resultsColumns}
                                    items={dataGridData}
                                    compact={true}
                                    selectionMode={SelectionMode.none}
                                    onRenderDetailsHeader={RenderStickyHeader}
                                />
                            </ScrollablePane>
                        </div>
                        <p>{patchServersSubtext}</p>
                    </div>
                    <div hidden={currentPanel !== PanelSection.Results}>
                        <h3>Server Patching Results</h3>
                        <div hidden={!currentlyPatchingServers}>
                            <Spinner label="Patching Servers..." ariaLive="assertive" labelPosition="bottom" />
                        </div>
                        <div hidden={currentlyPatchingServers}>
                            <div style={{ position: "relative", height: "75%" }}>
                                <ScrollablePane style={{ height: "400px", position: "relative" }}>
                                    <DetailsList
                                        columns={resultsColumns}
                                        items={resultsData}
                                        compact={true}
                                        selectionMode={SelectionMode.none}
                                        onRenderDetailsHeader={RenderStickyHeader}
                                    />
                                </ScrollablePane>
                            </div>
                        </div>
                    </div>
                </main>
            </CoherencePanel>
        </div>
    );
};
