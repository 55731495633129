import { Announced, DetailsListLayoutMode, Dropdown, IColumn, IconButton, IDetailsList,
    IDropdownOption, IDropdownStyles, ISliderStyles, IStackTokens, ITextFieldStyles, mergeStyles,
    ScrollablePane, Selection, SelectionMode, ShimmeredDetailsList, Slider, Stack, TextField, TooltipHost } from "@fluentui/react";
import { Guid } from "guid-typescript";
import * as React from "react";
import { IServiceDataType } from "../../App";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IManageJobsProps, IPanelOptions } from "../../Pages/ManageJobsPage";
import HttpClient from "../../services/HttpService/HttpClient";
import HttpService from "../../services/HttpService/HttpService";
import { OpenPanelType } from "../../Styles/Page.types";
import { TimeZone } from "../Layout/Header";
import { UnauthorizedMessage } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";
import { BulkAddJobsPanel, IBulkAddJobsPanelProps } from "./BulkAddJobsPanel";
import { DeleteJobsPanel, IDeleteJobsPanelProps } from "./DeleteJobsPanel";
import { IManageJobPanelProps, ManageJobPanel } from "./ManageJobPanel";
import { calloutProps } from "../../Styles/Page.styles";
import { trackException } from "../AppInsights/LoggingHelper";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { OnboardedServicesDropdown } from "../Shared/OnboardedServicesDropdown";
import { CSVLink } from "react-csv";

// Styles //

const textFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: "300px", minWidth: "150px" } };
const sliderStyles: Partial<ISliderStyles> = { root: { maxWidth: "300px", minWidth: "200px" } };
const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { maxWidth: "300px", minWidth: "200px" } };
const dropdownStylesMedium: Partial<IDropdownStyles> = { dropdown: { maxWidth: "220px", minWidth: "150px" } };
const dropdownStylesSmall: Partial<IDropdownStyles> = { dropdown: { maxWidth: "100px", minWidth: "75px" } };

const mainDivStyles = mergeStyles({
    display: "block",
    margin: "auto",
    overflow: "auto"
  });
  
const stackItemsPadding = mergeStyles({
padding: "0px 20px 0px 0px"
});

const ChildClass = mergeStyles({
    display: "block",
    marginBottom: "0px",
});
const stackTokens: IStackTokens = {
    childrenGap: 0 + " " + 0, // vertical gap + ' ' + horizontal gap
};

// Interfaces //

export interface IDetailsListManageJobsItem {
    eTag: string;
    jobId: Guid;
    jobName: string;
    dayOfTheWeek: string;
    weekOfTheMonth: number;
    startTimeHours: number;
    startTimeMinutes: number;
    duration: string;
    recurring: boolean;
    reboot: boolean;
    alwaysReboot: boolean;
    serviceName: string;
    serviceTreeGUID: string;
    serversCount: number;
    isAllServersSelected: boolean;
    lastScheduledDateTime: Date;
    nextScheduledDateTime: Date;
}

interface IDetailsListManageJobsState {
    items: IDetailsListManageJobsItem[];
    allItems: IDetailsListManageJobsItem[];
    selectionDetails: string;
    isOpen: boolean;
    panelOptions: IPanelOptions;
    jobsLoaded: boolean;
    jobFilterValue: string;
    selectedServiceGUID: string | number;
    selectedServiceName: string;
    columns: IColumn[];
    timezone: TimeZone | undefined;
    httpService: HttpClient;
    pendingRebootSelection: number[];
    selectedJobs: IDetailsListManageJobsItem[];
    serversSliderLow: number;
    serversSliderHigh: number;
    serversSliderMax: number;
    selectedDaysOfWeek: string[];
    selectedWeeksOfMonth: number[];
    filtersHidden: boolean;
    announcedItems: JSX.Element | undefined;
    announcedFiltersExpand: JSX.Element | undefined;
}

const exportCsvFileName = "jobsExport";

export class DetailsListManageJobs extends React.Component<IManageJobsProps, IDetailsListManageJobsState> {
    public static contextType: React.Context<any> = RootContext;
    private _selection: Selection;
    private _columns: IColumn[];
    private _root = React.createRef<IDetailsList>();
    private _panelOptions: IPanelOptions;
    private _timeZoneHours: number;
    private _timeZoneDisplay: string;
    private _pendingRebootOptions: IDropdownOption[];

    private _emptyJob: IDetailsListManageJobsItem = {
        eTag: "",
        jobId: Guid.createEmpty(),
        jobName: "",
        duration: "",
        weekOfTheMonth: 0,
        dayOfTheWeek: "",
        startTimeHours: 0,
        startTimeMinutes: 0,
        recurring: true,
        reboot: true,
        alwaysReboot: true,
        serviceName: "",
        serviceTreeGUID: "",
        serversCount: 0,
        isAllServersSelected: false,
        lastScheduledDateTime: new Date(),
        nextScheduledDateTime: new Date(),
    };

    private _noJobs: IDetailsListManageJobsItem = {
        eTag: "",
        jobId: Guid.createEmpty(),
        jobName: "No Jobs Available",
        duration: "",
        weekOfTheMonth: 0,
        dayOfTheWeek: "",
        startTimeHours: 0,
        startTimeMinutes: 0,
        recurring: true,
        reboot: true,
        alwaysReboot: true,
        serviceName: "",
        serviceTreeGUID: "",
        serversCount: 0,
        isAllServersSelected: false,
        lastScheduledDateTime: new Date(),
        nextScheduledDateTime: new Date(),
    };

    constructor(props: IManageJobsProps) {
        super(props);

        this._pendingRebootOptions = [
            { key: 0, text: "Pending Reboot" },
            { key: 1, text: "Not Pending Reboot" },
        ];

        this._selection = new Selection({
            onSelectionChanged: () => this.setState({ selectionDetails: this._getSelectionDetails() }),
        });

        const currentDate = new Date();
        this._timeZoneHours = (this.props.timezone?.standardName !== undefined && this.props.timezone?.standardName !== null) ? this.props.timezone?.offset! / 60 : - currentDate.getTimezoneOffset() / 60;
        this._timeZoneDisplay = this._timeZoneHours > 0 ? "+" + this._timeZoneHours.toString() : this._timeZoneHours.toString();

        this._columns = [
            { key: "column1", name: "Job Name", fieldName: "jobName", minWidth: 100, maxWidth: 200, isResizable: true, isSorted: true, onColumnClick: this._onColumnClick },
            { key: "column2", name: "Service Name", fieldName: "serviceName", minWidth: 120, maxWidth: 160, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column3", name: "Edit", fieldName: "Edit", minWidth: 30, maxWidth: 50, isResizable: true },
            { key: "column12", name: "Last Scheduled (UTC" + this._timeZoneDisplay + ")", fieldName: "lastScheduledDateTime", minWidth: 50, maxWidth: 150, isResizable: true, onColumnClick: this._onColumnClick },
            // { key: "column13", name: "Next Scheduled (UTC" + this._timeZoneDisplay + ")", fieldName: "nextScheduledDateTime", minWidth: 50, maxWidth: 150, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column5", name: "Start (UTC" + this._timeZoneDisplay + ")", fieldName: "startTime", minWidth: 60, maxWidth: 80, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column6", name: "Day", fieldName: "dayOfTheWeek", minWidth: 35, maxWidth: 80, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column7", name: "Week", fieldName: "weekOfTheMonth", minWidth: 25, maxWidth: 50, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column4", name: "Duration", fieldName: "duration", minWidth: 40, maxWidth: 70, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column8", name: "Recurring", fieldName: "recurring", minWidth: 40, maxWidth: 80, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column9", name: "Reboot", fieldName: "reboot", minWidth: 40, maxWidth: 70, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column10", name: "Always Reboot", fieldName: "alwaysReboot", minWidth: 40, maxWidth: 70, isResizable: true, onColumnClick: this._onColumnClick },
            { key: "column11", name: "Servers", fieldName: "serversCount", minWidth: 50, maxWidth: 50, isResizable: true, onColumnClick: this._onColumnClick },
        ];

        this._panelOptions = {
            daysOptions: this.props.panelOptions.daysOptions,
            durationOptions: this.props.panelOptions.durationOptions,
            weekOfMonthOptions: this.props.panelOptions.weekOfMonthOptions,
            serverOptions: this.props.panelOptions.serverOptions
        };

        this.state = {
            items: [this._emptyJob],
            allItems: [this._emptyJob],
            selectionDetails: "",
            isOpen: false,
            panelOptions: this._panelOptions,
            jobsLoaded: false,
            jobFilterValue: "",
            selectedServiceName: this.props.selectedService?.ServiceName ? this.props.selectedService?.ServiceName : "",
            selectedServiceGUID: this.props.selectedService?.ServiceTreeGUID ? this.props.selectedService?.ServiceTreeGUID : "", // set from props if it is passed
            columns: this._columns,
            timezone: this.props.timezone,
            httpService: HttpService(props.appInsights),
            pendingRebootSelection: [],
            selectedJobs: [],
            serversSliderLow: 0,
            serversSliderHigh: 0,
            serversSliderMax: 0,
            selectedDaysOfWeek: [],
            selectedWeeksOfMonth: [],
            filtersHidden: true,
            announcedItems: undefined,
            announcedFiltersExpand: undefined,
        };

        this.refreshDataGrid = this.refreshDataGrid.bind(this);
    }

    // only happens once on load
    public componentDidMount() {
        const newItems: IDetailsListManageJobsItem[] = [];

        this.setState({
            items: [],
            allItems: [],
            jobsLoaded: false,
            selectionDetails: this._getSelectionDetails()
        });

        if (newItems.length <= 1) {
            newItems.pop();
        }

        const { selectedServiceGUID } = this.state;

        if (selectedServiceGUID.toString() !== "") {
            this._selection.setItems([]); // reset selection

            this.setState({
                items: [],
                jobsLoaded: false,
            });

            this.fetchAndPopulate(selectedServiceGUID.toString());
        }
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        const { selectedServiceGUID, selectedDaysOfWeek, selectedWeeksOfMonth, 
            pendingRebootSelection, jobFilterValue,
            serversSliderLow, serversSliderHigh } = this.state;
        const { refreshData } = this.props;

        if (refreshData > 0 && prevProps.refreshData !== refreshData && selectedServiceGUID.toString() !== "") {
            this._selection.setItems([]); // reset selection

            this.setState({
                items: [],
                jobsLoaded: false,
            });

            this.fetchAndPopulate(selectedServiceGUID.toString());
        }

        // if any of the filters change
        if (prevState.selectedDaysOfWeek != selectedDaysOfWeek 
            || prevState.selectedWeeksOfMonth != selectedWeeksOfMonth
            || prevState.pendingRebootSelection != pendingRebootSelection
            || prevState.jobFilterValue != jobFilterValue
            || prevState.serversSliderLow != serversSliderLow
            || prevState.serversSliderHigh != serversSliderHigh) {
            this.applyFilters();

            this._announceNumberOfJobs(this.state.items.length);
        }
    }

    public render(): JSX.Element {
        const { items, jobsLoaded, selectedServiceGUID, selectedServiceName,
            pendingRebootSelection, selectedJobs, serversSliderLow, serversSliderHigh, 
            serversSliderMax, selectedDaysOfWeek, selectedWeeksOfMonth, filtersHidden, announcedItems,
            announcedFiltersExpand } = this.state;

        const defaultManageJobPanelProps: IManageJobPanelProps = {
            eTag: "",
            jobName: "",
            dayOfTheWeek: "",
            weekOfTheMonth: 0,
            startTimeHours: 0,
            startTimeMinutes: 0,
            duration: "",
            serviceName: selectedServiceName,
            serviceTreeGUID: selectedServiceGUID.toString(),
            reboot: true,
            alwaysReboot: false,
            recurring: true,
            jobID: Guid.createEmpty(),
            panelOptions: this._panelOptions,
            allServersSelected: false,
            requestType: OpenPanelType.Add, // Add New Job
            hideDialog: true,
            refreshDataGrid: () => this.refreshDataGrid(selectedServiceGUID),
            lastScheduledDateTime: new Date(),
            nextScheduledDateTime: new Date(),
            linkText: "New Job",
        };

        const bulkAddProps: IBulkAddJobsPanelProps = {
            refreshDataGrid: () => this.refreshDataGrid(selectedServiceGUID),
        };
    
        const deleteJobsProps: IDeleteJobsPanelProps = {
            refreshDataGrid: () => this.refreshDataGrid(selectedServiceGUID),
            jobsToBeDeleted: selectedJobs,
        };

        return (
            <div className={mainDivStyles}>
                <OnboardedServicesDropdown
                    id={"serviceDropdown"}
                    serviceTreeGuidFromParam={this.props.selectedService?.ServiceTreeGUID}
                    onSelectService={this._onSelectService}
                />
                <Stack wrap horizontal grow tokens={stackTokens}>
                    <Stack.Item align="end" className={stackItemsPadding}>
                        {/* Add New Job - Displays Button which opens Panel */}
                        <ManageJobPanel {...defaultManageJobPanelProps} />
                    </Stack.Item>
                    <Stack.Item align="end" hidden={!this.context.state?.AuthStore?.isAdmin} className={stackItemsPadding}>
                        <BulkAddJobsPanel {...bulkAddProps} />
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>
                        <IconButton
                            key={"showFilters"}
                            label={"filters " + (filtersHidden ? "hidden" : "shown")}
                            ariaLabel={"filters " + (filtersHidden ? "hidden" : "shown")}
                            aria-live={"polite"}
                            iconProps={{
                                iconName: ("Filter"),
                            }}
                            role={"button"}
                            onClick={this._toggleFilterCollapsible}
                        />
                        {announcedFiltersExpand}
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>
                        <DeleteJobsPanel {...deleteJobsProps} />
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>
                        <TooltipHost
                                content={"Refresh Job Data"}
                                calloutProps={calloutProps}
                            >
                            <IconButton
                                key={"Refresh"}
                                label={"Refresh"}
                                ariaLabel={"Refresh"}
                                iconProps={{
                                    iconName: ("Refresh"),
                                }}
                                role={"button"}
                                onClick={() => this.refreshDataGrid(selectedServiceGUID)}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item align="end" hidden={selectedServiceGUID === ""} className={stackItemsPadding}>
                        <CSVLink 
                            aria-label={"Export Data"} 
                            filename={exportCsvFileName} 
                            title={"Export Data"} 
                            data={items}>
                                Export Data
                        </CSVLink>
                    </Stack.Item>
                </Stack>
                <hr hidden={selectedServiceGUID === ""} />
                <Stack wrap horizontal tokens={stackTokens} verticalAlign="end">
                    <Stack.Item hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                        <TextField
                            role="textbox"
                            className={ChildClass}
                            label="Filter by Job Name:"
                            onChange={this._onFilterJobName}
                            styles={textFieldStyles}
                            ariaLabel="TextField Filter by Job Name:"
                            value={this.state.jobFilterValue}
                            placeholder={"Job Name"}
                        />
                    </Stack.Item>
                    <Stack.Item hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                        <Dropdown
                            label="Pending Reboot"
                            onChange={this._onFilterPendingReboot}
                            styles={dropdownStyles}
                            options={this._pendingRebootOptions}
                            ariaLabel="Pending Reboot Dropdown"
                            selectedKeys={pendingRebootSelection}
                            multiSelect={true}
                        />
                    </Stack.Item>
                    <Stack.Item hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                        <Slider
                            label="Server Count"
                            max={serversSliderMax}
                            ranged
                            styles={sliderStyles}
                            value={serversSliderHigh}
                            lowerValue={serversSliderLow}
                            showValue
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this._onFilterNumberOfServers}
                            valueFormat={(value: number) => `${value}`}
                            ariaValueText={(value: number) => `${value}`}
                            disabled={serversSliderMax <= 0}
                        />
                    </Stack.Item>
                    <Stack.Item hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                        <Dropdown
                            label="Day of Week"
                            onChange={this._onFilterByDayOfWeek}
                            styles={dropdownStylesMedium}
                            options={this._panelOptions.daysOptions}
                            ariaLabel="Day of Week Dropdown"
                            selectedKeys={selectedDaysOfWeek}
                            multiSelect={true}
                        />
                    </Stack.Item>
                    <Stack.Item hidden={selectedServiceGUID === "" || filtersHidden} className={stackItemsPadding}>
                        <Dropdown
                            label="Week of Month"
                            onChange={this._onFilterByWeekOfMonth}
                            styles={dropdownStylesSmall}
                            options={this._panelOptions.weekOfMonthOptions}
                            ariaLabel="Week of Month Dropdown"
                            selectedKeys={selectedWeeksOfMonth}
                            multiSelect={true}
                        />
                    </Stack.Item>
                </Stack>
                <div id="manageJobsDetailsList" hidden={selectedServiceGUID === ""}>
                    {announcedItems}
                    <div style={{ position: "relative", height: "65vh" }}>
                        <ScrollablePane style={{ height: filtersHidden ? "65vh" : "55vh", position: "relative" }}>
                            <ShimmeredDetailsList
                                items={items}
                                columns={this._columns}
                                onRenderItemColumn={this._renderItemColumn.bind(this)}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selection={this._selection}
                                selectionPreservedOnEmptyClick={true}
                                ariaLabelForSelectionColumn="Selection Disabled"
                                ariaLabelForSelectAllCheckbox="Selection Disabled"
                                ariaLabelForGrid="Jobs Table"
                                ariaLabelForShimmer="Job Table Loading"
                                checkButtonAriaLabel="Checkbox to delete job"
                                checkButtonGroupAriaLabel="Checkbox to delete job group"
                                componentRef={this._root}
                                compact={true}
                                selectionMode={SelectionMode.multiple}
                                enableShimmer={!jobsLoaded}
                                onRenderDetailsHeader={RenderStickyHeader}
                            />
                        </ScrollablePane>
                    </div>
                </div>
            </div>
        );
    }

    private _announceNumberOfJobs = (items: number) => {
        this.setState({
            announcedItems: <Announced
                id={"jobCountAnnouncement"}
                message={"Number of Jobs: " + items}
            />}
        );
    }

    private _announceFiltersExpand = (expanded: boolean) => {
        this.setState({
            announcedFiltersExpand: <Announced
                id={"filtersExpandAnnouncement"}
                message={"Filters" + expanded ? " Expanded" : " Collapsed"}
            />}
        );
    }

    public delay(ms: number) {
        return new Promise( (resolve) => setTimeout(resolve, ms) );
    }

    // refresh details list. Used when panel closes and/or page reloads
    public async refreshDataGrid(serviceFilter: string | number) {
        this._selection.setItems([]); // reset selection

        // reset filter field
        this.setState({
            jobFilterValue: "",
            items: [],
            allItems: [],
            jobsLoaded: false,
            selectedServiceGUID: serviceFilter,
        });

        await this.delay(2000);

        this.fetchAndPopulate(serviceFilter);
    }

    private _toggleFilterCollapsible = () => {
        const { filtersHidden } = this.state;

        this.setState({
           filtersHidden: !filtersHidden,
        });

        this._announceFiltersExpand(!filtersHidden);
    }

    private _onSelectService = (service: IServiceDataType) => {
        this._selection.setItems([]); // reset selection

        this.setState({
            jobFilterValue: "",
            items: [],
            allItems: [],
            jobsLoaded: false,
            selectedServiceGUID: service.ServiceTreeGUID,
            selectedServiceName: service.ServiceName,
        });
    
        this.fetchAndPopulate(service.ServiceTreeGUID);
      }

    // Filter by Job Name
    private _onFilterJobName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
        this.setState({
            jobFilterValue: text!,
        });
    }

    // Filter by reboot
    private _onFilterPendingReboot = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        const { pendingRebootSelection } = this.state;

        const newSelection: number[] = [];

        for(const p of this._pendingRebootOptions) {
            if(p.key === option?.key) {
                if(option?.selected) {
                    newSelection.push(Number(p.key));
                }
            } else {
                if (pendingRebootSelection.includes(Number(p.key))) {
                    newSelection.push(Number(p.key));
                }
            }
        }

        this.setState({
            pendingRebootSelection: newSelection,
        });
    }

    // Filter by number of servers
    private _onFilterNumberOfServers = (_: unknown, range: [number, number] | undefined) => {
        if (range !== undefined) {
            this.setState({
                serversSliderLow: range[0],
                serversSliderHigh: range[1],
            });
        }
    }

    // Filter by day of week
    private _onFilterByDayOfWeek = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        const { selectedDaysOfWeek} = this.state;

        const newSelection: string[] = [];

        for(const p of this._panelOptions.daysOptions) {
            if(p.key.toString() === option?.key.toString()) {
                if(option?.selected) {
                    newSelection.push(p.key.toString());
                }
            } else {
                if (selectedDaysOfWeek.includes(p.key.toString())) {
                    newSelection.push(p.key.toString());
                }
            }
        }

        this.setState({
            selectedDaysOfWeek: newSelection,
        });
    }
    // Filter by week of momth
    private _onFilterByWeekOfMonth = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        const { selectedWeeksOfMonth} = this.state;

        const newSelection: number[] = [];

        for(const p of this._panelOptions.weekOfMonthOptions) {
            if(Number(p.key) === Number(option?.key)) {
                if(option?.selected) {
                    newSelection.push(Number(p.key));
                }
            } else {
                if (selectedWeeksOfMonth.includes(Number(p.key))) {
                    newSelection.push(Number(p.key));
                }
            }
        }

        this.setState({
            selectedWeeksOfMonth: newSelection,
        });
    }

    private applyFilters = () => {
        const { allItems, selectedServiceGUID, selectedDaysOfWeek, selectedWeeksOfMonth, pendingRebootSelection, 
            jobFilterValue, serversSliderLow, serversSliderHigh } = this.state;

        // filter by service
        let combinedFilters: IDetailsListManageJobsItem[] = allItems.filter((i) => i.serviceTreeGUID === selectedServiceGUID);

        // week of month filter:
        if (selectedWeeksOfMonth.length > 0) {
            combinedFilters = combinedFilters.filter((i) => 
                selectedWeeksOfMonth.includes(i.weekOfTheMonth));
        }

        // week of month filter:
        if (selectedDaysOfWeek.length > 0) {
            combinedFilters = combinedFilters.filter((i) => 
                selectedDaysOfWeek.includes(i.dayOfTheWeek));
        }

        // number of servers
        combinedFilters = combinedFilters.filter((i) => 
            i.serversCount >= serversSliderLow &&
            i.serversCount <= serversSliderHigh);

        // job name filter
        if (jobFilterValue !== "") {
            combinedFilters = combinedFilters.filter((i) => 
            i.jobName?.toLowerCase().indexOf(jobFilterValue.toLowerCase()) > -1);
        }

        // pending reboot
        if (pendingRebootSelection.length == 1 && pendingRebootSelection.includes(0)) {
            combinedFilters = combinedFilters.filter((i) => i.reboot);
        }
        
        this.setState({
            items: [...new Set(combinedFilters)],
        })
    }

    private fetchAndPopulate = (serviceFilter: string | number) => {
        this._fetchPatchingJobData(serviceFilter.toString()).then((response: any) => {
            let newItems: IDetailsListManageJobsItem[] = [];
            if (response?.data !== UnauthorizedMessage) {
                const data: any = response.data;
                for (const d of data) {
                    newItems = [...newItems, {
                        eTag: d.eTag,
                        jobId: d.jobId as Guid,
                        jobName: d.jobName,
                        dayOfTheWeek: d.dayOfTheWeek,
                        weekOfTheMonth: d.weekOfTheMonth,
                        startTimeHours: d.startTimeHours ?? 0,
                        startTimeMinutes: d.startTimeMinutes ?? 0,
                        duration: d.duration,
                        recurring: Boolean(d.recurring),
                        reboot: Boolean(d.reboot),
                        alwaysReboot: Boolean(d.alwaysReboot),
                        serviceName: d.serviceName,
                        serviceTreeGUID: d.serviceTreeGUID,
                        serversCount: d.patchingServerCount,
                        isAllServersSelected: d.isAllServerSelected,
                        lastScheduledDateTime: new Date((d.lastScheduledDateTime.toString())),
                        nextScheduledDateTime: new Date((d.nextScheduledDateTime.toString())),
                    }];
                }
                
                // No jobs for this service and tell user
                if (data.length == 0) {
                    newItems = [this._noJobs];
                }
            } else {
                newItems = [this._noJobs];
            }

            // get max freeDiskSpace
            const maxServersCount: number = Math.max(...newItems.map((job: IDetailsListManageJobsItem) => job.serversCount));

            this.setState({
                items: newItems,
                allItems: newItems,
                jobsLoaded: true,
                serversSliderMax: maxServersCount,
                serversSliderHigh: maxServersCount > 0 ? maxServersCount : 0
            });

        }).catch((reason: any) => {
            if (this.props.appInsights !== undefined) {
                trackException(this.props.appInsights, reason, SeverityLevel.Error, "Manage Jobs", "fetchPatchingJobData", "fetchPatchingJobData", this.context.state.AuthStore, {});
            }

            this.setState({
                items: [],
                allItems: [],
                jobsLoaded: true,
                serversSliderMax: 0,
                serversSliderHigh: 0,
            });
        });
    }

    // Get Selected Servers as a string
    private _getSelectionDetails(): string {
        const selectionCount: number = this._selection.getSelectedCount();
        const values: string[] = [];
        const selectedJobs: IDetailsListManageJobsItem[] = [];

        for (let i = 0; i < this._selection.getSelectedCount(); i++) {
            selectedJobs.push(this._selection.getSelection()[i] as IDetailsListManageJobsItem);
            values.push((this._selection.getSelection()[i] as IDetailsListManageJobsItem).jobName);
        }

        const allSelectedJobs: string = values.join(", ");

        this.setState({
            selectedJobs: selectedJobs,
        });

        switch (true) {
          case selectionCount === 0:
            return "No jobs selected";
          case selectionCount < 5:
            return "Selected: " + allSelectedJobs;
          default:
            return "Selected: " + `${selectionCount} jobs selected`;
        }
    }

    // Fetch Patching Job Data
    private async _fetchPatchingJobData(serviceFilter: string)  {
        const d = new Date();
        const timezone: TimeZone = this.context.state.AuthStore.timezone;

        const timezoneOffset = (timezone?.offset !== undefined && timezone?.standardName !== null) 
                                ? - timezone?.offset 
                                : d.getTimezoneOffset();
        const response = await this.state.httpService.get({
            url: "api/PatchingJob",
            token: this.context.state.AuthStore.Token,
            params: {
                offset: timezoneOffset,
                Service: serviceFilter,
                timezone: timezone?.standardName
            },
        });

        return response;
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });

        const newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);

        this.setState({
          columns: newColumns,
          items: newItems,
        });
      }

    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    private _formattedDateString(d :Date): string{
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString("en-US", {hour:'2-digit', minute: '2-digit', hour12:false})}`;
    }

    // Stylize column information by column
    private _renderItemColumn(item?: IDetailsListManageJobsItem, index?: number, column?: IColumn) {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IDetailsListManageJobsItem] as string | Date | Guid | number | boolean;
            if ((item.jobId instanceof Guid) && item.jobId.isEmpty()) {
                if (column.fieldName == "jobName") {
                    return  <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{fieldContent}</span>;
                } else { return ""; }
            } else {
                if (item.jobName?.toString().indexOf("Job doesn't exist") !== -1) {
                    if (column.fieldName == "jobName") {
                        if (fieldContent) {
                            return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{fieldContent}</span>;
                        } else { return "Error Loading Job Data"; }
                    } else { return ""; }
                } else {
                    switch (column.fieldName) {
                        case "lastScheduledDateTime": {
                            const dateNow: Date = new Date();
                            const dateString: string = (dateNow.getFullYear() - (fieldContent as Date).getFullYear()) > 5 ? "N/A" : this._formattedDateString(fieldContent as Date);
                            return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{dateString}</span>;
                        }
                        // case "nextScheduledDateTime": {
                        //     const dateNow: Date = new Date();
                        //     const dateString: string = (dateNow.getFullYear() - (fieldContent as Date).getFullYear()) > 5 ? "N/A" : (fieldContent as Date).toLocaleString();
                        //     return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{dateString}</span>;
                        // }
                        case "startTime": {
                            const mins: string = item.startTimeMinutes < 10 ? "0" + item.startTimeMinutes?.toString() : item.startTimeMinutes?.toString();
                            const hrs: string = item.startTimeHours < 10 ? "0" + item.startTimeHours?.toString() : item.startTimeHours?.toString();
                            const startTime: string = hrs + ":" + mins;
                            return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{startTime}</span>;
                        }
                        case "recurring": {
                            if (fieldContent) {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>true</span>;
                            } else {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>false</span>;
                            }
                        }
                        case "reboot": {
                            if (fieldContent) {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>true</span>;
                            } else {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>false</span>;
                            }
                        }
                        case "alwaysReboot": {
                            if (fieldContent) {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>true</span>;
                            } else {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>false</span>;
                            }
                        }
                        case "Edit": {
                            const manageJobPanelProps: IManageJobPanelProps = {
                                eTag: item.eTag,
                                jobID: item.jobId,
                                jobName: item.jobName,
                                dayOfTheWeek: item.dayOfTheWeek,
                                weekOfTheMonth: item.weekOfTheMonth,
                                startTimeHours: item.startTimeHours,
                                startTimeMinutes: item.startTimeMinutes,
                                duration: item.duration,
                                serviceName: item.serviceName,
                                serviceTreeGUID: item.serviceTreeGUID,
                                reboot: item.reboot,
                                alwaysReboot: item.alwaysReboot,
                                recurring: item.recurring,
                                allServersSelected: item.isAllServersSelected,
                                requestType: OpenPanelType.Edit,
                                panelOptions: this.state.panelOptions,
                                hideDialog: true,
                                refreshDataGrid: () => this.refreshDataGrid(item.serviceTreeGUID),
                                lastScheduledDateTime: item.lastScheduledDateTime,
                                nextScheduledDateTime: item.nextScheduledDateTime,
                                linkText: "Edit",
                            };

                            return <ManageJobPanel {...manageJobPanelProps} />;
                        }
                        case "patchOptIn": {
                            if (fieldContent) {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>true</span>;
                            } else {
                                return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>false</span>;
                            }
                        }
                        default:
                            return <span className={mergeStyles({ display: "block", padding: "0px", margin: "0px", minHeight: "18px", maxHeight: "20px" })}>{fieldContent.toString()}</span>;
                    }
                }
            }
        }
    }
}
