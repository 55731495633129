import { AccountInfo } from "@azure/msal-browser";
import { TimeZone } from "../../components/Layout/Header";
import { UserPermission } from "./AuthReducer";

export enum AuthActionEnum {
    START_LOGIN = "START_LOGIN",
    START_LOGOUT = "START_LOGOUT",
    LOG_IN = "LOG_IN",
    LOG_OUT = "LOG_OUT",
    UPDATE_TOKEN = "UPDATE_TOKEN",
    SET_USER_ADMIN = "SET_USER_ADMIN",
    SET_TOGGLE_ADMIN = "SET_TOGGLE_ADMIN",
    SET_TIMEZONE = "SET_TIMEZONE",
    SET_USER_PERMISSION = "SET_USER_PERMISSION",
    SET_USER_TIMEOUT = "SET_USER_TIMEOUT",
    SET_API_ENDPOINT = "SET_API_ENDPOINT",
}

export type AuthActions = |
{
    type: AuthActionEnum.LOG_IN,
    name: string,
    email: string,
    account: AccountInfo,
    token: any,
} | {
    type: AuthActionEnum.LOG_OUT,
} | {
    type: AuthActionEnum.START_LOGOUT,
} | {
    type: AuthActionEnum.START_LOGIN,
} | {
    type: AuthActionEnum.UPDATE_TOKEN,
    token: any,
} | {
    type: AuthActionEnum.SET_USER_ADMIN,
    isAdmin: boolean,
    toggleAdmin: boolean,
} | {
    type: AuthActionEnum.SET_TOGGLE_ADMIN,
    toggleAdmin: boolean,
} | {
    type: AuthActionEnum.SET_TIMEZONE,
    timezone: TimeZone,
} | {
    type: AuthActionEnum.SET_USER_PERMISSION,
    userPermitted: UserPermission,
} | {
    type: AuthActionEnum.SET_USER_TIMEOUT,
    userTimeOut: boolean,
} | {
    type: AuthActionEnum.SET_API_ENDPOINT,
    apiEndpoint: string,
};
