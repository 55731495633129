import { Link, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { ActivityTokenTimeout } from "../../services/ActivityService/ActivityTokenTimeout";
import { UserPermission } from "../../services/AuthenticationService/AuthReducer";
import { UserCallResult } from "../../services/HttpService/HttpClient";
import { getCallResult } from "../../services/HttpService/HttpService";
import { RootContext } from "../Stores/RootStore";
import { AppName } from "./AppConstants";

export interface IPagePermissionsProps {
}

export const PagePermissionsComponent: React.FunctionComponent<IPagePermissionsProps> = (props: IPagePermissionsProps) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [userCallValue, SetUserCallValue] = React.useState<UserCallResult>(UserCallResult.Start);

    React.useEffect(() => {
        const newCallResult: UserCallResult = getCallResult();
        if (newCallResult !== userCallValue) {
            SetUserCallValue(newCallResult);
        }
    }, [getCallResult()]);

    return (
        <div>
            <div hidden={userCallValue === UserCallResult.Error}>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Denied}>
                    <UserDenied />
                </div>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Validating}>
                    <ValidationInProgess />
                </div>
                <div hidden={!(state.AuthStore.UserPermitted === UserPermission.Permitted && state.AuthStore.UserTimeOut)}>
                    <UserIdleTimeOut />
                </div>
                <div hidden={!(state.AuthStore.UserPermitted !== UserPermission.Permitted && state.AuthStore.UserTimeOut)}>
                    <SiteFailed />
                </div>
            </div>
            <ActivityTokenTimeout />
        </div>
    );
};

/* User is not permitted to view application. Needs to be added to Core Identity group or Service Tree */
export const UserDenied: React.FunctionComponent = () => {
    return (
        <p><b>You do not have permission to view this page.</b></p>
    );
};

/* User in validation process */
export const ValidationInProgess: React.FunctionComponent = () => {
    return (
        <div style={{
            position: "absolute", left: "50%", top: "40%",
            transform: "translate(-50%, -50%)"}}
        >
            <Spinner id="gettingThingsReadySpinner" label="Getting things ready..." size={SpinnerSize.large} ariaLive="assertive" />
        </div>
    );
};

/* User has been idle for 30 minutes */
export const UserIdleTimeOut: React.FunctionComponent = () => {
    return (
        <p>
            <i>Authentication has expired. Please </i><Link href={process.env.REACT_APP_URL}>Reload</Link>
        </p>
    );
};

/* Site Failed -- 500 error or API error */
export const SiteFailed: React.FunctionComponent = () => {
    return (
        <p>
            <i>The {AppName} is currently down for maintenance.
                Please return in 30 minutes to 1 hour.
                Thank you for your patience.</i>
        </p>
    );
};
